import { defineStyleConfig } from '@chakra-ui/react';

const TavernCardStyles = defineStyleConfig({
  baseStyle: {
    position: 'relative',
    bg: 'backgroundCard',
    color: 'typographySecondary',
    borderRadius: '0',
    pt: '20px',
    pb: '20px',
    pl: '24px',
    pr: '24px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  sizes: {},
  variants: {
    Tile: {
      flexDirection: 'column',
    },
    List: {
      flexDirection: 'row',
    },
  },
  defaultProps: {
    variant: 'Tile',
  },
});

export default TavernCardStyles;
