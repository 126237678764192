import { useState } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { Flex, Text, HStack } from '@chakra-ui/react';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';
import { SpeakInvite } from '../../types/tavern';
import { acceptInviteToSpeak, rejectInviteToSpeak } from '../../services/invite-functions';

export const RequestBlockInvite = (props: { request: SpeakInvite }) => {
  const { tavernId } = useParams();

  const [acceptIsLoading, setAcceptIsLoading] = useState(false);
  const [rejectIsLoading, setRejectIsLoading] = useState(false);

  return (
    <Flex
      w="100%"
      alignItems={{ base: 'flex-start', xl: 'center' }}
      justifyContent="space-between"
      p="16px"
      bg="backgroundInput"
      flexDirection={{ base: 'column', xl: 'row' }}
    >
      <Flex alignItems={{ base: 'flex-start', xl: 'center' }}>
        <Text color="typographySecondary" mr="6px">
          Invited as
        </Text>
        <Text mr="8px">{_.upperFirst(props.request.type)}</Text>
        <Text color="typographySecondary" mr="6px">
          by
        </Text>
        <Text maxW="106px" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
          {props.request.invitingHostName ?? props.request.invitingHost.id}
        </Text>
      </Flex>
      <HStack mt={{ base: '-8px', xl: '0' }} ml={{ base: '64px', xl: '16px' }} spacing="16px">
        <TavernButton
          isLoading={acceptIsLoading}
          disabled={acceptIsLoading || rejectIsLoading}
          variant="secondary"
          onClick={() => {
            setAcceptIsLoading(true);
            acceptInviteToSpeak(tavernId as string, props.request.id)
              .then(() => {
                setAcceptIsLoading(false);
              })
              .catch((error) => console.error(error));
          }}
        >
          Accept
        </TavernButton>
        <TavernButton
          isLoading={rejectIsLoading}
          disabled={acceptIsLoading || rejectIsLoading}
          variant="tertiary"
          onClick={() => {
            setRejectIsLoading(true);
            rejectInviteToSpeak(tavernId as string, props.request.id)
              .then(() => {
                setRejectIsLoading(false);
              })
              .catch((error) => console.error(error));
          }}
        >
          Reject
        </TavernButton>
      </HStack>
    </Flex>
  );
};
