import { defineStyleConfig } from '@chakra-ui/react';

const TavernUserStyles = defineStyleConfig({
  baseStyle: {
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    _hover: {
      bg: 'backgroundInput',
    },
  },
  sizes: {
    lg: {
      p: {
        '3xl': '24px 11px 8px',
        sm: '16px 11px 8px',
      },
    },
    sm: {
      p: {
        '3xl': '16px 11px',
        sm: '12px 11px',
      },
    },
  },
  variants: {},
  defaultProps: {
    size: 'lg',
  },
});

export default TavernUserStyles;
