/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { useParams } from 'react-router-dom';

import { BlockedSection } from '../TavernPage/BlockedSection/BlockedSection';
import { SessionType } from '../../types/tavern';
import { User, UserMock, UserNoSession } from '../../types/user';
import { db } from '../../connect';
import { getUserImage } from '../../utils/user-util';

export const BlockedUsersList = (props: {
  sessionType: SessionType | undefined;
  setBlockedCount: (count: number) => void;
}) => {
  const [bannedUsers, setBannedUsers] = React.useState<UserMock[]>([]);
  const { tavernId } = useParams();

  useEffect(() => {
    const unsub = onSnapshot(doc(db, 'taverns', tavernId as string), (tavernDoc) => {
      const list = tavernDoc.data()?.banList ?? [];

      const getUsersData = async () => {
        const users: UserMock[] = await Promise.all(
          list.map(async (id: string) => {
            const docSnap = await getDoc(doc(db, 'users', id));
            const data = docSnap.data() as User;
            return asUserNoSession(data);
          })
        );
        setBannedUsers(users);
        props.setBlockedCount(users.length);
      };

      getUsersData().catch(console.error);
    });
    return unsub;
  }, []);

  if (
    props.sessionType === undefined ||
    (props.sessionType !== SessionType.Host && props.sessionType !== SessionType.CoHost)
  ) {
    // Should only be visible to host or cohost.
    return <></>;
  }

  return <BlockedSection sessionType={props.sessionType} users={bannedUsers} />;
};

const asUserNoSession = (user: User): UserNoSession => {
  return {
    id: user.id,
    name: user.name,
    avatar: getUserImage(user),
    sessionType: SessionType.Joiner,
  };
};
