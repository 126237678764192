import { defineStyleConfig } from '@chakra-ui/react';

const ActionStreamButtonStyles = defineStyleConfig({
  baseStyle: {
    height: '48px',
    width: '48px',
    background: 'backgroundButton',
    color: 'typographySecondary',
    backdropFilter: 'blur(24px)',
    _hover: {
      background: 'backgroundButtonHover',
      _active: {
        background: 'brand.240',
      },
    },
    _active: {
      color: 'brandPrimary',
      background: 'brand.160',
    },
    _disabled: {
      opacity: '0.5',
      pointerEvents: 'none',
    },
    svg: {
      width: '24px',
      height: '24px',
    },
  },
  sizes: {},
  variants: {
    leave: {
      background: 'redAlpha.120',
      color: 'accent.red',
      _active: {
        background: 'redAlpha.120',
        color: 'accent.red',
      },
      _hover: {
        background: 'redAlpha.200',
        _active: {
          background: 'redAlpha.120',
        },
      },
    },
  },
  defaultProps: {},
});

export default ActionStreamButtonStyles;
