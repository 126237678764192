import { FC } from 'react';
import { chakra, Flex, Box, Text } from '@chakra-ui/react';
import { EmojiProps } from './types';
import { noop } from '../../../utils/noop';
import { RoundButton } from '../RoundButton/RoundButton';

const EmojiComponent: FC<EmojiProps> = (props) => {
  const { value, text, isClickable, isSelected, onClick = noop, size = 'xs', ...rest } = props;

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      p={text ? '4px 16px 4px 4px' : ''}
      bgColor={text ? 'backgroundInput' : ''}
      borderRadius={text ? '32px' : ''}
    >
      <RoundButton
        fontSize="22px"
        variant="emoji"
        size={size}
        aria-label={value}
        pointerEvents={isClickable ? 'all' : 'none'}
        icon={
          <Flex
            transition=".3s"
            transformOrigin="50% 85%"
            transform={isSelected ? 'scale(2)' : 'none'}
            boxSize="24px"
            justifyContent="center"
            alignItems="center"
          >
            {value}
          </Flex>
        }
        onClick={onClick}
        {...rest}
      />
      {text && (
        <Text ml="10px" textStyle="body16regular">
          {text}
        </Text>
      )}
    </Box>
  );
};

export const Emoji = chakra(EmojiComponent);
