import { useState, useContext, useEffect } from 'react';
import { query, collection, where, onSnapshot, doc } from 'firebase/firestore';
import GlobalPlayerContext from '../../context/global-player-context';
import { IconHandUp } from '../../theme/foundations/customIcons';
import { RequestInviteStatus } from '../../types/tavern';
import { StreamActionButton } from '../uiKit/StreamActionButton/StreamActionButton';
import { TavernTooltip } from '../uiKit/TavernTooltip/TavernTooltip';
import { cancelRequestToSpeak, requestToSpeak } from '../../services/request-functions';
import { db } from '../../connect';
import { useTavernToast } from '../hooks/useTavernToast';

export const RaiseHandButton = () => {
  const toast = useTavernToast();

  const { connectedTavern, userSession } = useContext(GlobalPlayerContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isHandUp, setIsHandUp] = useState(false);

  useEffect(() => {
    if (!connectedTavern || !userSession) {
      return;
    }

    const requests = query(
      collection(db, `taverns/${connectedTavern.id}/requests`),
      where('user', '==', doc(collection(db, 'users'), userSession.user.id)),
      where('status', '==', RequestInviteStatus.Pending)
    );

    const unsub = onSnapshot(requests, (snap) => {
      setIsHandUp(snap.docs.length > 0);
    });

    return unsub;
  }, [connectedTavern, userSession]);

  const handleHandUp = async () => {
    setIsLoading(true);
    if (isHandUp) {
      try {
        await cancelRequestToSpeak(connectedTavern?.id as string);
        toast({
          title: `Cancelled request.`,
          description: 'Your request to speak has been cancelled.',
          status: 'info',
          duration: 2000,
          isClosable: true,
        });
        setIsHandUp(!isHandUp);
      } catch (error) {
        console.error('Error cancelling request to speak: ', JSON.stringify(error));
      }
    } else {
      try {
        await requestToSpeak(connectedTavern?.id as string);
        toast({
          title: `Requested to speak.`,
          description: 'Your request to speak is successful.',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        setIsHandUp(!isHandUp);
      } catch (error: any) {
        if (error.code === 'functions/permission-denied') {
          toast({
            title: `Request error`,
            description: "Can't request speaker role, you may be banned or room is at limit",
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
        console.error('Error inviting user to speak: ', JSON.stringify(error));
      }
    }
    setIsLoading(false);
  };

  return (
    <TavernTooltip label={`${isHandUp ? 'Remove request' : 'Request'} to speak`}>
      <StreamActionButton
        mr="4px"
        isLoading={isLoading}
        isActive={isHandUp}
        aria-label="handUp"
        icon={<IconHandUp w="18px" h="18px" />}
        onClick={() => {
          handleHandUp().catch(console.error);
        }}
      />
    </TavernTooltip>
  );
};
