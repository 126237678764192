import Fuse from 'fuse.js';
import { Box, Grid, GridItem, HStack, Text } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IconSearch } from '../../../theme/foundations/customIcons';
import { GoingSectionProps } from './types';
import { TavernInput } from '../../uiKit/TavernInput/TavernInput';
import { TavernUser } from '../../uiKit/TavernUser/TavernUser';
import { SessionType } from '../../../types/tavern';
import { goingDropdownItems } from '../../uiKit/DropdownItem/DropdownItem';

export const GoingSection: FC<GoingSectionProps> = ({ goingUsers, hasHostingPermission, cardSize = 'lg', ...rest }) => {
  const { tavernId } = useParams();
  const [searchQuery, setSearchQuery] = useState('');

  const fuse = new Fuse(goingUsers || [], {
    includeScore: false,
    keys: ['name', 'id', 'nft'],
  });

  const results = fuse.search(searchQuery);
  const filteredGoingUsers = searchQuery ? results.map((r) => r.item) : goingUsers;

  return (
    <Box {...rest}>
      <Box mb="24px">
        <HStack justifyContent="flex-start">
          <TavernInput
            leftIcon={<IconSearch />}
            value={searchQuery}
            placeholder="Search by name"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </HStack>
      </Box>
      <Grid pt="16px" pb="34px" autoFlow="row dense" templateColumns="repeat(auto-fill, 152px)" gap="24px">
        {!filteredGoingUsers.length && <Text opacity={0.5}>No Results</Text>}
        {filteredGoingUsers.map((going) => {
          return (
            <GridItem key={going.id}>
              <TavernUser
                hideScore
                size={cardSize}
                user={{ ...going, sessionType: SessionType.Joiner }}
                sessionType={hasHostingPermission ? SessionType.Host : SessionType.Listener}
                dropdownItems={goingDropdownItems(going.id, tavernId as string)}
              />
            </GridItem>
          );
        })}
      </Grid>
    </Box>
  );
};
