import { Avatar, Box, Flex, Grid, GridItem, Text, useColorModeValue } from '@chakra-ui/react';
import React, { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import GlobalPlayerContext from '../../context/global-player-context';
import { TavernUserCountsStack } from '../tavern-user-counts-stack';
import { TavernTooltip } from '../uiKit/TavernTooltip/TavernTooltip';
import { TavernPanelProps } from './types';

import { MicrophoneSelect } from '../MicrophoneSelect/MicrophoneSelect';
import { OutputSelect } from '../MicrophoneSelect/OutputSelect';
import { useAudioDevice } from '../../hooks/useAudioDevice';

export const TavernPanel: FC<TavernPanelProps> = ({
  title,
  avatar,
  isMicSelectEnabled,
  children,
  isMultilineStats,
}) => {
  const navigate = useNavigate();
  const { isDevicesSupported } = useAudioDevice();

  const { connectedTavern, coHosts, listenerCount, playCount, joiners, speakers } = useContext(GlobalPlayerContext);

  const [highlightTitle, setHighlightTitle] = useState(false);

  const enableReturnToTavern = connectedTavern !== null;

  return (
    <Flex
      flexDirection="column"
      background="gradientPrimary"
      width="100%"
      pt="96px"
      pb={{ base: '24px', xl: '24px' }}
      px={{
        base: '12px',
        md: '14px',
        lg: '16px',
        xl: '24px',
        '2xl': '32px',
        '3xl': '64px',
      }}
      alignItems="flex-end"
      justifyContent="center"
      position="sticky"
      bottom="0"
      pointerEvents="none"
      zIndex="3"
    >
      <Grid
        width="100%"
        templateColumns={
          isMultilineStats
            ? {
                base: '48px calc(100% - 70px)',
                '3xl': '48px auto auto',
              }
            : {
                base: '48px calc(100% - 70px)',
                xl: '48px auto auto',
              }
        }
        gap="12px 21px"
        pointerEvents="auto"
      >
        <GridItem display="flex" justifyContent="center" alignItems="center">
          <Avatar
            objectFit="cover"
            boxSize="48px"
            borderRadius="50%"
            src={avatar}
            icon={<></>}
            bg={useColorModeValue('white', 'black')}
            border="1px solid white"
          />
        </GridItem>
        <TavernTooltip placement="top-start" label={enableReturnToTavern ? 'Return to Tavern' : undefined}>
          <GridItem
            display="flex"
            flexDirection="column"
            cursor={enableReturnToTavern ? 'pointer' : 'auto'}
            onTouchStart={() => {
              setHighlightTitle(true);
            }}
            onTouchEnd={() => {
              setHighlightTitle(false);
            }}
            onClick={() => {
              if (enableReturnToTavern) {
                navigate(`/taverns/${connectedTavern.id}`);
              }
            }}
          >
            <Text
              textStyle="body16regular"
              mb="4px"
              whiteSpace={{ base: 'nowrap', xl: 'normal' }}
              overflow="hidden"
              textOverflow="ellipsis"
              color={highlightTitle && enableReturnToTavern ? '#873AEA' : 'default'}
            >
              {title}
            </Text>
            <Flex color="typographySecondary">
              <TavernUserCountsStack
                tavern={connectedTavern}
                coHosts={coHosts}
                listenerCount={listenerCount}
                playCount={playCount}
                joiners={joiners}
                speakers={speakers}
              />
            </Flex>
          </GridItem>
        </TavernTooltip>
        <GridItem gridColumn={isMultilineStats ? { base: 'span 2', '3xl': 'auto' } : { base: 'span 2', xl: 'auto' }}>
          {children}
        </GridItem>
      </Grid>

      {isDevicesSupported && (
        <Flex
          flexDirection="row"
          w="100%"
          pointerEvents="auto"
          justifyContent="space-between"
          alignItems="center"
          display={{ base: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'flex' }}
          mt="18px"
        >
          {isMicSelectEnabled && (
            <Flex
              flexDirection="row"
              w="100%"
              pointerEvents="auto"
              justifyContent="flex-start"
              alignItems="center"
              display={{ base: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'flex' }}
            >
              <Box textStyle="body12Regular" mr={{ base: '8px' }}>
                Mic:
              </Box>
              <Box w="auto" flex="0 0 auto" minW="350px">
                <MicrophoneSelect />
              </Box>
            </Flex>
          )}
          <Flex
            flexDirection="row"
            w="100%"
            pointerEvents="auto"
            justifyContent="flex-end"
            alignItems="center"
            display={{ base: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'flex' }}
          >
            <Box textStyle="body12Regular" mr={{ base: '8px' }}>
              Output:
            </Box>
            <Box w="auto" flex="0 0 auto" minW="350px">
              <OutputSelect />
            </Box>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
