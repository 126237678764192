import { useMemo } from 'react';
import { ActionMeta, AsyncSelect, ChakraStylesConfig, OnChangeValue, OptionBase } from 'chakra-react-select';
import { noop } from 'lodash';
import { TavernSelectAsyncProps } from './types';

const defaultComponents = {
  DropdownIndicator: null,
};

// Docs with a list of available props:  https://react-select.com/props#api
export const TavernSelectAsync = <T extends OptionBase, IsMulti extends boolean>({
  components,
  chakraStyles: chakraStylesProps,
  inputIcon,
  onChange = noop,
  onSelect = noop,
  onRemove = noop,
  onPop = noop,
  onClear = noop,
  ...rest
}: TavernSelectAsyncProps<T, IsMulti>) => {
  const chakraStyles: ChakraStylesConfig<T, IsMulti> = {
    group: (provided) => ({
      ...provided,
      color: 'typographySecondary',
      marginBottom: '8px',
    }),
    groupHeading: (provided) => ({
      ...provided,
      paddingLeft: '12px',
      textStyle: 'body12regular',
      background: 'transparent',
    }),
  };

  const componentsMemo = useMemo(() => {
    const c: TavernSelectAsyncProps<T, IsMulti>['components'] = {
      ...defaultComponents,
    };

    return { ...c, ...components };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputIcon, components]);

  const handleChange = (newValue: OnChangeValue<T, IsMulti>, actionMeta: ActionMeta<T>) => {
    switch (actionMeta.action) {
      case 'pop-value':
        if (newValue) {
          onPop(newValue);
        }
        break;
      case 'select-option':
        if (newValue) {
          onSelect(newValue);
        }
        break;
      case 'remove-value':
        if (newValue) {
          onRemove(newValue);
        }
        break;
      case 'clear':
        if (newValue) {
          onClear(newValue);
        }
        break;
      default:
        break;
    }
    onChange(newValue, actionMeta);
  };

  return (
    <AsyncSelect
      chakraStyles={{ ...chakraStyles, ...chakraStylesProps }}
      {...rest}
      onChange={handleChange}
      components={componentsMemo}
    />
  );
};
