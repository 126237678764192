import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useDisclosure } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';

import GlobalPlayerContext from '../../context/global-player-context';
// import TavernDetailContext from '../../context/tavern-detail-context';
import CollectionsContext from '../../context/collections';
import AuthContext from '../../context/auth';
import { IconJoined } from '../../theme/foundations/customIcons';
import { Tavern } from '../../types/tavern';
import { TavernButtonAnime } from '../uiKit/TavernButton/TavernButtonAnime';
import { auth } from '../../connect';
import { joinTavern } from '../../services/tavern-functions';
import { useTavernToast } from '../hooks/useTavernToast';
import { JoinTavernModal } from '../JoinTavernModal/JoinTavernModal';
import { UsersNftCollection } from '../uiKit/NFTCollection/types';
import { noop } from '../../utils/noop';
import { useAudioDevice } from '../../hooks/useAudioDevice';

interface JoinTavernButtonProps {
  tavern: Tavern;
  onSuccess?: () => void;
}

export const JoinTavernButton = (props: JoinTavernButtonProps) => {
  const { tavern, onSuccess = noop } = props;
  const toast = useTavernToast();
  const { selectedDevice, fetchDevices } = useAudioDevice();
  const [searchParams, setSearchParams] = useSearchParams();
  const listenParams = searchParams.get('listen');

  const { setConnectedTavern } = useContext(GlobalPlayerContext);
  // const { coHosts, speakers } = useContext(TavernDetailContext);
  const { userNFTs, user } = useContext(AuthContext);
  const { collections } = useContext(CollectionsContext);

  const [disabled, setDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (listenParams === 'true' && !auth.currentUser?.isAnonymous) {
      setIsLoading(true);

      searchParams.delete('listen');
      setSearchParams(searchParams);

      onOpen();
    }
  }, [listenParams, auth.currentUser]);

  useEffect(() => {
    setDisabled(auth.currentUser === null || tavern.banList.includes(auth.currentUser.uid));
  }, [tavern.banList]);

  // TODO
  const blocked: UsersNftCollection[] = [];

  const isHost = tavern.host.id === user?.id;

  const allowed = _.chain(collections)
    .filter((c) => (_.isEmpty(tavern.joinAllowlist) ? true : _.indexOf(tavern.joinAllowlist, c.id) >= 0))
    .map((c) => {
      const tokens = _.chain(userNFTs)
        .filter((nft) => nft.collection === c.id)
        .map((nft) => {
          return {
            ...nft,
            collection: c,
          };
        })
        .value();
      return {
        ...c,
        tokens,
      };
    })
    .value();

  return (
    <>
      <JoinTavernModal
        allowDefault={_.isEmpty(tavern.joinAllowlist) || isHost}
        allowed={allowed}
        blocked={blocked}
        isVisible={isOpen}
        onClose={() => {
          onClose();
          setIsLoading(false);
        }}
        onChoose={async (selectedNft: string) => {
          onClose();
          const userId = auth.currentUser?.uid ?? '';
          try {
            await fetchDevices();
          } catch (error) {
            toast({
              title: `Connection failed`,
              description: 'Mic permissions blocked',
              status: 'error',
              duration: 10000,
              isClosable: true,
            });
          }
          joinTavern(
            tavern.id,
            // WE ARE GOING TO FORCE MUTE, UNLESS HOST
            // tavern.host.id === userId ||
            //   coHosts.map((session) => session.id).includes(userId) ||
            //   speakers.map((session) => session.id).includes(userId),
            tavern.host.id === userId,
            selectedNft,
            selectedDevice
          )
            .then(() => {
              setConnectedTavern(tavern);
              setIsLoading(false);
              onSuccess();
              toast({
                title: `Joined Tavern.`,
                description: "You've joined the Tavern and can now interact with reactions and more.",
                status: 'success',
                duration: 2000,
                isClosable: true,
              });
            })
            .catch((error) => {
              const hasPermissionDenied = error.toString().includes('Permission');
              setIsLoading(false);
              toast({
                title: `Join failed.`,
                description: hasPermissionDenied
                  ? 'Mic permissions blocked'
                  : 'You were unable to join this Tavern. Please try again later.',
                status: 'error',
                duration: 2000,
                isClosable: true,
              });
            });
        }}
      />
      <TavernButtonAnime
        isLoading={isLoading}
        isDisabled={disabled}
        variant="primary"
        leftIcon={<IconJoined />}
        onClick={() => {
          setIsLoading(true);

          if (auth.currentUser?.isAnonymous) {
            toast({
              title: `Login to join.`,
              description: 'To join this Tavern and interact with others, login at the top left.',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            setIsLoading(false);
            return;
          }

          onOpen();
        }}
      >
        Join
      </TavernButtonAnime>
    </>
  );
};
