import { RouteProps, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from '../../context/auth';

// if not user or user is anonymous, redirects to the main page
export const ProtectedRoute = ({ children }: { children: RouteProps['element'] }) => {
  const navigate = useNavigate();
  const { isAuthStateLoaded, user } = useContext(AuthContext);
  const isAuthenticated = user && !user.isAnon;

  if (!isAuthenticated && !isAuthStateLoaded) {
    return null;
  }

  if (!isAuthenticated && isAuthStateLoaded) {
    navigate('/');
    return null;
  }

  return children ? <>{children}</> : null;
};
