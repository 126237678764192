import React from 'react';
import _ from 'lodash';
import { Avatar, AvatarGroup, Flex } from '@chakra-ui/react';
import { ListenersListWithAvatarsProps } from './types';

const avatarStyle = {
  boxSize: '32px',
  borderColor: 'black.1000',
  textStyle: 'body14regular',
  color: 'gray.400',
  borderWidth: '1px',
};

export const ListenersListWithAvatars = (props: ListenersListWithAvatarsProps) => {
  const { listenerCount, joiners = [], speakers = [], cohosts = [], maxAvatars = 10, ...rest } = props;

  const uniqueAvatars = _.uniqBy([...cohosts, ...speakers, ...joiners], (s) => s.user.id);

  return (
    <Flex {...rest}>
      <AvatarGroup size="32px" spacing="-12px" position="relative">
        {uniqueAvatars.slice(0, maxAvatars).map((listener, i) => (
          <Avatar
            {...avatarStyle}
            zIndex={1 + i}
            name={listener?.name || ''}
            src={listener?.nft ?? listener?.avatar}
            borderColor="backgroundPrimary"
            border="1px solid white"
            bg="black"
          />
        ))}
        <Avatar
          {...avatarStyle}
          padding="0"
          color="typographySecondary"
          background="backgroundAdditional"
          borderColor="backgroundPrimary"
          zIndex={100}
          ignoreFallback
          icon={<></>}
          name=""
        >
          {joiners.length + speakers.length + cohosts.length + listenerCount}
        </Avatar>
      </AvatarGroup>
    </Flex>
  );
};
