export const timestampAsTavernPosition = (timestamp: number, startTime: number) => {
  const time = (timestamp - startTime) / 1000;
  return secondsToFormattedTime(time);
};

export const secondsToFormattedTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const remainingSeconds = Math.floor(seconds - hours * 3600 - minutes * 60);

  const formatted = `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  if (hours > 0) {
    return `${hours.toString().padStart(2, '0')}:${formatted}`;
  }
  return formatted;
};

export const secondsSinceTavernStart = (timestamp: number, startTime: number) => {
  return (timestamp - startTime) / 1000;
};
