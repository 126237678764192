/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import AgoraRTC, { IBufferSourceAudioTrack } from 'agora-rtc-sdk-ng';
import { AudioMixerControls } from '../AudioMixerControls/AudioMixerControls';
import { AudioMixerPlayerProps } from './types';
import { IconDelete } from '../../../theme/foundations/customIcons';
import { PlayPauseButton } from '../PlayPauseButton/PlayPauseButton';
import { TavernSlider } from '../TavernSlider/TavernSlider';
import { auth } from '../../../connect';
import { playSoundEffect, pauseSoundEffect, getTrack, setLooping } from '../../../services/agora-functions';
import GlobalPlayerContext from '../../../context/global-player-context';
import {
  removeExternalAudio,
  tavernFormatDuration,
  updateExternalAudioIsPlaying,
  updateExternalAudioPosition,
} from '../../../utils/player-util';
import { useTavernToast } from '../../hooks/useTavernToast';

export const AudioMixerPlayer = (props: AudioMixerPlayerProps) => {
  const { audio, isSoundboard } = props;
  const toast = useTavernToast();
  const { tavernId } = useParams();

  const { connectedTavern } = useContext(GlobalPlayerContext);

  const updateScrubberRef = useRef<NodeJS.Timeout>();

  const [localTrack, setLocalTrack] = useState<IBufferSourceAudioTrack | null>(getTrack(audio?.url ?? ''));

  const [trackVolume, setTrackVolume] = useState(10);
  const [trackIsLooping, setTrackIsLooping] = useState(false);
  const [isDeletingTrack, setIsDeletingTrack] = useState(false);

  const [trackProgress, setTrackProgress] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [localScrubDuringPlay, setLocalScrubDuringPlay] = useState(false);

  useEffect(() => {
    localTrack?.setVolume(trackVolume);
  }, [trackVolume]);

  useEffect(() => {
    if (localTrack) {
      setLooping(localTrack, trackIsLooping);
    }
  }, [trackIsLooping]);

  useEffect(() => {
    const getTrackEffect = async () => {
      if (!localTrack) {
        const newTrack = await AgoraRTC.createBufferSourceAudioTrack({
          source: audio.url,
        });
        setLocalTrack(newTrack);
        setTotalDuration(newTrack.duration);
        setTrackProgress(audio?.position ?? 0);
      }
    };
    getTrackEffect().catch(console.error);
  }, [localTrack]);

  useEffect(() => {
    // When user is NOT the one driving audio playback, update
    // scrubber position based on server position.
    if (isPlaying && localTrack?.currentState !== 'playing') {
      setTrackProgress(audio?.position ?? 0);
    }

    if (!isSoundboard) {
      setIsPlaying(audio?.isPlaying ?? false);
    }
  }, [audio, isPlaying]);

  useEffect(() => {
    const pausedByOtherHost = audio.lastControllingUser !== auth.currentUser?.uid && !isPlaying;
    if (pausedByOtherHost && !isSoundboard) {
      pauseSoundEffect(audio.url).catch(console.error);
    }
  }, [isPlaying, audio.lastControllingUser]);

  const startTimer = () => {
    if (!isPlaying || localTrack?.currentState !== 'playing') {
      return;
    }

    clearInterval(updateScrubberRef.current);

    // When user IS driving audio playback, update local scrubber
    // and server position.
    updateScrubberRef.current = setInterval(() => {
      setTrackProgress(localTrack?.getCurrentTime() ?? 0);

      if (localTrack?.currentState === 'stopped') {
        setIsPlaying(false);
      }

      if (!isSoundboard) {
        updateExternalAudioPosition(localTrack?.getCurrentTime() ?? 0, audio.id, tavernId as string).catch(
          console.error
        );
      }
    }, 100);
  };

  const playClicked = async () => {
    if (!audio || !audio.url) {
      console.log('Missing audio file.');
      toast({
        title: 'Missing Audio File',
        description: 'Remote audio file not found',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    if (!isPlaying) {
      const trackSound = await playSoundEffect(audio.url);

      trackSound.seekAudioBuffer(isSoundboard ? 0 : audio.position);
      setTrackProgress(isSoundboard ? 0 : audio.position);

      if (connectedTavern && connectedTavern.id === tavernId && !isSoundboard) {
        await updateExternalAudioPosition(trackSound?.getCurrentTime() ?? 0, audio.id, tavernId);
        await updateExternalAudioIsPlaying(true, audio.id, tavernId);
      }

      if (isSoundboard) {
        setIsPlaying(true);
      }

      setLocalTrack(trackSound);
      setTotalDuration(trackSound.duration);
    } else {
      if (connectedTavern && connectedTavern.id === tavernId && !isSoundboard) {
        await updateExternalAudioIsPlaying(false, audio.id, tavernId);
      }

      if (isSoundboard) {
        setIsPlaying(false);
      }

      await pauseSoundEffect(audio.url);
    }
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(updateScrubberRef.current);
    };
  }, [tavernId, audio, localTrack, isPlaying]);

  const onChangeStart = async () => {
    clearInterval(updateScrubberRef.current);
    if (isPlaying) {
      setLocalScrubDuringPlay(true);
    }
    if (audio.url) {
      await pauseSoundEffect(audio.url);
      await updateExternalAudioIsPlaying(false, audio.id, tavernId as string);
    }
  };

  const onChangeEnd = async () => {
    startTimer();

    if ((isPlaying || localScrubDuringPlay) && audio.url) {
      setLocalScrubDuringPlay(false);
      await playSoundEffect(audio.url);
      await updateExternalAudioIsPlaying(true, audio.id, tavernId as string);
    }
  };

  const onChange = async (value: number) => {
    setTrackProgress(value);
    if (localTrack) {
      localTrack.seekAudioBuffer(value);
      await updateExternalAudioPosition(value, audio.id, tavernId as string);
    }
  };

  const onDelete = async () => {
    setIsDeletingTrack(true);
    await pauseSoundEffect(audio.url);
    await removeExternalAudio(tavernId as string, audio.id);
    setIsDeletingTrack(false);
  };

  return (
    <Flex alignItems="center" minH="35px" width="100%" minWidth="0">
      <PlayPauseButton
        isPlaying={isPlaying}
        onClick={() => {
          playClicked().catch(console.error);
        }}
        isDisabled={!connectedTavern || connectedTavern.id !== tavernId}
        size="xs"
      />
      <Box w="100%" minWidth="0px" m="0 8px">
        <Text
          textStyle="body14regular"
          w="100%"
          color="typographyPrimary"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {audio.name}
        </Text>

        {!isSoundboard && (
          <Flex alignItems="center">
            <Text textStyle="body10regular" color="typographySecondary">
              {tavernFormatDuration(trackProgress)}
            </Text>

            <Box w="100%" m="0 12px">
              <TavernSlider
                max={totalDuration}
                value={trackProgress}
                onChangeStart={() => {
                  onChangeStart().catch(console.error);
                }}
                onChangeEnd={() => {
                  onChangeEnd().catch(console.error);
                }}
                onChange={(value) => {
                  onChange(value).catch(console.error);
                }}
              />
            </Box>

            <Text textStyle="body10regular" color="typographySecondary">
              -{tavernFormatDuration(totalDuration - trackProgress)}
            </Text>
          </Flex>
        )}
      </Box>
      {!isSoundboard && (
        <Flex ml="8px">
          <AudioMixerControls
            volume={trackVolume}
            onVolumeChange={setTrackVolume}
            onLoopClick={() => setTrackIsLooping(!trackIsLooping)}
            isLooping={trackIsLooping}
          />
          <IconButton
            isLoading={isDeletingTrack}
            minWidth="24px"
            boxSize="24px"
            variant="link"
            aria-label="delete"
            _hover={{
              svg: {
                color: 'accentDestructive',
              },
            }}
            onClick={() => {
              onDelete().catch(console.error);
            }}
          >
            <IconDelete color="typographySecondary" fontSize="24px" />
          </IconButton>
        </Flex>
      )}
    </Flex>
  );
};
