import { Stack } from '@chakra-ui/react';
import { useContext, useMemo } from 'react';

import GlobalPlayerContext from '../../context/global-player-context';
import TavernDetailContext from '../../context/tavern-detail-context';
import { Session, SessionType } from '../../types/tavern';
import { SpeakersSection } from '../TavernPage/SpeakersSection/SpeakersSection';
import { sessionAsUserMock } from './utils/list-util';
import { sessionsFilteredForReplay } from '../../utils/session-util';

export const SpeakersList = (props: { displayLiveData?: boolean }) => {
  const { displayLiveData = true } = props;

  const { connectedTavern, userSession, recordingProgressTimestamp, audioClips } = useContext(GlobalPlayerContext);
  const { hosts, coHosts, speakers, maxSpeakers } = useContext(TavernDetailContext);

  const sessions = useMemo(() => {
    let returnSessions: Session[] = [];
    returnSessions = hosts;
    returnSessions = sessionsFilteredForReplay(
      [...returnSessions, ...coHosts, ...speakers],
      displayLiveData,
      recordingProgressTimestamp,
      0
    );

    return returnSessions;
  }, [hosts, coHosts, speakers, displayLiveData, recordingProgressTimestamp]);

  const activelySpeakingUserIds = useMemo(() => {
    if (connectedTavern === null) {
      return [];
    }
    if (displayLiveData || recordingProgressTimestamp === null) {
      return sessions.filter((session) => session.isSpeaking).map((session) => session.id);
    }

    return audioClips
      .filter((clip) => {
        return (
          clip.startTime <= recordingProgressTimestamp &&
          (clip.endTime === undefined || clip.endTime > recordingProgressTimestamp)
        );
      })
      .map((clip) => clip.session.id);
  }, [displayLiveData, recordingProgressTimestamp, audioClips, connectedTavern, sessions]);

  const sessionsAsUserMock = useMemo(() => {
    return sessions.map((session) => {
      if (displayLiveData) {
        return sessionAsUserMock(session, activelySpeakingUserIds.includes(session.id));
      }

      // We don't have timed data on mute/unmute, so for now, show all speakers
      // during replay as unmuted.
      if (recordingProgressTimestamp === null) {
        return sessionAsUserMock({ ...session, isMuted: false }, false);
      }
      return sessionAsUserMock({ ...session, isMuted: false }, activelySpeakingUserIds.includes(session.id));
    });
  }, [activelySpeakingUserIds, displayLiveData, recordingProgressTimestamp, sessions]);

  return (
    <Stack width="100%">
      <SpeakersSection
        maxSpeakers={maxSpeakers}
        sessionType={userSession?.type ?? SessionType.Listener}
        speakers={sessionsAsUserMock}
      />
    </Stack>
  );
};
