import { Box, Flex, IconButton } from '@chakra-ui/react';
import { noop } from 'lodash';

import { AudioControlsPopover } from '../AudioControlsPopover/AudioControlsPopover';
import { AudioMixerControlsProps } from './types';
import { IconLoop, IconVolumeOff, IconVolumeOn } from '../../../theme/foundations/customIcons';

export const AudioMixerControls = (props: AudioMixerControlsProps) => {
  const { onLoopClick = noop, onVolumeClick = noop, onVolumeChange = noop, isLooping = false, isMuted, volume } = props;

  return (
    <Flex alignItems="center">
      <Box w="24px" mr="12px">
        <AudioControlsPopover
          iconOn={<IconVolumeOn color="typographySecondary" boxSize="24px" />}
          iconOff={<IconVolumeOff color="typographySecondary" boxSize="24px" />}
          isMuted={isMuted}
          volume={volume}
          onVolumeClick={onVolumeClick}
          onVolumeChange={onVolumeChange}
        />
      </Box>

      <Box boxSize="24px" mr="12px">
        <IconButton onClick={onLoopClick} minWidth="24px" boxSize="24px" variant="link" aria-label="loop">
          <IconLoop
            _hover={{ color: 'typographyPrimary' }}
            color={isLooping ? 'typographyPrimary' : 'typographySecondary'}
            fontSize="24px"
          />
        </IconButton>
      </Box>
    </Flex>
  );
};
