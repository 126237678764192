import React, { useMemo } from 'react';
import _ from 'lodash';
import { Box, chakra, HStack, MenuButton, MenuItem, MenuList, Text, Flex } from '@chakra-ui/react';
import { IconLock } from '../../theme/foundations/customIcons';
import { AllowedNftCollectionsProps } from './types';
import { TavernDropdown } from '../uiKit/TavernDropdown/TavernDropdown';
import { SelectOptionWithImage } from '../uiKit/SelectOptionWithImage/SelectOptionWithImage';

/**
 * List of collections that allowed to connect to Tavern
 * @param props
 */
const AllowedNftCollectionsComponent = (props: AllowedNftCollectionsProps) => {
  const { collections, maxVisible = 999, ...rest } = props;

  const visibleCollections = useMemo(() => {
    return [...collections].slice(0, maxVisible);
  }, [collections, maxVisible]);

  const remainingCollections = useMemo(() => {
    if (collections.length - maxVisible < 0) {
      return [];
    }
    return [...collections].slice(maxVisible);
  }, [collections, maxVisible]);

  return (
    <Box {...rest}>
      <HStack flexWrap="wrap" spacing="0" textStyle="body12regular">
        <HStack spacing="6px" color="typographySecondary" alignItems="center" mr="6px">
          <IconLock /> <Box>{collections.length ? 'Only for:' : 'For everyone'}</Box>
        </HStack>
        {visibleCollections.map((collection, i) => (
          <Flex key={i}>
            <Text as="span" color="typographyAdditional">
              {_.get(collection, 'name', '')}
            </Text>
            {remainingCollections.length || i < visibleCollections.length - 1 ? (
              <Text as="span" textStyle="typographyTertiary" mr="2px">
                ,
              </Text>
            ) : null}
          </Flex>
        ))}
        {remainingCollections.length > 0 && (
          <TavernDropdown>
            <MenuButton>
              <Text textStyle="body12regular" as="span" color="brandPrimary" aria-label="show-nft-collections">
                +{remainingCollections.length}
              </Text>
            </MenuButton>
            <MenuList>
              {remainingCollections.map((collection) => (
                <MenuItem key={collection.id}>
                  <SelectOptionWithImage image={collection.image} label={collection.name} />
                </MenuItem>
              ))}
            </MenuList>
          </TavernDropdown>
        )}
      </HStack>
    </Box>
  );
};

export const AllowedNftCollections = chakra(AllowedNftCollectionsComponent);
