import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { query, collection, onSnapshot } from 'firebase/firestore';
import { getDownloadURL, ref, StorageReference } from 'firebase/storage';

import { AudioMixerCard } from '../AudioMixerCard/AudioMixerCard';
import { DraggableAudioListProps } from './types';
import { DraggableList } from '../uiKit/DraggableList/DraggableList';
import { ExternalAudio } from '../../types/audio';
import { db, storage } from '../../connect';

export const DraggableAudioList = (props: DraggableAudioListProps) => {
  const { isDraggable, onChange, onRemove, tavernId } = props;

  const [externalAudio, setExternalAudio] = useState<ExternalAudio[]>([]);

  const renderItem = (item: ExternalAudio) => {
    return <AudioMixerCard key={item.id} audioFile={item} onRemove={() => onRemove(item.id)} />;
  };

  useEffect(() => {
    const externalAudioQuery = query(collection(db, `taverns/${tavernId as string}/externalAudio`));

    const unsub = onSnapshot(externalAudioQuery, (querySnapshot) => {
      const externalAudioData = querySnapshot.docs.map((d) => d.data() as ExternalAudio);
      const setUrls = async () => {
        await Promise.all(
          externalAudioData.map(async (audio) => {
            const storageRef: StorageReference = ref(
              storage,
              `taverns/${tavernId as string}/externalAudio/${audio.name}`
            );
            audio.url = await getDownloadURL(storageRef);
            return audio;
          })
        );
        setExternalAudio(externalAudioData);
      };
      setUrls().catch(console.error);
    });
    return unsub;
  }, []);

  return (
    <Box>
      <DraggableList<ExternalAudio>
        onChange={onChange}
        isDraggableDisabled={!isDraggable}
        renderItem={renderItem}
        items={externalAudio}
      />
    </Box>
  );
};
