import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { SearchEmojiSelect } from '../SearchEmojiSelect/SearchEmojiSelect';
import { SearchEmojiSelectPopoverProps } from './types';

export const SearchEmojiSelectPopover = (props: SearchEmojiSelectPopoverProps) => {
  const { onClose, onSelect, triggerElement, width = 'auto', selectProps = {} } = props;
  return (
    <Popover {...props} isLazy lazyBehavior="keepMounted" closeOnBlur onClose={onClose}>
      {triggerElement && <PopoverTrigger>{triggerElement}</PopoverTrigger>}
      <PopoverContent width={width} mb="6px">
        <SearchEmojiSelect {...selectProps} menuIsOpen onSelect={onSelect} />
      </PopoverContent>
    </Popover>
  );
};
