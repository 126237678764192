/* eslint-disable */
import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import checkIcon from '../../../assets/checkIcon.svg';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const TavernCheckboxStyles = defineMultiStyleConfig({
  baseStyle: {
    control: {
      width: '20px',
      height: '20px',
      borderRadius: 0,
      border: 'none',
      background: 'backgroundTertiary',
      backgroundImage: 'none',
      _checked: {
        background: 'brandPrimary',
        _hover: {
          background: 'brandPrimary',
        },
      },
      _disabled: {
        opacity: '0.5',
        background: 'backgroundInputHover',
        _checked: {
          background: 'brandPrimary',
        },
      },
      _hover: {
        background: 'backgroundHover',
      },
    },
    icon: {
      polyline: {
        display: 'none',
      },
      backgroundImage: checkIcon,
      width: '13px',
      height: '10px',
    },
  },
});

export default TavernCheckboxStyles;
