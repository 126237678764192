import { VStack, Image, Text, Flex, Avatar } from '@chakra-ui/react';
import { FC } from 'react';
import { NFTCardProps } from './types';

export const NFTCard: FC<NFTCardProps> = ({ nft, onClick, isSmall, isChecked }) => {
  return (
    <VStack cursor="pointer" spacing={0} onClick={() => onClick && onClick(nft)}>
      {isSmall ? (
        <Avatar
          w={{ base: '80px', lg: '96px' }}
          h={{ base: '80px', lg: '96px' }}
          margin={{ base: '24px 10px 8px 10px', lg: '24px 32px 16px' }}
          src={nft.image}
          border={isChecked ? '1px solid' : 'none'}
          _dark={{ borderColor: 'brandPrimary' }}
        />
      ) : (
        <Image
          w={['102px', '108px', '139px', '168px', '240px']}
          h="auto"
          src={nft.image}
          mb={['8px', '16px']}
          alt="nft"
        />
      )}
      <Flex w="100%" justifyContent={isSmall ? 'center' : 'flex-start'}>
        <Text color={isChecked ? 'brandPrimary' : 'typographyPrimary'} textStyle="body14regular" textAlign="center">
          {`${nft.name ?? nft.tokenId}`}
        </Text>
      </Flex>
    </VStack>
  );
};
