import { useNavigate } from 'react-router-dom';

import { TavernButton } from '../uiKit/TavernButton/TavernButton';

export const GoToTavernButton = (props: { tavernId: string }) => {
  const navigate = useNavigate();
  return (
    <TavernButton variant="primary" onClick={() => navigate(`/taverns/${props.tavernId}`)} minW="200px">
      Go to Tavern
    </TavernButton>
  );
};
