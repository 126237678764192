/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// import CollectionsContext from '../context/collections';
import UserContext from '../context/user';
import { FollowerFollowingList } from '../components/FollowerFollowingList/FollowerFollowingList';
import { IconAsterisk, IconJoined, IconTavernLogo, IconLive, IconCalendar } from '../theme/foundations/customIcons';
import { NFTinfoModal } from '../components/NFTInfoModal/NFTinfoModal';
// import { NFTsList } from '../components/NFTsList/NFTsList';
import { NftData } from '../components/NFTInfoModal/types';
import { ProdUser, User, UserNft } from '../types/user';
import { Tavern, TavernStatus } from '../types/tavern';
import { TavernCardLive } from '../components/TavernCard/TavernCardLive';
import { TavernsSection } from '../components/ProfilePage/TavernsSection/TavernsSection';
import { UserDetails } from '../components/UserDetails/UserDetails';
// import { UsersNftCollection } from '../components/uiKit/NFTCollection/types';
import {
  tavernsQuery,
  userLiveTavernSubscription,
  userSubscription,
  userTavernsWithRemindersSubscription,
  userCreatedTavernsSubscription,
  userScoresSubscription,
} from '../utils/tavern-util';
import { usersFollowersSubscription, usersFollowingSubscription } from '../utils/user-util';
import AuthContext from '../context/auth';

export const ProfileDetail = () => {
  const navigate = useNavigate();
  const { uid } = useParams();

  // const { collections } = useContext(CollectionsContext);
  const { user: currentUser } = useContext(AuthContext);

  const [user, setUser] = useState<ProdUser | null>(null);
  const [nft, setNft] = useState<NftData | null>(null);
  const [liveTavern, setLiveTavern]: any = useState(null);
  const [userNFTs, setUserNFTs] = useState<UserNft[]>([]);

  const [userTavernIds, setUserTavernIds] = useState<string[]>([]);
  const [userTaverns, setUserTaverns] = useState<Tavern[]>([]);

  const [userCreatedTavernIds, setUserCreatedTavernIds] = useState<string[]>([]);
  const [userCreatedTaverns, setUserCreatedTaverns] = useState<Tavern[]>([]);

  const [userScheduledTavernIds, setUserScheduledTavernIds] = useState<string[]>([]);
  const [userScheduledTaverns, setUserScheduledTaverns] = useState<Tavern[]>([]);

  const [userScores, setUserScores] = useState<number[]>([]);

  const [usersFollowers, setUsersFollowers] = useState<User[]>([]);
  const [usersFollowing, setUsersFollowing] = useState<User[]>([]);

  useEffect(() => {
    document.title = `Profile`;
  }, []);

  useEffect(() => {
    if (user?.name) {
      document.title = `${user.name} | Profile`;
    }
  }, [user?.name]);

  useEffect(() => {
    if (!uid) {
      return;
    }

    const unsubUser = userSubscription(uid, setUser, setUserNFTs, setUserTavernIds, () => {
      navigate('/');
    });
    const unsubFollowers = usersFollowersSubscription(uid, setUsersFollowers);
    const unsubReminders = userTavernsWithRemindersSubscription(uid, setUserScheduledTavernIds);
    const unsubCreated = userCreatedTavernsSubscription(uid, setUserCreatedTavernIds);
    const unsubScores = userScoresSubscription(uid, setUserScores);

    return () => {
      unsubUser();
      unsubFollowers();
      unsubReminders();
      unsubCreated();
      unsubScores();
    };
  }, [uid]);

  useEffect(() => {
    if (!user || !user.following) {
      return;
    }
    return usersFollowingSubscription(user.following, setUsersFollowing);
  }, [user?.following]);

  useEffect(() => {
    tavernsQuery(userCreatedTavernIds, setUserCreatedTaverns).catch(console.error);
  }, [userCreatedTavernIds]);

  useEffect(() => {
    tavernsQuery(userScheduledTavernIds, setUserScheduledTaverns).catch(console.error);
  }, [userScheduledTavernIds]);

  useEffect(() => {
    tavernsQuery(userTavernIds, setUserTaverns).catch(console.error);
  }, [userTavernIds]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return userLiveTavernSubscription(user?.lastJoinedLiveTavern, setLiveTavern);
  }, [user?.lastJoinedLiveTavern]);

  // const allowed = _.chain(collections)
  //   .map((c) => {
  //     const tokens = _.chain(userNFTs)
  //       .filter((n) => n.collection === c.id)
  //       .map((n) => {
  //         return {
  //           ...n,
  //           collection: c,
  //         };
  //       })
  //       .value();
  //     return {
  //       ...c,
  //       tokens,
  //     };
  //   })
  //   .value();

  const joinedTaverns = userTaverns.filter((t) => _.indexOf(userCreatedTavernIds, t.id) === -1);
  const goingTaverns = userScheduledTaverns.filter(
    (t) => _.indexOf(userTavernIds, t.id) === -1 && t.status === TavernStatus.Upcoming
  );
  const twitter = user?.provider === 'twitter' ? user.username : '';
  const farcaster = user?.provider === 'farcaster' ? user.username : '';
  const score = _.sum([...userScores, user?.score ?? 0]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        userNFTs,
        setUserNFTs,
        userTaverns,
        setUserTaverns,
      }}
    >
      <VStack width="100%" spacing={8} pb="32px">
        <UserDetails
          {...user}
          id={user?.id as string}
          isOwner={Boolean(currentUser) && currentUser?.id === user?.id}
          name={user?.name as string}
          isProfile
          size="lg"
          isLive={!!liveTavern}
          avatar={user?.nft || user?.avatar}
          nft={user?.nft}
          joiningTimestamp={user?.timestamp}
          twitter={twitter}
          farcaster={farcaster}
          score={score}
        />
        <Box width="100%">{liveTavern && <TavernCardLive {...liveTavern} tavern={liveTavern} isActive />}</Box>
        <Tabs width="100%" mt="32px">
          <TabList>
            <Tab>
              <IconTavernLogo mr="8px" boxSize="16px" />
              Created
              {userCreatedTaverns.length > 0 && (
                <Text ml="8px" color="typographySecondary">
                  {userCreatedTaverns.length}
                </Text>
              )}
            </Tab>
            <Tab>
              <IconLive mr="8px" boxSize="24px" />
              Joined
              {joinedTaverns.length > 0 && (
                <Text ml="8px" color="typographySecondary">
                  {joinedTaverns.length}
                </Text>
              )}
            </Tab>
            {/* <Tab>
              <IconNft mr="8px" boxSize="24px" />
              NFTs
              {userNFTs.length > 0 && (
                <Text ml="8px" color="typographySecondary">
                  {userNFTs.length}
                </Text>
              )}
            </Tab> */}
            <Tab>
              <IconCalendar mr="8px" boxSize="24px" />
              Going
              {goingTaverns.length > 0 && (
                <Text ml="8px" color="typographySecondary">
                  {goingTaverns.length}
                </Text>
              )}
            </Tab>
            <Tab>
              <IconJoined mr="8px" boxSize="20px" />
              Followers
              {usersFollowers.length > 0 && (
                <Text ml="8px" color="typographySecondary">
                  {usersFollowers.length}
                </Text>
              )}
            </Tab>
            <Tab>
              <IconAsterisk mr="8px" boxSize="20px" />
              Following
              {usersFollowing.length > 0 && (
                <Text ml="8px" color="typographySecondary">
                  {usersFollowing.length}
                </Text>
              )}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p="0" pt="32px">
              <TavernsSection taverns={userCreatedTaverns} />
            </TabPanel>
            <TabPanel p="0" pt="32px">
              <TavernsSection taverns={joinedTaverns} />
            </TabPanel>
            {/* <TabPanel p="0" pt="32px">
              <NFTsList
                isSuitable
                collections={allowed}
                setSelectedNft={(id) => {
                  const found: any = _.find(userNFTs, (n) => n.id === id);
                  const collection: UsersNftCollection = _.find(collections, (c) => c.id === found?.collection) || {
                    id: '',
                    name: '',
                  };
                  const userNft: NftData | null = {
                    ...found,
                    collection,
                  };

                  setNft(userNft);
                }}
              />
            </TabPanel> */}
            <TabPanel p="0" pt="32px">
              <TavernsSection taverns={goingTaverns} />
            </TabPanel>
            <TabPanel p="0" pt="32px">
              <FollowerFollowingList users={usersFollowers} emptyText="No followers yet." />
            </TabPanel>
            <TabPanel p="0" pt="32px">
              <FollowerFollowingList users={usersFollowing} emptyText="Not following anyone yet." />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
      {nft && (
        <NFTinfoModal
          nftData={nft}
          isVisible={!!setNft}
          onClose={() => {
            setNft(null);
          }}
        />
      )}
    </UserContext.Provider>
  );
};
