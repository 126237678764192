export const rules = `
**Tavern Roots** is a point system used to encourage folks to create and join Taverns by rewarding points based on usage and activity. Roots point system also tracks how far along a user is towards different tiered levels within Tavern. The higher the tier the more functionality and possibilities are unlocked inside Tavern. Earning Roots will unlock airdrops during each season, rewarding participants with **$POSE** the base token for Pose, layer zero for non-fungible tokens.

&nbsp;

**Why do Tavern users want $POSE?**

Users can spend **$POSE** on Tavern functionalities including: individual storage, creating organizations, increasing Tavern max length, buying Root multipliers, creating Tavern POAPs, token gating Taverns, customizing profiles, customizing reactions, issuing unique anon PFPs. Once, full decentralized Taverns are relay hosted, paying nodes to host live Taverns, and then pay for long term decentralized storage all done in **$POSE**.

&nbsp;

**How do users earn Roots?**

Roots are accumulated in two ways: Hosting & Joining Taverns. The Tavern needs to meet the following requirements:

&nbsp;

    1. Lasting a minimum of 10 minutes.

    2. Includes 2 additional participants (co-hosts/speakers/joiners).
        a. Each participant needs to have been in the Tavern for at least 10 minutes.
        b. Anon listeners do not count.

    3. Must have caption data that when analyzed by an AI constitutes a real conversation (to remove exploits)

    4. Can not be destroyed until the end of season.

&nbsp;

For every Tavern that meets these requirements, it will accumulate points for each participant. Hosts, Co-Hosts, Speakers, & Listeners all accumulate points in different ways. Each participant starts with a base point amount, if that participant was part of the Tavern for at least 10 minutes.

&nbsp;

| Participant | Base Points |
| ----------- | ----------- |
| Host | 100 |
| Co-Host | 70 |
| Speaker | 50 |
| Joiner | 20 |

&nbsp;

>   P = B + 10 * log(C)

>   B = Base Points

>   C = Count of users that joined after the user and stayed for at least 10 minutes.

>   P = Final Points

&nbsp;

Additional points will accumulate based on the above formula. When calculating Base Points, it will use the highest session the user was during the Tavern. If a user was a joiner, then speaker, then co-host. It will calculate the user's base points as a co-host. However, it will use your original session start time for the tavern calculation. If a user leaves a Tavern before it ends, anyone who joined after they left won’t be used in calculating their final score. Banned users will be removed from the calculation. Anon users do not count in the accumulation, even if an Anon user is made a speaker. Total time of the Tavern does not play a role, however, if people are found exploiting the system all captions are reviewed by an AI before the airdrop and Taverns that don’t fit the criteria for an actual conversation won’t be counted.

&nbsp;

**What are some additional ways to earn Roots?**

&nbsp;

    1. Taverns will have the ability to add multipliers to their point systems (2x, 3x, 5x, 10x, 100x)
    2. Every RSVP (Set Going) that converts to the user staying in the Tavern will earn a 2x multiplier for that tavern.
    3. For including テ in your display name you will earn a 3x multiplier on all taverns while having that name.
    4. For including Φ in your display name you will earn a 3x multiplier on all taverns while having that name.
    5. For including ⌐◨-◨ in your display name you will earn a 2x multiplier on all taverns while having that name.
    6. For including 🐻 in your display name you will earn a 2x multiplier on all taverns while having that name.
    7. Taverns that have playback will earn the Host additional points at the rate of 2 * log(C), where C = playback count including anon listeners
    8. At the end of the season you have the chance to roll a dice to earn a multiplier based on the number of total Taverns you joined. If you join 100 Taverns, you will roll a d100 (on-chain). This multiplier will be applied to the total amount or Root points you accumulated that season.

&nbsp;

**What happens at the end of a Season?**

Each season users will have their Root points calculated and verified. User’s will then be able to roll their dice for their multiplier. After a period of verification and dice rolling, players will see their final standings on the leaderboard. The leaderboard determines how the airdrop for **$POSE** will be calculated. Each season will have a specific amount of **$POSE** allocated for the airdrop, with the total amount dropping each season. Amount airdropped is proportional to the place you are in the leaderboard. I.E the #1 person will receive the most **$POSE** and the last person will receive the least amount of $POSE. The conversion of Roots to **$POSE** is just based on your position of accumulated Roots in that particular season.

&nbsp;

After the airdrop claim, users will keep their remaining Roots for calculating overall points, but will be reset for the next season on accumulation. If you got an airdrop in one season, you aren’t guaranteed airdrop in another season. Each season will have different ways to accumulate and the calculations will change from season to season.

&nbsp;
`;
