import { FC } from 'react';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { AlertModalProps } from './types';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';
import { TavernModal } from '../TavernModal/TavernModal';

const AlertModal: FC<AlertModalProps> = ({
  title,
  description,
  primaryButton,
  secondaryButton,
  multiButtons,
  isLoading,
  ...rest
}) => {
  const buttons = multiButtons ? (
    <VStack spacing="24px" w="100%">
      <TavernButton
        variant={primaryButton.isDestructive ? 'destructive' : 'primary'}
        w="100%"
        onClick={primaryButton.onClick}
        isLoading={primaryButton.isLoading}
        isDisabled={primaryButton.isDisabled}
      >
        {primaryButton.text}
      </TavernButton>
      {multiButtons.map((button, i) => (
        <TavernButton
          key={i}
          variant={button.isDestructive ? 'destructive' : 'primary'}
          w="100%"
          onClick={button.onClick}
          isLoading={button.isLoading}
          isDisabled={button.isDisabled}
        >
          {button.text}
        </TavernButton>
      ))}
    </VStack>
  ) : (
    <HStack spacing="24px" w="100%">
      {secondaryButton ? (
        <TavernButton
          variant="tertiary"
          w="100%"
          onClick={secondaryButton.onClick}
          isLoading={secondaryButton.isLoading}
        >
          {secondaryButton.text}
        </TavernButton>
      ) : (
        <></>
      )}
      <TavernButton
        variant={primaryButton.isDestructive ? 'destructive' : 'primary'}
        w="100%"
        onClick={primaryButton.onClick}
        isLoading={primaryButton.isLoading}
      >
        {primaryButton.text}
      </TavernButton>
    </HStack>
  );

  return (
    <TavernModal
      maxWidth={{ lg: '400px', md: 'calc(100% - 32px)', sm: 'calc(100% - 28px)' }}
      isLoading={isLoading}
      isCloseButtonVisible
      header={
        <Text p="5px 0" textStyle="h3" mb="12px">
          {title}
        </Text>
      }
      footer={buttons}
      {...rest}
    >
      <Text pb="8px" mb="16px" fontStyle="body16regular" color="typographySecondary">
        {description}
      </Text>
    </TavernModal>
  );
};

export default AlertModal;
