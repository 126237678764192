/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, HStack, useBreakpointValue, VStack } from '@chakra-ui/react';
import { collection, doc, DocumentSnapshot, limit, onSnapshot, query, where } from 'firebase/firestore';
import { getDownloadURL, ref, StorageReference } from 'firebase/storage';
import { useContext, useEffect } from 'react';

import AuthContext from '../../context/auth';
import GlobalPlayerContext from '../../context/global-player-context';
import { CaptionsButton } from '../CaptionsButton/CaptionsButton';
import { ExternalAudio } from '../../types/audio';
import { FloatingReactions } from '../FloatingReactions/FloatingReactions';
import { LeaveTavernButton } from '../buttons/leave-tavern-button';
import { LiveAddReactionControl } from '../controls/add-reaction-control';
import { MuteButton } from '../buttons/mute-button';
import { RaiseHandButton } from '../buttons/raise-hand-button';
import { RecordTimer } from '../uiKit/RecordTimer/RecordTimer';
import { RecordedTavernPlayer } from './recorded-tavern-player';
import { ReturnToRoleButton } from '../buttons/return-to-role-button';
import { Session, SessionType, Tavern } from '../../types/tavern';
import { TavernPanel } from '../TavernPanel/TavernPanel';
import { agoraEngine, leaveAgoraChannel, stopAllAudio } from '../../services/agora-functions';
import { auth, db, storage } from '../../connect';
import { hasSpeakingPermissions, sessionIsOneOfType } from '../../utils/session-util';
import { leaveTavern, listenToTavern } from '../../services/tavern-functions';
import { useTavernToast } from '../hooks/useTavernToast';
import { getUserImage } from '../../utils/user-util';
import { ShareTavernButton } from '../buttons/share-tavern-button';
// import { FloatingScore } from '../FloatingReactions/FloatingScore';

export const GlobalPlayer = () => {
  const { connectedTavern, setConnectedTavern, userSession, setUserSession, listenerCount, setExternalAudio } =
    useContext(GlobalPlayerContext);
  const { user } = useContext(AuthContext);

  const toast = useTavernToast();

  const handleListeningTavern = async (snapshot: DocumentSnapshot) => {
    if (connectedTavern === null) {
      setUserSession(null);
      stopAllAudio();
      await leaveAgoraChannel();
      return;
    }
    const tavernData = snapshot.data() as Tavern;

    const tavernJustEnded = connectedTavern?.endTime === undefined && tavernData.endTime !== undefined;

    if (tavernJustEnded) {
      setUserSession(null);
      setConnectedTavern(null);

      stopAllAudio();
      await leaveAgoraChannel();
      await leaveTavern(tavernData.id);
      toast({
        title: `Tavern ended.`,
        description: 'This Tavern ended. Feel free to listen to the recording.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  // Handle if Tavern ends while user is in it.
  useEffect(() => {
    if (connectedTavern === null) {
      return;
    }

    const unsub = onSnapshot(doc(db, 'taverns', connectedTavern.id), (snapshot) => {
      handleListeningTavern(snapshot).catch(console.error);
    });
    return unsub;
  }, [connectedTavern]);

  // Join Agora channel if not already connected.
  useEffect(() => {
    const join = async () => {
      if (
        connectedTavern !== null &&
        connectedTavern.endTime === undefined &&
        agoraEngine.connectionState !== 'CONNECTING' &&
        agoraEngine.connectionState !== 'CONNECTED'
      ) {
        await listenToTavern(connectedTavern.id);
      }
    };
    join().catch(console.error);
  }, [connectedTavern, agoraEngine]);

  useEffect(() => {
    const userId = auth.currentUser?.uid;

    const noHostPermission =
      connectedTavern === null ||
      userId === undefined ||
      (userSession?.type !== SessionType.Host && userSession?.type !== SessionType.CoHost);

    if (noHostPermission) {
      return;
    }

    const externalAudioQuery = query(collection(db, `taverns/${connectedTavern.id}/externalAudio`));

    const unsub = onSnapshot(externalAudioQuery, (querySnapshot) => {
      const externalAudio = querySnapshot.docs.map((d) => d.data() as ExternalAudio);
      const setUrls = async () => {
        await Promise.all(
          externalAudio.map(async (audio) => {
            const storageRef: StorageReference = ref(
              storage,
              `taverns/${connectedTavern.id}/externalAudio/${audio.name}`
            );
            audio.url = await getDownloadURL(storageRef);
            return audio;
          })
        );
        setExternalAudio(externalAudio);
      };
      setUrls().catch(console.error);
    });
    return unsub;
  }, [connectedTavern, userSession]);

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (connectedTavern === null || userId === undefined) {
      return;
    }

    const userSessionQuery = query(
      collection(db, `taverns/${connectedTavern.id}/sessions`),
      where('user', '==', doc(collection(db, 'users'), userId)),
      where('endTime', '==', null),
      limit(1)
    );
    const unsub = onSnapshot(userSessionQuery, (querySnapshot) => {
      if (!querySnapshot.empty) {
        const session = querySnapshot.docs[0].data() as Session;
        if (session.user.id === auth.currentUser?.uid) {
          setUserSession(session);
        }
      }
    });
    return unsub;
  }, [connectedTavern]);

  const isRecordTimerVisible = useBreakpointValue({ base: 'none', xl: 'block' });

  if (connectedTavern === null) {
    return <></>;
  }

  const isLive = connectedTavern.endTime === undefined;

  if (!isLive) {
    return (
      <TavernPanel
        isMultilineStats
        avatar={getUserImage(user)}
        listenerCount={listenerCount}
        title={connectedTavern.title}
      >
        <Flex w="100%" flexDirection="column-reverse">
          <Flex w="100%" alignItems="center" mt="12px">
            <RecordedTavernPlayer />
          </Flex>
          <FloatingReactions displayLiveData={false} />
          <HStack justifyContent="flex-end" spacing="8px">
            <CaptionsButton />
            <ShareTavernButton isCollapsed />
            <LeaveTavernButton />
          </HStack>
        </Flex>
      </TavernPanel>
    );
  }

  return (
    <TavernPanel
      avatar={userSession?.nft ?? userSession?.avatar ?? ''}
      listenerCount={listenerCount}
      title={connectedTavern.title}
      isMicSelectEnabled={hasSpeakingPermissions(userSession)}
    >
      <Flex w="100%" h="100%" justifyContent="flex-end">
        <Flex
          direction={{
            base: 'column-reverse',
            xl: 'row',
          }}
          align="center"
          justify="flex-end"
        >
          <Box mr="24px" display={isRecordTimerVisible}>
            <RecordTimer startTimestamp={connectedTavern.startTime} />
          </Box>
          <HStack
            mr="4px"
            spacing={2}
            align="flex-end"
            justify="flex-end"
            width="100%"
            mt={{
              base: '8px',
              xl: 0,
            }}
          >
            <CaptionsButton />
            {hasSpeakingPermissions(userSession) && <MuteButton />}
            <ReturnToRoleButton />
            {sessionIsOneOfType(userSession, [SessionType.Joiner, SessionType.Listener]) ? <RaiseHandButton /> : <></>}
            <LeaveTavernButton />
          </HStack>
          <VStack spacing="4px" align="flex-end">
            <FloatingReactions displayLiveData />
            <LiveAddReactionControl tavern={connectedTavern} />
          </VStack>
        </Flex>
      </Flex>
    </TavernPanel>
  );
};
