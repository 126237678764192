import { defineStyleConfig } from '@chakra-ui/react';

const ActionButtonStyles = defineStyleConfig({
  baseStyle: {
    border: 'none',
    backgroundColor: 'transparent',
    borderRadius: '0',
    height: 'auto',
    padding: '0',
    marginTop: '0',
    _hover: {
      backgroundColor: 'transparent',
      '.icon': {
        backgroundColor: 'backgroundButtonHover',
      },
    },
    _active: {
      backgroundColor: 'transparent',
      '.icon': {
        backgroundColor: 'backgroundButtonHover',
      },
    },
    _disabled: {
      opacity: '0.5',
      pointerEvents: 'none',
    },
    svg: {
      width: '24px',
      height: '24px',
    },
    div: {
      transition: 'all 0.1s',
      marginBottom: '8px',
    },
    span: {
      fontFamily: 'body',
      fontWeight: 'regular',
      fontSize: 'sm',
      lineHeight: '18px',
      color: 'typographyPrimary',
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
});

export default ActionButtonStyles;
