import { ref, uploadBytes } from 'firebase/storage';

import { storage } from '../connect';

export const uploadTavernFeaturedImage = async (tavernId: string, imageUrl: string): Promise<void> => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();

  await uploadBytes(ref(storage, `taverns/${tavernId}/featured-image`), blob).catch((error) => {
    console.log(`Error uploading featured image. ${error as string}`);
  });
};
