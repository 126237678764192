import { gsap } from 'gsap';

import { Reaction } from '../types/tavern';

export const reactionsFilteredForReplay = (
  reactions: Reaction[],
  displayLiveData: boolean,
  recordingProgressTimestamp: number | null
): Reaction[] => {
  if (recordingProgressTimestamp === null || displayLiveData) {
    return reactions;
  }

  return reactions.filter((item) => item.timestamp <= recordingProgressTimestamp);
};

export const animateReaction = (reaction: HTMLDivElement, container: HTMLDivElement): void => {
  const startX = Math.random() * container.clientWidth;
  const startY = container.clientTop - 55;
  const swayDistance = Math.random() * 100 - 60;

  gsap.set(reaction, {
    x: startX,
    y: startY,
    scale: Math.random() * 0.5 + 0.5,
  });

  gsap.to(reaction, {
    duration: 2,
    y: -225 + Math.random() * 20,
    x: startX + swayDistance,
    opacity: 0,
    ease: 'sine.inOut',
    onComplete: () => {
      container.removeChild(reaction);
    },
  });
};

export const createReaction = (element: string): HTMLDivElement => {
  const reaction = document.createElement('div');
  reaction.classList.add('floating-reaction');
  reaction.style.position = 'absolute';
  reaction.style.opacity = '1';
  reaction.style.zIndex = '100';
  reaction.style.fontSize = '3rem';
  reaction.style.userSelect = 'none';
  reaction.style.pointerEvents = 'none';
  reaction.innerHTML = element;
  return reaction;
};
