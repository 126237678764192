import { useState, useContext } from 'react';
import _ from 'lodash';
import { useDisclosure } from '@chakra-ui/react';
import { FaFileExport } from 'react-icons/fa';
// import moment from 'moment';

import AlertModal from '../AlertModal/AlertModal';
import { AlertModalProps } from '../AlertModal/types';
import { Tavern } from '../../types/tavern';
import { TavernButtonAnime } from '../uiKit/TavernButton/TavernButtonAnime';
import { auth } from '../../connect';
import { useTavernToast } from '../hooks/useTavernToast';
import { getRecordingUrlForTavern } from '../../utils/player-util';
import TavernDetailContext from '../../context/tavern-detail-context';
import { triggerIPFS } from '../../services/tavern-functions';
import { getTavernUsers } from '../../utils/tavern-util';

export const DownloadTavernButton = (props: { tavern: Tavern; url: string; users: any }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { audioClips } = useContext(TavernDetailContext);

  const toast = useTavernToast();

  const [isProcessing, setIsProcessing] = useState(0);

  if (props.tavern.host.id !== auth.currentUser?.uid) {
    return <></>;
  }

  const sortedAudioClips = _.sortBy(audioClips, ['startTime'], ['asc']);

  const multiButtons = [
    {
      text: 'Download Transcript',
      isLoading: false,
      isDisabled: !!isProcessing,
      onClick: () => {
        const transcript = sortedAudioClips
          .map((t) => {
            return `${t.startTime.toString()}: ${t.userName as string}: ${t.caption as string}`;
          })
          .join('\n');

        const blob = new Blob([transcript], { type: 'text/plain' });
        const urlT = URL.createObjectURL(blob);
        const downloadLinkT = document.createElement('a');
        downloadLinkT.href = urlT;
        downloadLinkT.download = `${props.tavern.id}-transcript.txt`;

        document.body.appendChild(downloadLinkT);
        downloadLinkT.click();
        document.body.removeChild(downloadLinkT);

        toast({
          title: `Downloaded Transcript.`,
          description: 'Transcript has been downloaded.',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      },
    },
    {
      text: 'Download Attendees',
      isLoading: false,
      isDisabled: !!isProcessing,
      onClick: async () => {
        const fcUserIds = props.users.filter((u: any) => u.provider === 'farcaster').map((u: any) => u.user.id);
        const fcUsers = await getTavernUsers(fcUserIds);
        const attendees = props.users.map((u: any) => {
          const fc = _.find(fcUsers, (fcUser) => {
            return fcUser.id === u.user.id;
          });

          return {
            id: u.user.id,
            username: u.name,
            type: u.provider || 'anon',
            fid: fc ? fc.fid.toString() : '',
            role: u.type,
          };
        });

        const arrayToCSV = (array: any) => {
          const headers = Object.keys(array[0]).join(',');
          const rows = array.map((obj: any) =>
            Object.values(obj)
              .map((value: any) => `${String(value)}`)
              .join(',')
          );

          return [headers, ...rows].join('\n');
        };

        const downloadCSV = (csv: any, filename = 'attendees.csv') => {
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          const link = document.createElement('a');
          const url = URL.createObjectURL(blob);

          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };

        const csvString = arrayToCSV(attendees);
        downloadCSV(csvString);

        toast({
          title: `Downloaded Attendees.`,
          description: 'Attendees have been downloaded.',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      },
    },
    {
      text: props.tavern.audioHash
        ? 'IPFS Link'
        : props.tavern.processingIPFS
        ? 'Processing IPFS'
        : 'Decentralize to IPFS',
      isLoading: isProcessing === 2 || props.tavern.processingIPFS,
      isDisabled: (isProcessing ? isProcessing !== 2 : false) || props.tavern.processingIPFS,
      onClick: async () => {
        setIsProcessing(2);

        if (props.tavern.audioHash) {
          const newWindow = window.open(
            `https://tavern.infura-ipfs.io/ipfs/${props.tavern.audioHash}`,
            '_blank',
            'noopener,noreferrer'
          );
          if (newWindow) {
            newWindow.opener = null;
          }
        } else {
          // trigger IPFS upload
          try {
            await triggerIPFS({
              tavernId: props.tavern.id,
            });

            toast({
              title: `Exporting to IPFS`,
              description: 'Tavern is exporting to IPFS',
              status: 'success',
              duration: 2000,
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: `Failed to Export`,
              description: 'Tavern failed exporting to IPFS',
              status: 'error',
              duration: 2000,
              isClosable: true,
            });
          }
        }

        setIsProcessing(0);
      },
    },
  ];

  if (!props.tavern.audioHash) {
    multiButtons.push();
  }

  const modalProps: AlertModalProps = {
    isOpen,
    onClose,
    title: 'Export Tavern',
    description: 'How do you want to export your Tavern?',
    primaryButton: {
      text: 'Download MP4',
      isLoading: isProcessing === 1,
      isDisabled: isProcessing ? isProcessing !== 1 : false,
      onClick: async () => {
        setIsProcessing(1);

        const url = props.url || (await getRecordingUrlForTavern(props.tavern.id)) || '';

        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        toast({
          title: `Downloaded Tavern mp4.`,
          description: 'mp4 has been downloaded.',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });

        setIsProcessing(0);
      },
    },
    multiButtons,
  };

  return (
    <>
      <TavernButtonAnime leftIcon={<FaFileExport />} variant="secondary" onClick={onOpen}>
        Export
      </TavernButtonAnime>
      <AlertModal {...modalProps} />
    </>
  );
};
