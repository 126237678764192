/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDisclosure } from '@chakra-ui/react';
import { onSnapshot, doc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { auth, db } from '../../connect';
import { mute } from '../../services/agora-functions';
import { Tavern } from '../../types/tavern';
import { AlertModalProps } from '../AlertModal/types';
import AlertModal from '../AlertModal/AlertModal';

export const BanModal = () => {
  const { tavernId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const userId = _.get(auth, 'currentUser.uid', null);

  const [tavernTitle, setTavernTitle] = useState('');

  useEffect(() => {
    if (userId === null) {
      return;
    }
    const unsub = onSnapshot(doc(db, 'taverns', tavernId as string), (d) => {
      const tavern = d.data() as Tavern;
      setTavernTitle(tavern.title);

      if (tavern?.banList?.includes(userId)) {
        onOpen();
        mute().catch(console.error);
      }
    });
    return unsub;
  }, [tavernId, userId]);

  const modalProps: AlertModalProps = {
    isOpen,
    onClose,
    isDestructive: true,
    title: `Blocked`,
    description: `You've been blocked from ${tavernTitle}. You can listen, but can't
    interact in any way.`,
    primaryButton: {
      text: 'Ok',
      onClick: () => {
        onClose();
      },
    },
  };

  return <AlertModal {...modalProps} />;
};
