import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import { FC, useContext } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import GlobalPlayerContext from '../../../context/global-player-context';
import { SpeakersSectionProps } from './types';
import { TavernUser } from '../../uiKit/TavernUser/TavernUser';
import { speakingDropDownItems, goingSpeakerDropdownItems } from '../../uiKit/DropdownItem/DropdownItem';
import { UserMock } from '../../../types/user';
import { SessionType } from '../../../types/tavern';
import AuthContext from '../../../context/auth';

export const SpeakersSection: FC<SpeakersSectionProps> = ({ speakers, hasHostingPermission, sessionType, ...rest }) => {
  const { tavernId } = useParams();
  const { userSession } = useContext(GlobalPlayerContext);
  const { user } = useContext(AuthContext);

  return (
    <Box {..._.omit(rest, ['maxSpeakers'])} minH="220px">
      <Box mb="16px">
        <Text as="span" textStyle="body16primary" mb="40px">
          {'Speakers '}
        </Text>
        {/* <Text as="span" textStyle="body16primary" color="typographySecondary">
          {speakers.length}/{maxSpeakers}
        </Text> */}
      </Box>

      <Grid pt="16px" autoFlow="row dense" templateColumns="repeat(auto-fill, 152px)" gap="24px">
        {speakers.map((speaker, i) => {
          let dropdownItems;

          if (!userSession) {
            dropdownItems = goingSpeakerDropdownItems(speaker.id, tavernId as string);
          } else if (speaker.session) {
            dropdownItems = speakingDropDownItems(speaker as UserMock, userSession, tavernId as string);
          }

          const permission = i > 0 && (hasHostingPermission || user?.id === speaker.id);

          return (
            <GridItem key={speaker.id}>
              <TavernUser
                hideScore
                isSpeaking={!speaker.isMuted && speaker.isSpeaking}
                user={speaker}
                sessionType={permission ? SessionType.Host : sessionType}
                isRoleVisible
                isMuted={speaker.session?.isForceMuted || speaker.isMuted}
                dropdownItems={dropdownItems}
              />
            </GridItem>
          );
        })}
      </Grid>
    </Box>
  );
};
