import { Spinner } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { FollowedByUserNotification } from '../../types/notification';
import { FollowUnfollowButton } from '../FollowUnfollowButton/FollowUnfollowButton';
import { NotificationCard } from './NotificationCard';
import { User } from '../../types/user';
import { markNotificationAsSeen } from '../../services/notifications';
import { getUserFromUserId } from '../../utils/user-util';

type NotificationStartTavernProps = FollowedByUserNotification;

export const NotificationFollowedByUser = (props: NotificationStartTavernProps) => {
  const { followerId, id: notificationId } = props;

  const [follower, setFollower] = useState<User>();
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    getUserFromUserId(followerId)
      .then(setFollower)
      .catch(console.error)
      .finally(() => setHasLoaded(true));
  }, [followerId]);

  const message = useMemo(() => {
    if (!follower) {
      return '';
    }
    // TODO: User.name should be string | null
    return `Followed by ${follower.name ?? follower.id}.`;
  }, [follower]);

  const handleHover = useCallback(() => {
    markNotificationAsSeen(notificationId).catch(console.error);
  }, [notificationId]);

  if (!hasLoaded) {
    return <Spinner />;
  }

  return (
    <>
      {follower !== undefined ? (
        <NotificationCard
          {...props}
          message={message}
          highlight={follower?.name ?? follower.id}
          hasSeen={props.seen}
          link={`/profiles/${followerId}`}
          buttons={<FollowUnfollowButton userId={followerId} />}
          onHover={handleHover}
          user={follower}
        />
      ) : (
        <></>
      )}
    </>
  );
};
