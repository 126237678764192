import { FC, useState } from 'react';
import Fuse from 'fuse.js';
import { useParams } from 'react-router-dom';
import { Box, Grid, GridItem, HStack, Text } from '@chakra-ui/react';
import { BlockedSectionProps } from './types';
import { TavernUser } from '../../uiKit/TavernUser/TavernUser';
import { TavernInput } from '../../uiKit/TavernInput/TavernInput';
import { IconSearch } from '../../../theme/foundations/customIcons';
import { blockedDropdownItems } from '../../uiKit/DropdownItem/DropdownItem';
import { UserMock } from '../../../types/user';

export const BlockedSection: FC<BlockedSectionProps> = ({ users, sessionType, cardSize = 'lg', ...rest }) => {
  const { tavernId } = useParams();
  const [query, setQuery] = useState('');

  const fuse = new Fuse(users || [], {
    includeScore: false,
    keys: ['name', 'id'],
  });

  const results = fuse.search(query);
  const filteredBlocked = query ? results.map((r) => r.item) : users;

  return (
    <Box {...rest}>
      <Box mb="24px">
        <HStack justifyContent="flex-start">
          <TavernInput
            leftIcon={<IconSearch />}
            value={query}
            placeholder="Search by name"
            onChange={(e) => setQuery(e.target.value)}
          />
        </HStack>
      </Box>
      <Grid pt="16px" autoFlow="row dense" templateColumns="repeat(auto-fill, 152px)" gap="24px">
        {!filteredBlocked.length && <Text opacity={0.5}>No Results</Text>}
        {filteredBlocked.map((user: UserMock, i: any) => (
          <GridItem key={i}>
            <TavernUser
              size={cardSize}
              user={user}
              sessionType={sessionType}
              dropdownItems={blockedDropdownItems(user, tavernId as string)}
            />
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};
