import { FC, useEffect, useState } from 'react';
import { format, parse } from 'date-fns';
import { Grid, GridItem } from '@chakra-ui/react';

import { DatePicker } from '../uiKit/DatePicker/DatePicker';
import { TimePickerSelect } from '../uiKit/TimePickerSelect/TimePickerSelect';
import { TimezonePickerSelect } from '../uiKit/TimezonePickerSelect/TimezonePickerSelect';
import { DatetimePickerFormProps } from './types';
import { roundTime } from '../../utils/date';

/**
 * Converts date into 3 pieces for each picker
 */
const splitData = (date: number) => {
  const d = new Date(date);
  return { date: new Date(date), time: format(d, 'HH:mm') };
};

/**
 * Builds date timestamp from pickers values
 */
const buildDate = (date: Date, time: string) => {
  const dateStr = format(date, 'yyy-MM-dd');

  const datetime = parse(`${dateStr}T${time}`, "yyyy-MM-dd'T'HH:mm", new Date());
  return datetime.valueOf();
};

export const DatetimePickerForm: FC<DatetimePickerFormProps> = (props) => {
  const { date: value, onDateChange, onTimezoneChange, minDatetime } = props;

  const [date, setDate] = useState<Date>();
  const [time, setTime] = useState<string>();

  useEffect(() => {
    const dateParts = splitData(value ?? Date.now());
    setDate(dateParts.date);
    setTime(dateParts.time);
  }, [value]);

  const handleTimeChange = (newTime: string) => {
    if (date && time) {
      onDateChange(buildDate(date, newTime));
    }
  };

  const handleDateChange = (newDate: Date) => {
    if (date && time) {
      onDateChange(buildDate(newDate, time));
    }
  };

  const handleTimezoneChange = (newTzOffset: number) => {
    onTimezoneChange(newTzOffset);
  };

  const getMinAllowedTime = () => {
    if (!date || !minDatetime) {
      return '00:00';
    }
    const minDate = new Date(minDatetime);
    const isMinDate = date.getDate() === minDate.getDate();
    return isMinDate ? roundTime(date, 15) : '00:00';
  };

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return (
    <Grid templateColumns={{ base: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }} w="100%" gap="8px">
      <GridItem>
        <DatePicker
          onDateChange={handleDateChange}
          date={date}
          minDate={minDatetime ? new Date(minDatetime) : undefined}
        />
      </GridItem>
      <GridItem>
        <TimePickerSelect
          onSelect={handleTimeChange}
          startTime={getMinAllowedTime()}
          value={time ? { label: time, value: time } : undefined}
        />
      </GridItem>

      <GridItem>
        <TimezonePickerSelect
          isDisabled
          onSelect={handleTimezoneChange}
          value={{ label: timeZone as any, value: timeZone as any }}
        />
      </GridItem>
    </Grid>
  );
};
