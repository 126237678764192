const colors = {
  brand: {
    1000: '#915AD9',
    900: '#873AEA',
    240: 'rgba(145, 90, 217, 0.24)',
    200: 'rgba(145, 90, 217, 0.2)',
    160: 'rgba(145, 90, 217, 0.16)',
    120: 'rgba(145, 90, 217, 0.12)',
  },
  white: '#FFFFFF',
  whiteAlpha: {
    240: 'rgba(255, 255, 255, 0.24)',
    200: 'rgba(255, 255, 255, 0.2)',
    160: 'rgba(255, 255, 255, 0.16)',
    120: 'rgba(255, 255, 255, 0.12)',
    80: 'rgba(255, 255, 255, 0.08)',
  },
  gray: {
    1000: '#363636',
    900: '#4B4844',
    800: '#65615C',
    400: '#6C6C6C',
    300: '#999999',
    200: '#B0ADA8',
  },
  black: {
    1000: '#000000',
    900: '#111111',
    800: '#121212',
    700: '#141414',
    600: '#191919',
    500: '#1B1B1B',
    400: '#242424',
    300: '#313131',
  },
  blackAlpha: {
    800: 'rgba(0, 0, 0, 0.8)',
    300: 'rgba(0, 0, 0, 0.3)',
    120: 'rgba(0, 0, 0, 0.12)',
    80: 'rgba(0, 0, 0, 0.08)',
  },
  redAlpha: {
    200: 'rgba(255, 90, 95, 0.2)',
    120: 'rgba(255, 90, 95, 0.12)',
    80: 'rgba(255, 90, 95, 0.08)',
  },
  accent: {
    green: '#7CD30D',
    orange: '#FBB10A',
    red: '#FF5A5F',
    pink: '#DD78F6',
  },
  gradientDark: {
    1000: 'linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)',
    950: 'linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 100%)',
    900: 'linear-gradient(0deg, rgba(18, 18, 18, 0) 0%, #121212 100%), linear-gradient(0deg, rgba(18, 18, 18, 0) 0%, #121212 100%)',
    800: 'linear-gradient(0deg, rgba(25, 25, 25, 0) 0%, #191919 100%), linear-gradient(0deg, rgba(25, 25, 25, 0) 0%, #191919 100%);',
  },
  gradientDarkToLeft: {
    1000: 'linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%)',
    900: 'linear-gradient(90deg, rgba(18, 18, 18, 0) 0%, #121212 100%), linear-gradient(90deg, rgba(18, 18, 18, 0) 0%, #121212 100%)',
    800: 'linear-gradient(90deg, rgba(25, 25, 25, 0) 0%, #191919 100%), linear-gradient(90deg, rgba(25, 25, 25, 0) 0%, #191919 100%);',
  },
  gradientWhite: {
    1000: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
    950: 'linear-gradient(90deg, rgba(244, 241, 237, 0) 0%, #F4F1ED 100%)',
    900: 'linear-gradient(180deg, rgba(250, 247, 244, 0) 0%, #FAF7F4 100%), linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
    800: 'linear-gradient(180deg, rgba(244, 241, 237, 0) 0%, #F4F1ED 100%), linear-gradient(180deg, rgba(244, 241, 237, 0) 0%, #F4F1ED 100%)',
  },
  gradientWhiteToLeft: {
    1000: 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
    900: 'linear-gradient(90deg, rgba(250, 247, 244, 0) 0%, #FAF7F4 100%), linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
    800: 'linear-gradient(90deg, rgba(244, 241, 237, 0) 0%, #F4F1ED 100%), linear-gradient(90deg, rgba(244, 241, 237, 0) 0%, #F4F1ED 100%)',
  },
  gradientModalWhite:
    'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
  gradientModalBlack:
    'linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, #121212 100%), linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, #121212 100%)',
  gradientCardWhite:
    'linear-gradient(180deg, rgba(244, 241, 237, 0) 0%, #F4F1ED 100%), linear-gradient(180deg, rgba(244, 241, 237, 0) 0%, #F4F1ED 100%)',
  gradientCardBlack: {
    1000: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
    600: 'linear-gradient(180deg, rgba(25, 25, 25, 0) 0%, #191919 100%), linear-gradient(180deg, rgba(25, 25, 25, 0) 0%, #191919 100%)',
  },
  gradientPopoverDark:
    'linear-gradient(180deg, rgba(25, 25, 25, 0) 0%, #191919 100%), linear-gradient(180deg, rgba(25, 25, 25, 0) 0%, #191919 100%)',
  gradientPopoverWhite:
    'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
  sandy: {
    1000: '#2C2924',
    200: '#F4F1ED',
    100: '#FAF7F4',
    80: '#E3E0DC',
    50: '#D9D5D2',
  },
  sandyAlpha: {
    650: 'rgba(228, 222, 215, 0.65)',
    600: 'rgba(195, 188, 179, 0.6)',
    500: 'rgba(195, 188, 179, 0.5)',
    460: 'rgba(195, 188, 179, 0.46)',
    360: 'rgba(195, 188, 179, 0.36)',
    340: 'rgba(196, 188, 179, 0.34)',
  },
};

export default colors;
