/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { Tavern, Session, SessionType } from '../../types/tavern';
import { renewToAudienceToken, renewToHostToken } from '../../services/agora-functions';

export const TokenRenewalMonitor = (props: { tavern: Tavern | null; userSession: Session | null }) => {
  const [userRole, setUserRole] = useState<SessionType | null>(props.userSession?.type ?? null);

  useEffect(() => {
    const checkToRenewToken = async () => {
      if (props.userSession === null || props.tavern === null) {
        return;
      }

      const newType = props.userSession.type;
      if (userRole === newType) {
        return;
      }

      if (newType === SessionType.Joiner || newType === SessionType.Listener) {
        await renewToAudienceToken(props.tavern.id);
      } else if (newType === SessionType.CoHost || newType === SessionType.Host || newType === SessionType.Speaker) {
        await renewToHostToken(props.tavern.id);
      }

      setUserRole(props.userSession.type);
    };
    checkToRenewToken().catch(console.error);
  }, [props.userSession, props.tavern]);
  return <></>;
};
