/* eslint-disable */
import React, { FC, useEffect, useState } from 'react';
import {
  ActionMeta,
  chakraComponents,
  ChakraStylesConfig,
  ControlProps,
  InputProps,
  MenuProps,
  Select,
  SingleValue,
} from 'chakra-react-select';
import { IconTime } from '../../../theme/foundations/customIcons';
import { TavernButton } from '../TavernButton/TavernButton';
import { Flex } from '@chakra-ui/react';
import { TimePickerOption, TimePickerSelectProps } from './types';
import { convertTimeToAMPM, generateTimeArray } from '../../../utils/date';
import { format } from 'date-fns';

const chakraStyles: ChakraStylesConfig<TimePickerOption, false> = {
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isSelected ? 'globalWhite' : 'typographySecondary',
    bgColor: state.isSelected ? 'backgroundInput' : 'none',
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    maxW: '120px',
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    scrollbarWidth: 'thin',
    scrollbarColor: 'typographyTertiary backgroundTertiary',
    '::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      w: '2px',
    },
    '::-webkit-scrollbar-thumb': {
      bgColor: 'typographyTertiary',
    },
    '::-webkit-scrollbar-track': {
      bgColor: 'backgroundTertiary',
    },
  }),
  container: (baseStyles) => ({
    ...baseStyles,
    display: 'flex',
    width: '100%',
    height: '48px',
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    display: 'flex',
    height: '100%',
    flex: '1 1 0',
  }),
};

const components = {
  IndicatorSeparator: () => null,
  DropdownIndicator: () => null,
  Input: ({ ...props }: InputProps<TimePickerOption>) => {
    return <chakraComponents.Input maxLength={4} {...props} />;
  },
  Control: ({ children, ...props }: ControlProps<TimePickerOption>) => (
    <chakraComponents.Control {...props}>
      <IconTime ml={'10px'} mr={'-4px'} alignSelf={'center'} boxSize={'24px'} color={'typographySecondary'} />
      {children}
    </chakraComponents.Control>
  ),
  Menu: ({ children, ...props }: MenuProps<TimePickerOption>) => {
    return (
      <chakraComponents.Menu {...props}>
        {children}
        {
          <Flex
            position={'relative'}
            zIndex={'5'}
            alignItems={'center'}
            height={'36px'}
            pb={'2px'}
            borderTop={'1px solid'}
            borderColor={'backgroundInput'}
            bgColor={'backgroundAdditional'}
          >
            <TavernButton
              boxSize={'100%'}
              variant={'link'}
              whiteSpace={'nowrap'}
              w={'110px'}
              color={'brandPrimary'}
              textStyle={'body14regular'}
              _hover={{ textDecoration: 'none' }}
              onClick={() => {
                const t = format(new Date(), 'HH:mm');
                props.setValue({ label: convertTimeToAMPM(t), value: t }, 'select-option');
              }}
            >
              Now
            </TavernButton>
          </Flex>
        }
      </chakraComponents.Menu>
    );
  },
};

export const TimePickerSelect = (props: TimePickerSelectProps) => {
  const { onSelect, startTime = '00:00', ...rest } = props;

  const options = generateTimeArray(startTime, '23:45', 15).map((time) => {
    return { label: convertTimeToAMPM(time), value: time } as TimePickerOption;
  });

  const handleChange = (option: SingleValue<TimePickerOption>, actionMeta: ActionMeta<TimePickerOption>) => {
    switch (actionMeta.action) {
      case 'select-option':
        if (option) {
          onSelect(option.value);
        }
        break;
      default:
        break;
    }
  };

  return (
    <Select
      placeholder={'__:__'}
      chakraStyles={chakraStyles}
      options={options}
      onChange={handleChange}
      components={components}
      {...rest}
    />
  );
};
