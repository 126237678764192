import { Box, Heading, HStack, Text } from '@chakra-ui/react';
import { ChangeEvent, FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { DatetimePickerForm } from '../DatetimePickerForm/DatetimePickerForm';
import { FeaturedImageUploader } from '../FeaturedImageUploader/FeaturedImageUploader';
import { IconPlay } from '../../theme/foundations/customIcons';
import { TavernStatus, Topic } from '../../types/tavern';
// import { NftCollection } from '../../types/user';
// import { TavernAccess } from '../TavernAccessForm/types';
// import { TavernAccessForm } from '../TavernAccessForm/TavernAccessForm';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';
import { TavernFormControl } from '../uiKit/TavernFormControl/TavernFormControl';
import { TavernFormProps } from './types';
import { TavernInput } from '../uiKit/TavernInput/TavernInput';
import { TavernSwitch } from '../uiKit/TavernSwitch/TavernSwitch';
import { TextareaWithLimit } from '../TextareaWithLimit/TextareaWithLimit';
import { TopicsManageForm } from '../TopicsManageForm/TopicsManageForm';
import { buildDateWithTimezone } from '../../utils/date';
import { createNewTavern } from '../../utils/tavern-util';
import { useTavernToast } from '../hooks/useTavernToast';
import { OrgPickerSelect } from './TavernOrgPicker';

export const TavernForm: FC<TavernFormProps> = ({ initData }) => {
  // const [isMixerEnabled, setIsMixerEnabled] = useState(false);
  const [isScheduledMode, setIsScheduledMode] = useState(false);

  const [title, setTitle] = useState(initData?.title ?? '');
  const [featuredImageUrl, setFeaturedImageUrl] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState(initData?.description ?? '');
  const [isLoading, setIsLoading] = useState(false);

  const [startDate, setStartDate] = useState(initData?.startTime || Date.now());
  const [tzOffset, setTxOffset] = useState(new Date().getTimezoneOffset() * -1);

  const [org, setOrg] = useState('personal');

  const navigate = useNavigate();
  const toast = useTavernToast();

  // TODO get user orgs

  // const [accessMode, setAccessMode] = useState(initData?.access?.mode || TavernAccess.all);
  // const [accessCollections, setAccessCollections] = useState(initData?.access?.collections || []);

  const [topics, setTopics] = useState<Topic[]>([]);

  useEffect(() => {
    document.title = `Create Tavern`;
  }, []);

  // Description input
  const handleDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const handleTopicsChange = (changedTopics: Topic[]) => {
    setTopics(changedTopics);
  };

  // ACCESS
  // const handleAccessModeChange = (value: TavernAccess) => {
  //   setAccessMode(value);
  // };

  // const handleAccessCollectionAdd = (added: NftCollection) => {
  //   setAccessCollections((prevState) => {
  //     return [...prevState, added];
  //   });
  // };

  // const handleAccessCollectionRemove = (id: string) => {
  //   setAccessCollections((prevState) => {
  //     return prevState.filter((c) => c.id !== id);
  //   });
  // };

  // const handleAccessCollectionClear = () => {
  //   setAccessCollections([]);
  // };

  const handleFormSubmit = () => {
    const isScheduled = isScheduledMode && startDate;
    const startTime = isScheduled ? buildDateWithTimezone(new Date(startDate), tzOffset) : undefined;
    setIsLoading(true);

    createNewTavern(
      {
        title,
        description,
        topics,
        links: [],
        startTime,
        // joinAllowlist: accessCollections.map((c) => c.id),
        status: isScheduled ? TavernStatus.Upcoming : TavernStatus.Live,
        featuredImageUrl,
        org,
      },
      setIsLoading,
      navigate,
      toast
    )
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  };

  const isLiveTavernInvalid = () => {
    return !title || !title.length;
  };

  const isScheduledTavernInvalid = () => {
    return !title || !title.length || !startDate || buildDateWithTimezone(new Date(startDate), tzOffset) < Date.now();
  };

  const isSubmitButtonDisabled = () => {
    if (isScheduledMode) {
      return isScheduledTavernInvalid();
    }
    return isLiveTavernInvalid();
  };

  return (
    <Box
      as="div"
      display="flex"
      alignContent="flex-start"
      justifyContent="flex-start"
      flexDirection="column"
      w="100%"
      pb="32px"
    >
      <Heading mb="40px">New Tavern</Heading>
      <Text textStyle="h4" mb="32px" color="typographySecondary">
        General
      </Text>
      <TavernFormControl mb="24px" label="Title">
        <TavernInput value={title} autoFocus onChange={(e) => setTitle(e.target.value)} />
      </TavernFormControl>
      <Box mb="24px">
        <HStack mb="8px">
          <Text textStyle="body14regular" color="white">
            Featured Image
          </Text>
          <Box as="span" ml="8px" color="typographyTertiary" textStyle="body14medium">
            Optional
          </Box>
        </HStack>
        <FeaturedImageUploader croppedImageReceived={setFeaturedImageUrl} />
      </Box>
      <Box mb="24px">
        <TextareaWithLimit
          value={description}
          onChange={handleDescription}
          label="Description"
          isOptional
          placeholder="Description"
          maxLength={200}
        />
      </Box>
      <Box mb="40px">
        <HStack mb="8px">
          <Text textStyle="body14regular" color="white">
            Organization
          </Text>
        </HStack>
        <OrgPickerSelect onSelect={setOrg} value={{ label: org, value: org }} />
      </Box>
      <Box mb="24px">
        <HStack mb="8px">
          <Text textStyle="body14regular" color="white">
            Topics
          </Text>
          <Box as="span" ml="8px" color="typographyTertiary" textStyle="body14medium">
            Optional
          </Box>
        </HStack>
        <TopicsManageForm topics={topics} onChange={handleTopicsChange} maxTopics={5} />
      </Box>

      {/* <TavernAccessForm
        accessMode={accessMode}
        collections={accessCollections}
        onAccessModeChange={handleAccessModeChange}
        onCollectionAdd={handleAccessCollectionAdd}
        onCollectionRemove={handleAccessCollectionRemove}
        onCollectionClear={handleAccessCollectionClear}
      /> */}

      <Box display="flex" mb="40px">
        <TavernSwitch onChange={(e) => setIsScheduledMode(e.target.checked)} />
        <Text marginLeft="12px">Schedule</Text>
      </Box>
      {isScheduledMode && (
        <Box mb="40px">
          <DatetimePickerForm
            minDatetime={Date.now()}
            date={startDate}
            timezoneOffset={tzOffset}
            onDateChange={setStartDate}
            onTimezoneChange={setTxOffset}
          />
        </Box>
      )}
      <Box>
        <TavernButton
          isLoading={isLoading}
          isDisabled={isSubmitButtonDisabled()}
          variant="primary"
          onClick={handleFormSubmit}
          mr="16px"
        >
          <IconPlay mr="8px" />
          {`${isScheduledMode ? 'Schedule' : 'Start'} Tavern`}
        </TavernButton>
      </Box>
    </Box>
  );
};
