import { useContext, useState, useEffect } from 'react';
import AuthContext from '../context/auth';

// use only inside AuthContext
export const useIsSignedIn = () => {
  const { user } = useContext(AuthContext);
  const [isSignedIn, setIsSignedIn] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (user && !user.isAnon) {
      setIsSignedIn(true);
    } else {
      setIsSignedIn(false);
    }
  }, [user]);

  return isSignedIn;
};
