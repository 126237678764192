import {
  Box,
  Flex,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { doc, collection, setDoc } from 'firebase/firestore';
import { ref, StorageReference, uploadBytes } from 'firebase/storage';
import { useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import AuthContext from '../../context/auth';
import GlobalPlayerContext from '../../context/global-player-context';
import { LocalUploadPopoverProps } from './types';
import { AudioMixerStatus } from '../AudioMixerStatus/AudioMixerStatus';
import { DraggableAudioList } from '../DraggableAudioList/DraggableAudioList';
import { IconClose, IconPlus } from '../../theme/foundations/customIcons';
import { ScrollableGradientBox } from '../ScrollableGradientBox/ScrollableGradientBox';
import { alreadyUploadedError, uploadError } from '../uiKit/TavernFileUpload/errors';
import { db, storage } from '../../connect';
import { hasHostingPermission } from '../../utils/session-util';
import { removeExternalAudio } from '../../utils/player-util';
import { useTavernToast } from '../hooks/useTavernToast';

// TODO: If we use this upload experience, needs renaming from AudioMixer to MediaMixer or similar.
export const LocalUploadPopover = (props: LocalUploadPopoverProps) => {
  const { width = 'auto', scrollHeight = 'auto', creatorId } = props;

  const toast = useTavernToast();
  const { tavernId } = useParams();

  const { user } = useContext(AuthContext);
  const { connectedTavern, userSession, externalAudio } = useContext(GlobalPlayerContext);
  const inputFile = useRef<HTMLInputElement | null>(null);

  const [isProcessing, setIsProcessing] = useState(false);

  const hasAccess = (connectedTavern !== null && connectedTavern.id !== tavernId) || !hasHostingPermission(userSession);

  if (creatorId !== user?.id && hasAccess) {
    return <></>;
  }

  const uploadFile = async (file: File): Promise<any> => {
    if (externalAudio.some((audio) => audio.name === file.name)) {
      toast(alreadyUploadedError);
      return;
    }

    const isVideo = file.type.includes('video');
    const isImage = file.type.includes('image');

    let refPath = `taverns/${tavernId as string}/`;

    if (isVideo) {
      refPath += `externalVideo/${file.name}`;
    } else if (isImage) {
      refPath += `externalImage/${file.name}`;
    } else if (file.type.includes('audio')) {
      refPath += `externalAudio/${file.name}`;
    } else {
      toast(uploadError);
      return;
    }

    // TODO this should be done in a firebase function to check perms.

    const storageRef: StorageReference = ref(storage, refPath);
    try {
      await uploadBytes(storageRef, file);
      console.log(`Successfully uploaded ${file.name} to ${tavernId as string}.`);

      if (!user) {
        return;
      }

      if (isVideo || isImage) {
        const linkRef = doc(collection(doc(db, `taverns/${tavernId as string}`), 'links'));
        await setDoc(linkRef, {
          id: linkRef.id,
          user: {
            id: user.id,
            name: user.name ?? user.id,
          },
          link: refPath,
          type: isVideo ? 'storage-video' : 'storage-image',
          timestamp: Date.now(),
        });
      }
    } catch (error) {
      console.error(`uploadFile: ${JSON.stringify(error)}`);
      toast(uploadError);
    }
  };

  return (
    <Popover {...props}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Box as="button" maxW="436px">
              <AudioMixerStatus
                hasTrack={externalAudio.length !== 0}
                numberPlaying={externalAudio.filter((file) => file.isPlaying).length}
              />
            </Box>
          </PopoverTrigger>
          <PopoverContent width={width}>
            <PopoverHeader>
              <Flex alignItems="center">
                <Text textStyle="h4" color="typographyPrimary">
                  Upload Media
                </Text>

                <Spacer />

                <IconButton
                  _hover={{ bg: 'transparent' }}
                  bg="transparent"
                  aria-label="close-popover"
                  onClick={onClose}
                >
                  <IconClose boxSize="24px" color="typographySecondary" />
                </IconButton>
              </Flex>
            </PopoverHeader>

            <PopoverBody>
              <Box pb="12px">
                <ScrollableGradientBox gradient="gradientCardBlack.600" scrollHeight={scrollHeight}>
                  <Box as="label">
                    <Box mb="16px">
                      <Flex
                        alignItems="center"
                        justifyContent="flex-start"
                        mb="16px"
                        _hover={{
                          cursor: 'pointer',
                        }}
                      >
                        {isProcessing ? (
                          <Spinner />
                        ) : (
                          <>
                            <IconPlus mr="12px" boxSize="16px" color="brandPrimary" />
                            <Text textStyle="body16regular" color="brandPrimary">
                              Upload audio, video or images
                            </Text>
                          </>
                        )}
                      </Flex>
                    </Box>

                    <input
                      type="file"
                      id="file"
                      ref={inputFile}
                      style={{ display: 'none' }}
                      accept="audio/*,video/*,image/*"
                      multiple={false}
                      onChange={(event) => {
                        const file = event.target.files?.item(0);
                        if (file) {
                          setIsProcessing(true);
                          uploadFile(file)
                            .then(() => {
                              setIsProcessing(false);
                            })
                            .catch((error) => {
                              console.error(`uploadFile: ${JSON.stringify(error)}`);
                              toast(uploadError);
                            });

                          // Reset input value to allow for this callback
                          // to fire again so we can show toast.
                          event.target.value = '';
                        }
                      }}
                    />
                  </Box>
                  <DraggableAudioList
                    tavernId={tavernId}
                    isDraggable
                    onRemove={(id) => removeExternalAudio(tavernId as string, id)}
                  />
                </ScrollableGradientBox>
              </Box>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
