/* eslint-disable */
import { sliderAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
import { orient } from '@chakra-ui/theme-tools';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(sliderAnatomy.keys);

const baseStyle = defineStyle((props) => {
  const { orientation } = props;

  return {
    container: {
      ...orient({
        orientation,
        vertical: { h: '100%', minH: '100px', w: '10px' },
        horizontal: { w: '100%', minW: '100px', h: '10px' },
      }),
    },
    track: {
      borderRadius: '0',
      background: 'typographySecondary',
      ...orient({
        orientation,
        vertical: { w: '1px' },
        horizontal: { h: '1px' },
      }),
    },
    thumb: {
      boxSize: '10px',
      background: 'typographyPrimary',
    },
    filledTrack: {
      h: '2px',
      background: 'typographyPrimary',
    },
    mark: {
      transform: 'translateX(-50%)',
      background: 'typographySecondary',
      ...orient({
        orientation,
        vertical: {
          w: '4px',
          h: '1px',
          '&.slider-mark-left': {
            left: '-4px',
          },
          '&.slider-mark-right': {
            right: '-8px',
          },
        },
        horizontal: {
          w: '1px',
          h: '4px',
          '&.slider-mark-left': {
            bottom: '-6px',
          },
          '&.slider-mark-right': {
            top: '-6px',
          },
        },
      }),
    },
  };
});

export const TavernSliderStyles = defineMultiStyleConfig({ baseStyle });
