import { FC } from 'react';
import { upperFirst } from 'lodash';
import { Avatar, Box, Flex, Text } from '@chakra-ui/react';
import { UserStatusProps } from './types';

export const UserStatus: FC<UserStatusProps> = (props) => {
  const { avatar, status, ...restProps } = props;

  return (
    <Box display="inline-flex" textStyle="body14regular" color="typographyPrimary" {...restProps}>
      <Flex>
        <Avatar
          border="1px solid"
          borderColor="typographyPrimary"
          objectFit="cover"
          boxSize="24px"
          borderRadius="50%"
          src={avatar}
        />
        <Flex alignItems="center" _hover={{ textDecoration: 'none' }}>
          <Text ml="8px" color="typographySecondary">
            You
          </Text>
          <Text ml="4px" color="typographyPrimary">
            {status === 'joiner' || status === 'listener' ? 'Audience' : upperFirst(status)}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};
