import { Box, useBoolean, useBreakpointValue } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';

import { TavernNotificationsWrapper } from '../TavernNotificationsWrapper/TavernNotificationsWrapper';
import { Header } from '../Header/Header';
import { Sidebar } from '../sidebar/sidebar';
import { GlobalPlayer } from '../player/global-player';

export const Layout = () => {
  const [isOpen, setIsOpen] = useBoolean();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useBoolean();
  const isHeaderVisible = useBreakpointValue({ base: true, '2xl': false });
  const location = useLocation();

  const sidebarToggle = () => {
    document.body.classList.toggle('disableScroll');
    setIsOpen.toggle();
  };

  const hideSidebar = () => {
    document.body.classList.remove('disableScroll');
    setIsOpen.off();
  };

  const isLanding = location.pathname === '/';

  return (
    <TavernNotificationsWrapper>
      <Box overflow={isOpen ? 'hidden' : ''}>
        {isHeaderVisible && !isLanding && <Header isHamburgerOpen={isOpen} onHamburgerClick={sidebarToggle} />}
        <Box h="100%" w="100%">
          {!isLanding && (
            <Sidebar
              isCollapsed={isSidebarCollapsed}
              onCollapseButtonClick={setIsSidebarCollapsed.toggle}
              onSidebarToggle={hideSidebar}
              isOpen={isOpen}
            />
          )}
          <Box
            position="fixed"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bgColor="backgroundOverlay"
            zIndex="10"
            transition="0.3s ease-in"
            visibility={isOpen ? 'visible' : 'hidden'}
            opacity={isOpen ? 1 : 0}
            onClick={sidebarToggle}
          />
          <Box
            width="100%"
            transition="0.3s ease-in"
            pl={{ base: 0, '2xl': isLanding ? '0px' : isSidebarCollapsed ? '72px' : '280px' }}
          >
            <Box
              paddingTop={{
                base: !isLanding ? '84px' : '32px',
                '2xl': '32px',
              }}
              paddingX={{
                base: '12px',
                md: '14px',
                lg: '16px',
                xl: '24px',
                '2xl': '32px',
                '3xl': '64px',
              }}
              minH="100vh"
              overflowX="hidden"
            >
              <Outlet />
            </Box>
            <GlobalPlayer />
          </Box>
        </Box>
      </Box>
    </TavernNotificationsWrapper>
  );
};
