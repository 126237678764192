import { Tag } from '@chakra-ui/react';
import { noop } from 'lodash';
import { TopicTagProps } from './types';

const hoverClickableStyle = {
  cursor: 'pointer',
  color: 'typographyAdditional',
  bgColor: 'backgroundInputHover',
};

export const TopicTag = (props: TopicTagProps) => {
  const { onClick = noop, isClickable, ...rest } = props;

  return <Tag {...rest} onClick={onClick} textStyle="body14regular" _hover={isClickable ? hoverClickableStyle : {}} />;
};
