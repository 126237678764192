import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';

import AlertModal from '../AlertModal/AlertModal';
import GlobalPlayerContext from '../../context/global-player-context';
import { AlertModalProps } from '../AlertModal/types';
import { IconDelete, IconRevert } from '../../theme/foundations/customIcons';
import { Tavern, TavernStatus } from '../../types/tavern';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';
import { auth } from '../../connect';
import { destroyTavern } from '../../services/tavern-functions';
import { useTavernToast } from '../hooks/useTavernToast';

export const DestroyTavernButton = (props: { tavern: Tavern }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { connectedTavern, setConnectedTavern } = useContext(GlobalPlayerContext);

  const toast = useTavernToast();
  const navigate = useNavigate();

  const [isProcessing, setIsProcessing] = useState(false);

  if (props.tavern.host.id !== auth.currentUser?.uid) {
    return <></>;
  }

  const modalProps: AlertModalProps = {
    isOpen,
    onClose,
    isDestructive: true,
    title: 'Destroy Tavern',
    description: 'Are you sure you want to destroy this the Tavern?',
    primaryButton: {
      text: 'Destroy',
      isDestructive: true,
      isLoading: isProcessing,
      onClick: () => {
        setIsProcessing(true);
        destroyTavern(props.tavern.id, false)
          .then(() => {
            toast({
              title: `Destroyed Tavern.`,
              description: "This Tavern has been destroyed. There's nothing left.",
              status: 'success',
              duration: 2000,
              isClosable: true,
            });
            onClose?.();
          })
          .catch((error: any) => {
            console.error('Error destroying Tavern', JSON.stringify(error));
          })
          .finally(() => {
            setIsProcessing(false);
            navigate(`/profiles/${auth.currentUser?.uid as string}`);
          });

        // Make sure to disconnect if listening to recording.
        if (connectedTavern?.id === props.tavern.id && connectedTavern?.status === TavernStatus.Past) {
          setConnectedTavern(null);
        }
      },
    },
    secondaryButton: {
      text: 'Cancel',
      onClick: () => {
        onClose();
      },
    },
  };

  return (
    <>
      <TavernButton leftIcon={<IconDelete />} variant="secondary" onClick={onOpen}>
        Destroy
      </TavernButton>
      <AlertModal {...modalProps} />
    </>
  );
};

export const FadeTavernButton = (props: { tavern: Tavern }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { connectedTavern, setConnectedTavern } = useContext(GlobalPlayerContext);

  const toast = useTavernToast();

  const [isProcessing, setIsProcessing] = useState(false);

  if (props.tavern.host.id !== auth.currentUser?.uid) {
    return <></>;
  }

  const modalProps: AlertModalProps = {
    isOpen,
    onClose,
    isDestructive: true,
    title: 'Fade Tavern',
    description:
      'Are you sure you want to fade this the Tavern? It will remove recordings and captions, but will keep everything else.',
    primaryButton: {
      text: 'Fade',
      isDestructive: true,
      isLoading: isProcessing,
      onClick: () => {
        setIsProcessing(true);
        destroyTavern(props.tavern.id, true)
          .then(() => {
            toast({
              title: `Faded Tavern.`,
              description: 'The Tavern was once here, but now has been faded.',
              status: 'success',
              duration: 2000,
              isClosable: true,
            });
            onClose?.();
          })
          .catch((error: any) => {
            console.error('Error fading Tavern', JSON.stringify(error));
          })
          .finally(() => {
            setIsProcessing(false);
          });

        // Make sure to disconnect if listening to recording.
        if (connectedTavern?.id === props.tavern.id && connectedTavern?.status === TavernStatus.Past) {
          setConnectedTavern(null);
        }
      },
    },
    secondaryButton: {
      text: 'Cancel',
      onClick: () => {
        onClose();
      },
    },
  };

  return (
    <>
      <TavernButton leftIcon={<IconRevert />} variant="secondary" onClick={onOpen}>
        Fade
      </TavernButton>
      <AlertModal {...modalProps} />
    </>
  );
};
