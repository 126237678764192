/* eslint-disable */
import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  dialog: {
    borderRadius: 0,
    bg: 'backgroundModal',
    padding: {
      base: '20px 16px 24px',
      xl: '24px 32px 32px',
    },
    boxShadow: 'unset',
  },
  closeButton: {
    marginTop: {
      base: '10px',
      xl: '18px',
    },
    marginRight: {
      base: '0',
      xl: '16px',
    },
    borderRadius: '50px',
    color: 'typographySecondary',
    outline: 'none',
    boxSize: '40px',
    svg: {
      boxSize: '24px',
    },
  },
});

const variantFluid = definePartsStyle(() => {
  return {
    dialog: {
      padding: 0,
      my: 0,
    },
    dialogContainer: {
      bg: { base: 'backgroundModal', xl: 'none' },
      margin: { base: '0', xl: 16 },
    },
    body: {
      padding: 0,
    },
  };
});

const variants = {
  fluid: variantFluid,
};

export const TavernModalStyles = defineMultiStyleConfig({
  baseStyle,
  variants,
});
