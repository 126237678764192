import { Box, Flex, FormControl, FormLabel, Heading, Text, VStack } from '@chakra-ui/react';
import { doc, setDoc } from 'firebase/firestore';
import { useContext, useMemo, useEffect } from 'react';

import AuthContext from '../context/auth';
import { IconSettings } from '../theme/foundations/customIcons';
import { TavernSwitch } from '../components/uiKit/TavernSwitch/TavernSwitch';
import { UserNotificationSettings } from '../types/user';
import { db } from '../connect';

export const defaultNotificationSettings: UserNotificationSettings = {
  myScheduledTaverns: true,
  goingToTaverns: true,
  newFollowers: true,
  followeeStartsTavern: true,
  followeeJoinsTavern: true,
};

export const SettingsScreen = () => {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    document.title = `Settings`;
  }, []);

  const notificationSettings = useMemo(() => {
    if (!user) {
      return defaultNotificationSettings;
    }
    return {
      ...defaultNotificationSettings,
      ...(user.notificationSettings || {}),
    };
  }, [user]);

  const setSettingTo = (setting: keyof UserNotificationSettings, value: boolean) => {
    if (!user) {
      return;
    }
    const sessionDoc = doc(db, 'users', user.id);
    setDoc(sessionDoc, { notificationSettings: { [setting]: value } }, { merge: true }).catch(console.error);
  };

  const switchForSetting = (setting: keyof UserNotificationSettings) => {
    return (
      <TavernSwitch
        isChecked={notificationSettings[setting]}
        onChange={(e) => {
          setSettingTo(setting, e.target.checked);
        }}
      />
    );
  };

  return (
    <Box>
      <Heading mb={{ base: '32px', xl: '40px' }}>
        <Flex alignItems="center">
          <IconSettings color="typographySecondary" mr="12px" boxSize="32px" />
          <Text textStyle="h2">Settings</Text>
        </Flex>
      </Heading>
      <Box mb={{ base: '32px', xl: '40px' }}>
        <Text color="typographySecondary" mb={{ base: '24px', xl: '32px' }} textStyle="h4">
          Notifications
        </Text>
        <Text color="typographySecondary" mb="16px" textStyle="body16regular">
          Scheduled Taverns
        </Text>
        <VStack spacing={{ base: '12px', xl: '16px' }}>
          <FormControl display="flex">
            {switchForSetting('myScheduledTaverns')}
            <FormLabel textStyle="body16regular" mb="0" ml="12px" mr="8px">
              My scheduled Taverns
            </FormLabel>
          </FormControl>
          <FormControl display="flex">
            {switchForSetting('goingToTaverns')}
            <FormLabel textStyle="body16regular" mb="0" ml="12px" mr="8px">
              Taverns I am going to
            </FormLabel>
          </FormControl>
        </VStack>
        <Text mt="32px" color="typographySecondary" mb="16px" textStyle="body16regular">
          Following
        </Text>
        <VStack spacing={{ base: '12px', xl: '16px' }}>
          <FormControl display="flex">
            {switchForSetting('newFollowers')}
            <FormLabel textStyle="body16regular" mb="0" ml="12px" mr="8px">
              New followers
            </FormLabel>
          </FormControl>
          <FormControl display="flex">
            {switchForSetting('followeeStartsTavern')}
            <FormLabel textStyle="body16regular" mb="0" ml="12px" mr="8px">
              Someone I follow starts a Tavern
            </FormLabel>
          </FormControl>
          <FormControl display="flex">
            {switchForSetting('followeeJoinsTavern')}
            <FormLabel textStyle="body16regular" mb="0" ml="12px" mr="8px">
              Someone I follow joins a Tavern
            </FormLabel>
          </FormControl>
        </VStack>
      </Box>
    </Box>
  );
};
