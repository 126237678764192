import { Tooltip, TooltipProps } from '@chakra-ui/react';

export const TavernTooltip = (props: TooltipProps) => {
  return (
    <Tooltip
      bg="backgroundAdditional"
      color="typographyAdditional"
      textStyle="body12regular"
      fontSize="sm"
      padding="8px 12px"
      placement="top"
      openDelay={250}
      {...props}
    />
  );
};
