import Carousel from 'nuka-carousel';
import { Box, Button, chakra, HStack, useBreakpointValue } from '@chakra-ui/react';

import { IconArrowLeft, IconArrowRight } from '../../theme/foundations/customIcons';
import { ImageBlock } from '../ImageBlock/ImageBlock';
import { LinkBlock } from '../LinkBlock/LinkBlock';
import { LinksCarouselProps } from './types';
import { TweetBlock } from '../VideoBlock/TweetBlock';
import { VideoBlock } from '../VideoBlock/VideoBlock';
import { isImage, isMedia, isTweetLink, isVideo } from '../../utils/link-util';

const LinksCarouselComponent = (props: LinksCarouselProps) => {
  const { links, isDotsEnabled, hasHostingPermission, ...rest } = props;

  const containsMedia = links.find((link) => isMedia(link));
  const slidesToShow =
    useBreakpointValue({
      base: 1,
      // Expand slide size for video blocks which are
      // wider than link blocks.
      xl: 2 - (containsMedia ? 1 : 0),
      '3xl': 3 - (containsMedia ? 1 : 0),
    }) || 1;

  if (links.length === 0) {
    return <></>;
  }

  return (
    <Box {...rest}>
      <Carousel
        style={{ overflow: 'unset' }}
        cellSpacing={24}
        slidesToShow={slidesToShow}
        renderBottomCenterControls={({ goToSlide, currentSlide, slideCount }) => {
          return !isDotsEnabled ? (
            <></>
          ) : (
            <HStack>
              {[...Array(Math.ceil(slideCount / slidesToShow))].map((x, i) => (
                <Button
                  type="button"
                  key={i}
                  onClick={() => goToSlide(i)}
                  width="8px"
                  height="8px"
                  minWidth="8px"
                  padding="0"
                  display="block"
                  borderRadius="8px"
                  bg={currentSlide === i ? 'brand.900' : 'white'}
                  _hover={{ bg: 'brand.1000' }}
                />
              ))}
            </HStack>
          );
        }}
        renderCenterLeftControls={({ previousDisabled, previousSlide }) => (
          <Button
            mt="-25px"
            minW="32px"
            minH="32px"
            boxSize="32px"
            p="0"
            borderRadius="50%"
            opacity={previousDisabled ? '0' : '1'}
            visibility={previousDisabled ? 'hidden' : 'visible'}
            onClick={previousSlide}
            disabled={previousDisabled}
          >
            <IconArrowLeft color="typographySecondary" boxSize="16px" />
          </Button>
        )}
        renderCenterRightControls={({ nextDisabled, nextSlide }) => (
          <Button
            mt="-25px"
            minW="32px"
            minH="32px"
            boxSize="32px"
            p="0"
            borderRadius="50%"
            opacity={nextDisabled ? '0' : '1'}
            visibility={nextDisabled ? 'hidden' : 'visible'}
            onClick={nextSlide}
            disabled={nextDisabled}
          >
            <IconArrowRight color="typographySecondary" boxSize="16px" />
          </Button>
        )}
      >
        {links.map((link) => {
          if (isVideo(link)) {
            return (
              <Box key={link.id} height="250px">
                <VideoBlock link={link} hasHostingPermission={hasHostingPermission} />
              </Box>
            );
          }
          if (isImage(link)) {
            return (
              <Box key={link.id} height="250px">
                <ImageBlock link={link} hasHostingPermission={hasHostingPermission} />
              </Box>
            );
          }

          if (isTweetLink(link)) {
            return (
              <Box key={link.id} height="250px">
                <TweetBlock link={link} hasHostingPermission={hasHostingPermission} />
              </Box>
            );
          }
          return (
            <Box key={link.id} maxHeight="250px">
              <LinkBlock
                image={link.imageUrl}
                title={link.title}
                description={link.description}
                link={link.link}
                linkId={link.id}
                sharedTime={link.timestamp}
                sharedUser={link.user.name}
                hasDropdown={hasHostingPermission}
              />
            </Box>
          );
        })}
      </Carousel>
    </Box>
  );
};

export const LinksCarousel = chakra(LinksCarouselComponent);
