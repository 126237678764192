import { Box, chakra, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import { CaptionsListProps } from './types';
import { ScrollableGradientBox } from '../ScrollableGradientBox/ScrollableGradientBox';
import { UserDetailsHorizontal } from '../UserDetailsHorizontal/UserDetailsHorizontal';

const CaptionsListComponent = (props: CaptionsListProps) => {
  const { captions, ...rest } = props;

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const captionsList = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isFirstLoad && captions.length > 0) {
      captionsList.current?.scrollTo(0, Number.MAX_SAFE_INTEGER);
      setIsFirstLoad(false);
    }
  }, [captionsList, isFirstLoad, captions]);

  return (
    <ScrollableGradientBox scrollRef={captionsList} {...rest}>
      <Box>
        <div style={{ overflowAnchor: 'none' }}>
          {captions.map((caption, i) => {
            const { author, transcriptions, onClick, side = 'left' } = caption;
            return (
              <Box
                as="button"
                display="block"
                mb="18px"
                textAlign={side}
                ml={side === 'right' ? 'auto' : 0}
                mr={side === 'left' ? 'auto' : 0}
                onClick={onClick}
                key={i}
              >
                <UserDetailsHorizontal
                  id={author.id}
                  name={author.name}
                  avatar={author.session?.nft || author.avatar}
                  isAvatarBordered
                  textAlign={side}
                  mb="8px"
                />
                {transcriptions?.map((transcript: string, k: number) => {
                  return (
                    <Text key={k} id={transcript + i.toString()} fontSize="small" color="brandPrimary">
                      {transcript}
                    </Text>
                  );
                })}
              </Box>
            );
          })}
        </div>
        <div style={{ overflowAnchor: 'auto', height: '1px' }} />
      </Box>
    </ScrollableGradientBox>
  );
};

export const CaptionsList = chakra(CaptionsListComponent);
