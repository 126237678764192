import { DocumentData, DocumentReference } from 'firebase/firestore';

export interface TavernsObject {
  [key: string]: Tavern;
}

export type Tavern = {
  id: string;
  title: string;
  description?: string;
  startTime: number;
  status: TavernStatus;
  isVisible: boolean;
  sessions: Session[];
  banList: string[];
  creator: DocumentReference<DocumentData>;
  host: TavernHost;
  listenerCount: number;
  containsFeaturedImage: boolean;
  endTime?: number;
  playCount: number;
  joinAllowlist?: string[];
  topicNames?: string[];
  coHosts?: string[];
  recordingStartTime?: number;
  recordingEndTime?: number;
  score?: number;
  audioHash?: string;
  transcriptHash?: string;
  htmlHash?: string;
  processingIPFS?: boolean;
  threadId?: string;
  org?: string;
  scoreMultiplier?: number;
  faded?: boolean;
};

export enum TavernStatus {
  Live = 'Live',
  Past = 'Past',
  Upcoming = 'Upcoming',
}

export enum SessionType {
  Listener = 'listener',
  Joiner = 'joiner',
  Speaker = 'speaker',
  CoHost = 'coHost',
  Host = 'host',
}

export type Session = {
  id: string;
  user: DocumentReference<DocumentData>;
  name: string | null;
  startTime: number;
  endTime: number | null;
  type: SessionType;
  isMuted: boolean;
  isForceMuted: boolean;
  agoraUid: number;
  avatar?: string;
  nft?: string;
  nftId?: string;
  isSpeaking: boolean;
  isAnon: boolean;
};

export type SpeakRequest = {
  id: string;
  user: DocumentReference<DocumentData>;
  timestamp: number;
  type: SessionType;
  respondingHost?: DocumentReference<DocumentData>;
  status: RequestInviteStatus;
  avatar?: string;
  name?: string;
};

export enum RequestInviteStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export type SpeakInvite = {
  id: string;
  user: DocumentReference<DocumentData>;
  name: string | null;
  avatar: string;
  timestamp: number;
  type: SessionType;
  invitingHost: DocumentReference<DocumentData>;
  invitingHostName: string | null;
  status: RequestInviteStatus;
};

export type Reaction = {
  id: string;
  session: Session;
  timestamp: number;
  type: string;
  reactionTime: number;
};

export type Gif = {
  id: string;
  session: Session;
  timestamp: number;
};

export type Score = {
  id: string;
  score: number;
};

export type AudioClip = {
  id: string;
  session: DocumentReference<DocumentData>;
  startTime: number;
  userId: string;
  endTime?: number;
  caption?: string;
  avatar?: string;
  userName: string | null;
};

export type AnonResponse = {
  id: string;
  startTime: number;
  endTime: number;
  response: string;
};

export type Link = {
  id: string;
  link: string;
  timestamp: number;
  type: 'url' | 'storage-video' | 'storage-image';
  video?: {
    state: string;
    lastUpdatedPosition: number;
  };
};

export type LinkExtra = Link & {
  user: {
    id: string;
    name: string;
  };
  timestamp: number;
  title: string;
  description: string;
  imageUrl?: string;
};

export type Topic = {
  id: string;
  name: string;
};

export type Times = {
  value: string;
  label: string;
};

export type TavernHost = {
  id: string;
  name: string;
  avatar?: string;
  twitter?: string;
  nft?: string;
};
