import { Box, Heading, HStack, Text } from '@chakra-ui/react';
import { ChangeEvent, FC, useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';

import { DatetimePickerForm } from '../DatetimePickerForm/DatetimePickerForm';
// import { FeaturedImageUploader } from '../FeaturedImageUploader/FeaturedImageUploader';
import { IconPublish } from '../../theme/foundations/customIcons';
import { Tavern, TavernStatus } from '../../types/tavern';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';
import { TavernFormControl } from '../uiKit/TavernFormControl/TavernFormControl';
import { TavernFormProps } from './types';
import { TavernInput } from '../uiKit/TavernInput/TavernInput';
import { TextareaWithLimit } from '../TextareaWithLimit/TextareaWithLimit';
// import { TopicsManageForm } from '../TopicsManageForm/TopicsManageForm';
import { buildDateWithTimezone } from '../../utils/date';
import { editTavern } from '../../utils/tavern-util';
import { useTavernToast } from '../hooks/useTavernToast';
import { OrgPickerSelect } from './TavernOrgPicker';
import { db } from '../../connect';
import AuthContext from '../../context/auth';

export const TavernEdit: FC<TavernFormProps> = ({ initData }) => {
  const { tavernId } = useParams();
  const { user } = useContext(AuthContext);
  const [title, setTitle] = useState(initData?.title ?? '');
  // const [featuredImageUrl, setFeaturedImageUrl] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState(initData?.description ?? '');
  const [isLoading, setIsLoading] = useState(false);

  const [startDate, setStartDate] = useState(initData?.startTime || Date.now());
  const [tzOffset, setTxOffset] = useState(new Date().getTimezoneOffset() * -1);

  const [org, setOrg] = useState('personal');

  const navigate = useNavigate();
  const toast = useTavernToast();

  // const [topics, setTopics] = useState<Topic[]>([]);

  useEffect(() => {
    document.title = `Edit Tavern`;
  }, []);

  useEffect(() => {
    if (user === null) {
      return;
    }

    getDoc(doc(db, 'taverns', tavernId as string))
      .then((docSnap) => {
        if (!docSnap.exists()) {
          throw new Error();
        }

        const tavern = docSnap.data() as Tavern;

        if (tavern.host.id !== user.id || tavern.status !== TavernStatus.Upcoming) {
          navigate(`/taverns/${tavernId as string}`);
        }

        setTitle(tavern.title);
        setDescription(tavern.description || '');
        setOrg(tavern.org || '');
        setStartDate(tavern.startTime);

        if (tavern.containsFeaturedImage) {
          // TODO GET Image
          // setFeaturedImageUrl()
        }
      })
      .catch((error) => {
        console.error('Tavern error', error);
        navigate(`/taverns/${tavernId as string}`);
      });
  }, [user]);

  const handleDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  // const handleTopicsChange = (changedTopics: Topic[]) => {
  //   setTopics(changedTopics);
  // };

  const handleFormSubmit = () => {
    const isScheduled = true;
    const startTime = isScheduled ? buildDateWithTimezone(new Date(startDate), tzOffset) : undefined;
    setIsLoading(true);

    if (!tavernId) {
      return;
    }

    editTavern(
      {
        tavernId,
        title,
        description,
        startTime,
        org,
      },
      setIsLoading,
      navigate,
      toast
    )
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  };

  const isScheduledTavernInvalid = () => {
    return !title || !title.length || !startDate || buildDateWithTimezone(new Date(startDate), tzOffset) < Date.now();
  };

  const isSubmitButtonDisabled = () => {
    return isScheduledTavernInvalid();
  };

  return (
    <Box
      as="div"
      display="flex"
      alignContent="flex-start"
      justifyContent="flex-start"
      flexDirection="column"
      w="100%"
      pb="32px"
    >
      <Heading mb="40px">Edit Tavern</Heading>
      <Text textStyle="h4" mb="32px" color="typographySecondary">
        General
      </Text>
      <TavernFormControl mb="24px" label="Title">
        <TavernInput value={title} autoFocus onChange={(e) => setTitle(e.target.value)} />
      </TavernFormControl>
      {/* <Box mb="24px">
        <HStack mb="8px">
          <Text textStyle="body14regular" color="white">
            Featured Image
          </Text>
          <Box as="span" ml="8px" color="typographyTertiary" textStyle="body14medium">
            Optional
          </Box>
        </HStack>
        <FeaturedImageUploader croppedImageReceived={setFeaturedImageUrl} imageUrl={featuredImageUrl} />
      </Box> */}
      <Box mb="24px">
        <TextareaWithLimit
          value={description}
          onChange={handleDescription}
          label="Description"
          isOptional
          placeholder="Description"
          maxLength={200}
        />
      </Box>
      <Box mb="40px">
        <HStack mb="8px">
          <Text textStyle="body14regular" color="white">
            Organization
          </Text>
        </HStack>
        <OrgPickerSelect onSelect={setOrg} value={{ label: org, value: org }} />
      </Box>

      {/* <Box mb="24px">
        <HStack mb="8px">
          <Text textStyle="body14regular" color="white">
            Topics
          </Text>
          <Box as="span" ml="8px" color="typographyTertiary" textStyle="body14medium">
            Optional
          </Box>
        </HStack>
        <TopicsManageForm topics={topics} onChange={handleTopicsChange} maxTopics={5} />
      </Box> */}

      <Box mb="40px">
        <DatetimePickerForm
          minDatetime={Date.now()}
          date={startDate}
          timezoneOffset={tzOffset}
          onDateChange={setStartDate}
          onTimezoneChange={setTxOffset}
        />
      </Box>

      <HStack>
        <TavernButton
          isLoading={isLoading}
          isDisabled={isSubmitButtonDisabled()}
          variant="primary"
          onClick={handleFormSubmit}
          mr="16px"
        >
          <IconPublish mr="8px" />
          Save Tavern
        </TavernButton>
        <TavernButton
          variant="secondary"
          onClick={() => {
            navigate(`/taverns/${tavernId as string}`);
          }}
          mr="16px"
        >
          Cancel
        </TavernButton>
      </HStack>
    </Box>
  );
};
