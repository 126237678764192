import React, { useMemo } from 'react';
import { ActionMeta, ChakraStylesConfig, Select, SingleValue } from 'chakra-react-select';
import { noop } from '../../utils/noop';
import { MicrophoneOption, MicrophoneSelectProps } from './types';
import { useAudioDevice } from '../../hooks/useAudioDevice';

// Make sure this is defined outside of the component which returns your select
// or you'll run into rendering issues
const components = {
  IndicatorSeparator: () => null,
  DropdownIndicator: () => null,
};
const chakraStyles: ChakraStylesConfig<MicrophoneOption, false> = {
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isSelected ? 'globalWhite' : 'typographySecondary',
    bgColor: state.isSelected ? 'backgroundInput' : 'none',
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    scrollbarWidth: 'thin',
    scrollbarColor: 'typographyTertiary backgroundTertiary',
    '::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      w: '2px',
    },
    '::-webkit-scrollbar-thumb': {
      bgColor: 'typographyTertiary',
    },
    '::-webkit-scrollbar-track': {
      bgColor: 'backgroundTertiary',
    },
  }),
  container: (baseStyles) => ({
    ...baseStyles,
    display: 'flex',
    width: 'auto',
    height: '100%',
    cursor: 'pointer',
    padding: '2px 4px',
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    display: 'flex',
    height: '100%',
    flex: '1 1 0',
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    width: 'auto',
    height: '100%',
    padding: '0',
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    display: 'flex',
    width: '100%',
    height: '22px',
    fontSize: '12px',
    cursor: 'pointer',
    padding: '0',
    minHeight: 'auto',
  }),
};

export const MicrophoneSelect = (props: MicrophoneSelectProps) => {
  const { audioDevices, setSelectedDevice, selectedDevice, selectedDeviceLabel } = useAudioDevice();
  const { onSelect = noop, ...rest } = props;

  const buildOptionFromDevice = (device: MediaDeviceInfo) => {
    return { label: device.label, value: device.deviceId };
  };

  const options = useMemo(() => {
    return audioDevices.map(buildOptionFromDevice);
  }, [audioDevices]);

  const selectedValue = useMemo(() => {
    return { label: selectedDeviceLabel || 'default', value: selectedDevice || '' };
  }, [selectedDevice, selectedDeviceLabel]);

  const handleChange = (option: SingleValue<MicrophoneOption>, actionMeta: ActionMeta<MicrophoneOption>) => {
    switch (actionMeta.action) {
      case 'select-option':
        if (option) {
          setSelectedDevice(option.value);
          onSelect(option);
        }
        break;
      default:
        break;
    }
  };

  return (
    <Select<MicrophoneOption>
      chakraStyles={chakraStyles}
      options={options}
      menuPlacement="top"
      value={selectedValue}
      isSearchable={false}
      onChange={handleChange}
      components={components}
      {...rest}
    />
  );
};
