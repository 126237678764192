import { Flex, MenuButton, MenuList, MenuItem, Text, Spinner } from '@chakra-ui/react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { IconMeatballs, IconDelete } from '../../theme/foundations/customIcons';
import { LinkExtra } from '../../types/tavern';
import { TavernDropdown } from '../uiKit/TavernDropdown/TavernDropdown';
import { deleteLink } from '../../services/tavern-functions';
import { getSharedTime } from '../../utils/link-util';

export const MediaBlockMetadata = (props: { link: LinkExtra; hasHostingPermission: boolean }) => {
  const { link, hasHostingPermission } = props;
  const { tavernId } = useParams();
  const [deleteIsPending, setDeleteIsPending] = useState(false);

  const showDeleteOption = hasHostingPermission;

  return (
    <Flex mt="7px" textStyle="body12regular" justifyContent="space-between" w="100%">
      <Flex>
        <Text color="typographySecondary">Shared by</Text>
        <Text ml="8px" color="typographyAdditional">
          {link.user.name}
        </Text>
      </Flex>
      <Flex alignItems="center">
        <Text color="typographySecondary">at {getSharedTime(link)}</Text>
        {showDeleteOption && (
          <TavernDropdown>
            <MenuButton
              ml="12px"
              bg="0"
              boxSize="24px"
              borderRadius="50%"
              _expanded={{ bg: 'backgroundInput' }}
              _hover={{ bg: 'backgroundInput' }}
              aria-label="actions"
            >
              <IconMeatballs color="typographySecondary" boxSize="24px" />
            </MenuButton>
            <MenuList>
              <MenuItem
                closeOnSelect={false}
                onClick={() => {
                  setDeleteIsPending(true);
                  deleteLink(tavernId as string, link.id)
                    .then(() => {
                      setDeleteIsPending(false);
                    })
                    .catch((error) => console.error(error));
                }}
                icon={deleteIsPending ? <Spinner /> : <IconDelete boxSize="16px" />}
              >
                Delete
              </MenuItem>
            </MenuList>
          </TavernDropdown>
        )}
      </Flex>
    </Flex>
  );
};
