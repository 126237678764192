import { Text } from '@chakra-ui/react';
import { TwitterTweetEmbed } from 'react-twitter-embed';

import { LinkExtra } from '../../types/tavern';
import { MediaBlockMetadata } from '../MediaBlockMetadata/MediaBlockMetadata';
import { twitterIdFromLink } from '../../utils/link-util';

export const TweetBlock = (props: { link: LinkExtra; hasHostingPermission: boolean }) => {
  const { link, hasHostingPermission } = props;

  const id = twitterIdFromLink(link.link);
  return (
    <>
      {id === null ? (
        <Text>Invalid Twitter link </Text>
      ) : (
        <TwitterTweetEmbed tweetId={id} options={{ width: '540px', height: '100%', maxHeight: '360px' }} />
      )}
      <MediaBlockMetadata link={link} hasHostingPermission={hasHostingPermission} />
    </>
  );
};
