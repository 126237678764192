import { Image, ImageProps, Modal, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { useState } from 'react';

type ExpandableImageProps = ImageProps;

export const ExpandableImage = (props: ExpandableImageProps) => {
  const { src, borderRadius = 'md', objectFit = 'contain', ...rest } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Image
        src={src}
        onClick={(event) => {
          event.stopPropagation();
          setIsOpen(true);
        }}
        cursor="pointer"
        boxShadow="lg"
        _hover={{ boxShadow: 'xl' }}
        borderRadius={borderRadius}
        objectFit={objectFit}
        {...rest}
      />
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent maxW="60vh" maxH="60vh" p={0}>
          <Image src={src} objectFit="contain" />
          {/* TODO: Need a better high-contrast button. */}
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};
