import {
  CircularProgress,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FC } from 'react';
import { IconCross } from '../../theme/foundations/customIcons';
import { TavernModalProps } from './types';

export const TavernModal: FC<TavernModalProps> = ({ ...props }) => {
  const { children, header, footer, isCloseButtonVisible, maxWidth = '400px', isLoading, ...cleanProps } = props;
  return (
    <Modal {...cleanProps}>
      <ModalOverlay bg="backgroundOverlay" />
      <ModalContent maxW={maxWidth}>
        {header && <ModalHeader p="0">{header}</ModalHeader>}
        {isCloseButtonVisible && (
          <ModalCloseButton zIndex="5">
            <IconCross />
          </ModalCloseButton>
        )}
        {isLoading && (
          <Flex
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            zIndex="4"
            bg="backgroundModal"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress
              size="60px"
              thickness="5px"
              color="typographySecondary"
              trackColor="backgroundInput"
              isIndeterminate
            />
          </Flex>
        )}
        <ModalBody p="0">{children}</ModalBody>
        {footer && <ModalFooter p="0">{footer}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
};
