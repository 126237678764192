import { FC } from 'react';
import { Box, chakra } from '@chakra-ui/react';
import { ScrollableGradientBoxProps } from './types';

const ScrollableGradientBoxComponent: FC<ScrollableGradientBoxProps> = ({
  children,
  scrollHeight,
  scrollRef,
  gradient = 'gradientCard',
  isGradientDisabled = false,
  ...rest
}) => {
  return (
    <Box position="relative" {...rest}>
      {!isGradientDisabled && (
        <Box height="48px" position="absolute" bottom={0} right={0} left={0} pointerEvents="none" bg={gradient} />
      )}
      <Box
        ref={scrollRef}
        overflowY="auto"
        overflowX="hidden"
        height={`${scrollHeight}`}
        pb="16px"
        pr="8px"
        sx={{
          scrollbarWidth: 'thin',
          scrollbarColor: 'typographyTertiary backgroundTertiary',
          '::-webkit-scrollbar': {
            WebkitAppearance: 'none',
            w: '2px',
          },
          '::-webkit-scrollbar-thumb': {
            bgColor: 'typographyTertiary',
          },
          '::-webkit-scrollbar-track': {
            bgColor: 'backgroundTertiary',
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export const ScrollableGradientBox = chakra(ScrollableGradientBoxComponent);
