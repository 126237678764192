import {
  Box,
  Flex,
  HStack,
  Spacer,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';

import GlobalPlayerContext from '../context/global-player-context';
import TavernDetailContext from '../context/tavern-detail-context';
import { ActivityGanttChart } from '../components/TavernActivityChartContainer/ActivityGanttChart';
import { DestroyTavernButton, FadeTavernButton } from '../components/buttons/destroy-tavern-button';
import { FeaturedImage } from '../components/FeaturedImage/FeaturedImage';
import { ParticipantsSection } from '../components/TavernPage/ParticipantsSection/ParticipantsSection';
import { ListenToTavernButton } from '../components/buttons/listen-to-tavern-button';
import { MediaCarouselContainer } from '../components/MediaCarouselContainer/MediaCarouselContainer';
import { Session, SessionType, Tavern } from '../types/tavern';
import { TavernTabs } from '../components/uiKit/TavernTabs/TavernTabs';
import { TavernUserCountsStack } from '../components/tavern-user-counts-stack';
import { TopicsList } from '../components/uiKit/TopicsList/TopicsList';
import { getRecordingUrlForTavern } from '../utils/player-util';
import { sessionAsUserMock } from '../components/lists/utils/list-util';
import { sessionsFilteredForReplay } from '../utils/session-util';
import { DownloadTavernButton } from '../components/buttons/download-tavern-button';
// import { AnalyzeTavernButton } from '../components/buttons/analyze-tavern-button';
import { BoxAnime } from '../components/uiKit/TavernButton/BoxAnime';
import { BarSection } from '../components/TavernPage/ParticipantsSection/BarSection';
import { tavernAIMessages } from '../utils/tavern-util';

const newTheme = {
  h1: (props: any) => {
    const { children } = props;
    return (
      <Text mb={1} fontSize="14px" fontWeight="bold">
        {children}
      </Text>
    );
  },
  p: (props: any) => {
    const { children } = props;
    return (
      <Text mb={4} fontSize="12px">
        {children}
      </Text>
    );
  },
  li: (props: any) => {
    const { children } = props;
    return (
      <Text mb={4} fontSize="12px">
        {children}
      </Text>
    );
  },
};

export const PastTavernDetail = (props: { tavern: Tavern }) => {
  const { tavern } = props;
  const { title, description } = tavern;

  const { connectedTavern, recordingProgressTimestamp, recordedPlayState } = useContext(GlobalPlayerContext);
  const { hosts, coHosts, speakers, joiners, listeners, topics, playCount, listenerCount, scores } =
    useContext(TavernDetailContext);

  const [recordingUrl, setRecordingUrl] = useState<string | null | undefined>(undefined);
  const [summary, setSummary] = useState<string | null | undefined>(undefined);

  const recordingStartTime = connectedTavern ? connectedTavern.recordingStartTime || connectedTavern.startTime : 0;
  const recordingEndTime = connectedTavern
    ? connectedTavern.recordingEndTime || connectedTavern.endTime || recordingStartTime
    : 0;
  const tavernLength = recordingEndTime - recordingStartTime;
  const offset = (tavernLength - (recordedPlayState ? recordedPlayState.duration * 1000 : tavernLength)) / 1000;

  useEffect(() => {
    const getInit = async () => {
      setRecordingUrl(await getRecordingUrlForTavern(tavern.id));

      const messages = await tavernAIMessages(tavern.id);

      if (messages) {
        setSummary(messages[0].content[0].text.value);
      }
    };
    getInit().catch(console.error);
  }, [tavern.id]);

  const displayedParticipants = useMemo(() => {
    const sessions = [
      ..._.orderBy(hosts, ['startTime'], ['desc']),
      ..._.orderBy(coHosts, ['startTime'], ['desc']),
      ..._.orderBy(speakers, ['startTime'], ['desc']),
      ..._.orderBy(joiners, ['startTime'], ['desc']),
      ..._.orderBy(listeners, ['startTime'], ['desc']),
    ];

    return sessionsFilteredForReplay(sessions, false, recordingProgressTimestamp, offset);
  }, [coHosts, hosts, joiners, listeners, recordingProgressTimestamp, speakers, offset]);

  return (
    <Box width="100%">
      <Flex alignItems="center" mb="16px" flexDirection={{ base: 'column', xl: 'row' }}>
        <Box maxW={{ base: '640px', xl: '240px' }} mt="16px" mb="16px" mr="16px">
          <FeaturedImage tavernId={tavern.id} />
        </Box>
        <VStack align="start" spacing={5} width="100%">
          <TavernUserCountsStack
            tavern={tavern}
            coHosts={coHosts}
            listenerCount={listenerCount}
            playCount={playCount}
            joiners={displayedParticipants}
            speakers={speakers}
          />
          <Flex
            flexDirection={{ base: 'column', xl: 'row' }}
            alignItems={{ base: 'flex-start', xl: 'center' }}
            mb="8px"
            width="100%"
          >
            <Box mb={{ base: '12px', xl: '0' }} textStyle="h1" pr="24px">
              {title}
            </Box>
            <Spacer />
          </Flex>
        </VStack>
      </Flex>

      <Box mb="16px">
        <TopicsList topics={topics} />
      </Box>

      <Wrap mb="16px" spacing={4}>
        <WrapItem>
          <HStack spacing="16px">{listenButton(recordingUrl, connectedTavern, tavern)}</HStack>
        </WrapItem>
        {/* <WrapItem>
          <AnalyzeTavernButton tavern={tavern} />
        </WrapItem> */}
        {!tavern.faded && (
          <WrapItem>
            <DownloadTavernButton tavern={tavern} url={recordedPlayState?.url || ''} users={displayedParticipants} />
          </WrapItem>
        )}
        {!tavern.faded && (
          <WrapItem>
            <FadeTavernButton tavern={tavern} />
          </WrapItem>
        )}
        <WrapItem>
          <DestroyTavernButton tavern={tavern} />
        </WrapItem>
      </Wrap>

      {description && (
        <Box mb="24px" color="typographySecondary">
          {description}
        </Box>
      )}

      {summary && (
        <Box m="24px" mb="48px" fontSize="sm">
          <ReactMarkdown components={ChakraUIRenderer(newTheme)} skipHtml>
            {summary}
          </ReactMarkdown>
        </Box>
      )}

      <Box mt="24px" mb="24px" position="relative" zIndex="0">
        <Box overflow="hidden">
          <MediaCarouselContainer hasHostingPermission={false} />
        </Box>
      </Box>

      <Box mb="24px">
        <ActivityGanttChart tavern={tavern} isConnected={!!connectedTavern} />
      </Box>

      <Box mb="24px" mt="24px">
        <TavernTabs>
          <TabList>
            <Tab>
              <Box mr={4}>Participants</Box>
              <Text>{displayedParticipants.length}</Text>
            </Tab>
            <Tab>
              <BoxAnime title="Bar" />
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <ParticipantsSection
                cardSize="sm"
                joiners={displayedParticipants.map((session: Session) => {
                  const userS = sessionAsUserMock(session, false);
                  userS.score = _.find(scores, (s) => s.id === userS.id)?.score;

                  return userS;
                })}
                sessionType={SessionType.Listener}
              />
            </TabPanel>
            <TabPanel>
              <BarSection />
            </TabPanel>
          </TabPanels>
        </TavernTabs>
      </Box>
    </Box>
  );
};

const listenButton = (
  url: string | null | undefined,
  connectedTavern: Tavern | null,
  tavern: Tavern
): JSX.Element | null => {
  if (connectedTavern?.id === tavern.id) {
    return null;
  }

  // URL hasn't been retrieved yet, show disabled button.
  if (url === undefined) {
    return <ListenToTavernButton tavern={tavern} isDisabled />;
  }

  if (tavern.faded) {
    return <></>;
  }

  // URL has been retrieved and recording isn't ready.
  if (url === null) {
    return (
      <HStack>
        <Text>Generating Recording</Text>
        <Spinner />
      </HStack>
    );
  }

  return <ListenToTavernButton tavern={tavern} />;
};
