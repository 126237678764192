import { Box, Text, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { intervalToDuration } from 'date-fns';
import { RecordTimerProps } from './types';

const zeroPad = (num: number | string) => String(num).padStart(2, '0');

export const RecordTimer = (props: RecordTimerProps) => {
  const { startTimestamp } = props;
  const [start] = useState(startTimestamp);
  const [durationFormatted, setDurationFormatted] = useState('00:00:00');

  useEffect(() => {
    const interval = setInterval(() => {
      const duration = intervalToDuration({ start, end: Date.now() });
      setDurationFormatted([duration.hours || 0, duration.minutes || 0, duration.seconds || 0].map(zeroPad).join(':'));
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="inline-flex" flexDirection="column">
      <Text textStyle="body16regular" mb="4px">
        {durationFormatted}
      </Text>
      <Flex
        h="20px"
        w="52px"
        pl="8px"
        border="1px solid"
        borderColor="typographyTertiary"
        alignItems="center"
        borderRadius="32px"
      >
        <Box bg="accentDestructive" w="8px" h="8px" mr="6px" borderRadius="50%" />
        <Box fontSize="12px" fontWeight="500" textTransform="uppercase">
          rec
        </Box>
      </Flex>
    </Box>
  );
};
