import _ from 'lodash';
import { Avatar, Box, chakra, Flex, Highlight, Spinner, Text } from '@chakra-ui/react';
import UserDetailsHorizontalProps from './types';
import { noop } from '../../utils/noop';
import { getUserImage } from '../../utils/user-util';

export const UserDetailsHorizontalComponent = (props: UserDetailsHorizontalProps) => {
  const { isVertical, highlight = '', name, postfix = '', isAvatarBordered, onClick = noop, ...rest } = props;

  const verticalStyle = (): object | null => {
    if (isVertical && postfix) {
      return {
        position: 'absolute',
        top: '0',
        right: '0',
      };
    }
    return null;
  };

  const trimmedName = name ? name.slice(0, 14) : '';

  return (
    <Box
      display={isVertical ? 'inline-flex' : 'block'}
      color="typographyPrimary"
      onClick={onClick}
      {..._.omit(rest, ['nftId'])}
    >
      <Box display="inline-flex" pt={isVertical && postfix ? '22px' : '0'} position="relative">
        <Avatar
          objectFit="cover"
          boxSize="24px"
          borderRadius="50%"
          src={getUserImage(props)}
          name={name}
          border={isAvatarBordered ? '1px solid' : ''}
          borderColor="typographyPrimary"
          bg="black"
        />
        <Flex alignItems="center" _hover={{ textDecoration: 'none' }}>
          <Text
            ml="12px"
            color={highlight ? 'typographySecondary' : ''}
            maxWidth="106px"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {name !== undefined ? (
              <Highlight query={highlight || ''} styles={{ color: 'typographyPrimary', fontWeight: 500 }}>
                {trimmedName}
              </Highlight>
            ) : (
              <Spinner />
            )}
          </Text>
          {postfix && (
            <Text textStyle="body12regular" color="typographySecondary" ml="8px" {...verticalStyle()}>
              {postfix}
            </Text>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export const UserDetailsHorizontal = chakra(UserDetailsHorizontalComponent);
