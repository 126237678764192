import { HStack, MenuItem, Box, useColorModeValue } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaRedditAlien } from 'react-icons/fa';

import React, { useContext } from 'react';
import {
  IconCalendar,
  IconLive,
  IconNotify,
  IconRevert,
  IconSettings,
  IconStar,
  IconTwitter,
  IconDiscord,
  IconWarpcast,
  IconActivity,
} from '../../theme/foundations/customIcons';
import NotificationBadge from '../uiKit/NotificationBadge/NotificationBadge';
import NotificationsContext from '../../context/notifications';
import { TavernTooltip } from '../uiKit/TavernTooltip/TavernTooltip';

export enum SidebarButtonType {
  Live = 'Live',
  Upcoming = 'Upcoming',
  Past = 'Past',
  Leaderboard = 'Leaderboard',
  Moments = 'Moments',
  Notifications = 'Notifications',
  Settings = 'Settings',
  Warcast = 'Warpcast',
  Twitter = 'Twitter',
  Discord = 'Discord',
  Reddit = 'Reddit',
}

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) {
    newWindow.opener = null;
  }
};

export const SidebarButton = (props: {
  type: SidebarButtonType;
  onSidebarToggle: () => void;
  isCollapse?: boolean;
}) => {
  const { type, onSidebarToggle, isCollapse } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const selectedColor = useColorModeValue('black', 'white');
  const destination = destinationForType(type);
  const { unseenCount } = useContext(NotificationsContext);

  return (
    <TavernTooltip placement="right" label={isCollapse ? titleForType(type) : ''}>
      <MenuItem
        position="relative"
        icon={iconForType(type, location.pathname === destination, selectedColor, isCollapse)}
        textColor={location.pathname === destination ? selectedColor : ''}
        onClick={() => {
          onSidebarToggle();

          if (!destination) {
            if (type === SidebarButtonType.Twitter) {
              openInNewTab('https://twitter.com/tavernFM');
            } else if (type === SidebarButtonType.Discord) {
              openInNewTab('https://discord.com/invite/WyeD4Fx5WU');
            } else if (type === SidebarButtonType.Reddit) {
              openInNewTab('https://www.reddit.com/r/tavern_fm');
            } else if (type === SidebarButtonType.Warcast) {
              openInNewTab('https://warpcast.com/~/channel/tavern');
            }
          } else {
            navigate(destination);
          }
        }}
      >
        <HStack justify="space-between">
          {!isCollapse && <>{titleForType(type)} </>}

          {type === SidebarButtonType.Notifications &&
            unseenCount > 0 &&
            (isCollapse ? (
              <Box boxSize="5px" bgColor="brandPrimary" borderRadius="50%" position="absolute" top="5px" right="9px" />
            ) : (
              <NotificationBadge>{unseenCount}</NotificationBadge>
            ))}
        </HStack>
      </MenuItem>
    </TavernTooltip>
  );
};

const destinationForType = (type: SidebarButtonType) => {
  switch (type) {
    case SidebarButtonType.Live:
      return '/taverns';
    case SidebarButtonType.Upcoming:
      return '/upcoming';
    case SidebarButtonType.Past:
      return '/past';
    case SidebarButtonType.Leaderboard:
      return '/roots';
    case SidebarButtonType.Moments:
      return '/moments';
    case SidebarButtonType.Notifications:
      return '/notifications';
    case SidebarButtonType.Settings:
      return '/settings';
    case SidebarButtonType.Twitter:
      return '';
    case SidebarButtonType.Discord:
      return '';
    case SidebarButtonType.Reddit:
      return '';
    case SidebarButtonType.Warcast:
      return '';
  }
};

const iconForType = (type: SidebarButtonType, isSelected: boolean, selectedColor: string, isCollapse?: boolean) => {
  const style = { stroke: isSelected ? selectedColor : '', ml: isCollapse ? '-5px' : '0' };
  switch (type) {
    case SidebarButtonType.Live:
      return <IconLive sx={style} />;
    case SidebarButtonType.Upcoming:
      return <IconCalendar sx={style} />;
    case SidebarButtonType.Past:
      return <IconRevert sx={style} />;
    case SidebarButtonType.Leaderboard:
      return <IconActivity sx={style} boxSize="24px" />;
    case SidebarButtonType.Moments:
      return <IconStar sx={style} />;
    case SidebarButtonType.Notifications:
      return <IconNotify sx={style} />;
    case SidebarButtonType.Settings:
      return <IconSettings sx={style} />;
    case SidebarButtonType.Twitter:
      return <IconTwitter sx={style} />;
    case SidebarButtonType.Discord:
      return <IconDiscord sx={style} />;
    case SidebarButtonType.Reddit:
      return (
        <Box sx={style}>
          <FaRedditAlien />
        </Box>
      );
    case SidebarButtonType.Warcast:
      return <Box sx={style}> {IconWarpcast} </Box>;
  }
};

export const titleForType = (type: SidebarButtonType) => {
  switch (type) {
    case SidebarButtonType.Live:
      return 'Live';
    case SidebarButtonType.Upcoming:
      return 'Upcoming';
    case SidebarButtonType.Past:
      return 'Past';
    case SidebarButtonType.Leaderboard:
      return 'Leaderboard';
    case SidebarButtonType.Moments:
      return 'Moments';
    case SidebarButtonType.Notifications:
      return 'Notifications';
    case SidebarButtonType.Settings:
      return 'Settings';
    case SidebarButtonType.Twitter:
      return 'Twitter';
    case SidebarButtonType.Discord:
      return 'Discord';
    case SidebarButtonType.Reddit:
      return 'Reddit';
    case SidebarButtonType.Warcast:
      return 'Warpcast';
  }
};
