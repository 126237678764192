import { defineStyleConfig } from '@chakra-ui/react';

const TavernTextareaStyle = defineStyleConfig({
  baseStyle: {
    backgroundColor: 'redAlpha',
    border: '1px solid',
    borderColor: 'accentDestructive',
    borderRadius: '0',
    minHeight: '72px',
    fontFamily: 'body',
    fontWeight: 'regular',
    fontSize: 'lg',
    lineHeight: '24px',
    color: 'white',
    resize: 'none',
    boxShadow: 'none!important',
    _hover: {
      backgroundColor: 'redAlpha',
      borderColor: 'accentDestructive',
      ':not([aria-invalid=true])': {
        backgroundColor: 'backgroundInputHover',
        borderColor: 'backgroundInputHover',
      },
    },
    _focus: {
      backgroundColor: 'redAlpha',
      borderColor: 'accentDestructive',
      ':not([aria-invalid=true])': {
        backgroundColor: 'backgroundInputAdd',
        borderColor: 'brandPrimary',
        boxShadow: '0px 0px 0px 4px rgba(145, 90, 217, 0.25)!important',
      },
    },
    '::placeholder': {
      color: 'typographyTertiary',
    },
    ':not([aria-invalid=true])': {
      backgroundColor: 'backgroundInputAdd',
      borderColor: 'backgroundInputAdd',
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
});

export default TavernTextareaStyle;
