import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';

import AlertModal from '../AlertModal/AlertModal';
import { AlertModalProps } from '../AlertModal/types';
import { IconLeave } from '../../theme/foundations/customIcons';
import { Session, SessionType } from '../../types/tavern';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';
import { endTavern } from '../../services/tavern-functions';
import { hasHostingPermission } from '../../utils/session-util';
import { leaveAgoraChannel } from '../../services/agora-functions';

export const EndTavernButton = (props: {
  sessionType: SessionType;
  userSession: Session | null;
  onEnd: () => void;
}) => {
  const { tavernId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userSession, onEnd, sessionType } = props;

  const [endTavernProcessing, setEndTavernProcessing] = useState(false);

  if (!endTavernProcessing && (!hasHostingPermission(userSession) || sessionType !== SessionType.Host)) {
    return <></>;
  }

  const modalProps: AlertModalProps = {
    // do not allow closing confirmation while tavern is processing
    isOpen: isOpen || endTavernProcessing,
    onClose,
    isDestructive: true,
    title: 'End Tavern',
    description: 'Are you sure you want to end the Tavern?',
    primaryButton: {
      text: 'End',
      isDestructive: true,
      isLoading: endTavernProcessing,
      onClick: async () => {
        try {
          setEndTavernProcessing(true);
          await leaveAgoraChannel(true);
          await endTavern(tavernId as string);
          onEnd();
        } catch (e) {
          console.error(e);
        } finally {
          setEndTavernProcessing(false);
        }
      },
    },
    secondaryButton: {
      text: 'Cancel',
      onClick: () => {
        onClose();
      },
    },
  };

  return (
    <>
      <TavernButton leftIcon={<IconLeave />} variant="secondary" onClick={onOpen}>
        End Tavern
      </TavernButton>
      <AlertModal {...modalProps} />
    </>
  );
};
