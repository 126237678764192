import React, { useContext, useEffect, useState } from 'react';
import { collection, doc, onSnapshot, query, where } from 'firebase/firestore';
import { markUserAsGoing, unmarkUserAsGoing } from '../../services/tavern-functions';
import { Tavern } from '../../types/tavern';
import { useTavernToast } from '../hooks/useTavernToast';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';
import { IconReminderCheck } from '../../theme/foundations/customIcons';
import { db } from '../../connect';
import AuthContext from '../../context/auth';

export const SetReminderTavernButton = (props: { tavern: Tavern }) => {
  const toast = useTavernToast();
  const { user } = useContext(AuthContext);
  const { tavern } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [hasExistingReminder, setHasExistingReminder] = useState(false);

  // Since this query is loaded on first launch, we want to re-run
  // it once a first-time user has logged in as an anon.
  useEffect(() => {
    if (!user) {
      return;
    }
    const userRef = doc(db, `users/${user.id}`);
    const goingUsersQuery = query(collection(db, 'taverns', tavern.id, 'going'), where('user', '==', userRef));
    const unsub = onSnapshot(goingUsersQuery, (d) => {
      setHasExistingReminder(!d.empty);
    });
    return unsub;
  }, [tavern.id, user]);

  const setReminder = async () => {
    setIsLoading(true);
    try {
      await markUserAsGoing(props.tavern.id);
      toast({
        title: `Marked as going.`,
        description: `You're marked as attending ${props.tavern.title}. We will notify you when it starts.`,
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const removeReminder = async () => {
    setIsLoading(true);
    try {
      await unmarkUserAsGoing(props.tavern.id);
      toast({
        title: `Reminder removed`,
        description: `You're removed reminder about ${props.tavern.title} tavern`,
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {hasExistingReminder ? (
        <TavernButton
          variant="tertiary"
          leftIcon={<IconReminderCheck color="inherit" />}
          w="100%"
          onClick={async (event) => {
            event.stopPropagation();
            await removeReminder();
          }}
          isLoading={isLoading}
        >
          Stop Going
        </TavernButton>
      ) : (
        <TavernButton
          variant="primary"
          leftIcon={<IconReminderCheck color="inherit" />}
          w="100%"
          onClick={async (event) => {
            event.stopPropagation();
            await setReminder();
          }}
          isLoading={isLoading}
        >
          Set Going
        </TavernButton>
      )}
    </>
  );
};
