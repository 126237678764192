import { Popover, PopoverTrigger, PopoverContent, PopoverBody, useBreakpointValue } from '@chakra-ui/react';
import { useState } from 'react';

import { CaptionsBox } from '../captions-box';
import { IconCaptionsOn, IconCaptionsOff } from '../../theme/foundations/customIcons';
import { StreamActionButton } from '../uiKit/StreamActionButton/StreamActionButton';

export const CaptionsButton = () => {
  const [captionsOn, setCaptionsOn] = useState(true);

  const popoverWidth = useBreakpointValue({ base: '330px', lg: '440px' });

  return (
    <Popover closeOnBlur={false} placement="top-start" isLazy lazyBehavior="keepMounted">
      <PopoverTrigger>
        <StreamActionButton
          flexShrink="0"
          icon={captionsOn ? <IconCaptionsOn /> : <IconCaptionsOff />}
          aria-label="Captions"
          onClick={() => {
            setCaptionsOn(!captionsOn);
          }}
        />
      </PopoverTrigger>
      <PopoverContent bg="backgroundAdditional" width={popoverWidth}>
        <PopoverBody>
          <CaptionsBox />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
