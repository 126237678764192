import {
  Box,
  Flex,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { MdLibraryMusic } from 'react-icons/md';

import { LocalUploadPopoverProps } from './types';
import { IconClose } from '../../theme/foundations/customIcons';
import { ScrollableGradientBox } from '../ScrollableGradientBox/ScrollableGradientBox';
import { DraggableSoundList } from '../DraggableAudioList/DraggableSoundList';

export const Soundboard = (props: LocalUploadPopoverProps) => {
  const { width = 'auto', scrollHeight = 'auto' } = props;
  const { tavernId } = useParams();

  return (
    <Popover {...props}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Box as="button" maxW="436px">
              <Flex w="100%" background="backgroundMixerButton" borderRadius="24px" p="12px 16px">
                <Flex>
                  <Text textStyle="body16regular" color="typographyPrimary" m="0 12px">
                    Soundboard
                  </Text>
                </Flex>
                <Spacer />
                <MdLibraryMusic />
              </Flex>
            </Box>
          </PopoverTrigger>
          <PopoverContent width={width}>
            <PopoverHeader>
              <Flex alignItems="center">
                <Text textStyle="h4" color="typographyPrimary">
                  Soundboard
                </Text>
                <Spacer />
                <IconButton
                  _hover={{ bg: 'transparent' }}
                  bg="transparent"
                  aria-label="close-popover"
                  onClick={onClose}
                >
                  <IconClose boxSize="24px" color="typographySecondary" />
                </IconButton>
              </Flex>
            </PopoverHeader>
            <PopoverBody>
              <Box pb="12px">
                <ScrollableGradientBox gradient="gradientCardBlack.600" scrollHeight={scrollHeight} isGradientDisabled>
                  <DraggableSoundList tavernId={tavernId} onRemove={() => {}} />
                </ScrollableGradientBox>
              </Box>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
