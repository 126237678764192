import { AudioClip } from '../types/tavern';

export const clipsFilteredForReplay = (
  items: AudioClip[],
  displayLiveData: boolean,
  recordingProgressTimestamp: number | null,
  offset: number
): AudioClip[] => {
  if (recordingProgressTimestamp === null || displayLiveData) {
    return items;
  }

  return items.filter((item) => item.startTime - offset - 2000 <= recordingProgressTimestamp);
};
