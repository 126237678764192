import { forwardRef, IconButton, useStyleConfig } from '@chakra-ui/react';
import React from 'react';
import { RoundButtonProps } from './types';

export const RoundButton = forwardRef<RoundButtonProps, 'button'>(
  ({ onClick, icon, size = 'xs', variant, ...rest }, ref) => {
    const styles = useStyleConfig('RoundButtonStyle', {
      size,
      variant,
    });

    return <IconButton sx={styles} ref={ref} icon={icon} isRound onClick={onClick} {...rest} />;
  }
);
