/* eslint-disable */
import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const TavernTabsStyles = defineMultiStyleConfig({
  variants: {
    line: {
      root: {
        overflow: 'hidden',
      },
      tablist: {
        width: '100%',
        '::-webkit-scrollbar': { display: 'none' },
        scrollbarWidth: 'none',
        overflowX: 'auto',
        overflowY: 'hidden',
        border: 'none',
      },
      tabpanel: {
        pl: 0,
        pr: 0,
        borderTop: '1px solid',
        borderColor: 'backgroundInput',
        mt: '-1px',
      },
      tab: {
        whiteSpace: 'nowrap',
        paddingInline: 0,
        padding: '12px 0 12px',
        color: 'typographySecondary',
        fontSize: '16px',
        lineHeight: '24px',
        marginBottom: 0,
        borderColor: 'transparent',
        borderBottom: '1px solid',
        position: 'relative',
        _notLast: {
          mr: '24px',
        },
        _hover: {
          background: 'none',
          color: 'typographyPrimary',
          borderColor: 'typographyPrimary',
        },
        _selected: {
          background: 'none',
          color: 'typographyPrimary',
          borderColor: 'typographyPrimary',
        },
      },
    },
  },
  sizes: {
    lg: {
      tab: {
        p: '12px 0 8px',
      },
    },
    sm: {
      tab: {
        padding: '4px 0 2px',
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
});

export default TavernTabsStyles;
