import { Flex, ModalHeader, Tab, TabList, TabPanel, TabPanels, Text } from '@chakra-ui/react';
import { FC } from 'react';
import _ from 'lodash';

import { JoinTavernModalProps } from './types';
import { NFTsList } from '../NFTsList/NFTsList';
import { TavernModal } from '../TavernModal/TavernModal';
import { TavernTabs } from '../uiKit/TavernTabs/TavernTabs';

export const JoinTavernModal: FC<JoinTavernModalProps> = ({
  allowDefault,
  allowed,
  blocked,
  isVisible,
  onClose,
  onChoose,
}) => {
  const items = ['Suitable'];
  return (
    <TavernModal isOpen={isVisible} onClose={onClose} isCloseButtonVisible maxWidth="568px" variant="fluid">
      <ModalHeader padding={{ base: '23px 16px', xl: '24px 32px' }}>
        <Text py="5px" mr={{ base: '60px', xl: 0 }} textStyle={{ base: 'h3modal', xl: 'h3' }}>
          Pick an Avatar to join as
        </Text>
      </ModalHeader>
      <TavernTabs px={{ base: '16px', xl: '32px' }}>
        <TabList>
          {items.map((item, i) => (
            <Tab key={i}>
              {item}
              <Text ml="8px" color="typographySecondary" textStyle="body16regular">
                {i ? blocked.length : _.flatMap(allowed, 'tokens').length + 1}
              </Text>
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel padding={0} pt="16px">
            <Flex w="100%" bg="backgroundModal">
              <NFTsList
                allowDefault={allowDefault}
                isSmall
                isSuitable
                collections={allowed}
                setSelectedNft={onChoose}
              />
            </Flex>
          </TabPanel>
          {/* <TabPanel padding={0} pt="16px">
            <Flex w={"100%"} bg={"backgroundModal"}>
              <NFTsList
                isSmall={true}
                isSuitable={false}
                collections={blocked}
              />
            </Flex>
          </TabPanel> */}
        </TabPanels>
      </TavernTabs>
    </TavernModal>
  );
};
