import ReactDOM from 'react-dom';
import { Box, Flex } from '@chakra-ui/react';
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, Droppable } from 'react-beautiful-dnd';

import { DraggableListProps } from './types';
import { IconDragAndDrop } from '../../../theme/foundations/customIcons';

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const portal: HTMLElement = document.createElement('div');
portal.id = 'draggable-portal';

if (!document.body) {
  throw new Error('body not ready for portal creation!');
}

document.body.appendChild(portal);

interface PortalAwareItemProps {
  providedItem: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  item: any;
  renderItem: any;
  isDraggableDisabled?: boolean;
}

const PortalAwareItem = (props: PortalAwareItemProps): JSX.Element => {
  const { providedItem, snapshot, item, renderItem, isDraggableDisabled } = props;

  const usePortal: boolean = snapshot.isDragging;

  const child = (
    <Flex
      mb="8px"
      alignItems="center"
      background={snapshot.isDragging ? 'backgroundDragging' : 'backgroundDraggable'}
      _hover={{
        background: 'backgroundDragging',
      }}
      ref={providedItem.innerRef}
      {...providedItem.draggableProps}
      {...providedItem.dragHandleProps}
    >
      {!isDraggableDisabled && <IconDragAndDrop ml="8px" color="typographySecondary" fontSize="24px" cursor="grab" />}
      <Box w="100%" minWidth="0px">
        {renderItem(item)}
      </Box>
    </Flex>
  );

  if (!usePortal) {
    return child;
  }

  // if dragging - put the item in a portal
  return ReactDOM.createPortal(child, portal);
};

export const DraggableList = <T extends { id: number | string }>(props: DraggableListProps<T>) => {
  const { items, renderItem, isDraggableDisabled, onChange } = props;

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    onChange?.(reorder(items, result.source.index, result.destination.index));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="vertical">
        {(provided) => (
          <Box as="div" {...provided.droppableProps} ref={provided.innerRef} alignItems="flex-start" overflow="hidden">
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={`${item.id}`} index={index} isDragDisabled={isDraggableDisabled}>
                {(providedItem, snapshot) => (
                  <PortalAwareItem
                    providedItem={providedItem}
                    snapshot={snapshot}
                    isDraggableDisabled={isDraggableDisabled}
                    item={item}
                    renderItem={renderItem}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};
