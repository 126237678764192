/* eslint-disable */
import { forwardRef } from '@chakra-ui/react';
import { CreatableSelect } from 'chakra-react-select';
import React, { KeyboardEventHandler } from 'react';
import { TavernSelectProps, TopicOption } from './types';

const defaultComponents = {
  DropdownIndicator: null,
};

// Docs with a list of available props:  https://react-select.com/props#api
export const TavernSelectCreatable = forwardRef<TavernSelectProps, 'select'>(({ onAdd, components, ...rest }, ref) => {
  const [inputValue, setInputValue] = React.useState('');

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) {
      return;
    }

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        const valueTrimmed = inputValue.trim();
        onAdd({ value: valueTrimmed, label: valueTrimmed });
        setInputValue('');
        event.preventDefault();
    }
  };

  return (
    <CreatableSelect<TopicOption, true>
      {...rest}
      ref={ref}
      components={components || defaultComponents}
      inputValue={inputValue}
      isClearable
      onInputChange={(newValue) => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
    />
  );
});
