/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@chakra-ui/react';

import { AudioMixerCardProps } from './types';
import { AudioMixerPlayer } from '../uiKit/AudioMixerPlayer/AudioMixerPlayer';

export const AudioMixerCard = (props: AudioMixerCardProps) => {
  const { audioFile, isBgEnabled, isSoundboard } = props;

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      background={isBgEnabled ? 'backgroundMixerCard' : 'transparent'}
      w="100%"
      _hover={{
        background: isBgEnabled ? 'backgroundMixerCardHover' : 'transparent',
        '.controls': {
          opacity: '1',
        },
      }}
      p="8px"
      cursor="pointer"
    >
      <AudioMixerPlayer audio={audioFile} isSoundboard={isSoundboard} />
    </Flex>
  );
};
