import { noop } from 'lodash';
import { Box, chakra, Flex } from '@chakra-ui/react';
import { TopicTag } from '../TopicTag/TopicTag';
import { TopicsListProps } from './types';

const TopicsListComponent = (props: TopicsListProps) => {
  const {
    featuredTopics = [],
    topics = [],
    onTopicClick = noop,
    isClickable,
    variant = 'multipleLines',
    ...rest
  } = props;

  return (
    <Box {...rest} margin="0 -4px">
      <Flex flexWrap={variant === 'multipleLines' ? 'wrap' : 'nowrap'}>
        {featuredTopics.map((topic) => (
          <TopicTag
            color="brandPrimary"
            key={topic.id}
            isClickable={isClickable}
            onClick={(e) => onTopicClick(topic, e)}
          >
            {topic.name}
          </TopicTag>
        ))}
        {topics.map((topic) => (
          <TopicTag key={topic.id} isClickable={isClickable} onClick={(e) => onTopicClick(topic, e)}>
            {topic.name}
          </TopicTag>
        ))}
      </Flex>
    </Box>
  );
};

export const TopicsList = chakra(TopicsListComponent);
