import { ChakraTheme } from '@chakra-ui/theme/dist/theme.types';

const semantic = {
  colors: {
    brandPrimary: {
      _light: 'brand.900',
      _dark: 'brand.1000',
    },
    brandSecondary: {
      _light: 'rgba(135, 58, 234, 0.16)',
      _dark: 'brand.160',
    },
    typographyPrimary: {
      _light: 'black.900',
      _dark: 'white',
    },
    typographySecondary: {
      _light: 'gray.800',
      _dark: 'gray.400',
    },
    typographyTertiary: {
      _light: 'gray.200',
      _dark: 'gray.1000',
    },
    typographyAdditional: {
      _light: 'gray.900',
      _dark: 'gray.300',
    },
    tertiaryButtonHover: {
      _light: 'sandyAlpha.340',
      _dark: 'whiteAlpha.80',
    },
    backgroundPrimary: {
      _light: 'sandy.200',
      _dark: 'black.1000',
    },
    backgroundCard: {
      _light: 'sandy.100',
      _dark: 'black.1000',
    },
    backgroundMixerCard: {
      _light: 'sandyAlpha.340',
      _dark: 'whiteAlpha.80',
    },
    backgroundMixerButton: {
      _light: 'sandyAlpha.340',
      _dark: 'black.600',
    },
    backgroundMixerCardHover: {
      _light: 'sandyAlpha.460',
      _dark: 'whiteAlpha.120',
    },
    backgroundModal: {
      _light: 'white',
      _dark: 'black.800',
    },
    backgroundAdditional: {
      _light: 'white',
      _dark: 'black.600',
    },
    backgroundAdditional2: {
      _light: 'black.400',
      _dark: 'sandy.80',
    },
    backgroundAdditional3: {
      _light: 'black.300',
      _dark: 'sandy.50',
    },
    backgroundTertiary: {
      _light: 'sandyAlpha.360',
      _dark: 'black.500',
    },
    backgroundOverlay: {
      _light: 'blackAlpha.300',
      _dark: 'blackAlpha.800',
    },
    backgroundInput: {
      _light: 'sandyAlpha.340',
      _dark: 'whiteAlpha.80',
    },
    backgroundSnackbar: {
      _light: 'sandyAlpha.650',
      _dark: 'black.600',
    },
    backgroundInputAdd: {
      _light: 'sandyAlpha.650',
      _dark: 'black.700',
    },
    backgroundInputHover: {
      _light: 'sandyAlpha.460',
      _dark: 'whiteAlpha.120',
    },
    backgroundButton: {
      _light: 'sandyAlpha.340',
      _dark: 'whiteAlpha.80',
    },
    backgroundButtonHover: {
      _light: 'sandyAlpha.460',
      _dark: 'whiteAlpha.120',
    },
    backgroundHover: {
      _light: 'sandyAlpha.500',
      _dark: 'whiteAlpha.160',
    },
    borderPrimary: {
      _light: 'blackAlpha.80',
      _dark: 'whiteAlpha.80',
    },
    borderSecondary: {
      _light: 'blackAlpha.300',
      _dark: 'whiteAlpha.200',
    },
    accentSuccess: {
      _light: 'accent.green',
      _dark: 'accent.green',
    },
    accentWarning: {
      _light: 'accent.orange',
      _dark: 'accent.orange',
    },
    accentDestructive: {
      _light: 'accent.red',
      _dark: 'accent.red',
    },
    accentActivity: {
      _light: 'accent.pink',
      _dark: 'accent.pink',
    },
    gradientPrimary: {
      _light: 'gradientWhite.800',
      _dark: 'gradientDark.1000',
    },
    gradientPrimaryToLeft: {
      _light: 'gradientWhiteToLeft.800',
      _dark: 'gradientDarkToLeft.1000',
    },
    gradientCard: {
      _light: 'gradientCardWhite',
      _dark: 'gradientCardBlack.1000',
    },
    gradientSecondary: {
      _light: 'gradientWhite.1000',
      _dark: 'gradientDark.800',
    },
    gradientModal: {
      _light: 'gradientModalWhite',
      _dark: 'gradientModalBlack',
    },
    gradientFade: {
      _light: 'gradientWhite.950',
      _dark: 'gradientDark.950',
    },
    gradientPopover: {
      _light: 'gradientPopoverWhite',
      _dark: 'gradientPopoverDark',
    },
    backgroundUploadHover: {
      _light: 'sandyAlpha.340',
      _dark: 'whiteAlpha.80',
    },
    borderUpload: {
      _light: 'sandyAlpha.600',
      _dark: 'whiteAlpha.240',
    },
    backgroundDraggable: {
      _light: 'sandyAlpha.340',
      _dark: 'black.600',
    },
    backgroundDragging: {
      _light: 'sandyAlpha.500',
      _dark: 'black.400',
    },
    globalWhite: {
      _light: 'white',
      _dark: 'white',
    },
  },
} as ChakraTheme['semanticTokens'];

export default semantic;
