import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getDownloadURL, ref, listAll } from 'firebase/storage';
import _ from 'lodash';

import { AudioMixerCard } from '../AudioMixerCard/AudioMixerCard';
import { DraggableAudioListProps } from './types';
import { DraggableList } from '../uiKit/DraggableList/DraggableList';
import { ExternalAudio } from '../../types/audio';
import { storage } from '../../connect';

export const DraggableSoundList = (props: DraggableAudioListProps) => {
  const { isDraggable, onChange } = props;

  const [externalAudio, setExternalAudio] = useState<ExternalAudio[]>([]);

  const renderItem = (item: ExternalAudio) => {
    return <AudioMixerCard key={item.id} audioFile={item} isSoundboard />;
  };

  useEffect(() => {
    const storageRef = ref(storage, `sounds`);

    listAll(storageRef)
      .then(async (result) => {
        const promises = result.items.map((itemRef) =>
          getDownloadURL(itemRef).then((url) => {
            return {
              id: itemRef.name,
              name: itemRef.name,
              url,
              isPlaying: false,
              position: 0,
            };
          })
        );

        const externalAudioData = await Promise.all(promises);

        setExternalAudio(_.sortBy(externalAudioData, ['name'], ['asc']));
      })
      .catch(console.error);
  }, []);

  return (
    <Box>
      <DraggableList<ExternalAudio>
        onChange={onChange}
        isDraggableDisabled={!isDraggable}
        renderItem={renderItem}
        items={externalAudio}
      />
    </Box>
  );
};
