/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useConst, VStack, Text } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, doc, getDocs, onSnapshot, query } from 'firebase/firestore';

import AuthContext from '../context/auth';
import TavernDetailContext from '../context/tavern-detail-context';
import { LiveTavernDetail } from './live-tavern-detail';
import { PastTavernDetail } from './past-tavern-detail';
import { AudioClip, Reaction, Session, Tavern, TavernStatus, Topic, Score, Gif } from '../types/tavern';
import { db } from '../connect';
import { tavernParticipantsSubscription } from '../utils/tavern-util';
import { ScheduledTavernDetail } from './scheduled-tavern-details';

const removeTokenParam = (navigate: any) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  if (params.has('token')) {
    params.delete('token');
    const newUrl = `${url.pathname}?${params.toString()}`;

    // Use navigate to update the URL in the browser without reloading
    navigate(newUrl, { replace: true });
  }
};

export const TavernDetail = () => {
  const { tavernId } = useParams();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [detailTavern, setDetailTavern] = useState<Tavern | null>(null);
  const [detailHosts, setDetailHosts] = useState<Session[]>([]);
  const [detailCoHosts, setDetailCoHosts] = useState<Session[]>([]);
  const [detailSpeakers, setDetailSpeakers] = useState<Session[]>([]);
  const [detailJoiners, setDetailJoiners] = useState<Session[]>([]);
  const [detailListeners, setDetailListeners] = useState<Session[]>([]);
  const [listenerCount, setListenerCount] = useState<number>(0);
  const [playCount, setPlayCount] = useState<number>(0);
  const [reactions, setReactions] = useState<Reaction[]>([]);
  const [gifs, setGifs] = useState<Gif[]>([]);
  const [scores, setScores] = useState<Score[]>([]);
  const [audioClips, setAudioClips] = useState<AudioClip[]>([]);
  const maxSpeakers = useConst(10);

  // Topics are static, so they don't need to be a subscription.
  const [topics, setTopics] = useState<Topic[]>([]);

  useEffect(() => {
    document.title = `Tavern`;
    removeTokenParam(navigate);
  }, []);

  useEffect(() => {
    if (detailTavern?.title) {
      document.title = `${detailTavern.title} | Tavern`;
    }
  }, [detailTavern?.title]);

  // Since this query is loaded on first launch, we want to re-run
  // it once a first-time user has logged in as an anon.
  useEffect(() => {
    if (user === null) {
      return;
    }

    const unsub = onSnapshot(doc(db, 'taverns', tavernId as string), (d) => {
      setDetailTavern(d.data() as Tavern);
    });
    return unsub;
  }, [tavernId, user]);

  useEffect(() => {
    if (user === null || detailTavern === null || detailTavern === undefined) {
      return;
    }

    getDocs(query(collection(db, `taverns/${tavernId as string}/topics`)))
      .then((snapshot) => {
        setTopics(snapshot.docs.map((topicDoc) => topicDoc.data() as Topic));
      })
      .catch((error) => {
        console.error('TavernDetail error getting topics: ', error);
      });

    return tavernParticipantsSubscription(
      detailTavern,
      setDetailHosts,
      setDetailCoHosts,
      setDetailSpeakers,
      setDetailJoiners,
      setDetailListeners,
      setListenerCount,
      setPlayCount,
      setReactions,
      setGifs,
      setScores,
      setAudioClips
    );
  }, [detailTavern, user]);

  const renderTavernDetails = () => {
    const isLive = detailTavern?.status === TavernStatus.Live;
    const isPast = detailTavern?.status === TavernStatus.Past;
    const isScheduled = detailTavern?.status === TavernStatus.Upcoming;
    if (isLive) {
      return <LiveTavernDetail tavern={detailTavern} />;
    }

    if (isPast) {
      return <PastTavernDetail tavern={detailTavern} />;
    }

    if (isScheduled) {
      return <ScheduledTavernDetail tavern={detailTavern} />;
    }
  };

  if (detailTavern === null) {
    return <></>;
  }

  if (detailTavern === undefined) {
    return (
      <VStack>
        <Text color="red" fontWeight="bold" fontSize="2xl">
          DED
        </Text>
        <Text>Tavern not found.</Text>
      </VStack>
    );
  }

  return (
    <TavernDetailContext.Provider
      value={{
        hosts: detailHosts,
        coHosts: detailCoHosts,
        speakers: detailSpeakers,
        joiners: detailJoiners,
        listeners: detailListeners,
        listenerCount,
        playCount,
        reactions,
        gifs,
        scores,
        audioClips,
        topics,
        maxSpeakers,
      }}
    >
      {renderTavernDetails()}
    </TavernDetailContext.Provider>
  );
};
