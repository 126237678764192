import { Session } from '../../../types/tavern';
import { UserMock } from '../../../types/user';

// TODO: As noted in user.ts, this is a temp solution that should be fixed before prod.
export const sessionAsUserMock = (session: Session, isSpeaking: boolean): UserMock & { isMuted: boolean } => {
  return {
    id: session.user.id,
    name: session.name ?? session.user.id,
    sessionType: session.type,
    isMuted: session.isMuted,
    avatar: session.avatar,
    session,
    isSpeaking,
    isAnon: session.isAnon,
    score: undefined,
  };
};
