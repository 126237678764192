import _ from 'lodash';
import { useEffect, useState } from 'react';
import { HStack, Stack, Text } from '@chakra-ui/react';
import accounting from 'accounting';

import { CardHeaderIndicator } from './TavernCard/CardHeaderIndicator';
import { CardHeaderIndicatorAnime } from './TavernCard/CardHeaderIndicatorAnime';
import {
  IconCardCalendar,
  IconCardPlay,
  IconListeners,
  IconLive,
  IconSpeaker,
  IconTavernLogo,
} from '../theme/foundations/customIcons';
import { Session, Tavern } from '../types/tavern';
import { formatDate } from '../utils/date';

export const TavernUserCountsStack = ({
  tavern,
  coHosts,
  speakers,
  joiners,
  listenerCount,
  playCount,
}: {
  tavern: Tavern | null;
  coHosts: Session[];
  speakers: Session[];
  joiners: Session[];
  listenerCount: number;
  playCount: number;
}) => {
  const [speakingCount, setSpeakingCount] = useState<number>(0);
  const [joinerCount, setJoinerCount] = useState<number>(0);

  useEffect(() => {
    const uniqSpeakers = _.uniqBy([...speakers, ...coHosts], (s) => s.user.id);
    const uniqJoiners = _.uniqBy(joiners, (s) => s.user.id);
    setSpeakingCount(1 + uniqSpeakers.length);
    setJoinerCount(uniqJoiners.length);
  }, [coHosts, speakers, joiners, tavern]);

  const isPast = !!tavern?.endTime;

  const audience = joinerCount + listenerCount;

  const score = accounting.formatNumber(tavern?.score || 0, 0);
  const scoreMultiplier = tavern?.scoreMultiplier;

  return (
    <Stack direction={{ base: isPast ? 'column' : 'row', lg: 'row' }} spacing={{ base: '6px', xl: '8px' }}>
      {!isPast ? (
        <>
          <CardHeaderIndicator icon={IconLive} label="Live" />
          <Text color="typographySecondary">·</Text>
        </>
      ) : (
        <>
          <CardHeaderIndicator icon={IconCardCalendar} label={tavern?.startTime ? formatDate(tavern?.startTime) : ''} />
          <Text color="typographySecondary" display={{ base: 'none', lg: 'block' }}>
            ·
          </Text>
        </>
      )}
      <HStack spacing={{ base: '6px', xl: '8px' }}>
        <CardHeaderIndicator
          icon={IconSpeaker}
          label={speakingCount.toString()}
          tooltip={isPast ? `${speakingCount.toString()} speakers` : `${speakingCount.toString()} speaking`}
          textColor="accent.green"
        />
        <Text color="typographySecondary">·</Text>
        <CardHeaderIndicator
          icon={IconListeners}
          label={audience.toString()}
          tooltip={isPast ? `${audience.toString()} listeners` : `${audience.toString()} listening`}
          textColor="accent.green"
        />
        {isPast && (
          <>
            <Text color="typographySecondary">·</Text>
            <CardHeaderIndicator
              icon={IconCardPlay}
              label={playCount.toString()}
              tooltip={`${playCount.toString()} replays`}
              textColor="accent.green"
            />
            <Text color="typographySecondary">·</Text>
            <CardHeaderIndicatorAnime
              icon={IconTavernLogo}
              label={score}
              tooltip={`${score} roots`}
              textColor="accent.green"
            />
          </>
        )}
        {scoreMultiplier && !isPast && (
          <>
            <Text color="typographySecondary">·</Text>
            <CardHeaderIndicatorAnime
              icon={IconTavernLogo}
              label={`${scoreMultiplier}x`}
              tooltip="Roots Bonus Multiplier"
            />
          </>
        )}
      </HStack>
    </Stack>
  );
};
