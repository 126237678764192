import { httpsCallable } from 'firebase/functions';

import { fbFunctions } from '../connect';

export const markNotificationAsSeen = async (notificationId: string) => {
  try {
    const submit = httpsCallable<{ notificationId: string }>(fbFunctions, 'markNotificationAsSeen');
    await submit({
      notificationId,
    });
  } catch (error) {
    console.error(error);
  }
};
