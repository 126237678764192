import { ref, onDisconnect, OnDisconnect } from 'firebase/database';
import { realtimeDb } from '../connect';

let currentDisconnectRef: OnDisconnect | null = null;

export const setDisconnectListener = async (tavernId: string, sessionId: string) => {
  if (currentDisconnectRef) {
    await currentDisconnectRef.cancel();
  }
  const sessionRef = ref(realtimeDb, `${tavernId}/sessionStatus/${sessionId}`);

  const disconnectRef = onDisconnect(sessionRef);
  await disconnectRef.set('disconnected');

  currentDisconnectRef = disconnectRef;
};
