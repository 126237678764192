import { Box, Flex, IconButton, Tabs } from '@chakra-ui/react';
import { TavernTabsProps } from './types';
import { IconArrowRight } from '../../../theme/foundations/customIcons';

export const TavernTabs = (props: TavernTabsProps) => {
  const tabPanels = props.children[0];
  const items = tabPanels?.props.children?.length;
  const tabsSize = props.size;

  return (
    <Flex w="100%" alignItems="center" justifyContent="center" position="relative">
      {items > 4 && (
        <>
          <Box
            display={{ base: 'block', lg: 'none' }}
            w="90px"
            h={tabsSize === 'sm' ? '27px' : '35px'}
            bg="gradientFade"
            position="absolute"
            top={tabsSize === 'sm' ? '3px' : '10px'}
            right="0"
            justifyContent="flex-end"
            alignItems="center"
            pointerEvents="none"
            zIndex={2}
          />
          <IconButton
            display={{ base: 'block', lg: 'none' }}
            top={tabsSize === 'sm' ? '3px' : '15px'}
            right="5px"
            position="absolute"
            aria-label="arrow-left"
            justifyContent="center"
            alignItems="center"
            bg="backgroundPrimary"
            w="25px"
            minW="25px"
            h="25px"
            borderRadius="50%"
            cursor="pointer"
            _hover={{ bg: 'backgroundHover' }}
            zIndex="3"
          >
            <IconArrowRight color="typographyPrimary" />
          </IconButton>
        </>
      )}
      <Tabs w="100%" {...props} />
    </Flex>
  );
};
