import { UID } from 'agora-rtc-sdk-ng';
import { doc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

import { Link, Tavern, TavernStatus, Topic } from '../types/tavern';
import { db, fbFunctions } from '../connect';
import { getAgoraToken, joinAgoraChannel, leaveAgoraChannel } from './agora-functions';
import { uploadTavernFeaturedImage } from '../utils/image-util';

export const startTavern = async (params: {
  title: string;
  description: string;
  topics?: Topic[];
  links?: Link[];
  startTime?: number;
  joinAllowlist?: string[];
  joinBlocklist?: string[];
  speakerAllowlist?: string[];
  speakerBlocklist?: string[];
  status: TavernStatus;
  featuredImageUrl?: string;
  org?: string;
}): Promise<Tavern> => {
  try {
    // Leave in case user is already in a channel.
    await leaveAgoraChannel();

    const start = httpsCallable<
      {
        title: string;
        description: string;
        status: TavernStatus;
        containsFeaturedImage: boolean;
        topics?: Topic[];
        links?: string[];
        startTime?: number;
        joinAllowlist?: string[];
        joinBlocklist?: string[];
        speakerAllowlist?: string[];
        speakerBlocklist?: string[];
        featuredImageUrl?: string;
        org?: string;
      },
      { tavern: any }
    >(fbFunctions, 'startTavern');
    const result = await start({
      ...params,
      containsFeaturedImage: !!params.featuredImageUrl,
      // TODO: Need to create actual document references for links.
      links: params.links?.map((link) => link.link),
    });

    const { tavern } = result.data;

    if (params.featuredImageUrl) {
      await uploadTavernFeaturedImage(tavern.id as string, params.featuredImageUrl).catch((error) => {
        console.error(`Error uploading featured image. ${error as string}`);
      });
    }

    return {
      ...tavern,
      host: doc(db, result.data.tavern.host as string),
      creator: doc(db, result.data.tavern.creator as string),
    };
  } catch (error) {
    throw new Error(`Error starting tavern. ${error as string}`);
  }
};

export const saveTavern = async (params: {
  tavernId: string;
  title: string;
  description: string;
  topics?: Topic[];
  startTime?: number;
  joinAllowlist?: string[];
  joinBlocklist?: string[];
  speakerAllowlist?: string[];
  speakerBlocklist?: string[];
  featuredImageUrl?: string;
  org?: string;
}) => {
  try {
    const save = httpsCallable<
      {
        tavernId: string;
        title: string;
        description: string;
        containsFeaturedImage?: boolean;
        topics?: Topic[];
        startTime?: number;
        joinAllowlist?: string[];
        joinBlocklist?: string[];
        speakerAllowlist?: string[];
        speakerBlocklist?: string[];
        featuredImageUrl?: string;
        org?: string;
      },
      { tavern: any }
    >(fbFunctions, 'saveTavern');
    await save({
      ...params,
      // containsFeaturedImage: !!params.featuredImageUrl,
    });

    // if (params.featuredImageUrl) {
    //   await uploadTavernFeaturedImage(tavern.id as string, params.featuredImageUrl).catch((error) => {
    //     console.error(`Error uploading featured image. ${error as string}`);
    //   });
    // }
  } catch (error) {
    throw new Error(`Error saving tavern. ${error as string}`);
  }
};

export const startScheduledTavern = async (params: { tavernId: string }): Promise<void> => {
  try {
    // Leave in case user is already in a channel.
    leaveAgoraChannel().catch((error) => {
      console.error(`Error leaving Agora channel. ${error as string}`);
    });

    const start = httpsCallable<{ tavernId: string }, { tavern: any }>(fbFunctions, 'startScheduledTavern');
    await start({
      ...params,
    });
  } catch (error) {
    throw new Error(`Error starting tavern. ${error as string}`);
  }
};

export const listenToTavern = async (tavernId: string, nftId?: string) => {
  // Leave if user is already connected to a channel/Tavern.
  await leaveAgoraChannel();

  const token = await getAgoraToken(tavernId, 'audience');
  const { agoraUid } = await joinAgoraChannel(tavernId, token, 'audience');

  const joinTavern = httpsCallable<{
    tavernId: string;
    agoraUid: UID;
    muted: boolean;
    asListener?: boolean;
    nftId?: string;
  }>(fbFunctions, 'joinTavern');
  await joinTavern({
    tavernId,
    agoraUid,
    muted: true,
    asListener: true,
    nftId,
  });
};

export const startJoinTavern = async (tavernId: string, nftId?: string, microphoneId?: string) => {
  // Leave if user is already connected to a channel/Tavern.
  await leaveAgoraChannel();

  const permission = 'host';
  const token = await getAgoraToken(tavernId, permission);
  const { agoraUid } = await joinAgoraChannel(tavernId, token, permission, microphoneId);

  const startJoinTavernCall = httpsCallable<{
    tavernId: string;
    agoraUid: UID;
    nftId?: string;
  }>(fbFunctions, 'startJoinTavern');

  await startJoinTavernCall({
    tavernId,
    agoraUid,
    nftId,
  });
};

export const joinTavern = async (
  tavernId: string,
  speakingPermission = false,
  nftId?: string,
  microphoneId?: string
) => {
  // Leave if user is already connected to a channel/Tavern.
  await leaveAgoraChannel();

  const permission = speakingPermission ? 'host' : 'audience';
  const token = await getAgoraToken(tavernId, permission);
  const { agoraUid } = await joinAgoraChannel(tavernId, token, permission, microphoneId);

  const joinTavernCall = httpsCallable<{
    tavernId: string;
    agoraUid: UID;
    muted: boolean;
    nftId?: string;
  }>(fbFunctions, 'joinTavern');
  await joinTavernCall({
    tavernId,
    agoraUid,
    muted: !speakingPermission,
    nftId,
  });
};

export const updateJoinerNft = async (tavernId: string, nftId: string) => {
  const updateJoinerNftCall = httpsCallable<{
    tavernId: string;
    nftId: string;
  }>(fbFunctions, 'updateJoinerNft');
  await updateJoinerNftCall({
    tavernId,
    nftId,
  });
};

export const leaveTavern = async (tavernId: string) => {
  const leave = httpsCallable<{ tavernId: string }>(fbFunctions, 'leaveTavern');
  await leave({
    tavernId,
  });
};

export const endTavern = async (tavernId: string) => {
  const end = httpsCallable<{ tavernId: string }>(fbFunctions, 'endTavern');
  await end({
    tavernId,
  });
};

export const destroyTavern = async (tavernId: string, fade: boolean) => {
  const destroy = httpsCallable<{
    tavernId: string;
    fade: boolean;
  }>(fbFunctions, 'destroyTavern');
  await destroy({
    tavernId,
    fade,
  });
};

export const submitLink = async (tavernId: string, link: string, toast?: any) => {
  try {
    const submit = httpsCallable<{ tavernId: string; link: string }>(fbFunctions, 'submitLink');
    await submit({
      tavernId,
      link,
    });
    if (toast) {
      toast({
        title: `Submitted link.`,
        description: `You successfully submitted ${link} to the Tavern.`,
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
  } catch (error) {
    if (toast) {
      toast({
        title: `Submission failed.`,
        description: `Submitting the link to Tavern failed. Please try again.`,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }
};

export const deleteLink = async (tavernId: string, linkId: string) => {
  const deleteLinkCall = httpsCallable<{ tavernId: string; linkId: string }>(fbFunctions, 'deleteLink');
  await deleteLinkCall({
    tavernId,
    linkId,
  });
};

export const markUserAsGoing = async (tavernId: string) => {
  const mark = httpsCallable<{ tavernId: string }>(fbFunctions, 'markUserAsGoing');
  await mark({
    tavernId,
  });
};

export const unmarkUserAsGoing = async (tavernId: string) => {
  const mark = httpsCallable<{ tavernId: string }>(fbFunctions, 'unmarkUserAsGoing');
  await mark({
    tavernId,
  });
};

export const createMoment = async (tavernId: string, startTime: number, endTime: number) => {
  const create = httpsCallable<{
    tavernId: string;
    startTime: number;
    endTime: number;
  }>(fbFunctions, 'createMoment');
  await create({
    tavernId,
    startTime,
    endTime,
  });
};

export const increasePlayCount = async (tavernId: string) => {
  const increase = httpsCallable<{ tavernId: string }>(fbFunctions, 'increasePlayCount');
  await increase({
    tavernId,
  });
};

export const triggerIPFS = async (params: { tavernId: string }): Promise<void> => {
  try {
    const trigger = httpsCallable<{ tavernId: string }, { tavern: any }>(fbFunctions, 'triggerIPFS');
    await trigger({
      ...params,
    });
  } catch (error) {
    throw new Error(`Error exporting tavern. ${error as string}`);
  }
};

export const analyzeTavern = async (params: { tavernId: string }): Promise<void> => {
  try {
    const trigger = httpsCallable<{ tavernId: string }, { tavern: any }>(fbFunctions, 'analyzeTavern');
    await trigger({
      ...params,
    });
  } catch (error) {
    throw new Error(`Error analyzing tavern. ${error as string}`);
  }
};

export const addAnonAI = async (params: { tavernId: string }): Promise<void> => {
  try {
    const trigger = httpsCallable<{ tavernId: string }, { tavern: any }>(fbFunctions, 'addAnonAI');
    await trigger({
      ...params,
    });
  } catch (error) {
    throw new Error(`Error adding Anon AI to tavern. ${error as string}`);
  }
};
