import {
  Avatar,
  AvatarBadge,
  Box,
  Flex,
  IconButton,
  keyframes,
  Link,
  Text,
  useColorModeValue,
  usePrefersReducedMotion,
  useStyleConfig,
} from '@chakra-ui/react';
import { useState } from 'react';
import { fromUnixTime } from 'date-fns';
import { noop } from 'lodash';
import accounting from 'accounting';

import {
  IconCardCalendar,
  IconChevronRight,
  IconDiscord,
  IconLive,
  IconQr,
  IconTwitter,
  IconTavernLogo,
  IconWarpcast,
} from '../../theme/foundations/customIcons';
import { FollowUnfollowButton } from '../FollowUnfollowButton/FollowUnfollowButton';
import { UserDetailsProps } from './types';
// import { ChangeProfileNftButton } from '../buttons/change-profile-nft-button';

const pulse = keyframes`
  from {
    transform: scale(0.7);
  }
  50% {
    transform: scale(1)
  }
  to {
    transform: scale(0.7)
  }
`;

export const UserDetails = (props: UserDetailsProps) => {
  const {
    size = 'sm',
    isProfile,
    isLive,
    description,
    joiningTimestamp = 0,
    twitter,
    farcaster,
    discord,
    avatar,
    nft,
    name,
    id,
    isAnon,
    score = 0,
    onClick,
    onQrCodeClick = noop,
    isCollapse,
    // isOwner,
  } = props;

  const styles = useStyleConfig('UserDetailsStyles', { size, isCollapse });
  const prefersReducedMotion = usePrefersReducedMotion();
  const [isHovered, setIsHovered] = useState(false);

  const animation = (delay: string): undefined | string => {
    return prefersReducedMotion ? undefined : `${pulse} infinite 4s ${delay} linear`;
  };

  const isLgSize: boolean = size === 'lg';

  const getDateFromTimestamp = (): string => {
    const date = fromUnixTime(joiningTimestamp / 1000);
    return `${date.toLocaleString('en-US', {
      month: 'long',
      year: 'numeric',
    })}`;
  };

  const avatarBg = useColorModeValue('white', 'black');

  const formatScore = accounting.formatNumber(score || 0, 0);

  return (
    <Flex pt={isLgSize ? '24px' : ''} sx={styles} flexDirection="column" width="100%">
      <Box
        alignSelf="flex-start"
        position="relative"
        mt={isLgSize ? '18px' : ''}
        mr={isLgSize ? '24px' : ''}
        onClick={onClick}
        _hover={{
          cursor: onClick ? 'pointer' : 'default',
        }}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        {isLive && (
          <>
            <Box
              animation={animation('0.3s')}
              pointerEvents="none"
              boxSize={isLgSize ? '140px' : '92px'}
              borderRadius="50%"
              border="1px solid"
              borderColor="brandPrimary"
              opacity=".1"
              position="absolute"
              left={isLgSize ? 'calc(50% - 70px)' : 'calc(50% - 46px)'}
              top={isLgSize ? 'calc(50% - 70px)' : 'calc(50% - 46px)'}
            />
            <Box
              animation={animation('0.2s')}
              pointerEvents="none"
              boxSize={isLgSize ? '128px' : '80px'}
              borderRadius="50%"
              border="1px solid"
              borderColor="brandPrimary"
              opacity=".3"
              position="absolute"
              left={isLgSize ? 'calc(50% - 64px)' : 'calc(50% - 40px)'}
              top={isLgSize ? 'calc(50% - 64px)' : 'calc(50% - 40px)'}
            />
            <Box
              animation={animation('0.1s')}
              pointerEvents="none"
              boxSize={isLgSize ? '112px' : '68px'}
              borderRadius="50%"
              border="1px solid"
              borderColor="brandPrimary"
              position="absolute"
              left={isLgSize ? 'calc(50% - 56px)' : 'calc(50% - 34px)'}
              top={isLgSize ? 'calc(50% - 56px)' : 'calc(50% - 34px)'}
            />
          </>
        )}
        <Avatar
          objectFit="cover"
          boxSize={isLgSize ? '104px' : isCollapse ? '48px' : '56px'}
          borderRadius="50%"
          src={nft || avatar}
          name={name}
          icon={<></>}
          bg={avatarBg}
          border="1px solid white"
        >
          {isLive && (
            <AvatarBadge
              transform={isLgSize ? 'none' : 'translate(20%, 20%)'}
              bg="brandPrimary"
              border="1px solid"
              borderColor="backgroundPrimary"
              boxSize="24px"
            >
              <IconLive boxSize="16px" color="globalWhite" />
            </AvatarBadge>
          )}
        </Avatar>
      </Box>
      {!isCollapse && (
        <Box
          onClick={onClick}
          _hover={{
            cursor: onClick ? 'pointer' : 'default',
          }}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
        >
          <Flex alignItems={{ base: 'flex-start', xl: 'center' }} mt="12px" _hover={{ textDecoration: 'none' }}>
            <Flex
              alignItems={{ base: 'flex-start', xl: 'center' }}
              mb={isProfile ? '8px' : ''}
              flexDirection={{ base: 'column', xl: 'row' }}
            >
              <Box mr="24px" mb={{ base: '12px', xl: '0' }}>
                <Text
                  textStyle={isLgSize ? 'h1' : 'body16regular'}
                  mr="auto"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {name || 'anon'}
                </Text>
              </Box>
              {isProfile && !isAnon && (
                <Box mb={{ base: '12px', xl: '0' }}>
                  <FollowUnfollowButton userId={id} />
                </Box>
              )}
            </Flex>
            {isProfile && onQrCodeClick !== noop && (
              <IconButton onClick={onQrCodeClick} ml="auto" borderRadius="50%" aria-label="qr code" bg="0">
                <IconQr color="typographySecondary" boxSize="24px" />
              </IconButton>
            )}
            {!isProfile && (
              <Flex
                ml="auto"
                alignItems="center"
                color="typographySecondary"
                boxSize="16px"
                mt={{ base: '5px', xl: '0' }}
              >
                <IconChevronRight color={isHovered ? 'typographyPrimary' : 'typographySecondary'} boxSize="16px" />
              </Flex>
            )}
          </Flex>

          <Flex textStyle="body12regular" color="typographySecondary">
            {farcaster && (
              <Link
                href={`https://warpcast.com/${farcaster}`}
                _hover={{
                  textDecoration: 'none',
                  color: 'white',
                }}
                target="_blank"
                display="flex"
                flexDirection="row"
              >
                {IconWarpcast}
                <Text paddingLeft={1}>{farcaster}</Text>
              </Link>
            )}
            {twitter && (
              <Link
                href={`https://twitter.com/${twitter}`}
                _hover={{
                  textDecoration: 'none',
                  color: 'white',
                }}
                target="_blank"
              >
                <IconTwitter boxSize="12px" mr="8px" />
                {twitter}
              </Link>
            )}
            {discord && (
              <Link
                ml="8px"
                href={`https://discord.com/users/${discord}`}
                _before={{ content: '"·"' }}
                _hover={{ textDecoration: 'none' }}
                target="_blank"
              >
                <IconDiscord boxSize="12px" ml="8px" mr="8px" />
                {discord}
              </Link>
            )}
            {isProfile && (
              <Text textStyle="body12regular" ml="8px" color="brandPrimary">
                <IconTavernLogo boxSize="12px" ml="8px" mr="8px" />
                {formatScore}
              </Text>
            )}
          </Flex>

          {description && (
            <Text textStyle="body16regular" mt="20px">
              {description}
            </Text>
          )}

          {isProfile && joiningTimestamp && (
            <Flex alignItems="center" color="typographySecondary" textStyle="body14regular" mt="20px">
              <IconCardCalendar mr="8px" boxSize="16px" />
              Joined {getDateFromTimestamp()}
            </Flex>
          )}

          {/* {isProfile && isOwner && <ChangeProfileNftButton mt="8px" />} */}
        </Box>
      )}
    </Flex>
  );
};
