import React, { useEffect } from 'react';
import { query, collection, onSnapshot, where } from 'firebase/firestore';
import { useParams } from 'react-router-dom';

import { InvitationsSection } from '../TavernPage/InvitationsSection/InvitationsSection';
import { Session, SpeakInvite, SessionType, RequestInviteStatus } from '../../types/tavern';
import { db } from '../../connect';

// Displays list of invites from the host's perspective.
export const SpeakerInvitesList = (props: { userSession: Session | null; setInviteCount: (count: number) => void }) => {
  const [invites, setInvites] = React.useState<SpeakInvite[]>([]);
  const [userRole, setUserRole] = React.useState<SessionType>(SessionType.Listener);
  const { tavernId } = useParams();

  useEffect(() => {
    if (props.userSession !== null) {
      setUserRole(props.userSession.type);
    }
  }, [props.userSession]);

  useEffect(() => {
    const allInvitesQuery = query(
      collection(db, `taverns/${tavernId as string}/invites`),
      where('status', '==', RequestInviteStatus.Pending)
    );
    const unsubAllInvites = onSnapshot(allInvitesQuery, (querySnapshot) => {
      setInvites(querySnapshot.docs.map((doc) => doc.data()) as SpeakInvite[]);
      props.setInviteCount(querySnapshot.size);
    });
    return unsubAllInvites;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tavernId]);

  if (userRole !== SessionType.Host && userRole !== SessionType.CoHost) {
    // Should only be visible to host or cohost.
    return <></>;
  }

  return <InvitationsSection sessionType={userRole} invitations={invites} />;
};
