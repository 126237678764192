import { HStack, Text } from '@chakra-ui/react';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import GlobalPlayerContext from '../../context/global-player-context';
import { ListenToTavernButton } from '../buttons/listen-to-tavern-button';
import { TavernCard } from './TavernCard';
import { TavernCardPastProps } from './types';
import { db } from '../../connect';
import { formatDuration } from '../../utils/date';

export const TavernCardPast = (props: TavernCardPastProps) => {
  const navigate = useNavigate();
  const { connectedTavern } = useContext(GlobalPlayerContext);

  const [playCount, setPlayCount] = useState<number | undefined>(props.playCount);

  const {
    buttons = <ListenToTavernButton tavern={props.tavern} isDisabled={props.tavern?.id === connectedTavern?.id} />,
  } = props;

  useEffect(() => {
    if (props.tavern?.id === undefined) {
      return;
    }
    setPlayCount(props.playCount);
    // If costs to run this query become unsustainable, we can
    // remove and fall back to the playCount property on Tavern
    // object which is updated every 5 minutes.
    const shardsQuery = query(collection(db, `taverns/${props.tavern.id}/playCounterShards`));
    const unsub = onSnapshot(shardsQuery, (querySnapshot) => {
      setPlayCount(
        querySnapshot.docs.reduce((acc, doc) => {
          return acc + (doc.data().count as number);
        }, 0)
      );
    });

    return unsub;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tavern?.id]);

  return (
    <TavernCard
      {...props}
      isClickable
      onClick={() => {
        navigate(`/taverns/${props.tavern.id}`);
      }}
      buttons={<HStack>{buttons}</HStack>}
      playCount={playCount}
      footerLeft={
        <Text color="typographySecondary" fontSize="xs" ml="16px">
          {formatDuration(props.tavern.startTime, props.tavern.endTime)}
        </Text>
      }
    />
  );
};
