import { Flex, Icon } from '@chakra-ui/react';

import { CardHeaderIndicatorProps } from './types';
import { TavernTooltip } from '../uiKit/TavernTooltip/TavernTooltip';

export const CardHeaderIndicator = (props: CardHeaderIndicatorProps) => {
  const { icon, label, tooltip, ...rest } = props;
  return (
    <Flex {...rest} textStyle="body14regular" color={props.textColor ?? 'typographyPrimary'} alignItems="center">
      {icon && (
        <TavernTooltip label={tooltip}>
          <Icon
            as={icon}
            textStyle="body14regular"
            color={props.iconColor ?? 'typographyPrimary'}
            boxSize="16px"
            mr={label ? '8px' : 0}
          />
        </TavernTooltip>
      )}{' '}
      {label}
    </Flex>
  );
};
