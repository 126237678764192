import {
  Box,
  forwardRef,
  useStyleConfig,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Textarea,
} from '@chakra-ui/react';
import { TavernTextareaProps } from './types';

export const TavernTextarea = forwardRef<TavernTextareaProps, 'textarea'>(
  ({ placeholder, label, hint, isInvalid, isOptional, errorText, ...rest }, ref) => {
    const styles = useStyleConfig('TavernTextareaStyle');

    return (
      <FormControl as="div" textAlign="left" isInvalid={isInvalid} ref={ref}>
        {label && (
          <FormLabel mb="8px" textStyle="body14medium" color="typographyPrimary">
            {label}
            {isOptional && (
              <Box as="span" ml="8px" color="typographyTertiary">
                Optional
              </Box>
            )}
          </FormLabel>
        )}

        <Textarea
          sx={styles}
          errorBorderColor="accentDestructive"
          placeholder={placeholder}
          isInvalid={isInvalid}
          {...rest}
        />

        {hint && !isInvalid && (
          <FormHelperText textStyle="body12regular" mt="4px" color="typographySecondary">
            {hint}
          </FormHelperText>
        )}

        {isInvalid && errorText && (
          <FormErrorMessage textStyle="body12regular" mt="4px" color="accentDestructive">
            {errorText}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  }
);
