import { DocumentReference } from 'firebase/firestore';

export enum NotificationType {
  UserGoingTavernStart = 'UserGoingTavernStart',
  FollowedByUser = 'FollowedByUser',
  FolloweeTavernStart = 'FolloweeTavernStart',
  FolloweeTavernJoin = 'FolloweeTavernJoin',
}

export type Notification =
  | UserGoingTavernStartNotification
  | FollowedByUserNotification
  | FolloweeTavernStartNotification
  | FolloweeTavernJoinNotification;

export interface BaseNotification {
  id: string;
  timestamp: number;
  seen: boolean;
  cleared: boolean;
  type: NotificationType;
}

export interface UserGoingTavernStartNotification extends BaseNotification {
  tavern: DocumentReference;
}

export interface FollowedByUserNotification extends BaseNotification {
  followerId: string;
}

export interface FolloweeTavernStartNotification extends BaseNotification {
  tavern: DocumentReference;
  followee: DocumentReference;
}

export interface FolloweeTavernJoinNotification extends BaseNotification {
  tavern: DocumentReference;
  followee: DocumentReference;
}
