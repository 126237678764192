import AgoraRTC, {
  ClientRole,
  IBufferSourceAudioTrack,
  ILocalAudioTrack,
  IMicrophoneAudioTrack,
  IRemoteAudioTrack,
  UID,
} from 'agora-rtc-sdk-ng';
import _ from 'lodash';
import { httpsCallable } from 'firebase/functions';
import { auth, fbFunctions } from '../connect';

const agoraAppId = 'eceba000ad2b4a77a55aa26ca20a97e8';

type ChannelParameters = {
  localAudioTrack?: IMicrophoneAudioTrack;
  remoteAudioTrack?: any;
  remoteUid?: any;
};

export const agoraEngine = AgoraRTC.createClient({
  mode: 'live',
  codec: 'vp8',
});
agoraEngine.enableAudioVolumeIndicator();

AgoraRTC.setLogLevel(4);

const channelParameters: ChannelParameters = {};
let externalAudioTracks: IBufferSourceAudioTrack[] = [];
let localAudioTracks: ILocalAudioTrack[] = [];

export const getTrack = (url: string): IBufferSourceAudioTrack | null => {
  return _.find(externalAudioTracks, (track) => track.source === url) || null;
};

export const playSoundEffect = async (url: string, loop = false): Promise<IBufferSourceAudioTrack> => {
  // TODO Handle attempted play while muted / in audience mode
  let track = _.find(externalAudioTracks, (t) => t.source === url);

  if (track && track.currentState !== 'stopped') {
    track.resumeProcessAudioBuffer();
  } else {
    track = await AgoraRTC.createBufferSourceAudioTrack({
      source: url,
    });
    externalAudioTracks.push(track);
    track.startProcessAudioBuffer({
      loop,
    });
    track.play();

    track.setVolume(10);
  }
  // TODO Pause playback local playback if publishing fails
  const tracks = [];
  if (channelParameters.localAudioTrack) {
    tracks.push(channelParameters.localAudioTrack);
  }
  tracks.push(track);

  await agoraEngine.publish(tracks);
  return track;
};

export const setLooping = (track: IBufferSourceAudioTrack, loop: boolean) => {
  const isStopped = track.currentState === 'stopped' || track.currentState === 'paused';
  const position = track.getCurrentTime();

  track.stopProcessAudioBuffer();
  track.startProcessAudioBuffer({
    loop,
  });
  track.seekAudioBuffer(position);

  if (isStopped) {
    track.pauseProcessAudioBuffer();
  } else {
    track.play();
  }
};

export const pauseSoundEffect = async (url: string) => {
  const track = _.find(externalAudioTracks, (t) => {
    return t.source === url;
  });

  if (!track) {
    console.log(`No track found to pause for ${url}.`);
    return;
  }

  track.pauseProcessAudioBuffer();
  await agoraEngine.unpublish([track]);
};

export const stopSoundEffect = async (url: string) => {
  const track = _.find(externalAudioTracks, (t) => {
    return t.source === url;
  });

  if (!track) {
    console.log(`No track found to stop for ${url}.`);
    return;
  }

  track.stopProcessAudioBuffer();
  track.stop();
  await agoraEngine.unpublish([track]);
  externalAudioTracks = _.filter(externalAudioTracks, (t) => {
    return t.source !== url;
  });
};

export const stopAllAudio = () => {
  externalAudioTracks.forEach((track) => {
    track.stopProcessAudioBuffer();
    track.stop();
  });
  externalAudioTracks = [];
};

export const getAgoraToken = async (tavernId: string, role: ClientRole): Promise<string> => {
  const callable = role === 'host' ? 'getAgoraHostToken' : 'getAgoraAudienceToken';
  const getToken = httpsCallable(fbFunctions, callable);
  const response = await getToken({
    tavernId,
    userId: auth.currentUser?.uid,
  });

  const token = _.get(response, 'data.token', null);

  if (token === null) {
    throw new Error('No token returned from getChannelToken.');
  }
  return token;
};

export const renewToHostToken = async (tavernId: string) => {
  const token = await getAgoraToken(tavernId, 'host');
  await agoraEngine.renewToken(token);
};

export const renewToAudienceToken = async (tavernId: string) => {
  const token = await getAgoraToken(tavernId, 'audience');
  await agoraEngine.renewToken(token);
};

const publish = async () => {
  await agoraEngine.setClientRole('host');
  if (channelParameters.localAudioTrack) {
    await agoraEngine.publish(channelParameters.localAudioTrack);
  }
};

const unpublish = async (isEnding?: boolean) => {
  if (channelParameters.localAudioTrack && channelParameters.localAudioTrack) {
    await agoraEngine.unpublish(channelParameters.localAudioTrack);
  }

  if (!isEnding) {
    await agoraEngine.setClientRole('audience');
  }
};

export const unmute = async (microphoneId?: string) => {
  if (!channelParameters.localAudioTrack) {
    channelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({ microphoneId });
    await publish();
  } else if (microphoneId) {
    await changeMicDevice(microphoneId);
  }
  localAudioTracks.push(channelParameters.localAudioTrack);
  await channelParameters.localAudioTrack.setMuted(false);
};

export const mute = async () => {
  // catch exception locally to prevent blocking the rest of the logic
  try {
    if (channelParameters.localAudioTrack) {
      await channelParameters.localAudioTrack.setMuted(true);
    }
  } catch (e) {
    console.error('mute', e);
  }
};

const cleanUpLocal = async (isEnding?: boolean) => {
  localAudioTracks.forEach((t) => {
    t.close();
  });

  await unpublish(isEnding);
  if (channelParameters.localAudioTrack) {
    channelParameters.localAudioTrack.close();
    channelParameters.localAudioTrack = undefined;
  }

  localAudioTracks = [];
};

export const joinAgoraChannel = async (
  tavernId: string,
  token: string,
  role: ClientRole,
  microphoneId?: string
): Promise<{ agoraUid: UID }> => {
  await agoraEngine.setClientRole(role);
  try {
    const agoraUid = await agoraEngine.join(agoraAppId, tavernId, token);
    if (role === 'host') {
      await unmute(microphoneId);
    }
    return { agoraUid };
  } catch (error) {
    throw new Error(`joinAgoraChannel ${error as string}`);
  }
};

export const leaveAgoraChannel = async (isEnding?: boolean) => {
  await cleanUpLocal(isEnding);
  try {
    if (agoraEngine.connectionState === 'CONNECTING' || agoraEngine.connectionState === 'CONNECTED') {
      await agoraEngine.leave();
    }
  } catch (error) {
    console.log(`leaveAgoraChannel ${error as string}`);
  }
};

export const changeMicDevice = async (microphoneId: string) => {
  try {
    const { localAudioTrack } = channelParameters;
    if (localAudioTrack) {
      await localAudioTrack.setDevice(microphoneId);
    }
  } catch (error) {
    console.log(`changeMicDevice ${error as string}`);
  }
};

export const changeSpeakerDevice = async (deviceId: string) => {
  try {
    const { remoteAudioTrack } = channelParameters;
    if (remoteAudioTrack) {
      await remoteAudioTrack.setPlaybackDevice(deviceId);
    }
  } catch (error) {
    console.log(`changeSpeakerDevice ${error as string}`);
  }
};

export const startAgoraSubscriptions = () => {
  agoraEngine.on('user-published', (user, mediaType) => {
    agoraEngine
      .subscribe(user, mediaType)
      .then(() => {
        if (mediaType === 'audio') {
          channelParameters.remoteUid = user.uid;
          channelParameters.remoteAudioTrack = user.audioTrack as IRemoteAudioTrack;
          channelParameters.remoteAudioTrack.play();
        }

        // agoraEngine.on('user-info-updated', (uid) => {});
        // agoraEngine.on('user-unpublished', (user) => {});
        // agoraEngine.on('connection-state-change', (state, reason) => {});
      })
      .catch(console.error);
  });
};

export const getLocalAudioTrack = () => {
  return channelParameters.localAudioTrack;
};
