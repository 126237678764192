/* eslint-disable @typescript-eslint/no-shadow */
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, { Crop, centerCrop, makeAspectCrop } from 'react-image-crop';
import { Image as ChakraImage, VStack } from '@chakra-ui/react';
import { useState, useRef, ChangeEvent } from 'react';

import { IconCheck, IconPlus } from '../../theme/foundations/customIcons';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';

interface FeaturedImageUploaderProps {
  croppedImageReceived: (image: string) => void;
  // imageUrl?: string | undefined;
}

export const FeaturedImageUploader = (props: FeaturedImageUploaderProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const imageRef = useRef<HTMLImageElement | null>(null);
  const fileUrl = useRef<string | null>(null);
  const fileInput = useRef<HTMLInputElement | null>(null);

  const [isCropping, setIsCropping] = useState(false);
  const [uncroppedImageSrc, setUncroppedImageSrc] = useState<string | null>(null);
  const [cropDimensions, setCropDimensions] = useState<Crop>();
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);

  const getCroppedImage = (crop: Crop, fileName: string): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      if (!selectedFile) {
        reject(new Error('No file selected'));
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const img: HTMLImageElement = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          const scaleX = img.naturalWidth / imageRef.current!.width;
          const scaleY = img.naturalHeight / imageRef.current!.height;

          canvas.width = crop.width * scaleX;
          canvas.height = crop.height * scaleY;

          ctx!.drawImage(
            img,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
          );

          canvas.toBlob((blob: Blob | null) => {
            if (!blob || !selectedFile) {
              console.error('Blob or selectedFile are empty.', blob, selectedFile);
              return;
            }

            const file = new File([blob], fileName, { type: selectedFile.type });
            if (fileUrl.current) {
              window.URL.revokeObjectURL(fileUrl.current);
            }
            fileUrl.current = window.URL.createObjectURL(file);

            resolve(fileUrl.current);
          }, selectedFile.type);
        };
        img.src = reader.result as string;
      };
      reader.readAsDataURL(selectedFile);
    });
  };

  return (
    <VStack align="flex-start">
      <TavernButton
        width="auto"
        color="brandPrimary"
        variant="link"
        leftIcon={!isCropping ? <IconPlus color="inherit" /> : undefined}
        rightIcon={isCropping ? <IconCheck color="inherit" /> : undefined}
        onClick={() => {
          if (!isCropping) {
            fileInput.current!.click();
          } else {
            setIsCropping(false);
          }
        }}
      >
        {isCropping ? 'Done' : croppedImageUrl ? 'Change image' : 'Add image'}
      </TavernButton>
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        ref={fileInput}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setSelectedFile(file);

            const reader = new FileReader();
            reader.addEventListener('load', () => {
              setIsCropping(true);
              setUncroppedImageSrc(reader.result as string);
            });
            reader.readAsDataURL(event.target.files[0]);

            // Reset input so user can re-crop same image.
            event.target.value = '';
          }
        }}
      />
      {uncroppedImageSrc && isCropping ? (
        <ReactCrop
          style={{ maxWidth: '33%' }}
          aspect={2 / 1}
          crop={cropDimensions}
          onChange={(crop) => setCropDimensions(crop)}
          onComplete={async (crop) => {
            if (selectedFile) {
              const croppedUrl = await getCroppedImage(crop, `newFile.${selectedFile.type.split('/')[1]}`);
              setCroppedImageUrl(croppedUrl);
              props.croppedImageReceived(croppedUrl);
            }
          }}
        >
          <ChakraImage
            src={uncroppedImageSrc}
            onLoad={(event) => {
              const { naturalWidth, naturalHeight } = event.currentTarget;

              setCropDimensions(
                centerCrop(
                  makeAspectCrop(
                    {
                      unit: '%',
                      width: 90,
                    },
                    2 / 1,
                    naturalWidth,
                    naturalHeight
                  ),
                  naturalWidth,
                  naturalHeight
                )
              );

              imageRef.current = event.currentTarget;
            }}
          />
        </ReactCrop>
      ) : (
        <></>
      )}
      {croppedImageUrl && !isCropping ? <ChakraImage maxW="33%" objectFit="contain" src={croppedImageUrl} /> : <></>}
    </VStack>
  );
};
