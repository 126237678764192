import { Grid, GridItem, Text, VStack } from '@chakra-ui/react';
import _ from 'lodash';

import { FollowUnfollowButton } from '../FollowUnfollowButton/FollowUnfollowButton';
import { SessionType } from '../../types/tavern';
import { TavernUser } from '../uiKit/TavernUser/TavernUser';
import { User } from '../../types/user';
import { followingUserAsUserMock } from '../../utils/follow-util';

export const FollowerFollowingList = (props: { users: User[]; emptyText: string }) => {
  const { users, emptyText } = props;

  const uniqUsers = _.uniqBy(users, 'id');

  return !users.length ? (
    <Text color="typographySecondary">{emptyText}</Text>
  ) : (
    <Grid pt="16px" autoFlow="row dense" templateColumns="repeat(auto-fill, 152px)" gap="24px">
      {uniqUsers.map((user: User) => {
        return (
          <GridItem key={user.id}>
            <VStack>
              <TavernUser hideScore size="lg" user={followingUserAsUserMock(user)} sessionType={SessionType.Listener} />
              <FollowUnfollowButton userId={user.id} />
            </VStack>
          </GridItem>
        );
      })}
    </Grid>
  );
};
