import { IconButton, useStyleConfig } from '@chakra-ui/react';
import { PlayPauseButtonProps } from './types';
import { IconAudioPause, IconAudioPlay } from '../../../theme/foundations/customIcons';

export const PlayPauseButton = (props: PlayPauseButtonProps) => {
  const { isPlaying, isDisabled = false, onClick, size } = props;

  const styles = useStyleConfig('PlayPauseButtonStyle', { size });

  return (
    <IconButton
      sx={styles}
      icon={isPlaying ? <IconAudioPause color="backgroundPrimary" /> : <IconAudioPlay color="backgroundPrimary" />}
      size={size}
      variant="solid"
      onClick={onClick}
      aria-label="play/pause"
      isDisabled={isDisabled}
    />
  );
};
