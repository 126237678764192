import { Spinner } from '@chakra-ui/react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

import AuthContext from '../../context/auth';
import GlobalPlayerContext from '../../context/global-player-context';
import {
  FolloweeTavernJoinNotification,
  FolloweeTavernStartNotification,
  NotificationType,
  UserGoingTavernStartNotification,
} from '../../types/notification';
import { GoToTavernButton } from '../buttons/go-to-tavern-button';
import { ListenToTavernButton } from '../buttons/listen-to-tavern-button';
import { NotificationCard } from './NotificationCard';
import { Tavern } from '../../types/tavern';
import { User } from '../../types/user';
import { getTavernById } from '../../utils/tavern-util';
import { getUserFromUserId } from '../../utils/user-util';
import { markNotificationAsSeen } from '../../services/notifications';

type NotificationStartTavernProps =
  | UserGoingTavernStartNotification
  | FolloweeTavernStartNotification
  | FolloweeTavernJoinNotification;

export const NotificationStartTavern = (props: NotificationStartTavernProps) => {
  const tavernId = props.tavern.id;

  const notificationId = props.id;

  const { user } = useContext(AuthContext);
  const { connectedTavern } = useContext(GlobalPlayerContext);

  const [tavern, setTavern] = useState<Tavern>();
  const [followee, setFollowee] = useState<User>();
  const [hasLoaded, setHasLoaded] = useState(false);

  const isHost = _.get(tavern, 'host.id') === user?.id;

  useEffect(() => {
    if (!tavernId) {
      return;
    }
    getTavernById(tavernId)
      .then(setTavern)
      .catch(console.error)
      .finally(() => setHasLoaded(true));
  }, [tavernId]);

  useEffect(() => {
    if (props.type === NotificationType.FolloweeTavernStart || props.type === NotificationType.FolloweeTavernJoin) {
      const casted = props as FolloweeTavernStartNotification | FolloweeTavernJoinNotification;
      getUserFromUserId(casted.followee.id)
        .then(setFollowee)
        .catch(console.error)
        .finally(() => setHasLoaded(true));
    }
  }, [props, props.type]);

  const renderButtons = () => {
    if (!tavern) {
      return;
    }

    const isEnded = tavern.endTime;
    if (isHost || (connectedTavern && connectedTavern.id === tavernId) || isEnded) {
      return <GoToTavernButton tavernId={tavernId} />;
    }

    return (
      <>
        <ListenToTavernButton variant="tertiary" tavern={tavern} mr="16px" />
        <GoToTavernButton tavernId={tavernId} />
      </>
    );
  };

  const message = useMemo(() => {
    if (!tavern) {
      return '';
    }

    if (followee) {
      if (props.type === NotificationType.FolloweeTavernStart) {
        return `${followee.name ?? followee.id} started a Tavern`;
      }

      if (props.type === NotificationType.FolloweeTavernJoin) {
        return `${followee.name ?? followee.id} joined a Tavern`;
      }
    }

    if (isHost) {
      return `Your Tavern ${tavern.title} started`;
    }

    return `The Tavern ${tavern.title} has started`;
  }, [followee, isHost, props.type, tavern]);

  const handleHover = useCallback(() => {
    markNotificationAsSeen(notificationId).then(console.log).catch(console.error);
  }, [notificationId]);

  if (!hasLoaded) {
    return <Spinner />;
  }

  return (
    <>
      {tavern ? (
        <NotificationCard
          {...props}
          message={message}
          highlight={tavern.title}
          hasSeen={props.seen}
          link={`/taverns/${tavernId}`}
          buttons={renderButtons()}
          onHover={handleHover}
          user={followee}
        />
      ) : (
        <></>
      )}
    </>
  );
};
