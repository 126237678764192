/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';

import GlobalPlayerContext from '../../context/global-player-context';
import { tavernParticipantsSubscription } from '../../utils/tavern-util';

export const TavernParticipantMonitor = () => {
  const {
    connectedTavern,
    setHosts,
    setCoHosts,
    setSpeakers,
    setJoiners,
    setListeners,
    setListenerCount,
    setPlayCount,
    setReactions,
    setGifs,
    setScores,
    setAudioClips,
  } = useContext(GlobalPlayerContext);

  useEffect(() => {
    if (connectedTavern === null) {
      setHosts([]);
      setCoHosts([]);
      setSpeakers([]);
      setJoiners([]);
      setListeners([]);
      setListenerCount(0);
      setPlayCount(0);
      setReactions([]);
      setGifs([]);
      setScores([]);
      setAudioClips([]);
      return;
    }

    return tavernParticipantsSubscription(
      connectedTavern,
      setHosts,
      setCoHosts,
      setSpeakers,
      setJoiners,
      setListeners,
      setListenerCount,
      setPlayCount,
      setReactions,
      setGifs,
      setScores,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setAudioClips
    );
  }, [connectedTavern]);

  return <></>;
};
