import { FC } from 'react';
import { Flex, HStack, Image, VStack, Text, Grid, GridItem, Link } from '@chakra-ui/react';

import { ExternalLinkIcon } from '@chakra-ui/icons';
import { NFTInfoModalProps } from './types';
import { TavernModal } from '../TavernModal/TavernModal';

export const NFTinfoModal: FC<NFTInfoModalProps> = ({ nftData, isVisible, onClose }) => {
  return (
    <TavernModal
      isOpen={isVisible}
      onClose={onClose}
      isCloseButtonVisible
      maxWidth={{ base: 768, '2xl': 968 }}
      variant="fluid"
    >
      <Flex
        flexWrap={{ base: 'wrap', xl: 'nowrap' }}
        alignItems="flex-start"
        w="100%"
        bg="backgroundModal"
        padding={{ base: '72px 16px 16px', xl: '32px' }}
      >
        <Flex display={{ xl: 'none' }} flexDirection="column" w="100%" mb="16px">
          <HStack w="100%">
            <Image src={nftData.collection.image} w="16px" h="16px" />
            <Text w="100%" textStyle="body12regular" color="typographySecondary">
              {nftData.collection.name}
            </Text>
          </HStack>
          <Text w="100%" textStyle="h3">
            {`${nftData.name ?? nftData.tokenId}`}
          </Text>
        </Flex>
        <Image
          src={nftData.image}
          w="100%"
          maxW={{ xl: '560px' }}
          mr={{ xl: '40px' }}
          mb={{ base: '16px', xl: '0' }}
          top={0}
        />
        <Flex flexDirection="column" w={{ base: '100%', xl: '308px' }} flexShrink="0" h="100%">
          <Flex
            display={{ base: 'none', xl: 'block' }}
            flexDirection="column"
            w="100%"
            gap="8px"
            mb={{ base: '16px', xl: '32px' }}
          >
            <HStack w="100%">
              <Image src={nftData.collection.image} w="16px" h="16px" />
              <Text w="100%" textStyle="body12regular" color="typographySecondary">
                {nftData.collection.name}
              </Text>
            </HStack>
            <Text w="100%" textStyle="h3" mt="8px">
              {`${nftData.name ?? nftData.tokenId}`}
            </Text>
          </Flex>
          <Flex flexDirection="column" w="100%">
            <Flex flexDirection="column" mb={{ base: '16px', xl: '32px' }} w="100%" h="100%">
              <Flex flexDirection="column" w="100%" alignItems="flex-start" mb={{ base: '16px', xl: '32px' }}>
                <Text textStyle="h4" color="typographySecondary" mb="16px">
                  Properties
                </Text>
                <Flex w="100%" justify="space-between">
                  <Grid
                    w="100%"
                    h="100%"
                    rowGap="16px"
                    columnGap="16px"
                    templateColumns="repeat(2, 1fr)"
                    templateRows="repeat(3, 1fr)"
                  >
                    {nftData.properties.map((property, i) => {
                      return (
                        <GridItem
                          key={i}
                          flexDirection="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          gap="2px"
                          w="100%"
                          h="100%"
                        >
                          <Text color="typographySecondary" textStyle="body12regular">
                            {property.trait_type}
                          </Text>
                          <Text w="120%" textStyle="body16regular">
                            {property.value}
                          </Text>
                        </GridItem>
                      );
                    })}
                  </Grid>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex flexDirection="column" w="100%" alignItems="flex-start">
            <Text textStyle="h4" color="typographySecondary" mb="16px">
              NFT Details
            </Text>
            <HStack w="100%" justify="space-between">
              <VStack w="100%" h="100%" justifyContent="flex-start">
                <Flex
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  gap="4px"
                  w="100%"
                  h="100%"
                >
                  <Text w="100%" color="typographySecondary" textStyle="body12regular">
                    Owner
                  </Text>
                  <InfoLink
                    url={`https://opensea.io/${nftData.owner}`}
                    text={`${nftData.owner.slice(0, 6)}…${nftData.owner.slice(-4)}`}
                  />
                </Flex>
                <Flex
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  gap="4px"
                  w="100%"
                  h="100%"
                >
                  <Text color="typographySecondary" textStyle="body12regular">
                    Contract Address
                  </Text>
                  <InfoLink
                    url={`https://etherscan.io/address/${nftData.collection.id}`}
                    text={`${nftData.collection.id.slice(0, 6)}…${nftData.collection.id.slice(-4)}`}
                  />
                </Flex>
                <Flex
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  gap="4px"
                  w="100%"
                  h="100%"
                >
                  <Text color="typographySecondary" textStyle="body12regular">
                    Blockchain
                  </Text>
                  <Text textStyle="body16regular">Ethereum</Text>
                </Flex>
              </VStack>
              <VStack w="100%" h="100%" justifyContent="flex-start">
                <Flex
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  gap="4px"
                  w="100%"
                  h="100%"
                >
                  <Text w="100%" color="typographySecondary" textStyle="body12regular">
                    Creator
                  </Text>
                  <InfoLink
                    url={`https://opensea.io/${nftData.creator}`}
                    text={`${nftData.creator.slice(0, 6)}…${nftData.creator.slice(-4)}`}
                  />
                </Flex>
                <Flex
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  gap="4px"
                  w="100%"
                  h="100%"
                >
                  <Text color="typographySecondary" textStyle="body12regular">
                    Token ID
                  </Text>
                  <InfoLink
                    url={`https://opensea.io/assets/ethereum/${nftData.collection.id}/${nftData.tokenId}`}
                    text={nftData.tokenId}
                  />
                </Flex>
                <Flex
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  gap="4px"
                  w="100%"
                  h="100%"
                >
                  <Text color="typographySecondary" textStyle="body12regular">
                    Token Standard
                  </Text>
                  <Text textStyle="body16regular">ERC721</Text>
                </Flex>
              </VStack>
            </HStack>
          </Flex>
          {/* <Flex flexDirection={"column"} w={"100%"} mb={{base: "16px", xl: "32px"}}>
            <Text
              w={"100%"}
              color={"typographySecondary"}
              textStyle={"h4"}
              mb={"16px"}
            >
              Collection
            </Text>
            <Text textStyle={"body16regular"}>
              {nftData.collection.description}
            </Text>
          </Flex> */}
        </Flex>
      </Flex>
    </TavernModal>
  );
};

const InfoLink = (props: { url: string; text: string }) => {
  return (
    <Link
      textStyle="body16regular"
      display="inline-block"
      href={props.url}
      target="_blank"
      _hover={{ textDecoration: 'none', color: 'brandPrimary' }}
    >
      {props.text}
      <ExternalLinkIcon ml="6px" mb="4px" boxSize="12px" color="typographySecondary" />
    </Link>
  );
};
