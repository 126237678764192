import { Box, Flex, Image, Link, MenuButton, MenuItem, MenuList, Spinner, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import _ from 'lodash';

import { IconDelete, IconMeatballs } from '../../theme/foundations/customIcons';
import { LinkBlockProps } from './types';
import { TavernDropdown } from '../uiKit/TavernDropdown/TavernDropdown';
import { deleteLink } from '../../services/tavern-functions';

const linkWithProtocol = (link: string): string => {
  return link.startsWith('http://') || link.startsWith('https://') ? link : `http://${link}`;
};

const urlHost = (link: string): string | undefined => {
  try {
    return new URL(linkWithProtocol(link)).host;
  } catch (error) {
    return undefined;
  }
};

export const LinkBlock = (props: LinkBlockProps) => {
  const { image, title, description, link, linkId, sharedTime = 0, sharedUser, hasDropdown } = props;

  const { tavernId } = useParams();

  const [deleteIsPending, setDeleteIsPending] = useState(false);

  const getSharedTime = (): string => {
    const date = new Date(sharedTime);
    return `${date.toLocaleString('en-UK', {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  };

  const host = urlHost(link);

  if (!host) {
    return null;
  }

  const shortenedLink = _.truncate(link, {
    length: 28,
    omission: '...',
    separator: '',
  });

  return (
    <Box>
      <Flex bg="borderPrimary" p="16px" alignItems="flex-start">
        {image && <Image src={image} alt={title} w="80px" h="80px" flexShrink="0" mr="16px" objectFit="cover" />}
        <Box flexGrow={1} flexShrink={1}>
          {title && (
            <Text as="strong" display="block" textStyle="body14regular">
              {title}
            </Text>
          )}
          {description && (
            <Text
              textStyle="body12regular"
              color="typographySecondary"
              overflow="hidden"
              mt="8px"
              sx={{
                display: '-webkit-box;',
                WebkitLineClamp: '4',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {description}
            </Text>
          )}
          <Link
            textStyle="body12regular"
            mt="8px"
            href={link}
            target="_blank"
            color="brandPrimary"
            _hover={{ textDecoration: 'underline' }}
          >
            {shortenedLink}
          </Link>
        </Box>
      </Flex>
      {/* TODO: Can reused MediaBlockMetadata is LinkBlock is changed to accept Link */}
      {sharedUser && sharedTime && (
        <Flex mt="7px" textStyle="body12regular" justifyContent="space-between">
          {sharedUser && (
            <Flex>
              <Text color="typographySecondary">Shared by</Text>
              <Text ml="8px" color="typographyAdditional">
                {sharedUser}
              </Text>
            </Flex>
          )}
          <Flex alignItems="center">
            {sharedTime !== 0 && <Text color="typographySecondary">at {getSharedTime()}</Text>}
            {hasDropdown && (
              <TavernDropdown>
                <MenuButton
                  ml="12px"
                  bg="0"
                  boxSize="24px"
                  borderRadius="50%"
                  _expanded={{ bg: 'backgroundInput' }}
                  _hover={{ bg: 'backgroundInput' }}
                  aria-label="actions"
                >
                  <IconMeatballs color="typographySecondary" boxSize="24px" />
                </MenuButton>
                <MenuList>
                  <MenuItem
                    closeOnSelect={false}
                    onClick={() => {
                      setDeleteIsPending(true);
                      deleteLink(tavernId as string, linkId)
                        .then(() => {
                          setDeleteIsPending(false);
                        })
                        .catch((error) => console.error(error));
                    }}
                    icon={deleteIsPending ? <Spinner /> : <IconDelete boxSize="16px" />}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </TavernDropdown>
            )}
          </Flex>
        </Flex>
      )}
    </Box>
  );
};
