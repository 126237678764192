import { useContext, useMemo } from 'react';
import { throttle } from 'lodash';
import GlobalPlayerContext from '../../context/global-player-context';
import { IconMicrophoneOff, IconMicrophoneOn } from '../../theme/foundations/customIcons';
import { SessionType } from '../../types/tavern';
import { StreamActionButton } from '../uiKit/StreamActionButton/StreamActionButton';
import { TavernTooltip } from '../uiKit/TavernTooltip/TavernTooltip';
import SpeechRecognitionContext from '../../context/speech-recognition-context';

export const MuteButton = () => {
  const { userSession } = useContext(GlobalPlayerContext);
  const { setIsMuted, isForceMuted, isMuted, isMicSwitchingState } = useContext(SpeechRecognitionContext);

  const toggleMute = () => {
    if (isForceMuted) {
      return setIsMuted(true);
    }
    setIsMuted((prev) => !prev);
  };

  const toggleThrottled = useMemo(() => {
    return throttle(toggleMute, 500, {
      trailing: false,
    });
  }, [isForceMuted]);

  // TODO: This should support Storybook; currently it exits
  // here in Storybook.
  if (userSession === null || userSession.type === SessionType.Joiner || userSession.type === SessionType.Listener) {
    return <></>;
  }

  return (
    <>
      <TavernTooltip label={`${isMuted || isForceMuted ? 'Unmute' : 'Mute'} microphone`}>
        <StreamActionButton
          mr="4px"
          isDisabled={isMicSwitchingState}
          aria-label="microphone"
          icon={isForceMuted || isMuted ? <IconMicrophoneOff /> : <IconMicrophoneOn />}
          onClick={toggleThrottled}
        />
      </TavernTooltip>
    </>
  );
};
