import { useContext, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Box } from '@chakra-ui/react';

import { Reaction } from '../../types/tavern';
import GlobalPlayerContext from '../../context/global-player-context';
import { animateReaction, createReaction } from '../../utils/reaction-util';

export const FloatingReactions = (props: { displayLiveData: boolean }) => {
  const { connectedTavern, reactions, recordingProgressTimestamp, recordedPlayState } = useContext(GlobalPlayerContext);
  const containerRef = useRef<HTMLDivElement>(null);

  const [usedReactions, setUsedReactions] = useState<Reaction[]>([]);

  const recordingStartTime = connectedTavern ? connectedTavern.recordingStartTime || connectedTavern.startTime : 0;
  const recordingEndTime = connectedTavern
    ? connectedTavern.recordingEndTime || connectedTavern.endTime || recordingStartTime
    : 0;
  const tavernLength = recordingEndTime - recordingStartTime;
  const offset = tavernLength - (recordedPlayState?.duration ? recordedPlayState.duration * 1000 : tavernLength);

  useEffect(() => {
    if (props.displayLiveData) {
      const newestReaction: any = _.first(reactions);

      if (!containerRef.current || !newestReaction) {
        return;
      }

      const container = containerRef.current;

      const reaction = createReaction(newestReaction.type);
      container.appendChild(reaction);
      animateReaction(reaction, container);
    }
  }, [reactions]);

  useEffect(() => {
    if (recordingProgressTimestamp) {
      const pastReactions = reactions.filter((item) => {
        return (
          item.timestamp - offset <= recordingProgressTimestamp && !_.some(usedReactions, (r) => _.isEqual(r, item))
        );
      });

      const newestReaction: any = _.first(pastReactions);

      if (!containerRef.current || !newestReaction) {
        setUsedReactions((oldReactions) => {
          return [...oldReactions.filter((item) => item.timestamp - offset <= recordingProgressTimestamp)];
        });

        return;
      }

      setUsedReactions((oldReactions) => {
        return [...oldReactions, newestReaction];
      });

      const container = containerRef.current;

      const reaction = createReaction(newestReaction.type);
      container.appendChild(reaction);
      animateReaction(reaction, container);
    }
  }, [recordingProgressTimestamp, offset]);

  return <Box ref={containerRef} w="200px" h={0} />;
};
