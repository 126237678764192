import { defineStyleConfig } from '@chakra-ui/react';

const PlayPauseButtonStyle = defineStyleConfig({
  baseStyle: {
    backgroundColor: 'typographyPrimary',
    marginTop: '0',
    padding: '0',
    borderRadius: '50%',
    _hover: { backgroundColor: 'typographyAdditional' },
  },
  sizes: {
    xs: {
      width: '32px',
      height: '32px',
      minWidth: '32px',
      svg: {
        width: '19px',
        height: '19px',
      },
    },
    md: {
      width: '40px',
      height: '40px',
      minWidth: '40px',
      svg: {
        width: '24px',
        height: '24px',
      },
    },
    lg: {
      width: '64px',
      height: '64px',
      svg: {
        width: '32px',
        height: '32px',
      },
    },
  },
  variants: {},
  defaultProps: {
    size: 'xs',
  },
});

export default PlayPauseButtonStyle;
