import { useEffect, useContext } from 'react';
import { Box, Text, VStack, HStack, Image, useBreakpointValue, Link } from '@chakra-ui/react';
import { useNavigate, Link as ReactRouterLink } from 'react-router-dom';
import { FaRedditAlien } from 'react-icons/fa';

import { IconLogo, IconTavernLogo, IconTwitter, IconDiscord, IconWarpcast } from '../theme/foundations/customIcons';
import { TavernButtonAnime } from '../components/uiKit/TavernButton/TavernButtonAnime';
import { useIsSignedIn } from '../hooks/useIsSignIn';
import { SignInOutButton } from '../components/buttons/sign-in-out-button';
import { UserDetails } from '../components/UserDetails/UserDetails';
import AuthContext from '../context/auth';
import { getUserImage } from '../utils/user-util';
import { useTavernToast } from '../components/hooks/useTavernToast';
import { TavernTooltip } from '../components/uiKit/TavernTooltip/TavernTooltip';
import { FilteredTavernList } from '../components/lists/filtered-tavern-list';
import { TavernStatus } from '../types/tavern';

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) {
    newWindow.opener = null;
  }
};

export const TavernLanding = () => {
  const navigate = useNavigate();
  const isSignedIn = useIsSignedIn();
  const toast = useTavernToast();

  const isHStack = useBreakpointValue({ base: false, '2xl': true });

  const { user } = useContext(AuthContext);

  useEffect(() => {
    document.title = `Hello friend. Stay awhile and listen!`;
  }, []);

  const InternalStack = () => (
    <>
      <Image marginTop={-28} src="/landing.gif" width="400px" />
      <VStack width={{ base: '100%', '2xl': '500px' }} align="flex-start" justify="flex-start">
        <Text as="span" textStyle="h1" fontWeight="bold" textAlign="left">
          HELLO FRIEND.
          <br />
          STAY AWHILE & LISTEN!
        </Text>
        {/* <Text as="span" color="whiteAlpha.600" textAlign="left">
          SocialFi built on
          <Link
            as="span"
            paddingLeft={2}
            color="purple.600"
            textAlign="left"
            onClick={() => {
              openInNewTab('https://www.berachain.com/');
            }}
          >
            Berachain
          </Link>
        </Text> */}
        <Text as="span" color="whiteAlpha.600" textAlign="left">
          Dive into audio conversations you care about most.
        </Text>
        <Box paddingTop="20px">
          <TavernButtonAnime
            width="100%"
            leftIcon={<IconTavernLogo boxSize="16px" />}
            size="md"
            variant="primary"
            onClick={() => {
              if (user && user.isAnon) {
                toast({
                  title: `Login to Create Tavern.`,
                  description: 'To create a new Tavern, login at the top right.',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
              } else {
                navigate('/create');
              }
            }}
          >
            Create New Tavern
          </TavernButtonAnime>
        </Box>
        <HStack paddingTop="20px" spacing={4}>
          <Box
            onClick={() => {
              openInNewTab('https://warpcast.com/~/channel/tavern');
            }}
            opacity={0.8}
            _hover={{
              cursor: 'pointer',
              opacity: 0.6,
            }}
          >
            {IconWarpcast}
          </Box>
          <Box
            onClick={() => {
              openInNewTab('https://twitter.com/tavernFM');
            }}
            opacity={0.8}
            _hover={{
              cursor: 'pointer',
              opacity: 0.6,
            }}
          >
            <IconTwitter />
          </Box>
          <Box
            onClick={() => {
              openInNewTab('https://discord.com/invite/WyeD4Fx5WU');
            }}
            opacity={0.8}
            _hover={{
              cursor: 'pointer',
              opacity: 0.6,
            }}
          >
            <IconDiscord />
          </Box>
          <Box
            onClick={() => {
              openInNewTab('https://www.reddit.com/r/tavern_fm');
            }}
            opacity={0.8}
            _hover={{
              cursor: 'pointer',
              opacity: 0.6,
            }}
          >
            <FaRedditAlien />
          </Box>
        </HStack>
      </VStack>
    </>
  );

  return (
    <VStack width="100%" height="100vh" align="flex-start" justify="flex-start">
      <HStack width="100%" justifyContent="space-between">
        <IconLogo />
        <Box>
          {isSignedIn ? (
            <TavernTooltip label={user?.name ?? user?.id.slice(0, 6) ?? ''}>
              <Box>
                <UserDetails
                  onClick={() => {
                    navigate(`/profiles/${user?.id as string}`);
                  }}
                  avatar={getUserImage(user)}
                  name={user?.name ?? user?.id.slice(0, 6) ?? ''}
                  id={user?.name ? user?.id.slice(0, 6) : ''}
                  isCollapse
                />
              </Box>
            </TavernTooltip>
          ) : (
            <SignInOutButton isSignedIn={isSignedIn} />
          )}
        </Box>
      </HStack>
      {isHStack ? (
        <HStack width="100%" align="flex-start" justify="center" spacing={10} paddingTop={40}>
          <InternalStack />
        </HStack>
      ) : (
        <VStack width="100%" height="100vh" align="center" paddingTop={40} spacing={4}>
          <InternalStack />
        </VStack>
      )}

      <VStack width="100%" align="center" justify="flex-start" paddingTop={20} paddingBottom={40}>
        <HStack>
          <Link as={ReactRouterLink} color="whiteAlpha.600" textAlign="center" padding={2} to="/taverns">
            Live
          </Link>
          <Link
            as={ReactRouterLink}
            color="whiteAlpha"
            textAlign="center"
            padding={2}
            textDecoration="underline"
            to="/upcoming"
          >
            Upcoming
          </Link>
          <Link as={ReactRouterLink} color="whiteAlpha.600" textAlign="center" padding={2} to="/past">
            Past
          </Link>
        </HStack>

        <Box maxWidth={1000}>
          <FilteredTavernList status={TavernStatus.Upcoming} />
        </Box>
      </VStack>

      {/* <HStack justify="center" paddingBottom={10}>
        <Text fontSize={12} as="span" color="whiteAlpha.600" textAlign="left">
          © 2023 Tavern.fm, All Rights Reserved
        </Text>
      </HStack> */}
    </VStack>
  );
};
