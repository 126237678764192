import { useEffect, useState } from 'react';
import { Box, Button, Flex, Grid, GridItem, HStack, Spacer } from '@chakra-ui/react';
import Fuse from 'fuse.js';
import _ from 'lodash';
import { TavernCardProps } from '../../TavernCard/types';
import { Tavern, TavernStatus, Topic } from '../../../types/tavern';
import { TavernCardPast } from '../../TavernCard/TavernCardPast';
import { TavernCardLive } from '../../TavernCard/TavernCardLive';
import { TavernCardUpcoming } from '../../TavernCard/TavernCardUpcoming';
import { TavernCard } from '../../TavernCard/TavernCard';
import { TavernsSectionProps } from './types';
import { IconSearch, IconTavernLogo } from '../../../theme/foundations/customIcons';
import { TavernInput } from '../../uiKit/TavernInput/TavernInput';
import { OrderSortingPanel } from '../../OrderSortingPanel/OrderSortingPanel';
import { SortingField, SortingValue } from '../../OrderSortingPanel/types';
import { ProfileEmptyPanel } from '../../ProfileEmptyPanel/ProfileEmptyPanel';

export const TavernsSection = (props: TavernsSectionProps) => {
  const { taverns } = props;

  const [view, setView] = useState<TavernCardProps['variant']>('Grid');
  // SORTING
  const [sortingFields, setSortingFields] = useState<SortingField[]>([
    { name: 'Start date', value: SortingValue.DESC },
    // { name: "Going", value: SortingValue.DESC },
  ]);
  const [selectedField, setSelectedField] = useState(sortingFields[0]);

  const isGrid = view === 'Grid';
  const isList = view === 'List';

  const renderCardComponent = (cardProps: any) => {
    if (cardProps.status === TavernStatus.Live) {
      return <TavernCardLive {...cardProps} />;
    }

    if (cardProps.status === TavernStatus.Past) {
      return <TavernCardPast {...cardProps} />;
    }

    if (cardProps.status === TavernStatus.Upcoming) {
      return <TavernCardUpcoming {...cardProps} />;
    }

    return <TavernCard {...cardProps} />;
  };

  const onTopicClick = (topic: Topic, e?: Event) => {
    e?.stopPropagation();
    setSearchQuery(topic.name);
  };

  const handleSortingChange = (name: string, newValue: SortingValue) => {
    setSelectedField({ name, value: newValue });
    setSortingFields((prevState) => {
      const index = prevState.findIndex((f) => f.name === name);
      if (index <= -1) {
        return prevState;
      }
      const newState = [...prevState];
      newState[index].value = newValue;

      return newState;
    });
  };

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredTaverns, setFilteredTaverns] = useState<Tavern[]>([]);

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredTaverns(taverns);
      return;
    }
    const fuse = new Fuse(taverns || [], {
      includeScore: false,
      shouldSort: false,
      threshold: 0.05,
      keys: ['title', 'description', 'topicNames'],
    });
    setFilteredTaverns(fuse.search(searchQuery).map((e) => e.item));
  }, [searchQuery, taverns]);

  let sortedData;

  if (selectedField.name === 'Start date' && selectedField.value === 'asc') {
    sortedData = _.sortBy(filteredTaverns, ['startTime']);
  } else if (selectedField.name === 'Start date' && selectedField.value === 'desc') {
    sortedData = _.orderBy(filteredTaverns, ['startTime'], ['desc']);
  } else {
    sortedData = filteredTaverns;
  }

  return (
    <>
      {taverns.length !== 0 ? (
        <>
          <Flex flexWrap={{ base: 'wrap', xl: 'nowrap' }} alignItems="center" mb={{ base: '24px', '2xl': '32px' }}>
            <Box mr={{ xl: '40px' }} order={{ base: 3, xl: 'initial' }}>
              <OrderSortingPanel fields={sortingFields} selected={selectedField} onChange={handleSortingChange} />
            </Box>
            <Spacer />
            <Box display={{ base: 'none', xl: 'block' }}>
              <HStack spacing="16px" mr="24px">
                <Button
                  bg="0"
                  textStyle="body14regular"
                  fontWeight="400"
                  p="0px"
                  _hover={{ textDecoration: 'none' }}
                  _focus={{ bg: '0' }}
                  color={isGrid ? 'typographyPrimary' : 'typographySecondary'}
                  onClick={() => setView('Grid')}
                >
                  Grid
                </Button>
                <Button
                  bg="0"
                  textStyle="body14regular"
                  fontWeight="400"
                  p="0px"
                  _hover={{ textDecoration: 'none' }}
                  _focus={{ bg: '0' }}
                  color={isList ? 'typographyPrimary' : 'typographySecondary'}
                  onClick={() => setView('List')}
                >
                  List
                </Button>
              </HStack>
            </Box>
            <Box
              maxWidth={{ xl: '240px' }}
              order={{ base: 1, xl: 'initial' }}
              mb={{ base: '24px', xl: '0px' }}
              w="100%"
            >
              <TavernInput
                leftIcon={<IconSearch />}
                value={searchQuery}
                placeholder="Search by Tavern name or description"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Box>
          </Flex>
          <Grid
            gap={{ base: '16px', xl: '24px' }}
            pb={{ base: '24px', xl: 0 }}
            autoFlow="row dense"
            templateColumns={
              isGrid ? { base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)', '3xl': 'repeat(3, 1fr)' } : 'repeat(1, 1fr)'
            }
          >
            {sortedData.map((tavern: Tavern, i) => (
              <GridItem key={i}>{renderCardComponent({ ...tavern, variant: view, tavern, onTopicClick })}</GridItem>
            ))}
          </Grid>
        </>
      ) : (
        <ProfileEmptyPanel icon={IconTavernLogo} />
      )}
    </>
  );
};
