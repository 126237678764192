import { Flex, Icon } from '@chakra-ui/react';
import { useState, useEffect } from 'react';

import { CardHeaderIndicatorProps } from './types';
import { TavernTooltip } from '../uiKit/TavernTooltip/TavernTooltip';

const colors = ['purple.500', 'red.500', 'blue.500', 'green.500', 'yellow.500'];

export const CardHeaderIndicatorAnime = (props: CardHeaderIndicatorProps) => {
  const { icon, label, tooltip, ...rest } = props;

  const [colorIndex, setColorIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Flex
      {...rest}
      textStyle="body14regular"
      alignItems="center"
      sx={{
        '&&': {
          color: colors[colorIndex],
        },
      }}
      transition="color 2s, border-color 2s"
    >
      {icon && (
        <TavernTooltip label={tooltip}>
          <Icon
            as={icon}
            textStyle="body14regular"
            boxSize="16px"
            mr={label ? '8px' : 0}
            sx={{
              '&&': {
                color: colors[colorIndex],
              },
            }}
            transition="color 2s, border-color 2s"
          />
        </TavernTooltip>
      )}{' '}
      {label}
    </Flex>
  );
};
