/* eslint-disable */
import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);

const TopicTagStyles = defineMultiStyleConfig({
  baseStyle: {
    container: {
      bgColor: 'backgroundInput',
      color: 'typographySecondary',
      borderRadius: 'none',
      p: '0 6px',
      m: '0 4px 8px',
      flexShrink: '0',
      whiteSpace: 'nowrap',
    },
  },
  variants: {},
  sizes: {},
  defaultProps: {},
});

export default TopicTagStyles;
