import { useContext, useEffect, useState } from 'react';

import GlobalPlayerContext from '../context/global-player-context';
import { AudioClip, TavernStatus } from '../types/tavern';
import { CaptionsList } from './CaptionsList/CaptionsList';
import { Caption } from './MomentSubtitles/types';
import { auth } from '../connect';
import { clipsFilteredForReplay } from '../utils/audio-clip-util';

export const CaptionsBox = () => {
  const { audioClips, connectedTavern, recordingProgressTimestamp, recordedPlayState } =
    useContext(GlobalPlayerContext);

  const [captions, setCaptions] = useState<Caption[]>([]);

  const recordingStartTime = connectedTavern ? connectedTavern.recordingStartTime || connectedTavern.startTime : 0;
  const recordingEndTime = connectedTavern
    ? connectedTavern.recordingEndTime || connectedTavern.endTime || recordingStartTime
    : 0;
  const tavernLength = recordingEndTime - recordingStartTime;
  const offset = tavernLength - (recordedPlayState ? recordedPlayState.duration * 1000 : tavernLength);

  useEffect(() => {
    const combinedCaptions: Caption[] = [];

    const filteredClips = clipsFilteredForReplay(
      audioClips,
      connectedTavern?.status === TavernStatus.Live,
      recordingProgressTimestamp,
      offset
    );

    let prevClip: AudioClip | null = null;
    filteredClips
      // Remove clips where sound was made but it was unintelligible, so there's no caption.
      .filter((clip) => clip.caption !== '')
      .sort((a, b) => a.startTime - b.startTime)
      .forEach((clip) => {
        const { userId, userName, avatar, caption, endTime } = clip;
        const captionSide = auth.currentUser?.uid === userId ? 'right' : 'left';

        // When there are consecutive clips from the same user, we combine them with
        // line breaks into one subtitle.
        if (caption === null && endTime === null) {
          if (prevClip && prevClip.userId === userId) {
            combinedCaptions[combinedCaptions.length - 1].transcriptions?.push('...');
          }
        } else if (prevClip && prevClip.userId === userId && caption) {
          combinedCaptions[combinedCaptions.length - 1].transcriptions?.push(`\n${caption}`);
        } else if (caption) {
          combinedCaptions.push({
            author: {
              id: userId,
              name: userName ?? userId,
              avatar,
              session: {} as any,
            },
            side: captionSide,
            transcriptions: [caption],
          });
        }

        prevClip = clip;
      });

    setCaptions(combinedCaptions);
  }, [audioClips, connectedTavern?.status, recordingProgressTimestamp]);

  return <CaptionsList scrollHeight="296px" gradient="gradientPopover" captions={captions} />;
};
