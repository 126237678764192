import Fuse from 'fuse.js';
import { Box, Grid, GridItem, HStack, Text } from '@chakra-ui/react';
import { FC, useState, useContext, useEffect } from 'react';
import { query, collection, onSnapshot, where } from 'firebase/firestore';
import { useParams } from 'react-router-dom';

import GlobalPlayerContext from '../../../context/global-player-context';
import { IconSearch } from '../../../theme/foundations/customIcons';
import { ParticipantsSectionProps } from './types';
import { RequestInviteStatus, SessionType, SpeakInvite, TavernStatus } from '../../../types/tavern';
import { TavernInput } from '../../uiKit/TavernInput/TavernInput';
import { TavernUser } from '../../uiKit/TavernUser/TavernUser';
import { UserMock } from '../../../types/user';
import { db } from '../../../connect';
import { joinerDropDownItems } from '../../uiKit/DropdownItem/DropdownItem';

export const ParticipantsSection: FC<ParticipantsSectionProps> = ({
  joiners,
  sessionType,
  cardSize = 'lg',
  status,
  ...rest
}) => {
  const { tavernId } = useParams();
  const { userSession } = useContext(GlobalPlayerContext);

  const [searchQuery, setSearchQuery] = useState('');

  const [speakRequests, setSpeakRequests] = useState<string[]>([]);
  const [speakInvites, setSpeakInvites] = useState<string[]>([]);
  const [coHostInvites, setCoHostInvites] = useState<string[]>([]);

  useEffect(() => {
    const requestsQuery = query(
      collection(db, `taverns/${tavernId as string}/requests`),
      where('status', '==', RequestInviteStatus.Pending)
    );
    const unsubRequests = onSnapshot(requestsQuery, (querySnapshot) =>
      setSpeakRequests(querySnapshot.docs.map((doc) => doc.data().user.id))
    );
    return unsubRequests;
  }, [tavernId]);

  useEffect(() => {
    const invitesQuery = query(
      collection(db, `taverns/${tavernId as string}/invites`),
      where('status', '==', RequestInviteStatus.Pending)
    );
    const unsubInvites = onSnapshot(invitesQuery, (querySnapshot) => {
      const sInvites: string[] = [];
      const cInvites: string[] = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data() as SpeakInvite;
        if (data.type === SessionType.Speaker) {
          sInvites.push(data.user.id);
        } else if (data.type === SessionType.CoHost) {
          cInvites.push(data.user.id);
        }
      });

      setSpeakInvites(sInvites);
      setCoHostInvites(cInvites);
    });
    return unsubInvites;
  }, [tavernId]);

  const fuse = new Fuse(joiners || [], {
    includeScore: false,
    keys: ['name', 'session.user.id'],
  });

  const results = fuse.search(searchQuery);
  const filteredJoiners = searchQuery ? results.map((r) => r.item) : joiners;

  const isPast = status === TavernStatus.Past || !status;

  return (
    <Box {...rest}>
      <Box mb="24px">
        <HStack justifyContent="flex-start">
          <TavernInput
            leftIcon={<IconSearch />}
            value={searchQuery}
            placeholder="Search by name"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </HStack>
      </Box>
      <Grid pt="16px" autoFlow="row dense" templateColumns="repeat(auto-fill, 152px)" gap="24px">
        {!filteredJoiners.length && <Text opacity={0.5}>No Results</Text>}
        {filteredJoiners.map((joined: UserMock) => {
          return (
            <GridItem key={joined.id}>
              <TavernUser
                size={cardSize}
                user={joined}
                sessionType={sessionType}
                requestedToSpeak={!isPast && speakRequests.includes(joined.session?.user.id ?? joined.name)}
                isInvitedToSpeak={!isPast && speakInvites.includes(joined.session?.user.id ?? joined.name)}
                isInvitedToCoHost={!isPast && coHostInvites.includes(joined.session?.user.id ?? joined.name)}
                dropdownItems={joinerDropDownItems(joined, userSession, tavernId as string)}
                hideScore={!isPast && !userSession}
              />
            </GridItem>
          );
        })}
      </Grid>
    </Box>
  );
};
