/* eslint-disable @typescript-eslint/no-non-null-assertion */
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Bar } from 'react-chartjs-2';
import { HStack, VStack, Box } from '@chakra-ui/react';
import { Chart as ChartJS, ChartOptions, registerables } from 'chart.js';
import { getRelativePosition } from 'chart.js/helpers';
import { useContext, useMemo, useRef } from 'react';
import _ from 'lodash';

import GlobalPlayerContext from '../../context/global-player-context';
import TavernDetailContext from '../../context/tavern-detail-context';
import { Session, Tavern } from '../../types/tavern';
import { UserDetailsHorizontal } from '../UserDetailsHorizontal/UserDetailsHorizontal';
import { secondsSinceTavernStart, secondsToFormattedTime } from '../../utils/timestamp-util';

ChartJS.register(...registerables);
ChartJS.register(annotationPlugin);

export const ActivityGanttChart = (props: { tavern: Tavern; isConnected: boolean }) => {
  const chartRef = useRef<any>(null);
  const { tavern } = props;
  const { audioClips, hosts, coHosts, speakers } = useContext(TavernDetailContext);

  const sortedAudioClips = _.sortBy(audioClips, ['startTime'], ['asc']);

  const { connectedTavern, recordedPlayState, setRecordedPlayState, recordedTavernProgress } =
    useContext(GlobalPlayerContext);

  const isCurrentTavernPlaying = connectedTavern?.id === tavern.id;

  const recordingStartTime = isCurrentTavernPlaying
    ? connectedTavern
      ? connectedTavern.recordingStartTime || connectedTavern.startTime
      : 0
    : tavern.recordingStartTime || tavern.startTime;
  const recordingEndTime = isCurrentTavernPlaying
    ? connectedTavern
      ? connectedTavern.recordingEndTime || connectedTavern.endTime || recordingStartTime
      : 0
    : tavern.recordingEndTime || tavern.endTime || recordingStartTime;

  const recordingPositionTimestamp = useMemo(() => {
    if (!connectedTavern || !recordedTavernProgress || !isCurrentTavernPlaying) {
      return recordingStartTime;
    }
    return recordingStartTime + recordedTavernProgress * 1000;
  }, [connectedTavern, recordedTavernProgress, isCurrentTavernPlaying, recordingStartTime]);

  const uniqueSpeakerSessions = useMemo(() => {
    const uniqueIds = new Set<string>();
    sortedAudioClips.forEach((audioClip) => {
      uniqueIds.add(audioClip.userId);
    });

    return Array.from(uniqueIds)
      .map((speakerId) => {
        return [...hosts, ...coHosts, ...speakers].find((session) => session?.user.id === speakerId);
      })
      .filter((session) => session !== undefined) as Session[];
  }, [sortedAudioClips, hosts, coHosts, speakers]);

  const uniqueSpeakerIds = useMemo(() => {
    return uniqueSpeakerSessions.map((session) => session.user.id);
  }, [uniqueSpeakerSessions]);

  const goToRecordingPosition = (position: number) => {
    if (!recordedPlayState || !isCurrentTavernPlaying) {
      return;
    }
    setRecordedPlayState({
      ...recordedPlayState,
      newProgress: position,
      isPlaying: true,
    });
  };

  const hasDuration = recordedPlayState && recordedPlayState.duration;
  const recordingAnnotationPosition = secondsSinceTavernStart(recordingPositionTimestamp, recordingStartTime);
  const tavernLength = recordingEndTime - recordingStartTime;
  const offset = (tavernLength - (hasDuration ? recordedPlayState.duration * 1000 : tavernLength)) / 1000;

  const datasetsForUserId = (speakerId: string) => {
    return [
      {
        backgroundColor: 'rgba(140, 87, 210)',
        data: sortedAudioClips
          .filter((clip) => clip.userId === speakerId)
          .map((clip) => {
            const startX = secondsSinceTavernStart(clip.startTime, recordingStartTime) - offset;
            const endX = secondsSinceTavernStart(clip.endTime!, recordingStartTime) - offset;

            if (startX < 0 || recordingAnnotationPosition < endX) {
              return {
                x: [0, 0],
                y: clip.userId,
              };
            }

            return {
              x: [startX, endX],
              y: clip.userId,
            };
          }),
        borderWidth: 0,
        maxBarThickness: 16,
        barPercentage: 1,
      },
      {
        backgroundColor: 'white',
        data: sortedAudioClips
          .filter((clip) => clip.userId === speakerId)
          .map((clip) => {
            const startX = secondsSinceTavernStart(clip.startTime, recordingStartTime) - offset;
            const endX = secondsSinceTavernStart(clip.endTime!, recordingStartTime) - offset;

            if (startX < 0 || recordingAnnotationPosition > endX) {
              return {
                x: [0, 0],
                y: clip.userId,
              };
            }

            return {
              x: [startX, endX],
              y: clip.userId,
            };
          }),
        borderWidth: 0,
        maxBarThickness: 16,
        barPercentage: 1,
      },
    ];
  };

  const options: ChartOptions<'bar'> = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    onClick: (event) => {
      const clickPosition = getRelativePosition(event, chartRef.current);
      goToRecordingPosition(chartRef.current.scales.x.getValueForPixel(clickPosition.x));
    },
    onHover: (event: any, chartElement) => {
      event.native.target.style.cursor = chartElement[0] && props.isConnected ? 'pointer' : 'default';
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        yAlign: 'bottom',
        callbacks: {
          title: (context: any) => {
            const session = _.find(uniqueSpeakerSessions, (s) => s.user.id === context[0].label);
            return session?.name || session?.user.id.substring(0, 6);
          },
          label: (context) => {
            const labelData = context.raw as any;
            const [start, end] = labelData.x;

            return ` ${secondsToFormattedTime(start as number)} - ${secondsToFormattedTime(end as number)}`;
          },
        },
      },
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            xMin: recordingAnnotationPosition,
            xMax: recordingAnnotationPosition,
            borderColor: 'rgb(255, 99, 132, 0.75)',
            borderWidth: 1,
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        type: 'linear',
        display: false,
      },
      y: {
        stacked: true,
        ticks: {
          display: false,
        },
        display: true,
      },
    },
    elements: {
      bar: {
        borderRadius: {
          topLeft: 0,
          topRight: 0,
          bottomLeft: 0,
          bottomRight: 0,
        },
        borderSkipped: false,
      },
    },
  };

  return (
    <HStack>
      <VStack align="flex-start" justify="space-between" paddingTop="16px" paddingBottom="16px" spacing={0}>
        {uniqueSpeakerSessions.map((speaker) => {
          return (
            <UserDetailsHorizontal
              key={speaker.id}
              id={speaker.id}
              name={speaker.name || speaker.user.id}
              avatar={speaker.nft ?? speaker.avatar}
              isAvatarBordered
              textAlign="right"
            />
          );
        })}
      </VStack>
      <Box width="100%" overflowX="auto">
        <Box
          width={`${_.floor(tavernLength / 1000)}px`}
          minWidth="800px"
          height={`${uniqueSpeakerSessions.length * 32}px`}
        >
          <Bar
            ref={chartRef}
            data={{
              labels: uniqueSpeakerIds,
              datasets: uniqueSpeakerIds.map(datasetsForUserId).flat(),
            }}
            options={options}
          />
        </Box>
      </Box>
    </HStack>
  );
};
