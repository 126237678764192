import { forwardRef, IconButton, useStyleConfig } from '@chakra-ui/react';
import React from 'react';
import { StreamActionButtonProps } from './types';

export const StreamActionButton = forwardRef<StreamActionButtonProps, 'button'>(
  ({ onClick, icon, isActive, variant, ...rest }, ref) => {
    const styles = useStyleConfig('ActionStreamButtonStyle', { variant });

    return <IconButton sx={styles} ref={ref} icon={icon} isRound isActive={isActive} onClick={onClick} {...rest} />;
  }
);
