import { PopoverTrigger, Popover, PopoverContent, IconButton } from '@chakra-ui/react';
import { noop } from 'lodash';

import { AudioControlsPopoverProps } from './types';
import { TavernSlider } from '../TavernSlider/TavernSlider';

export const AudioControlsPopover = (props: AudioControlsPopoverProps) => {
  const { volume, isMuted, iconOff, iconOn, onVolumeClick = noop, onVolumeChange = noop } = props;

  return (
    <Popover {...props} placement="top-start" trigger="hover">
      <PopoverTrigger>
        <IconButton
          onClick={onVolumeClick}
          borderRadius="50%"
          bg="none"
          minWidth="24px"
          boxSize="24px"
          _hover={{ bg: 'none', color: volume !== 0 || !isMuted ? 'typographyPrimary' : '' }}
          _focus={{ bg: 'none' }}
          aria-label="volume"
        >
          <>{volume === 0 || isMuted ? iconOff : iconOn}</>
        </IconButton>
      </PopoverTrigger>
      <PopoverContent width="25px">
        <TavernSlider
          isDisabled={isMuted}
          onChange={onVolumeChange}
          value={volume}
          defaultValue={50}
          orientation="vertical"
          hasMark
        />
      </PopoverContent>
    </Popover>
  );
};
