import React, { useEffect, useContext } from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';
import { query, collection, onSnapshot, where, doc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import AuthContext from '../../context/auth';
import { RequestBlockInvite } from '../RequestBlock/RequestBlockInvite';
import { RequestInviteStatus, SpeakInvite } from '../../types/tavern';
import { db } from '../../connect';

// Displays list of invites from the host's perspective.
export const InviteSpeakerRequestsList = ({
  setInviteRequestCount,
}: {
  setInviteRequestCount: (count: number) => void;
}) => {
  const { tavernId } = useParams();
  const { user } = useContext(AuthContext);
  const [invites, setInvites] = React.useState<SpeakInvite[]>([]);

  useEffect(() => {
    if (!user?.id) {
      return;
    }
    const myInvitesQuery = query(
      collection(db, `taverns/${tavernId as string}/invites`),
      where('status', '==', RequestInviteStatus.Pending),
      where('user', '==', doc(db, 'users', user.id))
    );

    const unsubAllRequests = onSnapshot(myInvitesQuery, (querySnapshot) => {
      setInvites(querySnapshot.docs.map((d) => d.data()) as SpeakInvite[]);
      setInviteRequestCount(querySnapshot.size);
    });
    return unsubAllRequests;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tavernId]);

  return (
    <Box w="100%">
      <VStack spacing="8px" maxW="825px">
        {!invites.length && <Text opacity={0.5}>No Results</Text>}
        {invites.map((request, i) => (
          <RequestBlockInvite key={i} request={request} />
        ))}
      </VStack>
    </Box>
  );
};
