import { noop } from 'lodash';
import { IconButton, HStack, Text, useBreakpointValue } from '@chakra-ui/react';
import { IconSkipBack, IconSkipForward } from '../../../theme/foundations/customIcons';
import { PlaybackControlsProps } from './types';

export const PlaybackControls = (props: PlaybackControlsProps) => {
  const { speed = 1, onSpeedClick = noop, onSkipBackClick = noop, onSkipForwardClick = noop } = props;

  const isButtonsVisible = useBreakpointValue({
    base: 'none',
    xl: 'flex',
  });

  return (
    <HStack spacing="8px" mr="20px">
      <IconButton
        onClick={onSkipBackClick}
        borderRadius="50%"
        bg="none"
        boxSize="40px"
        _hover={{ bg: 'backgroundInput' }}
        _focus={{ bg: 'none' }}
        aria-label="skip back"
        display={isButtonsVisible}
      >
        <IconSkipBack color="typographySecondary" boxSize="24px" />
      </IconButton>
      <IconButton
        onClick={onSkipForwardClick}
        borderRadius="50%"
        bg="none"
        boxSize="40px"
        _hover={{ bg: 'backgroundInput' }}
        _focus={{ bg: 'none' }}
        aria-label="skip forward"
        display={isButtonsVisible}
      >
        <IconSkipForward color="typographySecondary" boxSize="24px" />
      </IconButton>
      <IconButton
        onClick={onSpeedClick}
        borderRadius="24px"
        bg="none"
        p="8px"
        h="40px"
        _hover={{ bg: 'backgroundInput' }}
        _focus={{ bg: 'none' }}
        aria-label="speed"
      >
        <Text color="typographySecondary" letterSpacing="-2.2px" fontSize="17px" lineHeight="24px" fontWeight="300">
          {speed}
          <Text letterSpacing="0" as="span" ml="3px">
            x
          </Text>
        </Text>
      </IconButton>
    </HStack>
  );
};
