/* eslint-disable */
import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);

const TavernSwichStyles = defineMultiStyleConfig({
  baseStyle: {
    thumb: {
      w: '18px',
      h: '18px',
      transition: '200ms linear',
      _checked: {
        marginLeft: '6px',
      },
    },
    track: {
      display: 'flex',
      alignItems: 'center',
      w: '38px',
      h: '22px',
      padding: '0 2px',
      bg: 'backgroundInputHover',
      _active: {
        '.chakra-switch__thumb': {
          marginLeft: 0,
          width: '24px',
        },
      },
      _checked: {
        background: 'brandPrimary',
      },
    },
  },
});

export default TavernSwichStyles;
