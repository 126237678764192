import { Box, Flex, Menu, useBreakpointValue, VStack } from '@chakra-ui/react';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthContext from '../../context/auth';
import GlobalPlayerContext from '../../context/global-player-context';
import { IconChevronLeft, IconLogo, IconLogoSm, IconTavernLogo } from '../../theme/foundations/customIcons';
import { SidebarButton, SidebarButtonType } from './sidebar-button';
import { SidebarProps } from './types';
import { SignInOutButton } from '../buttons/sign-in-out-button';
import { TavernButtonAnime } from '../uiKit/TavernButton/TavernButtonAnime';
import { TavernTooltip } from '../uiKit/TavernTooltip/TavernTooltip';
import { UserDetails } from '../UserDetails/UserDetails';
import { useIsSignedIn } from '../../hooks/useIsSignIn';
import { getUserImage } from '../../utils/user-util';

export const Sidebar: FC<SidebarProps> = (props) => {
  const { isOpen, onSidebarToggle, isCollapsed, onCollapseButtonClick } = props;
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  const { connectedTavern } = useContext(GlobalPlayerContext);

  const isSignedIn = useIsSignedIn();

  const logoDisplay = useBreakpointValue({
    base: 'none',
    '2xl': 'flex',
  });

  const collapseButtonVisible = useBreakpointValue({
    base: false,
    '2xl': true,
  });

  return (
    <Flex
      position="fixed"
      top={{ base: '72px', xl: '0' }}
      left="0px"
      borderRight="1px"
      borderRightColor="borderPrimary"
      pt="32px"
      pb="24px"
      px={{ base: '4px', xl: isCollapsed ? '16px' : '20px' }}
      w={{ base: '100%', xl: isCollapsed ? '72px' : '280px' }}
      minHeight="100%"
      flexDirection="column"
      color="typographySecondary"
      bg="black"
      transition="0.3s ease-in"
      transform={{ base: isOpen ? 'none' : 'translateX(-100%)', '2xl': 'none' }}
      zIndex="15"
      role="group"
    >
      <Box
        flex="0 0 auto"
        mb="42px"
        justifyContent={isCollapsed ? 'center' : 'flex-start'}
        display={logoDisplay}
        onClick={() => {
          navigate('/');
        }}
        _hover={{
          cursor: 'pointer',
        }}
      >
        {isCollapsed ? <IconLogoSm boxSize="24px" color="typographyPrimary" /> : <IconLogo />}
      </Box>

      {collapseButtonVisible && (
        <Flex
          as="button"
          alignItems="center"
          justifyContent="center"
          boxSize="24px"
          borderRadius="50%"
          bgColor="backgroundTertiary"
          position="absolute"
          right="-12px"
          top="32px"
          visibility="hidden"
          opacity="0"
          _groupHover={{ visibility: 'visible', opacity: '1' }}
          _hover={{ visibility: 'visible', opacity: '1' }}
          onClick={onCollapseButtonClick}
        >
          <IconChevronLeft transform={isCollapsed ? 'scaleX(-1)' : 'none'} transition="0.3s ease-in" boxSize="12px" />
        </Flex>
      )}

      <Flex width="100%" flexDirection="column" flex="1 0 auto">
        <Box flex="1 0 auto">
          <VStack justify="flex-start" align="flex-start" spacing={8}>
            {isSignedIn && (
              <Box paddingX={{ base: '12px', xl: 0 }}>
                <UserDetails
                  onClick={() => {
                    navigate(`/profiles/${user?.id as string}`);
                    onSidebarToggle();
                  }}
                  avatar={getUserImage(user)}
                  name={user?.name ?? user?.id.slice(0, 6) ?? ''}
                  id={user?.name ? user?.id.slice(0, 6) : ''}
                  isCollapse={isCollapsed}
                />
              </Box>
            )}
            {isSignedIn && connectedTavern === null && (
              <TavernTooltip placement="right" label={props.isCollapsed ? 'Create New Tavern' : ''}>
                <Box width="100%" paddingX={{ base: '12px', xl: 0 }}>
                  <TavernButtonAnime
                    width="100%"
                    leftIcon={<IconTavernLogo boxSize="16px" mr={isCollapsed ? '-8px' : '0'} />}
                    size={isCollapsed ? 'xs' : 'md'}
                    variant="primary"
                    onClick={() => {
                      navigate('/create');
                      onSidebarToggle();
                    }}
                  >
                    {isCollapsed ? '' : 'Create New Tavern'}
                  </TavernButtonAnime>
                </Box>
              </TavernTooltip>
            )}
            <SignInOutButton isCollapse={isCollapsed} isSignedIn={isSignedIn} />
          </VStack>
          <Box width="100%" mt="32px">
            <Menu>
              <SidebarButton isCollapse={isCollapsed} onSidebarToggle={onSidebarToggle} type={SidebarButtonType.Live} />
              <SidebarButton
                isCollapse={isCollapsed}
                onSidebarToggle={onSidebarToggle}
                type={SidebarButtonType.Upcoming}
              />
              <SidebarButton isCollapse={isCollapsed} onSidebarToggle={onSidebarToggle} type={SidebarButtonType.Past} />
              <SidebarButton
                isCollapse={isCollapsed}
                onSidebarToggle={onSidebarToggle}
                type={SidebarButtonType.Leaderboard}
              />
              {isSignedIn ? (
                <>
                  <SidebarButton
                    isCollapse={isCollapsed}
                    onSidebarToggle={onSidebarToggle}
                    type={SidebarButtonType.Notifications}
                  />
                  <SidebarButton
                    isCollapse={isCollapsed}
                    onSidebarToggle={onSidebarToggle}
                    type={SidebarButtonType.Settings}
                  />
                </>
              ) : (
                <></>
              )}
              <Box paddingTop="20px" paddingLeft="4px" fontSize="12px">
                <SidebarButton
                  isCollapse={isCollapsed}
                  onSidebarToggle={onSidebarToggle}
                  type={SidebarButtonType.Warcast}
                />
                <SidebarButton
                  isCollapse={isCollapsed}
                  onSidebarToggle={onSidebarToggle}
                  type={SidebarButtonType.Twitter}
                />
                <SidebarButton
                  isCollapse={isCollapsed}
                  onSidebarToggle={onSidebarToggle}
                  type={SidebarButtonType.Discord}
                />
                <SidebarButton
                  isCollapse={isCollapsed}
                  onSidebarToggle={onSidebarToggle}
                  type={SidebarButtonType.Reddit}
                />
              </Box>
            </Menu>
          </Box>
        </Box>

        <Box width="100%" flex="0 0 auto">
          {/* Hiding while we focus on dark mode. */}
          {/* <Button
            variant={"tertiary"}
            leftIcon={<IconLightColor />}
            fontWeight={400}
            fontSize={16}
            onClick={() => {
              toggleColorMode();
            }}
          >
            Light theme
          </Button> */}
        </Box>
      </Flex>
    </Flex>
  );
};
