import React, { useEffect, useState } from 'react';
import { Box, HStack, Text, Grid } from '@chakra-ui/react';
import { query, collection, onSnapshot, where } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import Fuse from 'fuse.js';

import { IconSearch } from '../../theme/foundations/customIcons';
import { RequestBlock } from '../RequestBlock/RequestBlock';
import { RequestInviteStatus, SpeakRequest } from '../../types/tavern';
import { TavernInput } from '../uiKit/TavernInput/TavernInput';
import { db } from '../../connect';

// Displays list of requests from the host's perspective.
export const SpeakerRequestsList = ({ setRequestCount }: { setRequestCount: (count: number) => void }) => {
  const { tavernId } = useParams();

  const [requests, setRequests] = React.useState<SpeakRequest[]>([]);
  const [queryString, setQueryString] = useState('');

  useEffect(() => {
    const allRequestsQuery = query(
      collection(db, `taverns/${tavernId as string}/requests`),
      where('status', '==', RequestInviteStatus.Pending)
    );
    const unsubAllRequests = onSnapshot(allRequestsQuery, (querySnapshot) => {
      setRequests(querySnapshot.docs.map((doc) => doc.data()) as SpeakRequest[]);
      setRequestCount(querySnapshot.size);
    });
    return unsubAllRequests;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tavernId]);

  const fuse = new Fuse(requests || [], {
    includeScore: false,
    keys: ['name', 'user.id'],
  });

  const results = fuse.search(queryString);
  const filteredRequests = queryString ? results.map((r) => r.item) : requests;

  return (
    <Box w="100%" alignContent="flex-start">
      <Box mb="24px">
        <HStack justifyContent="flex-start">
          <TavernInput
            leftIcon={<IconSearch />}
            placeholder="Search by name"
            value={queryString}
            onChange={(e) => setQueryString(e.target.value)}
          />
        </HStack>
      </Box>
      <Grid pt="16px" autoFlow="row dense" gap="24px">
        {!filteredRequests.length && <Text opacity={0.5}>No Results</Text>}
        {filteredRequests.map((request, i) => (
          <RequestBlock key={i} request={request} />
        ))}
      </Grid>
      {/* <Button
          mt={"16px"}
          textDecoration={"none"}
          fontWeight={"400"}
          textStyle={"body16regular"}
          color={"brandPrimary"}
          variant={"link"}
        >
          <IconLoadMore mr={"12px"} boxSize={"16px"} />
          Show more
        </Button> */}
    </Box>
  );
};
