/* eslint-disable */
import React, { useState } from 'react';
import {
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import FocusLock from 'react-focus-lock';
import { CalendarPanel } from './components/calendarPanel';
import { CalendarConfigs, DefaultConfigs, OnDateSelected, SingleDatepickerProps } from './types';
import { IconCalendar } from '../../../theme/foundations/customIcons';

export const DatePicker: React.FC<SingleDatepickerProps> = ({
  configs,
  usePortal,
  defaultIsOpen = false,
  closeOnSelect = true,
  ...props
}) => {
  const { date: selectedDate, disabled, onDateChange, id, minDate, maxDate } = props;

  const [dateInView, setDateInView] = useState(selectedDate);
  const [offset, setOffset] = useState(0);
  const { onOpen, onClose, isOpen } = useDisclosure({ defaultIsOpen });
  const min = minDate ? new Date(minDate) : undefined;
  // set time to all zeros to make today date selectable
  min && min.setHours(0, 0, 0, 0);

  const calendarConfigs: CalendarConfigs = {
    ...DefaultConfigs,
    ...configs,
  };

  const onPopoverClose = () => {
    onClose();
    setDateInView(selectedDate);
    setOffset(0);
  };

  // dayzed utils
  const handleOnDateSelected: OnDateSelected = ({ selectable, date }) => {
    if (!selectable) return;
    if (date instanceof Date && !isNaN(date.getTime())) {
      onDateChange(date);
      if (closeOnSelect) onClose();
      return;
    }
  };

  const PopoverContentWrapper = usePortal ? Portal : React.Fragment;

  return (
    <Popover
      placement="bottom-start"
      variant="responsive"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onPopoverClose}
      isLazy
    >
      <PopoverTrigger>
        <InputGroup>
          <InputLeftElement h="100%" fontSize="2xl" pointerEvents="none" children={<IconCalendar color="#6C6C6C" />} />
          <Input
            backgroundColor="backgroundInputAdd"
            border="1px solid"
            borderColor="backgroundInputAdd"
            borderRadius="0"
            height="48px"
            fontFamily="body"
            fontWeight="regular"
            fontSize="lg"
            lineHeight="24px"
            color="typographyPrimary"
            _hover={{
              backgroundColor: 'backgroundInputHover',
              borderColor: 'backgroundInputHover',
            }}
            _focus={{
              backgroundColor: 'backgroundInputAdd',
              borderColor: 'brandPrimary',
              boxShadow: '0px 0px 0px 4px rgba(145, 90, 217, 0.25)!important',
            }}
            onKeyPress={(e) => {
              if (e.key === ' ' && !isOpen) {
                e.preventDefault();
                onOpen();
              }
            }}
            _placeholder={{
              color: '',
            }}
            id={id}
            autoComplete="off"
            isDisabled={disabled}
            name="date-input"
            value={selectedDate ? format(selectedDate, calendarConfigs.dateFormat) : ''}
            onChange={(e) => e.target.value}
          />
        </InputGroup>
      </PopoverTrigger>
      <PopoverContentWrapper>
        <PopoverContent width="100%" borderRadius="0" border="none" boxShadow="none" padding="0">
          <PopoverBody background="backgroundAdditional" borderRadius="0" border="none" padding="0">
            <FocusLock>
              <CalendarPanel
                dayzedHookProps={{
                  showOutsideDays: true,
                  onDateSelected: handleOnDateSelected,
                  selected: selectedDate,
                  date: dateInView,
                  minDate: min,
                  maxDate: maxDate,
                  offset: offset,
                  onOffsetChanged: setOffset,
                  firstDayOfWeek: calendarConfigs.firstDayOfWeek,
                }}
                configs={calendarConfigs}
              />
            </FocusLock>
          </PopoverBody>
        </PopoverContent>
      </PopoverContentWrapper>
    </Popover>
  );
};
