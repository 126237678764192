import { useMemo } from 'react';
import { Box, Flex, Grid, GridItem, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import * as unicodeEmoji from 'unicode-emoji';
import { GroupBase, OptionsOrGroups } from 'react-select/dist/declarations/src/types';
import {
  chakraComponents,
  ChakraStylesConfig,
  GroupProps,
  OptionProps,
  SingleValue,
  ValueContainerProps,
} from 'chakra-react-select';
import Fuse from 'fuse.js';
import { TavernSelectAsync } from '../uiKit/TavernSelectAsync/TavernSelectAsync';
import { IconSearch } from '../../theme/foundations/customIcons';
import { Emoji } from '../uiKit/Emoji/Emoji';
import { noop } from '../../utils/noop';
import { EmojiOption, SearchEmojiSelectProps } from './types';

// Include specific ones from other categories
const allEmojis = unicodeEmoji.getEmojis().filter((item) => item.category === 'face-emotion');

const emojis = allEmojis.map((item) => item.emoji);

// Make sure this is defined outside of the component which returns your select
// or you'll run into rendering issues
const components = {
  Option: ({ ...props }: OptionProps<EmojiOption>) => {
    const { label } = props.data;
    return (
      <chakraComponents.Option {...props}>
        <GridItem width="100%">
          <Emoji size="lg" value={label} aria-label={label} />
        </GridItem>
      </chakraComponents.Option>
    );
  },
  Group: ({ children, ...props }: GroupProps<EmojiOption>) => {
    return (
      <chakraComponents.Group {...props}>
        <Grid gridTemplateColumns="repeat(8, 1fr)">{children}</Grid>
      </chakraComponents.Group>
    );
  },
  ValueContainer: ({ children, ...props }: ValueContainerProps<EmojiOption>) => {
    return (
      <chakraComponents.ValueContainer {...props}>
        <InputGroup>
          <InputLeftAddon bg="none" border="none" padding="0">
            <IconSearch boxSize="24px" color="typographyTertiary" fill="typographyTertiary" />
          </InputLeftAddon>
          {children}
        </InputGroup>
      </chakraComponents.ValueContainer>
    );
  },
  SingleValue: () => {
    return <></>;
  },
};

const chakraStyles: ChakraStylesConfig<EmojiOption> = {
  option: (provided) => ({
    ...provided,
    _hover: null,
    _active: null,
    padding: 0,
  }),
  inputContainer: (provided) => ({
    ...provided,
    paddingLeft: '12px',
  }),
  input: (provided) => ({
    ...provided,
    marginLeft: '-3px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: '12px',
    paddingRight: '12px',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'typographySecondary',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    w: '100%',
    paddingLeft: '34px',
    alignSelf: 'center',
    left: '0',
  }),
  menu: (provided) => ({
    ...provided,
    position: 'relative',
    margin: 0,
    _after: {
      content: "''",
      position: 'absolute',
      bottom: 0,
      left: '8px',
      right: '8px',
      h: '48px',
      bg: 'gradientSecondary',
      pointerEvents: 'none',
      zIndex: 2,
      transform: 'scaleY(-1)',
    },
  }),
  menuList: (provided) => ({
    ...provided,
    scrollbarWidth: 'thin',
    scrollbarColor: 'typographyTertiary backgroundTertiary',
    height: '246px',
    boxShadow: 'none',
    padding: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
    marginTop: '4px',
    '::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      w: '2px',
    },
    '::-webkit-scrollbar-thumb': {
      bgColor: 'typographyTertiary',
    },
    '::-webkit-scrollbar-track': {
      bgColor: 'backgroundTertiary',
    },
  }),
};

export const SearchEmojiSelect = (props: SearchEmojiSelectProps) => {
  const { frequentlyUsed = [], onSelect = noop, value, ...rest } = props;

  const buildOptionFromEmoji = (emoji: string) => {
    return { label: emoji };
  };

  const buildGroupedOptions = (resp: string[], searchQuery = ''): GroupBase<EmojiOption>[] => {
    const frequentlyUsedOptions = frequentlyUsed.map((emoji) => buildOptionFromEmoji(emoji));
    const result = [];
    const searchResult = [];

    if (frequentlyUsedOptions.length > 0) {
      result.push({
        label: 'Frequently Used',
        options: frequentlyUsedOptions,
      });
    }

    result.push({
      label: 'Smiles and emotions',
      options: resp.map((emoji) => buildOptionFromEmoji(emoji)),
    });

    searchResult.push({
      options: resp.map((emoji) => buildOptionFromEmoji(emoji)),
    });

    if (searchQuery) {
      return searchResult;
    }

    return result;
  };

  const defaultOptions = useMemo(() => {
    return buildGroupedOptions(emojis);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frequentlyUsed]);

  /**
   * Search request to get emojis list
   * @param query
   * @param callback
   */
  const search = (
    query: string,
    callback: (values: OptionsOrGroups<EmojiOption, GroupBase<EmojiOption>>) => void
  ): void => {
    const fuse = new Fuse(allEmojis || [], {
      includeScore: false,
      threshold: 0.05,
      keys: ['description', 'subgroup', 'keywords', 'emoji'],
    });
    const data = fuse.search(query).map(({ item }) => item.emoji);
    callback(buildGroupedOptions(data, query));
  };

  const handleOnChange = (newValue: SingleValue<EmojiOption>) => {
    onSelect(newValue);
  };

  return (
    <Box width="100%">
      <Flex direction="column">
        <Box>
          <TavernSelectAsync<EmojiOption, false>
            noOptionsMessage={({ inputValue }) => {
              if (inputValue && inputValue.length) {
                return 'Nothing found';
              }
              return 'Type to search';
            }}
            menuIsOpen
            cacheOptions
            value={value}
            defaultOptions={defaultOptions}
            loadOptions={search}
            chakraStyles={chakraStyles}
            components={components}
            onChange={handleOnChange}
            placeholder="Search"
            {...rest}
          />
        </Box>
      </Flex>
    </Box>
  );
};
