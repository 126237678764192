import { arrayRemove, arrayUnion, doc, setDoc } from 'firebase/firestore';
import { forwardRef } from '@chakra-ui/react';
import { useContext, useMemo } from 'react';

import AuthContext from '../../context/auth';
import { IconBlock, IconJoined } from '../../theme/foundations/customIcons';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';
import { db } from '../../connect';

export const FollowUnfollowButton = forwardRef<{ userId: string }, 'button'>((props, ref) => {
  const { userId } = props;

  const { user } = useContext(AuthContext);

  const userDoc = useMemo(() => {
    return user?.id ? doc(db, 'users', user.id) : null;
  }, [user]);

  if (user === undefined || userId === user?.id || user?.isAnon) {
    return null;
  }

  const isFollowing = user?.following?.includes(userId);
  const isLoggedIn = user !== null && user !== undefined && user.isAnon === false;

  return (
    <TavernButton
      ref={ref}
      disabled={!isLoggedIn}
      variant={isFollowing ? 'tertiary' : 'primary'}
      leftIcon={isFollowing ? <IconBlock /> : <IconJoined />}
      onClick={(event) => {
        event.stopPropagation();
        if (!userDoc) {
          return;
        }

        if (isFollowing) {
          setDoc(userDoc, { following: arrayRemove(userId) }, { merge: true }).catch(console.error);
        } else {
          setDoc(userDoc, { following: arrayUnion(userId) }, { merge: true }).catch(console.error);
        }
      }}
    >
      {isFollowing ? 'Unfollow' : 'Follow'}
    </TavernButton>
  );
});
