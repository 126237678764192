import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { NotificationBadgeProps } from './types';

export const NotificationBadge = (props: NotificationBadgeProps) => {
  const { children } = props;

  return (
    <Box p="0px 4px" bg="brandSecondary" display="inline-flex">
      <Text textStyle="body16regular" color="brandPrimary">
        {children}
      </Text>
    </Box>
  );
};

export default NotificationBadge;
