import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { find, noop } from 'lodash';
import { LinksListProps } from './types';
import { ItemsList } from '../uiKit/ItemsList/ItemsList';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';
import { IconPlus } from '../../theme/foundations/customIcons';
import { TavernInput } from '../uiKit/TavernInput/TavernInput';
import { Link } from '../../types/tavern';
import { ItemsListItem } from '../uiKit/ItemsList/types';
import { LinkBlock } from '../LinkBlock/LinkBlock';

export const LinksList = (props: LinksListProps) => {
  const {
    links,
    isReadonly,
    linksPreview = [],
    onDelete = noop,
    onAdd = noop,
    onLinkChange = noop,
    isLinkWithTitle = false,
  } = props;

  const [isAddFormEnabled, setIsAddFormEnabled] = useState(false);
  const [newLink, setNewLink] = useState('');

  useEffect(() => {
    if (!isAddFormEnabled) {
      setNewLink('');
    }
  }, [isAddFormEnabled]);

  const handleItemDelete = (id: string) => {
    if (id === 'add-new') {
      setIsAddFormEnabled(false);
      return;
    }
    onDelete(id);
  };

  const handleLinkChange = (id: string, value: string) => {
    //  TODO: implement
    onLinkChange(id, value);
  };

  const getLinkPreview = (id: string) => {
    return find(linksPreview, (preview) => preview.linkId === id);
  };

  const generateNewLinkId = () => {
    return `new-link-${Date.now()}-${Math.random() * 1000}`;
  };

  const removeProtocol = (url: string) => {
    if (!url) {
      return url;
    }
    return url.replace(/^https?:\/\//, '');
  };

  const validateLink = (link: string) => {
    return !!link;
  };

  const handleBlur = () => {
    if (validateLink(newLink)) {
      onAdd({ id: generateNewLinkId(), link: newLink } as Link);
      setIsAddFormEnabled(false);
    }
  };

  const handleNewLinkChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewLink(removeProtocol(e.target.value));
  };

  const items = useMemo(() => {
    const linksItems = links.map((link, i) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, link: url } = link;
      const preview = getLinkPreview(id);
      return {
        id,
        label: isLinkWithTitle ? `Link #${i + 1}` : null,
        component: (
          <TavernInput
            size="lg"
            variant="link"
            defaultValue={removeProtocol(url)}
            onChange={(e) => handleLinkChange(id, e.target.value)}
            isInvalid={!validateLink(url)}
          />
        ),
        extraContent: preview ? (
          <Box mb="8px" mr={{ base: '28px', md: '48px' }}>
            <LinkBlock
              link={url}
              linkId={link.id}
              title={preview.title}
              description={preview.description}
              image={preview.src}
            />
          </Box>
        ) : null,
      } as ItemsListItem;
    });

    const extraItems = [];

    if (isAddFormEnabled) {
      extraItems.push({
        id: 'add-new',
        label: isLinkWithTitle ? `Link #${linksItems.length + 1}` : null,
        component: (
          <TavernInput
            size="lg"
            variant="link"
            value={newLink}
            onBlur={handleBlur}
            isInvalid={!validateLink(newLink)}
            onChange={handleNewLinkChange}
          />
        ),
      });
    }

    return [...linksItems, ...extraItems];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [links, isAddFormEnabled, linksPreview, newLink]);

  return (
    <Box>
      <Flex direction="column">
        <ItemsList items={items} hasRemoveButton={!isReadonly} onButtonClick={handleItemDelete} />
        {!isReadonly && (
          <Box>
            <TavernButton
              width="auto"
              color="brandPrimary"
              variant="link"
              isDisabled={isAddFormEnabled}
              leftIcon={<IconPlus color="inherit" />}
              onClick={() => setIsAddFormEnabled(true)}
            >
              Add link
            </TavernButton>
          </Box>
        )}
      </Flex>
    </Box>
  );
};
