import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Avatar,
  AvatarBadge,
  Box,
  Flex,
  keyframes,
  Link,
  MenuButton,
  MenuList,
  Text,
  useColorModeValue,
  useDisclosure,
  useOutsideClick,
  usePrefersReducedMotion,
  useStyleConfig,
} from '@chakra-ui/react';
import _, { noop } from 'lodash';
import accounting from 'accounting';

import AuthContext from '../../../context/auth';
// import CollectionsContext from '../../../context/collections';
import GlobalPlayerContext from '../../../context/global-player-context';
import TavernDetailContext from '../../../context/tavern-detail-context';
import {
  IconAsterisk,
  IconHandUp,
  IconMeatballs,
  IconMicrophoneOff,
  IconSpeaker,
  IconTwitter,
  IconTavernLogo,
} from '../../../theme/foundations/customIcons';
import { NFTinfoModal } from '../../NFTInfoModal/NFTinfoModal';
import { NftData } from '../../NFTInfoModal/types';
import { Reaction, SessionType, TavernStatus } from '../../../types/tavern';
import { TavernDropdown } from '../TavernDropdown/TavernDropdown';
import { TavernUserProps } from './types';
import { UserModal } from '../../UserModal/UserModal';
// import { UsersNftCollection } from '../NFTCollection/types';
// import { getNFTFromId } from '../../../utils/tavern-util';
import { getUserImage } from '../../../utils/user-util';

const pulse = keyframes`
  0% {
    transform: scale(0.85);
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

export const TavernUser = (props: TavernUserProps) => {
  const {
    size = 'lg',
    user,
    sessionType,
    avatarComponent,
    isMuted,
    isSpeaking,
    isRoleVisible,
    inviterName,
    invitedRole,
    dropdownItems,
    requestedToSpeak,
    isInvitedToSpeak,
    isInvitedToCoHost,
    rank,
    onActionButtonClick = noop,
    hideScore,
    ...rest
  } = props;
  const { id, name, twitter, sessionType: userType } = user;

  const loggedInUser = useContext(AuthContext).user;
  // const { collections } = useContext(CollectionsContext);
  const { connectedTavern, recordingProgressTimestamp, recordedPlayState } = useContext(GlobalPlayerContext);
  const { reactions } = useContext(TavernDetailContext);

  const recordingStartTime = connectedTavern ? connectedTavern.recordingStartTime || connectedTavern.startTime : 0;
  const recordingEndTime = connectedTavern
    ? connectedTavern.recordingEndTime || connectedTavern.endTime || recordingStartTime
    : 0;
  const tavernLength = recordingEndTime - recordingStartTime;
  const offset = tavernLength - (recordedPlayState ? recordedPlayState.duration * 1000 : tavernLength);

  const prefersReducedMotion = usePrefersReducedMotion();
  const dropdownUseDisclosure = useDisclosure();
  const userModalUseDisclosure = useDisclosure();

  const menuRef = useRef<HTMLDivElement>(null);

  const [isMenuProcessing, setIsMenuProcessing] = useState(false);
  const [nft, setNft] = useState<NftData | null>(null);

  const [displayedReaction, setDisplayedReaction] = useState<Reaction | undefined>(undefined);

  const nameColor = useMemo(() => {
    if (loggedInUser?.following?.includes(user.id)) {
      return 'accent.green';
    }
    return '';
  }, [loggedInUser, user.id]);

  useOutsideClick({
    // @ts-ignore
    ref: menuRef,
    handler: () => {
      if (isMenuProcessing) {
        return;
      }
      dropdownUseDisclosure.onClose();
    },
  });

  const styles = useStyleConfig('TavernUserStyles', { size });

  const animation = (delay: string): undefined | string => {
    return prefersReducedMotion ? undefined : `${pulse} infinite 1.5s ${delay} linear`;
  };
  const isLgSize: boolean = size === 'lg';
  const avatarBg = useColorModeValue('white', 'black');
  const userRoleName = userType === SessionType.CoHost ? 'Co-host' : userType[0].toUpperCase() + userType.slice(1);

  const displayLiveData = connectedTavern !== null && connectedTavern?.status === TavernStatus.Live;
  const currentTimestamp = displayLiveData ? Date.now() : recordingProgressTimestamp! + offset;

  const mostRecentReaction = useMemo(() => {
    if (currentTimestamp === null) {
      return undefined;
    }

    return _.first(
      reactions?.filter(
        (r) =>
          r.session.id === user.session?.id &&
          currentTimestamp - r.timestamp <= 3000 &&
          currentTimestamp - r.timestamp >= 0
      )
    );
  }, [currentTimestamp, reactions, user.session?.id]);

  useEffect(() => {
    if (mostRecentReaction === undefined) {
      setDisplayedReaction(undefined);
      return;
    }

    setDisplayedReaction(mostRecentReaction);

    if (!displayLiveData && currentTimestamp !== null) {
      return;
    }

    const interval = setTimeout(() => {
      setDisplayedReaction(undefined);
    }, 3000 - (Date.now() - mostRecentReaction.timestamp));
    return () => {
      setDisplayedReaction(undefined);
      clearInterval(interval);
    };
  }, [displayLiveData, mostRecentReaction, currentTimestamp]);

  // const hasNFT = user.session?.user?.id && user.session?.nftId && user.session?.nftId !== 'default';

  // const handleNFT = async () => {
  //   if (user.session?.user.id && user.session?.nftId && hasNFT) {
  //     const nftData = await getNFTFromId(user.session?.user.id, user.session?.nftId);
  //     const collection: UsersNftCollection = _.find(collections, (c) => c.id === nftData?.collection) || {
  //       id: '',
  //       name: '',
  //     };

  //     if (nftData) {
  //       const userNft: NftData | null = {
  //         ...nftData,
  //         collection,
  //       };

  //       setNft(userNft);
  //     }
  //   }
  // };

  return (
    <Flex as="div" sx={styles} role="group" {...rest} ref={menuRef}>
      {(sessionType === SessionType.Host || sessionType === SessionType.CoHost) &&
      userType !== SessionType.Host &&
      dropdownItems ? (
        <TavernDropdown placement="end-end" isOpen={dropdownUseDisclosure.isOpen}>
          <MenuButton
            visibility="hidden"
            opacity="0"
            transition="0.3s"
            position="absolute"
            top="4px"
            right="4px"
            bg="0"
            boxSize="32px"
            borderRadius="50%"
            _groupHover={{
              visibility: 'visible',
              opacity: '1',
            }}
            _expanded={{ bg: 'backgroundInput' }}
            _hover={{ bg: 'backgroundInput' }}
            aria-label="actions"
            onClick={() => {
              onActionButtonClick();
              dropdownUseDisclosure.onOpen();
            }}
          >
            <IconMeatballs color="typographySecondary" boxSize="24px" />
          </MenuButton>
          <MenuList>
            {dropdownItems.map((item, i) =>
              React.cloneElement(item, {
                onClose: dropdownUseDisclosure.onClose,
                onProcessing: setIsMenuProcessing,
                key: i,
              })
            )}
          </MenuList>
        </TavernDropdown>
      ) : (
        <></>
      )}
      <Box position="relative" mb="16px">
        {isSpeaking && (
          <>
            <Box
              animation={animation('0.2s')}
              pointerEvents="none"
              boxSize={isLgSize ? { xl: '132px', base: '116px' } : { xl: '108px', base: '92px' }}
              borderRadius="50%"
              border="1px solid"
              borderColor="rgba(135, 58, 234, 0.5)"
              position="absolute"
              left={
                isLgSize
                  ? { xl: 'calc(50% - 66px)', base: 'calc(50% - 58px)' }
                  : { xl: 'calc(50% - 54px)', base: 'calc(50% - 46px)' }
              }
              top={
                isLgSize
                  ? { xl: 'calc(50% - 66px)', base: 'calc(50% - 58px)' }
                  : { xl: 'calc(50% - 54px)', base: 'calc(50% - 46px)' }
              }
              opacity={0}
            />
            <Box
              animation={animation('0.1s')}
              pointerEvents="none"
              boxSize={isLgSize ? { xl: '120px', base: '104px' } : { xl: '96px', base: '80px' }}
              borderRadius="50%"
              border="1px solid"
              borderColor="rgba(135, 58, 234, 0.7)"
              position="absolute"
              left={
                isLgSize
                  ? { xl: 'calc(50% - 60px)', base: 'calc(50% - 52px)' }
                  : { xl: 'calc(50% - 48px)', base: 'calc(50% - 40px)' }
              }
              top={
                isLgSize
                  ? { xl: 'calc(50% - 60px)', base: 'calc(50% - 52px)' }
                  : { xl: 'calc(50% - 48px)', base: 'calc(50% - 40px)' }
              }
              opacity={0}
            />
            <Box
              pointerEvents="none"
              boxSize={isLgSize ? { xl: '108px', base: '92px' } : { xl: '84px', base: '68px' }}
              borderRadius="50%"
              border="1px solid"
              borderColor="brandPrimary"
              position="absolute"
              left={
                isLgSize
                  ? { xl: 'calc(50% - 54px)', base: 'calc(50% - 46px)' }
                  : { xl: 'calc(50% - 42px)', base: 'calc(50% - 34px)' }
              }
              top={
                isLgSize
                  ? { xl: 'calc(50% - 54px)', base: 'calc(50% - 46px)' }
                  : { xl: 'calc(50% - 42px)', base: 'calc(50% - 34px)' }
              }
            />
          </>
        )}

        {avatarComponent || (
          <Avatar
            ml="auto"
            mr="auto"
            borderRadius="50%"
            boxSize={isLgSize ? { xl: '80px', base: '60px' } : { xl: '60px', base: '40px' }}
            src={getUserImage(user)}
            // Set icon and bg to keep Avatar from rendering
            // bright green fallback.
            icon={<></>}
            bg={avatarBg}
            border={isLgSize ? '2px solid white' : '1px solid white'}
            _hover={{
              borderColor: 'brandPrimary',
              cursor: 'pointer',
            }}
            onClick={() => {
              userModalUseDisclosure.onOpen();
            }}
          >
            {isMuted && (
              <AvatarBadge borderWidth="1px" borderColor="backgroundPrimary" boxSize="32px" bg="backgroundAdditional">
                <IconMicrophoneOff color="typographySecondary" boxSize="24px" />
              </AvatarBadge>
            )}
            {requestedToSpeak && (
              <AvatarBadge borderWidth="1px" borderColor="backgroundPrimary" boxSize="32px" bg="backgroundAdditional">
                <IconHandUp color="#873AEA" boxSize="24px" />
              </AvatarBadge>
            )}
            {isInvitedToSpeak && (
              <AvatarBadge borderWidth="1px" borderColor="backgroundPrimary" boxSize="32px" bg="backgroundAdditional">
                <IconSpeaker color="#873AEA" boxSize="24px" />
              </AvatarBadge>
            )}
            {isInvitedToCoHost && (
              <AvatarBadge borderWidth="1px" borderColor="backgroundPrimary" boxSize="32px" bg="backgroundAdditional">
                <IconAsterisk color="#873AEA" boxSize="24px" />
              </AvatarBadge>
            )}
            {rank && (
              <AvatarBadge borderWidth="1px" borderColor="backgroundPrimary" boxSize="32px" bg="backgroundAdditional">
                <Text fontSize="sm">{rank}</Text>
              </AvatarBadge>
            )}
          </Avatar>
        )}

        {displayedReaction && (
          <Flex
            justifyContent="center"
            alignItems="center"
            bg="backgroundInputHover"
            borderRadius="50%"
            boxSize={isLgSize ? '40px' : '32px'}
            fontSize={isLgSize ? '22px' : '18px'}
            position="absolute"
            top={isLgSize ? '-33px' : '-30px'}
            left={isLgSize ? '-33px' : '-30px'}
          >
            <Box
              boxSize={isLgSize ? '8px' : '6px'}
              position="absolute"
              bottom={isLgSize ? '-4px' : '-5px'}
              right={isLgSize ? '-4px' : '-5px'}
              borderRadius="50%"
              bg="backgroundInputHover"
            />
            {displayedReaction.type}
          </Flex>
        )}
      </Box>
      <Flex alignItems="center">
        <Text
          maxWidth={isLgSize ? '106px' : '93px'}
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          textStyle={isLgSize ? 'body16regular' : { xl: 'body14regular', base: 'body12regular' }}
          color={nameColor || ''}
          _hover={{
            cursor: 'pointer',
            color: 'brandPrimary',
          }}
          onClick={() => {
            userModalUseDisclosure.onOpen();
          }}
        >
          {name || user.session?.name || user.session?.user?.id || id}
        </Text>
        {twitter && (
          <Link
            display="flex"
            boxSize={isLgSize ? '16px' : { lg: '16px', base: '12px' }}
            ml={isLgSize ? '8px' : { lg: '8px', base: '4px' }}
            href={`https://twitter.com/${twitter}`}
            target="_blank"
          >
            <IconTwitter boxSize={isLgSize ? '16px' : { lg: '16px', base: '12px' }} color="typographySecondary" />
          </Link>
        )}
      </Flex>
      {!displayLiveData && !hideScore && (
        <Flex color="typographySecondary" textStyle="body14regular">
          {accounting.formatNumber(user.score || 0, 0)} <IconTavernLogo boxSize="8px" />
        </Flex>
      )}
      {isRoleVisible && !invitedRole && (
        <Flex color="typographySecondary" textStyle="body14regular">
          {userRoleName}
        </Flex>
      )}
      {isRoleVisible && invitedRole && (
        <Flex color="typographySecondary" textStyle="body14regular">
          <Text mr="6px">as</Text>
          {invitedRole}
        </Flex>
      )}
      {inviterName && (
        <Flex textStyle="body12regular" mt="8px">
          <Text color="typographySecondary" mr="8px">
            by
          </Text>
          <Text maxW="106px" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
            {inviterName}
          </Text>
        </Flex>
      )}

      <UserModal isOpen={userModalUseDisclosure.isOpen} onClose={userModalUseDisclosure.onClose} user={user} />

      {nft && (
        <NFTinfoModal
          nftData={nft}
          isVisible={!!setNft}
          onClose={() => {
            setNft(null);
          }}
        />
      )}
    </Flex>
  );
};
