import { FC, useState } from 'react';
import { HStack, Text, Box } from '@chakra-ui/react';

import { AddLinksModalProps } from './types';
import { LinksList } from '../LinksList/LinksList';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';
import { TavernModal } from '../TavernModal/TavernModal';

export const AddLinksModal: FC<AddLinksModalProps> = ({
  isOpen,
  onClose,
  links,
  linksPreview,
  onAdd,
  onDelete,
  onLinkChange,
  submitLinks,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  return (
    <TavernModal
      maxWidth="568px"
      isCloseButtonVisible
      onClose={onClose}
      isOpen={isOpen}
      header={
        <Text p="5px 0" textStyle={{ base: 'h3modal', xl: 'h3' }}>
          Add links
        </Text>
      }
      footer={
        <HStack spacing="24px" w="100%">
          <TavernButton p={0} variant="tertiary" w="100%" onClick={onClose}>
            Cancel
          </TavernButton>
          <TavernButton
            isLoading={isProcessing}
            variant="primary"
            w="100%"
            onClick={() => {
              setIsProcessing(true);
              submitLinks?.(links)
                .then(() => {
                  links.forEach((link) => onDelete?.(link.id));
                  setIsProcessing(false);
                })
                .catch((error) => console.error(error));
            }}
          >
            Add links {links.length ? `(${links.length})` : null}
          </TavernButton>
        </HStack>
      }
    >
      <Box p="32px 0">
        <LinksList
          isLinkWithTitle
          links={links}
          linksPreview={linksPreview}
          onAdd={onAdd}
          onDelete={onDelete}
          onLinkChange={onLinkChange}
        />
      </Box>
    </TavernModal>
  );
};
