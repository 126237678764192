import { createContext } from 'react';

import { AudioClip, Reaction, Session, Topic, Score, Gif } from '../types/tavern';

export default createContext({
  hosts: [] as Session[],
  coHosts: [] as Session[],
  speakers: [] as Session[],
  joiners: [] as Session[],
  listeners: [] as Session[],
  reactions: [] as Reaction[],
  gifs: [] as Gif[],
  scores: [] as Score[],
  audioClips: [] as AudioClip[],
  listenerCount: 0 as number,
  playCount: 0 as number,
  topics: [] as Topic[],
  maxSpeakers: 10 as number,
});
