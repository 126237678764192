import { useDisclosure } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { IconPlus } from '../../theme/foundations/customIcons';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';
import { Link } from '../../types/tavern';
import { AddLinksModal } from '../AddLinksModal/AddLinksModal';
import { AddLinksModalProps } from '../AddLinksModal/types';
import { submitLink } from '../../services/tavern-functions';

export const LiveAddLinksModal = (props: { prefilledLinks?: Link[] }) => {
  const { tavernId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [localLinks, setLocalLinks] = useState<Link[]>(props.prefilledLinks || []);

  const handleAddLink = (link: Link) => {
    setLocalLinks((prevState) => {
      return [...prevState, link];
    });
  };

  const handleDeleteLink = (id: string) => {
    setLocalLinks((prevState) => {
      return prevState.filter((l) => l.id !== id);
    });
  };

  const handleLinkChange = (id: string, newValue: string) => {
    setLocalLinks((prevState) => {
      const newState = prevState.map((link) => {
        if (link.id === id) {
          return { ...link, link: newValue };
        }
        return link;
      });

      return [...newState];
    });
  };

  const modalProps: AddLinksModalProps = {
    isOpen,
    onClose,
    links: localLinks,
    onAdd: handleAddLink,
    onDelete: handleDeleteLink,
    onLinkChange: handleLinkChange,
    submitLinks: async (links: Link[]) => {
      await Promise.all(links.map(async (link) => submitLink(tavernId as string, link.link)));
      onClose();
    },
  };
  return (
    <>
      <TavernButton
        color="brandPrimary"
        variant="link"
        leftIcon={<IconPlus color="inherit" />}
        onClick={() => {
          onOpen();
        }}
      >
        Add link
      </TavernButton>
      <AddLinksModal {...modalProps} />
    </>
  );
};
