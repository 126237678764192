import { HStack, Text } from '@chakra-ui/react';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthContext from '../../context/auth';
import { CardHeaderIndicator } from './CardHeaderIndicator';
import { IconJoined } from '../../theme/foundations/customIcons';
import { SetReminderTavernButton } from '../buttons/set-reminder-tavern-button';
import { StartJoinTavernButton } from '../buttons/start-join-tavern-button';
import { TavernCard } from './TavernCard';
import { TavernCardUpcomingProps } from './types';
import { db } from '../../connect';
import { formatDurationInFuture } from '../../utils/date';

export const TavernCardUpcoming = (props: TavernCardUpcomingProps) => {
  const navigate = useNavigate();
  const { buttons, tavern } = props;
  const { user } = useContext(AuthContext);
  const [goingAmount, setGoingAmount] = useState(0);
  const isHost = user && user.id === tavern.host.id;

  // Since this query is loaded on first launch, we want to re-run
  // it once a first-time user has logged in as an anon.
  useEffect(() => {
    if (user === null || !tavern) {
      return;
    }
    const goingQuery = query(collection(db, 'taverns', tavern.id, 'going'));
    const unsub = onSnapshot(goingQuery, (d) => {
      setGoingAmount(d.size);
    });
    return unsub;
  }, [tavern, user]);

  const defaultButtons = useMemo(() => {
    if (!tavern || !user || (user && user.isAnon)) {
      return null;
    }

    return isHost ? <StartJoinTavernButton tavern={tavern} /> : <SetReminderTavernButton tavern={tavern} />;
  }, [tavern]);

  const renderHeaderStats = useCallback(() => {
    return [
      <CardHeaderIndicator
        key="card-header-indicator-going"
        icon={IconJoined}
        label={goingAmount.toString()}
        tooltip={`${goingAmount.toString()} are going to join this tavern`}
      />,
    ];
  }, [goingAmount]);

  return (
    <TavernCard
      {...props}
      isClickable
      renderHeaderStats={renderHeaderStats}
      buttons={<HStack>{buttons || defaultButtons}</HStack>}
      onClick={() => {
        navigate(`/taverns/${props.tavern.id}`);
      }}
      footerLeft={
        <Text color="typographySecondary" fontSize="xs" ml="16px">
          {`Starting ${formatDurationInFuture(props.tavern.startTime)}`}
        </Text>
      }
    />
  );
};
