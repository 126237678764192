/* eslint-disable */
import React, { useMemo } from 'react';
import {
  ActionMeta,
  chakraComponents,
  ChakraStylesConfig,
  ControlProps,
  InputProps,
  OptionProps,
  Select,
  SingleValue,
} from 'chakra-react-select';
import timezones from 'timezones-list';
import { Text } from '@chakra-ui/react';
import { TimezonePickerOption, TimezonePickerSelectProps } from './types';
import { formatTimezoneOffset, timezoneOffset } from '../../../utils/date';

const chakraStyles: ChakraStylesConfig<TimezonePickerOption, false> = {
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: 'typographyPrimary',
    bgColor: state.isSelected ? 'backgroundInput' : 'none',
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    maxW: '120px',
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    scrollbarWidth: 'thin',
    scrollbarColor: 'typographyTertiary backgroundTertiary',
    '::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      w: '2px',
    },
    '::-webkit-scrollbar-thumb': {
      bgColor: 'typographyTertiary',
    },
    '::-webkit-scrollbar-track': {
      bgColor: 'backgroundTertiary',
    },
  }),
  container: (baseStyles) => ({
    ...baseStyles,
    display: 'flex',
    width: '100%',
    height: '48px',
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    display: 'flex',
    height: '100%',
    flex: '1 1 0',
  }),
};

const components = {
  IndicatorSeparator: () => null,
  DropdownIndicator: () => null,
  Input: (props: InputProps<TimezonePickerOption, false>) => <chakraComponents.Input maxLength={5} {...props} />,
  Control: ({ children, ...rest }: ControlProps<TimezonePickerOption, false>) => (
    <chakraComponents.Control {...rest}>
      <Text ml="10px" mr="-12px" alignSelf="center" color="typographyTertiary" textStyle="body16regular">
        TZ
      </Text>
      {children}
    </chakraComponents.Control>
  ),
  Option: ({ children, ...rest }: OptionProps<TimezonePickerOption, false>) => (
    <chakraComponents.Option {...rest}>
      <Text mr="4px" alignSelf="center" color="typographySecondary" textStyle="body14regular">
        TZ
      </Text>
      {children}
    </chakraComponents.Option>
  ),
};

const zones = Array.from(new Set(timezones.map((item: any) => timezoneOffset(item.utc))));

export const TimezonePickerSelect = (props: TimezonePickerSelectProps) => {
  const { onSelect, ...rest } = props;

  const options = useMemo(() => {
    return zones.map((timezone: any) => {
      return {
        label: formatTimezoneOffset(timezone),
        value: timezone,
      } as TimezonePickerOption;
    });
  }, [zones]);

  const handleChange = (option: SingleValue<TimezonePickerOption>, actionMeta: ActionMeta<TimezonePickerOption>) => {
    switch (actionMeta.action) {
      case 'select-option':
        if (option) {
          onSelect(option.value);
        }
        break;
      default:
        break;
    }
  };

  return (
    <Select
      placeholder=""
      chakraStyles={chakraStyles}
      onChange={handleChange}
      options={options}
      components={components}
      {...rest}
    />
  );
};
