/* eslint-disable */
import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  field: {
    width: '100%',
    minWidth: 0,
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    fontFamily: 'body',
    fontWeight: 'regular',
    fontSize: 'lg',
    lineHeight: '24px',
    color: 'typographyPrimary',
    bg: 'backgroundInputAdd',
    border: '1px solid',
    borderColor: 'backgroundInputAdd',
    borderRadius: 0,
    _placeholder: {
      color: 'typographySecondary',
    },
    _hover: {
      ':not([aria-invalid=true])': {
        bg: 'backgroundInputHover',
        borderColor: 'transparent',
      },
    },
    _readOnly: {
      boxShadow: 'none !important',
      userSelect: 'all',
    },
    _focusVisible: {
      ':not([aria-invalid=true])': {
        bg: 'backgroundInputAdd',
        borderColor: 'brandPrimary',
        boxShadow: '0px 0px 0px 4px rgba(145, 90, 217, 0.25)!important',
      },
    },
    ':where([aria-invalid=true])': {
      bg: 'redAlpha.80',
      borderColor: 'accentDestructive!important',
      boxShadow: 'none!important',
    },
  },
});

const variantLink = definePartsStyle(() => {
  return {
    field: {
      paddingLeft: '85px',
      borderRadius: 0,
    },
  };
});

const variantOutline = definePartsStyle(() => {
  return {
    field: {
      bg: 'backgroundInputAdd',
      border: '1px solid',
      borderColor: 'backgroundInputAdd',
      borderRadius: 0,
      color: 'typographyPrimary',
      _hover: {
        ':not([aria-invalid=true])': {
          bg: 'backgroundInputHover',
        },
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
        height: '100px',
      },
      _focusVisible: {
        ':not([aria-invalid=true])': {
          bg: 'backgroundInputAdd',
          borderColor: 'brandPrimary',
          boxShadow: '0px 0px 0px 4px rgba(145, 90, 217, 0.25)!important',
        },
      },
      ':where([aria-invalid=true])': {
        bg: 'redAlpha.80',
        borderColor: 'accentDestructive!important',
      },
    },
  };
});

const variants = {
  link: variantLink,
  outline: variantOutline,
};

export const TavernInputStyle = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'outline',
  },
});
