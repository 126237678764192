import { NavigateFunction } from 'react-router-dom';
import _ from 'lodash';
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';

import {
  AudioClip,
  Link,
  Reaction,
  Session,
  SessionType,
  Tavern,
  TavernStatus,
  Topic,
  Score,
  Gif,
} from '../types/tavern';
import { NftCollection, ProdUser, UserNft } from '../types/user';
import { db } from '../connect';
import { noop } from './noop';
import { notEmpty } from './not-empty';
import { startTavern, saveTavern } from '../services/tavern-functions';

export const createNewTavern = async (
  params: {
    title: string;
    description: string;
    topics?: Topic[];
    links?: Link[];
    startTime?: number;
    joinAllowlist?: string[];
    joinBlocklist?: string[];
    speakerAllowlist?: string[];
    speakerBlocklist?: string[];
    status: TavernStatus;
    featuredImageUrl?: string;
    org?: string;
  },
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  navigate: NavigateFunction,
  toast: any
) => {
  setIsLoading(true);
  try {
    const newTavern = await startTavern(params);
    navigate(`/taverns/${newTavern.id}`);
    toast({
      title: `Created Tavern ${params.title}.`,
      description: "You've created your Tavern.",
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  } catch (error) {
    toast({
      title: `Start Failed`,
      description: 'Creating Tavern failed, try again.',
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
  }
  setIsLoading(false);
};

export const editTavern = async (
  params: {
    tavernId: string;
    title: string;
    description: string;
    topics?: Topic[];
    startTime?: number;
    joinAllowlist?: string[];
    joinBlocklist?: string[];
    speakerAllowlist?: string[];
    speakerBlocklist?: string[];
    featuredImageUrl?: string;
    org?: string;
  },
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  navigate: NavigateFunction,
  toast: any
) => {
  setIsLoading(true);
  try {
    await saveTavern(params);
    navigate(`/taverns/${params.tavernId}`);
    toast({
      title: `Saved Tavern ${params.title}.`,
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  } catch (error) {
    toast({
      title: `Saved Failed`,
      description: 'Tavern failed to save, try again.',
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
  }
  setIsLoading(false);
};

export const getTavernById = async (tavernId: string): Promise<Tavern> => {
  return (await getDoc(doc(collection(db, 'taverns'), tavernId))).data() as Tavern;
};

export const getNFTFromId = async (userId: string, nftId: string): Promise<UserNft | null> => {
  const data: any = (await getDoc(doc(collection(db, `users/${userId}/nfts`), nftId))).data();

  if (!data) {
    return null;
  }

  return {
    id: nftId,
    thumbnail: _.get(data, 'alchemy.media[0].thumbnail', ''),
    image: _.get(data, 'alchemy.media[0].gateway', ''),
    animation: _.get(data, 'alchemy.metadata.animation_url', ''),
    name: _.get(data, 'alchemy.metadata.name', ''),
    tokenId: data.tokenId,
    owner: userId,
    creator: _.get(data, 'alchemy.contractMetadata.contractDeployer', ''),
    properties: _.get(data, 'alchemy.metadata.attributes', []),
    collection: data.collectionId,
  };
};

export const tavernParticipantsSubscription = (
  tavern: Tavern,
  setHosts: (state: Session[]) => void,
  setCoHosts: (state: Session[]) => void,
  setSpeakers: (state: Session[]) => void,
  setJoiners: (state: Session[]) => void,
  setListeners: (state: Session[]) => void,
  setListenerCount: (state: number) => void,
  setPlayCount: (state: number) => void,
  setReactions: (state: Reaction[]) => void,
  setGifs: (state: Gif[]) => void,
  setScores: (state: Score[]) => void,
  setAudioClips?: (state: AudioClip[]) => void
  // setAnonResponses?: (state: AnonResponse[]) => void
) => {
  const sessionsQuery = query(
    collection(db, `taverns/${tavern.id}/sessions`),
    where('type', 'in', [
      SessionType.Host,
      SessionType.CoHost,
      SessionType.Speaker,
      SessionType.Joiner,
      SessionType.Listener,
    ])
  );

  const isLive = !tavern.endTime && tavern.status === TavernStatus.Live;

  const unsubSessions = onSnapshot(sessionsQuery, (querySnapshot) => {
    const sessions = querySnapshot.docs.map((d) => d.data()).filter((d) => (isLive ? !d.endTime : true)) as Session[];
    const hosts: Session[] = sessions.filter((s) => s.type === SessionType.Host);
    const coHosts: Session[] = sessions.filter((s) => s.type === SessionType.CoHost);
    const speakers: Session[] = sessions.filter((s) => s.type === SessionType.Speaker);
    const joiners: Session[] = sessions.filter((s) => s.type === SessionType.Joiner);
    const listeners: Session[] = sessions.filter((s) => s.type === SessionType.Listener);

    setHosts(hosts);
    setCoHosts(coHosts);
    setSpeakers(speakers);
    setJoiners(joiners);
    setListeners(listeners);
  });

  const listeningShardsQuery = query(collection(db, `taverns/${tavern.id}/listenerCounterShards`));
  const unsubListenerCount = onSnapshot(listeningShardsQuery, (querySnapshot) => {
    setListenerCount(
      querySnapshot.docs.reduce((acc: number, d) => {
        return acc + (d.data().count as number);
      }, 0)
    );
  });

  const playingShardsQuery = query(collection(db, `taverns/${tavern.id}/playCounterShards`));
  const unsubPlayCount = onSnapshot(playingShardsQuery, (querySnapshot) => {
    setPlayCount(
      querySnapshot.docs.reduce((acc: number, d) => {
        return acc + (d.data().count as number);
      }, 0)
    );
  });

  const reactionsQuery = query(collection(db, `taverns/${tavern.id}/reactions`), orderBy('reactionTime', 'desc'));
  const unsubReactions = onSnapshot(reactionsQuery, (querySnapshot) => {
    setReactions(querySnapshot.docs.map((d) => d.data()) as Reaction[]);
  });

  const gifsQuery = query(collection(db, `taverns/${tavern.id}/gifs`), orderBy('timestamp', 'desc'));
  const unsubGifs = onSnapshot(gifsQuery, (querySnapshot) => {
    setGifs(querySnapshot.docs.map((d) => d.data()) as Gif[]);
  });

  const audioClipsQuery = query(collection(db, `taverns/${tavern.id}/audioClips`), orderBy(`startTime`, 'desc'));
  const unsubAudioClips = onSnapshot(audioClipsQuery, (querySnapshot) => {
    setAudioClips?.(querySnapshot.docs.map((d) => d.data()) as AudioClip[]);
  });

  const scoreQuery = query(collection(db, `taverns/${tavern.id}/scores`));
  const unsubScore = onSnapshot(scoreQuery, (querySnapshot) => {
    setScores(querySnapshot.docs.map((d) => d.data()) as Score[]);
  });

  // const anonResponsesQuery = query(collection(db, `taverns/${tavern.id}/anon`), orderBy(`startTime`, 'desc'));
  // const unsubAnonResponses = onSnapshot(audioClipsQuery, (querySnapshot) => {
  //   setAnonResponses?.(querySnapshot.docs.map((d) => {
  //     const r = d.data();
  //     return {
  //       id: d.id,
  //       startTime: r.startTime,
  //       endTime: r.endTime,
  //       response: _.get(r.response[])
  //     }
  //   }) as AnonResponse[]);
  // });

  return () => {
    unsubSessions();
    unsubListenerCount();
    unsubPlayCount();
    unsubReactions();
    unsubAudioClips();
    unsubScore();
    unsubGifs();
  };
};

export const collectionsSubscription = (setCollections: (state: NftCollection[]) => void) => {
  const collectionsQuery = query(collection(db, `collections`));

  const unsub = onSnapshot(collectionsQuery, (querySnapshot) => {
    const collections = querySnapshot.docs.map((d) => {
      const data = d.data();
      return {
        id: d.id,
        image: _.get(data, 'alchemy.contractMetadata.openSea.imageUrl', ''),
        name: _.get(data, 'alchemy.contractMetadata.name', ''),
        description: _.get(data, 'alchemy.contractMetadata.openSea.description', ''),
      };
    }) as NftCollection[];

    setCollections(collections);
  });
  return unsub;
};

export const userNFTSubscription = (id: string, setUserNFTs: (state: UserNft[]) => void) => {
  const nftsQuery = query(collection(db, `users/${id}/nfts`));

  const unsub = onSnapshot(nftsQuery, (querySnapshot) => {
    const nfts = querySnapshot.docs.map((d) => {
      const data = d.data();
      return {
        id: d.id,
        thumbnail: _.get(data, 'alchemy.media[0].thumbnail', ''),
        image: _.get(data, 'alchemy.media[0].gateway', ''),
        animation: _.get(data, 'alchemy.metadata.animation_url', ''),
        name: _.get(data, 'alchemy.metadata.name', ''),
        tokenId: data.tokenId,
        owner: id,
        creator: _.get(data, 'alchemy.contractMetadata.contractDeployer', ''),
        properties: _.get(data, 'alchemy.metadata.attributes', []),
        collection: data.collectionId,
      };
    }) as UserNft[];

    setUserNFTs(nfts);
  });
  return unsub;
};

export const userSubscription = (
  id: string,
  setUser: (state: ProdUser | null) => void,
  setUserNFTs: (state: UserNft[]) => void,
  setUserTavernIds: (state: string[]) => void,
  redirect: () => void
) => {
  const nftsQuery = query(collection(db, `users/${id}/nfts`));
  const userRef = doc(db, 'users', id);
  const userSessionsQuery = query(collectionGroup(db, 'sessions'), where('user', '==', userRef));

  const unsubUser = onSnapshot(doc(db, 'users', id), (d) => {
    if (!d.data()) {
      redirect();
    }
    setUser(d.data() as ProdUser);
  });

  const unsubNFTs = onSnapshot(nftsQuery, (querySnapshot) => {
    const nfts = querySnapshot.docs.map((d) => {
      const data = d.data();
      return {
        id: d.id,
        thumbnail: _.get(data, 'alchemy.media[0].thumbnail', ''),
        image: _.get(data, 'alchemy.media[0].gateway', ''),
        animation: _.get(data, 'alchemy.metadata.animation_url', ''),
        name: _.get(data, 'alchemy.metadata.name', ''),
        tokenId: data.tokenId,
        owner: id,
        creator: _.get(data, 'alchemy.contractMetadata.contractDeployer', ''),
        properties: _.get(data, 'alchemy.metadata.attributes', []),
        collection: data.collectionId,
      };
    }) as UserNft[];

    setUserNFTs(nfts);
  });

  const unsubSessions = onSnapshot(userSessionsQuery, (querySnapshot) => {
    const tavernIds: any = [];
    querySnapshot.forEach((d) => {
      // TODO: we should use the session info to flag the tavern with session type
      tavernIds.push(d.ref.parent.parent?.id);
    });
    /* eslint-disable @typescript-eslint/no-unsafe-argument */
    setUserTavernIds(_.uniq(tavernIds));
  });

  return () => {
    unsubUser();
    unsubNFTs();
    unsubSessions();
  };
};

export const tavernsQuery = async (tavernIds: string[], callback: (taverns: Tavern[]) => void = noop) => {
  if (!tavernIds || !tavernIds.length) {
    return noop;
  }

  // Split the tavernIds array into chunks of 10
  const chunkedTavernIds = _.chunk(tavernIds, 10);

  // Helper function to query Firestore for each chunk of tavernIds
  const fetchTavernsForIds = async (ids: string[]) => {
    const chunkQuery = query(collection(db, 'taverns'), where('id', 'in', ids));
    const snapshot = await getDocs(chunkQuery);
    return snapshot.docs.map((tavern) => tavern.data() as Tavern);
  };

  // Fetch the taverns for each chunk and combine the results
  const fetchedTavernsPromises = chunkedTavernIds.map(fetchTavernsForIds);
  const fetchedTaverns = await Promise.all(fetchedTavernsPromises);
  const combinedTaverns = fetchedTaverns.flat();

  callback(combinedTaverns);
};

export const userLiveTavernSubscription = (tavernRef: any, setLiveTavern: (state: any) => void) => {
  if (tavernRef) {
    const unsubTavern = onSnapshot(tavernRef, (d: any) => {
      const tavernData = d.data();
      if (tavernData.status === TavernStatus.Live) {
        setLiveTavern(d.data() as Tavern);
      }
    });

    return () => {
      unsubTavern();
    };
  }

  setLiveTavern(null);
};

export const userTavernsWithRemindersSubscription = (userId: string, callback: (ids: string[]) => void) => {
  // Create a collection group query for all 'going' subcollections
  const goingCollectionGroup = collectionGroup(db, 'going');

  // Query for 'going' documents that have the user ID
  const q = query(goingCollectionGroup, where('user', '==', doc(db, `users/${userId}`)));

  // Fetch the matching 'going' documents
  const unsubscribe = onSnapshot(q, (snapshot) => {
    const ids = snapshot.docs.map((going) => {
      // get tavern id
      return going.ref.parent.parent?.id;
    });

    callback(ids.filter(notEmpty));
  });

  return unsubscribe;
};

export const userScoresSubscription = (userId: string, callback: (ids: number[]) => void) => {
  const scoresCollectionGroup = collectionGroup(db, 'scores');
  const q = query(scoresCollectionGroup, where('id', '==', userId));

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const scores = snapshot.docs.map((score) => {
      return score.data().score;
    });

    callback(scores);
  });

  return unsubscribe;
};

export const scoresQuery = async (callback: (state: any) => void) => {
  // TODO: this is terrible and needs to be done by some chunking
  const scoresCollectionGroup = collectionGroup(db, 'scores');
  const q = query(scoresCollectionGroup);

  const usersCollection = collection(db, 'users');
  const q2 = query(usersCollection, where('isAnon', '==', false));

  const snapshotScores = await getDocs(q);
  const snapshotUsers = await getDocs(q2);

  const users = snapshotUsers.docs.map((user) => user.data());

  const scores: any = {};

  snapshotScores.docs.forEach((scoreDoc) => {
    const score = scoreDoc.data();

    if (!scores[score.id]) {
      const user = _.find(users, (u) => u.id === score.id);

      if (user) {
        scores[score.id] = {
          id: score.id,
          score: score.score,
          taverns: 1,
          user,
        };
        scores[score.id].score += user.score ?? 0;
      }
    } else {
      scores[score.id].score += score.score;
      scores[score.id].taverns += 1;
    }
  });

  const orderedScores = _.orderBy(Object.values(scores), ['score'], ['desc']).map((s: any, i) => {
    return {
      ...s,
      rank: i + 1,
    };
  });

  callback(orderedScores);
};

export const userCreatedTavernsSubscription = (userId: string, callback: (ids: string[]) => void) => {
  const userRef = doc(db, 'users', userId);
  const creatorQuery = query(collection(db, 'taverns'), where('creator', '==', userRef));

  // Fetch the matching 'going' documents
  const unsubscribe = onSnapshot(creatorQuery, (snapshot) => {
    const tavernIds: any = [];
    snapshot.forEach((d) => {
      tavernIds.push(d.id);
    });

    callback(tavernIds);
  });

  return unsubscribe;
};

export const tavernAIMessages = async (tavernId: string) => {
  const tavernRef = doc(db, 'taverns', tavernId);
  const aiQuery = query(collection(tavernRef, 'ai'), orderBy(`created_at`, 'desc'));

  const snapshot = await getDocs(aiQuery);
  return snapshot.docs.map((message) => message.data());
};

export const getTavernUsers = async (docIds: string[]) => {
  const users = await Promise.all(
    docIds.map(async (id) => {
      const docRef = doc(db, 'users', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();

        if (data) {
          return {
            id,
            ...data.farcaster,
          };
        }
      }
    })
  );

  return users.filter((user) => !!user);
};
