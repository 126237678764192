import { createContext } from 'react';
import { ProdUser, UserNft } from '../types/user';

export default createContext({
  // we need this variable to know for sure when we can start checking for user auth status
  isAuthStateLoaded: false,
  user: {} as ProdUser | null,
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  setUser: (state: ProdUser | null) => {},
  userNFTs: [] as UserNft[],
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  setUserNFTs: (state: UserNft[]) => {},
});
