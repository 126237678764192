/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { chakra, useDisclosure } from '@chakra-ui/react';
import { useContext, useState } from 'react';

import AlertModal from '../AlertModal/AlertModal';
import GlobalPlayerContext from '../../context/global-player-context';
import { AlertModalProps } from '../AlertModal/types';
import { IconListeners, IconPlay } from '../../theme/foundations/customIcons';
import { Tavern, TavernStatus } from '../../types/tavern';
import { TavernButtonAnime } from '../uiKit/TavernButton/TavernButtonAnime';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';
import { TavernButtonProps } from '../uiKit/TavernButton/types';
import { getRecordingUrlForTavern } from '../../utils/player-util';
import { increasePlayCount, leaveTavern, listenToTavern } from '../../services/tavern-functions';
import { leaveAgoraChannel } from '../../services/agora-functions';
import { noop } from '../../utils/noop';

interface ListenToTavernButtonProps extends Omit<TavernButtonProps, 'variant'> {
  tavern: Tavern;
  variant?: TavernButtonProps['variant'];
  onSuccess?: () => void;
}

const ListenToTavernButtonComponent = (props: ListenToTavernButtonProps) => {
  const { connectedTavern, setConnectedTavern, setUserSession, setRecordedPlayState, setRecordedTavernProgress } =
    useContext(GlobalPlayerContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { tavern, variant = 'primary', onSuccess = noop, ...rest } = props;

  const [listButtonConnecting, setListButtonConnecting] = useState<boolean>(false);
  const [modalButtonConnecting, setModalButtonConnecting] = useState<boolean>(false);

  const connectToTavern = async () => {
    if (tavern.status === TavernStatus.Past) {
      const url = await getRecordingUrlForTavern(tavern.id);
      setConnectedTavern(tavern);
      if (url !== null) {
        setRecordedPlayState({
          url,
          initiatePlayback: true,
          isPlaying: false,
          newProgress: undefined,
          duration: 0,
        });
        setRecordedTavernProgress(0);
      }
      onSuccess();
      await increasePlayCount(tavern.id);
    } else {
      await listenToTavern(tavern.id);
      setConnectedTavern(tavern);
      onSuccess();
    }
  };

  const handleContinue = async () => {
    setModalButtonConnecting(true);
    setUserSession(null);
    try {
      await leaveAgoraChannel();
      await leaveTavern(connectedTavern?.id as string);
      await connectToTavern();
      onClose();
      setModalButtonConnecting(false);
    } catch (error) {
      console.error(`handleContinue error: ${error}`);
    }
  };

  const isLeavingConnectedTavern = connectedTavern !== null && connectedTavern.id !== props.tavern.id;

  const modalProps: AlertModalProps = {
    isOpen,
    onClose,
    isDestructive: true,
    title: 'Listen to New Tavern',
    description: `Hit continue to disconnect from ${connectedTavern?.title as string} and listen to ${
      props.tavern.title
    }.`,
    primaryButton: {
      text: 'Continue',
      isLoading: modalButtonConnecting,
      onClick: () => {
        handleContinue().catch((error) => console.error(error));
      },
    },
    secondaryButton: {
      text: 'Cancel',
      onClick: () => {
        onClose();
      },
    },
  };

  const isPast = props.tavern.status === TavernStatus.Past;

  const handleListen = async () => {
    if (isLeavingConnectedTavern) {
      onOpen();
    } else {
      try {
        setListButtonConnecting(true);
        await connectToTavern();
        setListButtonConnecting(false);
      } catch (error) {
        console.error(`handleListen error: ${error}`);
      }
    }
  };

  const TButton = isPast ? TavernButton : TavernButtonAnime;

  if (tavern.faded) {
    return <></>;
  }

  return (
    <>
      <TButton
        w="100%"
        variant={variant}
        leftIcon={isPast ? <IconPlay /> : <IconListeners />}
        isLoading={listButtonConnecting}
        onClick={(event) => {
          event.stopPropagation();
          handleListen().catch((error) => console.error(error));
        }}
        {...rest}
      >
        {isPast ? 'Play recording' : 'Listen'}
      </TButton>
      <AlertModal {...modalProps} />
    </>
  );
};

export const ListenToTavernButton = chakra(ListenToTavernButtonComponent);
