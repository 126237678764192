import { Box, Flex, Text, ToastProps, useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react';
import { uniqueId } from 'lodash';
import { IconClose, IconError, IconSuccess, IconWarning } from '../../theme/foundations/customIcons';
import { noop } from '../../utils/noop';
import { RoundButton } from '../uiKit/RoundButton/RoundButton';

interface UseToastCustomOptions extends UseToastOptions {
  onUserClose?: () => void;
}

// provide unique ID into toast to make it closable
export const useTavernToast = () => {
  const toast = useChakraToast();

  const getToastColor = (_status: ToastProps['status']) => {
    if (_status === 'error') {
      return 'accentDestructive';
    }

    if (_status === 'warning') {
      return 'accentWarning';
    }

    return 'accentSuccess';
  };

  const getToastIcon = (_status: ToastProps['status']) => {
    if (_status === 'error') {
      return <IconError boxSize="32px" />;
    }

    if (_status === 'warning') {
      return <IconWarning boxSize="32px" />;
    }

    if (_status === 'success') {
      return <IconSuccess boxSize="32px" />;
    }

    return null;
  };

  const customToast = (options: UseToastCustomOptions) => {
    const { title, description, status, isClosable = true, size = 'lg', onUserClose = noop } = options;
    const id = options.id || uniqueId('toast_');
    // @ts-ignore
    toast({
      containerStyle: {
        maxW: '100%',
        width: size === 'lg' ? 'min(calc(100% - 20px), 722px)' : '360px',
      },
      isClosable,
      ...options,
      id,
      render: () => (
        <Flex
          alignItems={size === 'lg' ? 'center' : 'flex-start'}
          p="16px"
          bg="backgroundSnackbar"
          color={getToastColor(status)}
          position="relative"
        >
          <Flex alignItems="flex-start" flexShrink="0" mr="16px">
            {getToastIcon(status)}
          </Flex>
          <Box>
            {title && title !== '' ? (
              <Text display="inline-block" as="strong" color="inherit" textStyle="body16regular">
                {title}
              </Text>
            ) : null}
            {description && description !== '' ? (
              <Text mt="2px" color="typographySecondary" textStyle="body14regular">
                {description}
              </Text>
            ) : null}
          </Box>
          {isClosable ? (
            <RoundButton
              onClick={() => {
                if (id) {
                  toast.close(id);
                }
                onUserClose();
              }}
              size="lg"
              aria-label="Close toast"
              position="absolute"
              top={size === 'lg' ? '10px' : '20px'}
              right={size === 'lg' ? '10px' : '20px'}
              border="none"
              bg="none"
              boxSize="40px"
              ml="auto"
              icon={<IconClose color="typographySecondary" boxSize="24px" />}
            />
          ) : null}
        </Flex>
      ),
    });
  };

  return Object.assign(customToast, toast);
};
