import { useContext, useEffect } from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';

import NotificationsContext from '../context/notifications';
import {
  FollowedByUserNotification,
  Notification,
  NotificationType,
  UserGoingTavernStartNotification,
} from '../types/notification';
import { IconNotification } from '../theme/foundations/customIcons';
import { NotificationStartTavern } from '../components/NotificationCard/NotificationStartTavern';
import { NotificationFollowedByUser } from '../components/NotificationCard/NotificationFollowedByUser';
import { markNotificationAsSeen } from '../services/notifications';

// go to /components/mockScreens/NotificationList if you need more notifications types
export const Notifications = () => {
  const { notifications } = useContext(NotificationsContext);

  useEffect(() => {
    document.title = `Notifications`;
  }, []);

  useEffect(() => {
    notifications?.forEach((n) => {
      if (!n.seen) {
        markNotificationAsSeen(n.id).catch(console.error);
      }
    });
  }, []);

  const renderCardComponent = (notification: Notification) => {
    switch (notification.type) {
      case NotificationType.UserGoingTavernStart:
      case NotificationType.FolloweeTavernStart:
      case NotificationType.FolloweeTavernJoin:
        return (
          <NotificationStartTavern {...(notification as UserGoingTavernStartNotification)} key={notification.id} />
        );
      case NotificationType.FollowedByUser:
        return <NotificationFollowedByUser {...(notification as FollowedByUserNotification)} key={notification.id} />;
      // case NotificationType.Join:
      //   return <NotificationJoin {...(notification as JoinNotification)} key={notification.id} />;
    }
  };

  return (
    <Box>
      <Heading mb="32px">
        <Flex alignItems="center">
          <IconNotification boxSize="32px" mr="12px" color="typographySecondary" />
          <Text as="span" textStyle="h2">
            Notifications
          </Text>
          <Box ml="12px" as="span" color="typographySecondary">
            {notifications.length !== 0 && notifications.length}
          </Box>
        </Flex>
      </Heading>
      {notifications.length < 1 ? (
        <Box maxWidth="352px" textAlign="center" m="0 auto" p={{ base: '16px 0', xl: '24px 0' }}>
          <IconNotification boxSize="56px" mb="32px" color="typographyTertiary" />
          <Text textStyle="h4" color="typographyPrimary" mb="12px">
            No new notifications
          </Text>
          <Text textStyle="body16regular" color="typographySecondary">
            The reminders you receive will be <br />
            displayed here.
          </Text>
        </Box>
      ) : (
        <>
          <Box as="div">
            {notifications.map((notification: Notification) => (
              <Box key={notification.id}>{renderCardComponent(notification)}</Box>
            ))}
          </Box>
          {/* <Box as="button" textStyle="body16regular" color="brandPrimary" onClick={() => setQuantity(quantity + 7)}> */}
          {/*  <IconLoadMore fontSize="lg" /> Show more */}
          {/* </Box> */}
        </>
      )}
    </Box>
  );
};
