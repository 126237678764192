import { Box, chakra, FormControl, FormErrorMessage, FormHelperText, FormLabel } from '@chakra-ui/react';
import { TavernFormControlProps } from './types';

const TavernFormControlComponent = ({
  children,
  label,
  hint,
  errorText,
  isOptional,
  isInvalid,
  ...rest
}: TavernFormControlProps) => {
  return (
    <FormControl isInvalid={isInvalid} {...rest}>
      <FormLabel mb="8px" textStyle="body14medium" color="white">
        {label || null}
        {isOptional && (
          <Box as="span" ml="8px" color="gray.1000">
            Optional
          </Box>
        )}
      </FormLabel>

      {children}

      {!isInvalid ? (
        <FormHelperText textStyle="body12regular" mt="4px" color="gray.400">
          {hint}
        </FormHelperText>
      ) : (
        <FormErrorMessage textStyle="body12regular" mt="4px" color="accent.red">
          {errorText}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export const TavernFormControl = chakra(TavernFormControlComponent);
