import { useState } from 'react';
import { Box, chakra, Flex, HStack } from '@chakra-ui/react';
import { SingleValue } from 'chakra-react-select';
import { noop } from '../../utils/noop';
import { RoundButton } from '../uiKit/RoundButton/RoundButton';
import { EmojiReactionPanelProps } from './types';
import { Emoji } from '../uiKit/Emoji/Emoji';
import { IconEmojiMore } from '../../theme/foundations/customIcons';
import { SearchEmojiSelectPopover } from '../SearchEmojiSelectPopover/SearchEmojiSelectPopover';
import { EmojiOption } from '../SearchEmojiSelect/types';

const EmojiReactionPanelComponent = (props: EmojiReactionPanelProps) => {
  const { emojis, isSelectable, selectedEmoji, onSelect = noop, ...rest } = props;
  const [isSearchPopoverVisible, setIsSearchPopoverVisible] = useState(false);
  const [selectedEmojiItem, setSelectedEmojiItem] = useState<string>(selectedEmoji || '');
  // const [isSmallerScreen] = useMediaQuery('(max-width: 768px)');

  const toggleShowSelect = () => {
    setIsSearchPopoverVisible((prevValue) => !prevValue);
  };

  const handleSelectEmoji = (emoji: SingleValue<EmojiOption>) => {
    if (!emoji) {
      onSelect('');
      return;
    }
    onSelect(emoji.label);
    setIsSearchPopoverVisible(false);
  };

  const handlePopoverClose = () => {
    setIsSearchPopoverVisible(false);
  };

  const isSelectedEmoji = (emoji: string) => {
    return selectedEmojiItem === emoji;
  };

  const toggleEmoji = (emoji: string) => {
    if (selectedEmojiItem !== emoji) {
      setSelectedEmojiItem(emoji);
    } else {
      setSelectedEmojiItem('');
    }
  };

  const getEmojisList = () => {
    // if (isSmallerScreen) {
    //   return emojis.slice(0, 3);
    // }
    return emojis;
  };

  return (
    <Box {...rest} display="flex">
      <Box>
        <Flex bg="backgroundInput" borderRadius="24px" p="4px 12px" alignItems="center">
          <HStack alignItems="center" spacing="0px">
            {getEmojisList().map((emoji, i) => (
              <Emoji
                key={i}
                boxSize="40px"
                value={emoji}
                isClickable
                isSelected={isSelectable && isSelectedEmoji(emoji)}
                onClick={() => {
                  onSelect(emoji);
                  if (isSelectable) {
                    toggleEmoji(emoji);
                  }
                }}
              />
            ))}
            <SearchEmojiSelectPopover
              placement="top-end"
              width="300px"
              isOpen={isSearchPopoverVisible}
              onSelect={handleSelectEmoji}
              selectProps={{ value: undefined }}
              onClose={handlePopoverClose}
              triggerElement={
                <RoundButton
                  variant="emoji"
                  size="lg"
                  boxSize="24px"
                  aria-label="search-emoji"
                  icon={<IconEmojiMore />}
                  onClick={toggleShowSelect}
                />
              }
            />
          </HStack>
        </Flex>
      </Box>
    </Box>
  );
};

export const EmojiReactionPanel = chakra(EmojiReactionPanelComponent);
