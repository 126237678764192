import { useMemo } from 'react';
import { TavernTextarea } from '../uiKit/TavernTextarea/TavernTextarea';
import { TextareaWithLimitProps } from './types';

export const TextareaWithLimit = (props: TextareaWithLimitProps) => {
  const { maxLength, value, ...rest } = props;

  const limitHint = useMemo(() => {
    const valueLength = value ? value.toString().length : 0;
    return `${maxLength - valueLength} characters remaining`;
  }, [value, maxLength]);

  return <TavernTextarea value={value} maxLength={maxLength} hint={limitHint} {...rest} />;
};
