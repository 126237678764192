import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  sm: '360px',
  md: '375px',
  lg: '480px',
  xl: '768px',
  '2xl': '1024px',
  '3xl': '1440px',
});

export default breakpoints;
