/* eslint-disable */
import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(radioAnatomy.keys);

const TavernRadioStyles = defineMultiStyleConfig({
  baseStyle: {
    control: {
      width: '20px',
      height: '20px',
      background: 'backgroundTertiary',
      _dark: {
        borderColor: 'backgroundTertiary',
      },
      _hover: {
        border: 'none',
        background: 'backgroundHover',
      },
      _checked: {
        border: 'none',
        background: 'brandPrimary',
        _before: {
          width: '8px',
          height: '8px',
          background: 'globalWhite',
        },
        _hover: {
          border: 'none',
          background: 'brandPrimary',
        },
      },
      _disabled: {
        opacity: '0.5',
        _dark: {
          borderColor: 'backgroundTertiary',
          background: 'backgroundTertiary',
        },
        _checked: {
          background: 'brandPrimary',
        },
        _hover: {
          _dark: {
            border: 'none',
            background: 'backgroundTertiary',
          },
        },
      },
    },
  },
});

export default TavernRadioStyles;
