import { httpsCallable } from 'firebase/functions';

import { SessionType } from '../types/tavern';
import { fbFunctions } from '../connect';

export const muteSession = async (
  tavernId: string,
  sessionId: string,
  muting: {
    isMuted?: boolean;
    isForceMuted?: boolean;
  }
): Promise<void> => {
  const update = httpsCallable<{
    tavernId: string;
    sessionId: string;
    muting: {
      isMuted?: boolean;
      isForceMuted?: boolean;
    };
  }>(fbFunctions, 'muteSession');
  await update({
    tavernId,
    sessionId,
    muting,
  });
};

export const convertAnonSessionToUser = async (tavernId: string, sessionId: string, anonId: string): Promise<void> => {
  console.log(`Converting session ${sessionId} with anon user id ${anonId} to user session for Tavern ${tavernId}.`);
  const convert = httpsCallable<{
    tavernId: string;
    sessionId: string;
    anonId: string;
  }>(fbFunctions, 'convertAnonSessionToUser');
  await convert({
    tavernId,
    sessionId,
    anonId,
  });
};

export const returnToSessionType = async (tavernId: string, type: SessionType): Promise<void> => {
  const returnToType = httpsCallable<{
    tavernId: string;
    type: SessionType;
  }>(fbFunctions, 'returnToSessionType');
  await returnToType({
    tavernId,
    type,
  });
};
