import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyA8SxB_n8ufYDt6fPbVyj6GwOFlRiVeksQ',
  authDomain: 'tavern-bf653.firebaseapp.com',
  projectId: 'tavern-bf653',
  storageBucket: 'tavern-bf653.appspot.com',
  messagingSenderId: '40464252416',
  appId: '1:40464252416:web:004e1c1a4919cb0a7ba2e5',
  measurementId: 'G-E76ZDG8QW8',
  databaseURL: 'https://tavern-bf653-default-rtdb.firebaseio.com',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const fbFunctions = getFunctions();
export const db = getFirestore();
export const storage = getStorage(app);
export const realtimeDb = getDatabase(app);
