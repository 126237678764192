import { MenuButton, MenuList, useDisclosure } from '@chakra-ui/react';
import { useContext } from 'react';

import GlobalPlayerContext from '../../context/global-player-context';
import { IconMeatballs } from '../../theme/foundations/customIcons';
import { MoveToJoinerDropdownItem } from '../uiKit/DropdownItem/DropdownItem';
import { SessionType } from '../../types/tavern';
import { TavernDropdown } from '../uiKit/TavernDropdown/TavernDropdown';
// import { noop } from '../../utils/noop';
// import { ChangeNftAvatarButton } from './change-nft-avatar-button';

export const ReturnToRoleButton = () => {
  const { connectedTavern, userSession } = useContext(GlobalPlayerContext);
  const { isOpen, onClose, onOpen } = useDisclosure();

  // const isJoinerOrHigher =
  //   userSession &&
  //   (userSession.type === SessionType.Host ||
  //     userSession.type === SessionType.CoHost ||
  //     userSession.type === SessionType.Joiner);

  if (connectedTavern === null || userSession === null || userSession.type === SessionType.Listener) {
    return <></>;
  }

  if (userSession.type === SessionType.CoHost || userSession.type === SessionType.Speaker) {
    return (
      <TavernDropdown placement="top-end" isOpen={isOpen} onClose={onClose}>
        <MenuButton
          _expanded={{ bg: 'backgroundInput' }}
          _hover={{ bg: 'backgroundInput' }}
          borderRadius="50%"
          boxSize="48px"
          mr="4px"
          onClick={onOpen}
          backgroundColor="backgroundButton"
          color="typographySecondary"
          backdropFilter="blur(24px)"
          style={{
            background: 'backgroundButton',
            color: 'typographySecondary',
          }}
        >
          <IconMeatballs w="24px" h="24px" />
        </MenuButton>
        <MenuList>
          <MoveToJoinerDropdownItem
            tavernId={connectedTavern.id}
            targetSession={userSession}
            toastTitle="Moved to Audience"
            toastDescription="You have successfully moved to the Audience."
            onClose={onClose}
          />
          {/* {isJoinerOrHigher && <ChangeNftAvatarButton tavern={connectedTavern} onSuccess={noop} />} */}
        </MenuList>
      </TavernDropdown>
    );
  }

  return <></>;
};
