/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@chakra-ui/react';
import { query, collection, onSnapshot, orderBy } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LinkExtra } from '../../types/tavern';
import { LinksCarousel } from '../LinksCarousel/LinksCarousel';
import { db } from '../../connect';
import { isMedia } from '../../utils/link-util';

export const MediaCarouselContainer = ({ hasHostingPermission }: { hasHostingPermission: boolean }) => {
  const { tavernId } = useParams();

  const [links, setLinks] = useState<LinkExtra[]>([]);

  useEffect(() => {
    const linksQuery = query(collection(db, `taverns/${tavernId as string}/links`), orderBy('timestamp', 'desc'));
    const unsub = onSnapshot(linksQuery, (querySnapshot) => {
      setLinks(querySnapshot.docs.map((doc) => doc.data() as LinkExtra));
    });
    return unsub;
  }, []);

  const urlLinks = links.filter((link) => !isMedia(link));
  const mediaLinks = links.filter((link) => isMedia(link));

  return (
    <Box>
      <LinksCarousel links={urlLinks} pb="40px" isDotsEnabled hasHostingPermission={hasHostingPermission} />
      <LinksCarousel links={mediaLinks} mb="8" pb="40px" isDotsEnabled hasHostingPermission={hasHostingPermission} />
    </Box>
  );
};
