/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import {
  Box,
  Flex,
  HStack,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';

import AuthContext from '../context/auth';
import CollectionsContext from '../context/collections';
import GlobalPlayerContext from '../context/global-player-context';
import TavernDetailContext from '../context/tavern-detail-context';
import { AllowedNftCollections } from '../components/AllowedNftCollections/AllowedNftCollections';
import { BanModal } from '../components/modals/ban-modal';
import { BlockedUsersList } from '../components/lists/blocked-users-list';
import { EndTavernButton } from '../components/buttons/end-tavern-button';
import { FeaturedImage } from '../components/FeaturedImage/FeaturedImage';
import { InviteSpeakerRequestsList } from '../components/lists/invite-speaker-requests-list';
import { InvitedToSpeakModal } from '../components/modals/invited-to-speak-modal';
import { JoinTavernButton } from '../components/buttons/join-tavern-button';
import { ParticipantsSection } from '../components/TavernPage/ParticipantsSection/ParticipantsSection';
import { ListenToTavernButton } from '../components/buttons/listen-to-tavern-button';
import { LiveAddLinksModal } from '../components/LiveAddLinksModal/LiveAddLinksModal';
import { LocalUploadPopover } from '../components/LocalUploadPopover/LocalUploadPopover';
import { MediaCarouselContainer } from '../components/MediaCarouselContainer/MediaCarouselContainer';
import { NotificationBadge } from '../components/uiKit/NotificationBadge/NotificationBadge';
import { ProdUser } from '../types/user';
import { RecordTimer } from '../components/uiKit/RecordTimer/RecordTimer';
import { Session, SessionType, Tavern, TavernStatus } from '../types/tavern';
import { SpeakerInvitesList } from '../components/lists/speaker-invites-list';
import { SpeakerRequestsList } from '../components/lists/speaker-requests-list';
import { SpeakersList } from '../components/lists/speakers-list';
import { TavernTabs } from '../components/uiKit/TavernTabs/TavernTabs';
import { TavernUserCountsStack } from '../components/tavern-user-counts-stack';
import { TokenRenewalMonitor } from '../components/monitors/token-renewal-monitor';
import { TopicsList } from '../components/uiKit/TopicsList/TopicsList';
import { UserStatus } from '../components/UserStatus/UserStatus';
import { sessionAsUserMock } from '../components/lists/utils/list-util';
import { setDisconnectListener } from '../services/realtime-functions';
import { useTavernToast } from '../components/hooks/useTavernToast';
import { BoxAnime } from '../components/uiKit/TavernButton/BoxAnime';
import { BarSection } from '../components/TavernPage/ParticipantsSection/BarSection';
import { Soundboard } from '../components/LocalUploadPopover/Soundboard';
// import { AddAnonAIButton } from '../components/buttons/add-anon-ai-tavern-button';

const handleMicrophoneCheck = (state: string, userSession: SessionType | undefined, toast: any) => {
  if (
    state === 'denied' &&
    (userSession === SessionType.Host ||
      userSession === SessionType.CoHost ||
      userSession === SessionType.Speaker ||
      !userSession)
  ) {
    toast({
      title: `Microphone Permissions Blocked`,
      description: 'Please unblock microphone permissions',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
  }
};

// TODO: Once UI integration is complete, re-sync these changes back into TavernLive + TavernLiveAsHost stories.
export const LiveTavernDetail = (props: { tavern: Tavern }) => {
  const toast = useTavernToast();
  const { tavern } = props;
  const { connectedTavern, userSession, setConnectedTavern } = useContext(GlobalPlayerContext);
  const { joiners, listeners, listenerCount, coHosts, playCount, speakers, topics } = useContext(TavernDetailContext);
  const { collections } = useContext(CollectionsContext);
  const { user } = useContext(AuthContext);

  const [requestCount, setRequestCount] = useState(0);
  const [inviteCount, setInviteCount] = useState(0);
  const [blockedCount, setBlockedCount] = useState(0);
  const [inviteRequestCount, setInviteRequestCount] = useState(0);

  const isRecordTimerVisible = useBreakpointValue({ base: 'none', xl: 'block' });
  const isUserSessionVisible = useBreakpointValue({ base: 'none', lg: 'flex' });
  const popoverSize = useBreakpointValue({ base: '330px', xl: '474px' });

  useEffect(() => {
    const checkMicPermission = async () => {
      try {
        const permission = await navigator.permissions.query({ name: 'microphone' as any });
        handleMicrophoneCheck(permission.state, userSession?.type, toast);

        permission.onchange = () => {
          handleMicrophoneCheck(permission.state, userSession?.type, toast);
        };
      } catch (error) {
        console.error('Error checking microphone permission:', error);
      }
    };

    checkMicPermission().catch(console.error);
  }, []);

  if (!tavern) {
    return <></>;
  }

  const isYourActiveTavern = connectedTavern?.id === tavern.id;

  if (userSession !== null) {
    setDisconnectListener(tavern.id, userSession.id).catch(console.error);
  }

  const { title, description, startTime } = tavern;

  const sessionType = isYourActiveTavern ? userSession?.type || SessionType.Listener : SessionType.Listener;

  const hasHostingPermission =
    isYourActiveTavern &&
    userSession !== null &&
    (userSession.type === SessionType.Host || userSession.type === SessionType.CoHost);

  const hasSpeakingPermission =
    isYourActiveTavern &&
    userSession !== null &&
    (userSession.type === SessionType.Host ||
      userSession.type === SessionType.CoHost ||
      userSession.type === SessionType.Speaker);

  const allowlist: any = tavern.joinAllowlist?.map((id) => {
    return _.find(collections, (c) => c.id === id);
  });

  return (
    <Box width="100%" pt={{ base: `16px`, xl: '6px' }}>
      <Flex alignItems="flex-start" mb="16px" flexDirection={{ base: 'column', xl: 'row' }}>
        <Box maxW={{ base: `${640 + 16}px`, xl: `${240 + 16}px` }}>
          <FeaturedImage tavernId={tavern.id} mb="16px" pr={{ base: 0, xl: `16px` }} />
        </Box>
        <VStack align="start" spacing={5} width="100%">
          <HStack>
            <TavernUserCountsStack
              tavern={tavern}
              coHosts={coHosts}
              listenerCount={listenerCount}
              playCount={playCount}
              joiners={joiners}
              speakers={speakers}
            />
            {userSession && isYourActiveTavern && (
              <HStack spacing="8px" display={isUserSessionVisible}>
                <Text ml="8px" color="typographySecondary">
                  ·
                </Text>
                <UserStatus ml="8px" avatar={userSession?.nft ?? userSession.avatar} status={sessionType} />
              </HStack>
            )}
          </HStack>
          <Flex
            flexDirection={{ base: 'column', xl: 'row' }}
            alignItems={{ base: 'flex-start', xl: 'center' }}
            width="100%"
          >
            <Box mb={{ base: '12px', lg: '0' }} textStyle="h1" pr="24px">
              {title}
            </Box>
          </Flex>
        </VStack>
        <Spacer />
        <VStack alignSelf={{ base: 'flex-start' }} justifyItems="flex-end" spacing="16px">
          <Box display={isRecordTimerVisible} alignSelf="flex-end">
            {tavern ? <RecordTimer startTimestamp={startTime || 0} /> : <></>}
          </Box>
          <Box mt={{ base: '16px', xl: '0' }} alignItems="flex-start">
            <HStack spacing="16px" alignSelf="flex-start">
              <EndTavernButton
                sessionType={sessionType}
                userSession={userSession}
                onEnd={() => setConnectedTavern(null)}
              />
              {joinOrListenButtons(user, userSession, tavern, connectedTavern)}
            </HStack>
          </Box>
        </VStack>
      </Flex>

      <Box mb="16px">
        <TopicsList topics={topics} />
      </Box>

      <AllowedNftCollections collections={allowlist} maxVisible={1} mt="24px" mb="14px" />

      {description && (
        <Box mb="24px" color="typographySecondary">
          {description}
        </Box>
      )}

      <Box mt="24px" mb="24px" position="relative" zIndex="0">
        <Box overflow="hidden">
          <MediaCarouselContainer hasHostingPermission={hasHostingPermission} />
        </Box>
      </Box>

      {/* {hasHostingPermission && (
        <Box w="100%" mt="28px" mb="28px">
          <AddAnonAIButton tavern={tavern} />
        </Box>
      )} */}

      {hasSpeakingPermission && (
        <Box w="100%" mb="28px">
          <Soundboard placement="bottom-start" width={popoverSize} scrollHeight="160px" />
        </Box>
      )}

      {hasSpeakingPermission && (
        <Box w="100%">
          <LocalUploadPopover placement="bottom-start" width={popoverSize} closeOnBlur />
          <Box mt="28px">
            <LiveAddLinksModal />
          </Box>
        </Box>
      )}

      <Box mt="28px">
        <SpeakersList />
      </Box>

      <Box mb="24px" mt="24px">
        <TavernTabs>
          <TabList>
            {tabTitles(hasHostingPermission).map((item) => {
              let notify = 0;
              let count = 0;

              if (item === 'Audience') {
                count = joiners.length + listeners.length;
              } else if (item === 'Requests') {
                notify = requestCount;
              } else if (item === 'Invitations') {
                notify = inviteCount;
              } else if (item === 'Blocked') {
                notify = blockedCount;
              } else if (item === 'Invites') {
                notify = inviteRequestCount;
              }

              return (
                <Tab key={item}>
                  {item === 'Bar' ? (
                    <BoxAnime title="Bar" />
                  ) : (
                    <Box mr={4} fontSize="md">
                      {item}
                    </Box>
                  )}
                  {count > 0 && <Text>{count}</Text>}
                  {notify > 0 && <NotificationBadge>{notify}</NotificationBadge>}
                </Tab>
              );
            })}
          </TabList>
          {tabContent(
            hasHostingPermission,
            [...joiners, ...listeners].map((joiner) => {
              return sessionAsUserMock(joiner, false);
            }),
            sessionType,
            userSession,
            setRequestCount,
            setInviteCount,
            setBlockedCount,
            setInviteRequestCount,
            tavern.status
          )}
        </TavernTabs>
      </Box>

      <InvitedToSpeakModal />
      <TokenRenewalMonitor tavern={tavern} userSession={userSession} />
      <BanModal />
    </Box>
  );
};

const tabTitles = (hostingPermission: boolean) => {
  return hostingPermission ? ['Audience', 'Bar', 'Requests', 'Invitations', 'Blocked'] : ['Audience', 'Bar', 'Invites'];
};

const tabContent = (
  hostingPermission: boolean,
  joinedUsers: any,
  sessionType: SessionType,
  userSession: Session | null,
  setRequestCount: (count: number) => void,
  setInviteCount: (count: number) => void,
  setBlockedCount: (count: number) => void,
  setInviteRequestCount: (count: number) => void,
  status: TavernStatus
) => {
  return hostingPermission ? (
    <TabPanels>
      <TabPanel>
        <ParticipantsSection status={status} cardSize="sm" joiners={joinedUsers} sessionType={sessionType} />
      </TabPanel>
      <TabPanel>
        <BarSection />
      </TabPanel>
      <TabPanel>
        <SpeakerRequestsList setRequestCount={setRequestCount} />
      </TabPanel>
      <TabPanel>
        <SpeakerInvitesList userSession={userSession} setInviteCount={setInviteCount} />
      </TabPanel>
      <TabPanel>
        <BlockedUsersList sessionType={userSession?.type} setBlockedCount={setBlockedCount} />
      </TabPanel>
    </TabPanels>
  ) : (
    <TabPanels>
      <TabPanel>
        <ParticipantsSection status={status} cardSize="sm" joiners={joinedUsers} sessionType={sessionType} />
      </TabPanel>
      <TabPanel>
        <BarSection />
      </TabPanel>
      <TabPanel>
        <InviteSpeakerRequestsList setInviteRequestCount={setInviteRequestCount} />
      </TabPanel>
    </TabPanels>
  );
};

export const joinOrListenButtons = (
  user: ProdUser | null,
  userSession: Session | null,
  detailTavern: Tavern,
  connectedTavern: Tavern | null
) => {
  if (userSession === null && (user?.id === detailTavern.host.id || !user?.isAnon)) {
    return <JoinTavernButton tavern={detailTavern} />;
  }

  if (userSession === null || detailTavern.id !== connectedTavern?.id) {
    return <ListenToTavernButton tavern={detailTavern} />;
  }

  switch (userSession.type) {
    case SessionType.Host:
    case SessionType.CoHost:
    case SessionType.Speaker:
      return <></>;
    case SessionType.Listener:
      return <JoinTavernButton tavern={detailTavern} />;

    default:
      return <></>;
  }
};
