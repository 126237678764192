import React from 'react';
import { MicrophoneDeviceContext } from '../context/microphone-device-context';

export const useAudioDevice = () => {
  const context = React.useContext(MicrophoneDeviceContext);

  if (context === undefined) {
    throw new Error('useAudioDevice must be used within an AudioDeviceProvider');
  }

  const { audioDevices, selectedDevice, audioSpeakers, selectedSpeaker } = context;

  // Find the device with the selected ID
  const selectedDeviceObject = audioDevices.find((device) => device.deviceId === selectedDevice);
  const selectedDeviceLabel = selectedDeviceObject ? selectedDeviceObject.label : null;

  const selectedSpeakerObject = audioSpeakers.find((device) => device.deviceId === selectedSpeaker);
  const selectedSpeakerLabel = selectedSpeakerObject ? selectedSpeakerObject.label : null;

  return { ...context, selectedDeviceLabel, selectedSpeakerLabel };
};
