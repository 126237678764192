import { Avatar, Box, Flex, Highlight, Link, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { NotificationCardProps } from './types';
import { formatDurationFromNow } from '../../utils/date';
import { noop } from '../../utils/noop';
import { getUserImage } from '../../utils/user-util';

export const NotificationCard = (props: NotificationCardProps) => {
  const { timestamp, message, buttons, hasSeen, highlight, link, user, onHover = noop } = props;

  const navigate = useNavigate();

  return (
    <Flex
      p={{ base: '16px 0', xl: '16px' }}
      w="100%"
      justifyContent="space-between"
      alignItems={{ base: 'flex-start', '3xl': 'center' }}
      flexDirection={{ base: 'column', '3xl': 'row' }}
      onMouseEnter={onHover}
    >
      <Flex alignItems={{ lg: 'flex-start', xl: 'center' }}>
        <Box
          as="div"
          boxSize={{ base: '40px', lg: '48px' }}
          flexShrink={0}
          mt={{ base: '4px', lg: 0 }}
          position="relative"
        >
          {user && (
            <Avatar
              bg="black"
              boxSize={{ base: '40px', lg: '48px' }}
              border="1px solid white"
              borderColor="backgroundCard"
              zIndex="1"
              boxSizing="content-box"
              name={user.name ?? user.id}
              src={getUserImage(user)}
            />
          )}
          {/* {speakers && */}
          {/*  speakers */}
          {/*    .slice(0, 1) */}
          {/*    .map((speaker) => ( */}
          {/*      <Avatar */}
          {/*        boxSize={speakers.length === 1 ? { base: '40px', lg: '48px' } : { base: '26px', lg: '32px' }} */}
          {/*        border="1px solid var(--chakra-colors-backgroundCard)" */}
          {/*        borderColor="backgroundCard" */}
          {/*        zIndex="1" */}
          {/*        boxSizing="content-box" */}
          {/*        name={speaker.name} */}
          {/*        src={speaker.avatar} */}
          {/*      /> */}
          {/*    ))} */}
          {/* {speakers && */}
          {/*  speakers */}
          {/*    .slice(1) */}
          {/*    .map((speaker) => ( */}
          {/*      <Avatar */}
          {/*        boxSize={{ base: '26px', lg: '32px' }} */}
          {/*        position="absolute" */}
          {/*        bottom="0" */}
          {/*        right="0" */}
          {/*        boxSizing="content-box" */}
          {/*        name={speaker.name} */}
          {/*        src={speaker.avatar} */}
          {/*      /> */}
          {/*    ))} */}
        </Box>
        <Box ml="20px">
          {/* {inviteMessage && inviteMessage} */}
          {message && (
            <Link
              display="inline-block"
              _hover={{ textDecoration: 'none' }}
              onClick={() => {
                if (link) {
                  navigate(link);
                }
              }}
            >
              <Text as="strong" color="typographySecondary" textStyle="body16regular">
                <Highlight query={highlight || ''} styles={{ color: 'typographyPrimary' }}>
                  {message}
                </Highlight>
              </Text>
            </Link>
          )}
          <Box mt="4px" lineHeight={0}>
            <Text as="strong" color="typographySecondary" textStyle="body12regular">
              {!hasSeen && (
                <Box as="span" display="inline-block" w="8px" h="8px" borderRadius="50%" mr="8px" bg="brandPrimary" />
              )}

              {formatDurationFromNow(timestamp)}
            </Text>
          </Box>
        </Box>
      </Flex>

      {buttons && (
        <Flex alignItems="center" mt={{ base: '16px' }} ml={{ base: '56px', lg: '68px', '3xl': '20px' }}>
          {buttons}
        </Flex>
      )}
    </Flex>
  );
};
