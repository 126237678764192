const typography = {
  fonts: {
    body: 'IBM Plex Mono',
    heading: 'IBM Plex Mono',
  },
  fontSizes: {
    xs: '10px',
    sm: '12px',
    md: '14px',
    lg: '16px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '32px',
    xxl: '36px',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
  },
};

export default typography;
