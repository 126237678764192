/* eslint-disable */
import { popoverAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(popoverAnatomy.keys);

const baseStyle = definePartsStyle({
  popper: {
    border: 'none',
    borderRadius: 'none',
  },
  header: {
    border: 'none',
    padding: '8px 12px',
  },
  body: {
    padding: '0 12px',
  },
  content: {
    background: 'black.600',
    padding: '8px',
    border: 'none',
    borderRadius: 'none',
    outline: 'none',
    _focusVisible: {
      outline: 'none',
      boxShadow: 'none',
    },
  },
});

export const TavernPopoverStyle = defineMultiStyleConfig({
  baseStyle,
});
