/* eslint-disable */
import moment from 'moment';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  formatDistanceStrict,
} from 'date-fns';

export const getTimezoneOffsetFromTimezone = (): number => {
  return 0;
};

export function convertTo24Hour(time: string) {
  // Check if the input time string is already in 24-hour format
  if (time.includes(':') && !time.includes('AM') && !time.includes('PM')) {
    return time;
  }

  // Split the time string into hours, minutes, and AM/PM designator
  const [hours, minutes, period] = time.split(/[: ]/);
  let hoursInt = parseInt(hours, 10);
  if (period === 'PM' && hoursInt !== 12) {
    hoursInt += 12;
  }

  if (period === 'AM' && hoursInt === 12) {
    hoursInt -= 12;
  }

  const hoursStr = hoursInt.toString().padStart(2, '0');
  const minutesStr = minutes.padStart(2, '0');

  return `${hoursStr}:${minutesStr}`;
}

export function convertTimeToAMPM(timeString: string) {
  // Split the time string into its component hours and minutes
  const [hours, minutes] = timeString.split(':').map(Number);

  // Determine whether the time is in the morning or the afternoon/evening
  const period = hours < 12 ? 'AM' : 'PM';

  // Convert the hours to 12-hour format
  let hours12 = hours % 12 === 0 ? 12 : hours % 12;

  if (hours12 === 12 && period === 'AM') {
    hours12 = 0;
  }

  // Format the time as a string in the format "hh:mm AM/PM"
  const formattedTime = `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;

  return formattedTime;
}

/**
 * Converts timezone string into timezone offset
 * @param timezoneStr
 */
export function timezoneOffset(timezoneStr: string) {
  const sign = timezoneStr.charAt(0) === '-' ? -1 : 1;
  const hours = parseInt(timezoneStr.substring(1, 3));
  const minutes = parseInt(timezoneStr.substring(4));
  return sign * (hours * 60 + minutes);
}

export function formatTimezoneOffset(offset: number) {
  const hours = Math.floor(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) % 60;
  const sign = offset < 0 ? '-' : '+';
  return sign + padZero(hours) + ':' + padZero(minutes);
}

function padZero(num: number) {
  return (num < 10 ? '0' : '') + num;
}

export function generateTimeArray(startTime: string, endTime: string, step: number) {
  const times = [];

  // Parse the start and end times using the Date constructor
  const startDate = new Date(`2000-01-01T${startTime}:00`);
  const endDate = new Date(`2000-01-01T${endTime}:00`);

  // Calculate the number of milliseconds in the step
  const stepMs = step * 60 * 1000;

  // Initialize the current time to the start time
  let currentTime = startDate;

  // Loop through each time between the start and end times and add it to the array
  while (currentTime <= endDate) {
    // Get the current time as a string in the format "hh:mm"
    const timeString = currentTime.toTimeString().slice(0, 5);

    // Add the current time to the array
    times.push(timeString);

    // Add the step to the current time
    currentTime = new Date(currentTime.getTime() + stepMs);
  }

  return times;
}

/**
 * Rounds time to next closer time multiple to step param; for example step=15 rounds 00:35 to 00:45
 * @param step
 */
export function roundTime(date: Date, step: number): string {
  const minutes = date.getMinutes();
  const remainder = step - (minutes % step);
  const nextRoundedTime = new Date(date.getTime() + remainder * 60000);

  const formattedHours = String(nextRoundedTime.getHours()).padStart(2, '0');
  const formattedMinutes = String(nextRoundedTime.getMinutes()).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

export function buildDateWithTimezone(date: Date, timezoneOffset: number): number {
  // Convert timezoneOffset to milliseconds and add it to the date
  const d = moment(date);
  const r = d.utcOffset(timezoneOffset, true).valueOf();
  return r.valueOf();
}

export const formatDate = (timestamp: number | Date) => {
  const d = moment(timestamp);
  return d.format('D MMM YYYY, h:mm A');
};

export const formatDateShort = (timestamp: number | Date) => {
  const d = moment(timestamp);
  return d.format('M/D/YY, h:mm A');
};

export const formatDuration = (startTimestamp: number, endTimestamp?: number): string => {
  if (!endTimestamp) {
    return 'Ongoing';
  }
  const startDate = new Date(startTimestamp);
  const endDate = new Date(endTimestamp);
  const secondsDifference = differenceInSeconds(endDate, startDate);
  const minutesDifference = differenceInMinutes(endDate, startDate);

  if (secondsDifference < 60) {
    return `${secondsDifference}s`;
  }

  const unit = minutesDifference < 60 ? 'minute' : 'hour';
  const formattedDuration = formatDistanceStrict(startDate, endDate, {
    unit: unit,
  });

  return formattedDuration
    .replace('minutes', 'min')
    .replace('minute', 'min')
    .replace('hours', 'h')
    .replace('hour', 'h');
};

export const formatDurationFromNow = (startTimestamp: number): string => {
  const now = new Date();
  const startDate = new Date(startTimestamp);
  const secondsDifference = differenceInSeconds(now, startDate);
  const minutesDifference = differenceInMinutes(now, startDate);

  if (secondsDifference < 60) {
    return 'now';
  }

  const unit = minutesDifference < 60 ? 'minute' : minutesDifference < 1440 ? 'hour' : 'day';
  const formattedDuration = formatDistanceStrict(startDate, now, {
    unit: unit,
    addSuffix: true,
  });

  return formattedDuration
    .replace(' minutes', 'min')
    .replace(' minute', 'min')
    .replace(' hours', 'h')
    .replace(' hour', 'h');
};

// Make method similar to above but also including days
export const formatDurationInFuture = (startTimestamp: number): string => {
  const now = new Date();
  const startDate = new Date(startTimestamp);
  const secondsDifference = differenceInSeconds(startDate, now);
  const minutesDifference = differenceInMinutes(startDate, now);
  const hoursDifference = differenceInHours(startDate, now);
  const daysDifference = differenceInDays(startDate, now);

  if (secondsDifference < 60) {
    return 'now';
  }

  if (minutesDifference < 60) {
    return `in ${minutesDifference}min`;
  }

  if (hoursDifference < 24) {
    return `in ${hoursDifference}h`;
  }

  return `in ${daysDifference}d`;
};
