import { extendTheme } from '@chakra-ui/react';
import breakpoints from './breakpoints';
import foundations from './foundations';
import typography from './typography';
import ActionButtonStyles from '../components/uiKit/ActionButton/ActionButton.styles';
import TavernButtonStyle from '../components/uiKit/TavernButton/TavernButton.styles';
import RoundButtonStyle from '../components/uiKit/RoundButton/RoundButton.styles';
import ActionStreamButtonStyles from '../components/uiKit/StreamActionButton/StreamActionButton.styles';
import TavernSwichStyles from '../components/uiKit/TavernSwitch/TavernSwichStyles';
import TavernRadioStyles from '../components/uiKit/TavernRadio/TavernRadio.styles';
import TavernDropdownStyles from '../components/uiKit/TavernDropdown/TavernDropdown.styles';
import TavernTabsStyles from '../components/uiKit/TavernTabs/TavernTabs.styles';

import colors from './foundations/colors';
import TavernCheckboxStyles from '../components/uiKit/TavernCheckbox/TavernCheckbox.styles';
import TavernTextareaStyle from '../components/uiKit/TavernTextarea/TavernTextarea.styles';
import semantic from './semantic';
import { GlobalProps } from '@emotion/react';
import { mode } from '@chakra-ui/theme-tools';
import { TavernInputStyle } from '../components/uiKit/TavernInput/TavernInput.styles';
import TavernHeadingStyles from '../components/uiKit/Heading/Heading.styles';
import TavernCardStyles from '../components/TavernCard/TavernCard.styles';
import CardStyles from '../components/uiKit/Card/Card.styles';
import TavernUserStyles from '../components/uiKit/TavernUser/TavernUser.styles';
import { TavernModalStyles } from '../components/TavernModal/TavernModals.styles';
import UserDetailsStyles from '../components/UserDetails/UserDetails.styles';
import { TavernPopoverStyle } from '../components/uiKit/TavernPopover/TavernPopover.styles';
import { TavernSliderStyles } from '../components/uiKit/TavernSlider/TavernSlider.styles';
import PlayPauseButtonStyle from '../components/uiKit/PlayPauseButton/PlayPauseButton.styles';
import TavernProgressStyles from '../components/uiKit/TavernProgress/TavernProgress.styles';
import TopicTagStyles from '../components/uiKit/TopicTag/TopicTag.styles';

const styles = {
  global: (props: GlobalProps) => ({
    body: {
      fontFamily: 'body',
      bg: mode('sandy.200', 'black.1000')(props),
    },
    'body.disableScroll': {
      overflow: 'hidden',
    },
  }),
};

const theme = extendTheme(
  {
    styles,
    semanticTokens: semantic,
    colors: colors,
    ...typography,
    ...foundations,
    components: {
      Heading: TavernHeadingStyles,
      Switch: TavernSwichStyles,
      Checkbox: TavernCheckboxStyles,
      Card: CardStyles,
      Popover: TavernPopoverStyle,
      Radio: TavernRadioStyles,
      Menu: TavernDropdownStyles,
      ActionButtonStyles,
      TavernButtonStyle,
      RoundButtonStyle,
      ActionStreamButtonStyle: ActionStreamButtonStyles,
      Input: TavernInputStyle,
      TavernTextareaStyle,
      TavernCardStyles,
      Slider: TavernSliderStyles,
      Tabs: TavernTabsStyles,
      TavernUserStyles,
      Modal: TavernModalStyles,
      UserDetailsStyles,
      PlayPauseButtonStyle,
      Progress: TavernProgressStyles,
      Tag: TopicTagStyles,
    },
    config: {
      initialColorMode: 'dark',
      useSystemColorMode: false,
    },
  },
  {
    breakpoints,
    fontSizes: {},
    fontWeights: {},
  }
);

export default theme;
