import { Avatar, chakra, Flex, Highlight, Text } from '@chakra-ui/react';
import SelectOptionWithImageProps from './types';

export const SelectOptionWithImageComponent = (props: SelectOptionWithImageProps) => {
  const { image, highlight = '', label, ...rest } = props;
  return (
    <Flex {...rest}>
      <Flex flexShrink={0} w="100%">
        <Avatar objectFit="cover" boxSize="24px" borderRadius="50%" src={image} name={label} />
        <Flex w="100%" alignItems="center" _hover={{ textDecoration: 'none' }}>
          <Text whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" w="80%" ml="16px">
            <Highlight query={highlight} styles={{ bg: 'transparent', color: 'white', fontWeight: 500 }}>
              {label}
            </Highlight>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const SelectOptionWithImage = chakra(SelectOptionWithImageComponent);
