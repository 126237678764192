import {
  ActionMeta,
  chakraComponents,
  ChakraStylesConfig,
  ControlProps,
  InputProps,
  MenuProps,
  Select,
  SingleValue,
} from 'chakra-react-select';
import { Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { collection, getDocs, query } from 'firebase/firestore';

import { IconLink } from '../../theme/foundations/customIcons';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';
import { OrgSelectProps, PickerOption } from './types';
import { auth, db } from '../../connect';

const chakraStyles: ChakraStylesConfig<PickerOption, false> = {
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isSelected ? 'globalWhite' : 'typographySecondary',
    bgColor: state.isSelected ? 'backgroundInput' : 'none',
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    maxW: '120px',
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    scrollbarWidth: 'thin',
    scrollbarColor: 'typographyTertiary backgroundTertiary',
    '::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      w: '2px',
    },
    '::-webkit-scrollbar-thumb': {
      bgColor: 'typographyTertiary',
    },
    '::-webkit-scrollbar-track': {
      bgColor: 'backgroundTertiary',
    },
  }),
  container: (baseStyles) => ({
    ...baseStyles,
    display: 'flex',
    width: '100%',
    height: '48px',
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    display: 'flex',
    height: '100%',
    flex: '1 1 0',
  }),
};

const components = {
  IndicatorSeparator: () => null,
  DropdownIndicator: () => null,
  Input: ({ ...props }: InputProps<PickerOption>) => {
    return <chakraComponents.Input maxLength={4} {...props} />;
  },
  Control: ({ children, ...props }: ControlProps<PickerOption>) => (
    <chakraComponents.Control {...props}>
      <IconLink ml="10px" mr="-4px" alignSelf="center" boxSize="24px" color="typographySecondary" />
      {children}
    </chakraComponents.Control>
  ),
  Menu: ({ children, ...props }: MenuProps<PickerOption>) => {
    return (
      <chakraComponents.Menu {...props}>
        {children}
        <Flex
          position="relative"
          zIndex="5"
          alignItems="center"
          height="36px"
          pb="2px"
          borderTop="1px solid"
          borderColor="backgroundInput"
          bgColor="backgroundAdditional"
        >
          <TavernButton
            boxSize="100%"
            variant="link"
            whiteSpace="nowrap"
            w="110px"
            color="brandPrimary"
            textStyle="body14regular"
            _hover={{ textDecoration: 'none' }}
            onClick={() => {
              props.setValue({ label: 'personal', value: 'personal' }, 'select-option');
            }}
          >
            Personal
          </TavernButton>
        </Flex>
      </chakraComponents.Menu>
    );
  },
};

export const OrgPickerSelect = (props: OrgSelectProps) => {
  const { onSelect, ...rest } = props;

  const [orgs, setOrgs]: any = useState([]);

  useEffect(() => {
    if (auth.currentUser?.uid === undefined) {
      return;
    }
    getDocs(query(collection(db, `users/${auth.currentUser.uid}/orgs`)))
      .then((snapshot) => {
        setOrgs(
          snapshot.docs.map((doc) => {
            return { label: doc.id, value: doc.id };
          })
        );
      })
      .catch((error) => {
        console.error('Error getting orgs: ', error);
      });
  }, []);

  const handleChange = (option: SingleValue<PickerOption>, actionMeta: ActionMeta<PickerOption>) => {
    switch (actionMeta.action) {
      case 'select-option':
        if (option) {
          onSelect(option.value);
        }
        break;
      default:
        break;
    }
  };

  return (
    <Select
      placeholder="default"
      chakraStyles={chakraStyles}
      options={orgs}
      onChange={handleChange}
      components={components}
      {...rest}
    />
  );
};
