import { httpsCallable } from 'firebase/functions';

import { SessionType } from '../types/tavern';
import { fbFunctions } from '../connect';

export const banUserFromTavern = async (tavernId: string, userId: string) => {
  const ban = httpsCallable(fbFunctions, 'banUserFromTavern');
  await ban({
    tavernId,
    userId,
  });
};

export const unbanUserFromTavern = async (tavernId: string, userId: string) => {
  const unban = httpsCallable(fbFunctions, 'unbanUserFromTavern');
  await unban({
    tavernId,
    userId,
  });
};

export const moveSessionToType = async (sessionType: SessionType, tavernId: string, userId: string) => {
  const move = httpsCallable(fbFunctions, 'moveSessionToType');
  await move({
    sessionType,
    tavernId,
    userId,
  });
};

export const updateProfileAvatar = async (nftId?: string) => {
  const updateProfileAvatarCall = httpsCallable<{
    nftId?: string;
  }>(fbFunctions, 'updateProfileAvatar');
  await updateProfileAvatarCall({
    nftId,
  });
};

export const promoteUserInTavern = async (tavernId: string, userId: string) => {
  const promote = httpsCallable(fbFunctions, 'promoteUserInTavern');
  await promote({
    tavernId,
    userId,
  });
};

export const demoteUserInTavern = async (tavernId: string, userId: string) => {
  const demote = httpsCallable(fbFunctions, 'demoteUserInTavern');
  await demote({
    tavernId,
    userId,
  });
};
