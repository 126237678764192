import { defineStyleConfig } from '@chakra-ui/react';

const UserDetailsStyles = defineStyleConfig({
  baseStyle: (props) => ({
    pr: props.isCollapse ? '0' : '12px',
    pl: { xl: props.isCollapse ? '0' : '12px' },
    ml: props.isCollapse ? '-4px' : '0',
  }),
  sizes: {
    sm: {
      flexDirection: 'column',
    },
    lg: {
      flexDirection: { base: 'column', xl: 'row' },
    },
  },
  variants: {},
  defaultProps: {
    size: 'sm',
  },
});

export default UserDetailsStyles;
