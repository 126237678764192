import { createContext } from 'react';

import { Notification } from '../types/notification';

interface NotificationContext {
  notifications: Notification[];
  unseenCount: number;
}

export default createContext<NotificationContext>({
  notifications: [],
  unseenCount: 0,
});
