import _ from 'lodash';
import { ref, listAll, getDownloadURL } from 'firebase/storage';
import { collection, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { auth, db, storage } from '../connect';

export const getRecordingUrlForTavern = async (tavernId: string): Promise<string | null> => {
  const allFiles = await listAll(ref(storage, `taverns/${tavernId}`));
  const mp4s = allFiles.items.filter((item) => {
    return item.name.endsWith('.mp4');
  });

  if (_.isEmpty(mp4s)) {
    return null;
  }

  const result = await getDownloadURL(mp4s[0]);

  return result;
};

function addZeroPadding(value: number) {
  return value < 10 ? `0${value}` : `${value}`;
}

export function tavernFormatDuration(durationInSeconds: number) {
  const hour = Math.floor(durationInSeconds / 3600);
  const minute = Math.floor((durationInSeconds - hour * 3600) / 60);
  const second = Math.floor(durationInSeconds - hour * 3600 - minute * 60);

  return `${addZeroPadding(hour)}:${addZeroPadding(minute)}:${addZeroPadding(second)}`;
}

export const updateExternalAudioPosition = async (position: number, id: string, tavernId: string) => {
  const docRef = doc(collection(doc(db, 'taverns', tavernId), 'externalAudio'), id);
  await updateDoc(docRef, {
    position,
  });
};

export const updateExternalAudioIsPlaying = async (isPlaying: boolean, id: string, tavernId: string) => {
  const docRef = doc(collection(doc(db, 'taverns', tavernId), 'externalAudio'), id);
  await updateDoc(docRef, {
    isPlaying,
    lastControllingUser: auth.currentUser?.uid,
  });
};

export const removeExternalAudio = async (tavernId: string, id: string) => {
  const docRef = doc(collection(doc(db, 'taverns', tavernId), 'externalAudio'), id);

  await updateDoc(docRef, {
    isPlaying: false,
  });

  await deleteDoc(docRef);
};
