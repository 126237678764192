import { Link } from '../types/tavern';

const supportedVideoProviders = ['youtube.com', 'youtu.be', 'twitch.tv', 'twitch.com', 'vimeo.com'];
const twitterHosts = ['twitter.com', 'twitter.co', 't.co', 'x.com'];
const videoTypes = ['mp4', 'webm', 'ogg', 'mov', 'avi', 'wmv', 'flv', 'mkv', 'm4v', 'mpg', 'mpeg', '3gp', '3g2'];

const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];

const fileType = (filename: string): string => {
  const parts = filename.split('.');
  return parts[parts.length - 1];
};

export function isVideo(link: Link): boolean {
  if (link.type === 'storage-video') {
    return true;
  }

  if (link.type === 'storage-image') {
    return false;
  }

  const extension = fileType(link.link);
  if (videoTypes.includes(extension)) {
    return true;
  }

  try {
    return supportedVideoProviders.includes(new URL(link.link).hostname.replace(/^www\./, ''));
  } catch (error) {
    console.error(`isVideo invalid URL: ${link.link}`);
    return false;
  }
}

export function isTweetLink(link: Link): boolean {
  if (link.type !== 'url') {
    return false;
  }

  try {
    const url = new URL(link.link);

    const slugs = url.pathname.split('/');

    // This feels a bit hacky. But Twitter tweet links are in the form:
    //     https://twitter.com/username/status/1234567890123456789
    // Based on this heuristic, we assume four slug links are tweets. All others just get shown as a normal `LinkBlock`.
    // It's good enough for now.
    return twitterHosts.includes(url.hostname.replace(/^www\./, '')) && slugs.length === 4 && slugs[2] === 'status';
  } catch (error) {
    console.error(`isTweetLink invalid URL: ${link.link}`);
    return false;
  }
}

export function isImage(link: Link): boolean {
  if (link.type === 'storage-image') {
    return true;
  }

  return imageTypes.includes(fileType(link.link));
}

export function isMedia(link: Link): boolean {
  return isVideo(link) || isImage(link) || isTweetLink(link);
}

export const twitterIdFromLink = (url: string): string | null => {
  try {
    const urlCasted = new URL(url);
    const parts = urlCasted.pathname.split('/');
    return parts[parts.length - 1];
  } catch (error) {
    console.error(`twitterIdFromLink invalid URL: ${url}`);
    return null;
  }
};

export const getSharedTime = (link: Link): string => {
  const date = new Date(link.timestamp);
  return `${date.toLocaleString('en-UK', {
    hour: '2-digit',
    minute: '2-digit',
  })}`;
};
