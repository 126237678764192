import _ from 'lodash';
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  chakra,
  Flex,
  HStack,
  keyframes,
  Spacer,
  Text,
  usePrefersReducedMotion,
  useMediaQuery,
} from '@chakra-ui/react';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import accounting from 'accounting';

import CollectionsContext from '../../context/collections';
import { AllowedNftCollections } from '../AllowedNftCollections/AllowedNftCollections';
import { CardHeaderIndicator } from './CardHeaderIndicator';
import { FeaturedImage } from '../FeaturedImage/FeaturedImage';
import {
  IconCardCalendar,
  IconCardLive,
  IconCardPlay,
  IconListeners,
  IconSpeaker,
  IconTavernLogo,
} from '../../theme/foundations/customIcons';
import { ListenersListWithAvatars } from '../uiKit/ListenersListWithAvatars/ListenersListWithAvatars';
import { NftCollection } from '../../types/user';
import { Session, SessionType, TavernStatus } from '../../types/tavern';
import { TavernCardProps } from './types';
import { TopicsList } from '../uiKit/TopicsList/TopicsList';
import { UserDetailsHorizontal } from '../UserDetailsHorizontal/UserDetailsHorizontal';
import { db } from '../../connect';
import { formatDate, formatDateShort } from '../../utils/date';

const pulse = keyframes`
  from {
    transform: scale(0.85);
  }
  50% {
    transform: scale(1)
  }
  to {
    transform: scale(0.85)
  }
`;

export const TavernCardComponent = (props: TavernCardProps) => {
  const {
    title,
    host,
    status,
    buttons,
    description,
    variant = 'Grid',
    listenerCount = 0,
    footerLeft,
    isActive,
    isClickable,
    onClick,
    onTopicClick,
    tavern,
    playCount = 0,
    renderHeaderStats,
  } = props;
  const [isSmallScreen] = useMediaQuery('(max-width: 768px)');

  const [sessions, setSessions] = useState<Session[]>([]);

  const { collections } = useContext(CollectionsContext);

  const isLive = status === TavernStatus.Live;
  const isPast = status === TavernStatus.Past;
  const isUpcoming = status === TavernStatus.Upcoming;
  const tavernId = tavern?.id;

  const allowlist: NftCollection[] =
    (tavern?.joinAllowlist
      ?.map((id) => {
        return _.find(collections, (c) => c.id === id);
      })
      .filter((c) => c !== undefined) as NftCollection[]) ?? [];

  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = (delay: string): undefined | string => {
    return prefersReducedMotion ? undefined : `${pulse} infinite 4s ${delay} linear`;
  };

  const isGrid = isSmallScreen || variant === 'Grid';
  const isList = !isSmallScreen && variant === 'List';

  useEffect(() => {
    if (!tavernId) {
      return;
    }

    const sessionQuery = query(
      collection(db, `taverns/${tavernId}/sessions`),
      where('type', 'in', [SessionType.CoHost, SessionType.Speaker, SessionType.Joiner]),
      orderBy('startTime', 'desc')
    );

    const unsubscribe = onSnapshot(sessionQuery, (querySnapshot) => {
      const sessionsData = querySnapshot.docs
        .map((doc) => doc.data())
        .filter((doc) => (isLive ? !doc.endTime : true)) as Session[];

      setSessions(sessionsData);
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tavernId]);

  const cohosts = _.uniqBy(
    sessions.filter((s) => s.type === SessionType.CoHost),
    (s) => s.user.id
  );
  const speakers = _.uniqBy(
    sessions.filter((s) => s.type === SessionType.Speaker),
    (s) => s.user.id
  );
  const joiners = _.uniqBy(
    sessions.filter((s) => s.type === SessionType.Joiner),
    (s) => s.user.id
  );

  const speakingCount = cohosts.length + speakers.length + 1;
  const joinerCount = joiners.length;

  const score = accounting.formatNumber(tavern?.score || 0, 0);

  const renderHeaderIndicator = () => {
    if (isLive) {
      return <CardHeaderIndicator icon={IconCardLive} label="Live" />;
    }

    if (isUpcoming) {
      return <CardHeaderIndicator icon={IconCardCalendar} label={formatDate(tavern?.startTime || 0)} />;
    }

    if (isPast) {
      return <CardHeaderIndicator icon={IconCardCalendar} label={formatDateShort(tavern?.startTime || 0)} />;
    }
  };

  const audience = joinerCount + listenerCount;

  const renderHeaderStatsDefault = () => {
    if (isLive) {
      return [
        <CardHeaderIndicator
          icon={IconSpeaker}
          key="card-header-indicator-speakers"
          label={speakingCount.toString()}
          tooltip={`${speakingCount.toString()} speaking`}
        />,
        <Text color="gray.1000" key="card-header-indicator-speakers-spacer">
          ·
        </Text>,
        <CardHeaderIndicator
          icon={IconListeners}
          key="card-header-indicator-listeners"
          label={audience.toString()}
          tooltip={`${audience.toString()} listening`}
        />,
      ];
    }

    if (isPast) {
      return [
        <CardHeaderIndicator
          icon={IconSpeaker}
          key="card-header-indicator-speakers"
          label={speakingCount.toString()}
          tooltip={`${speakingCount.toString()} speakers`}
          textColor="accent.green"
        />,
        <Text color="gray.1000" key="card-header-indicator-speakers-spacer">
          ·
        </Text>,
        <CardHeaderIndicator
          icon={IconListeners}
          key="card-header-indicator-listeners"
          label={audience.toString()}
          tooltip={`${audience.toString()} listeners`}
          textColor="accent.green"
        />,
        <Text color="gray.1000" key="card-header-indicator-joined-spacer">
          ·
        </Text>,
        <CardHeaderIndicator
          icon={IconCardPlay}
          key="card-header-indicator-plays"
          label={playCount.toString()}
          tooltip={`${playCount.toString()} replays`}
          textColor="accent.green"
        />,
        <Text color="gray.1000" key="card-header-indicator-score-spacer">
          ·
        </Text>,
        <CardHeaderIndicator
          key="card-header-score"
          icon={IconTavernLogo}
          label={score}
          tooltip={`${score} roots`}
          textColor="accent.green"
        />,
      ];
    }
  };

  // add extra elements that are only needed in the list mode
  const addImageIfList = (children: React.ReactElement) => {
    if (isGrid) {
      return <>{children}</>;
    }

    return (
      <Flex align="stretch">
        {isList && (
          <FeaturedImage maxW="400px" width="auto" height="auto" mr="10px" tavernId={tavern.id} isListView={isList} />
        )}
        <Box flex="auto">{children}</Box>
      </Flex>
    );
  };

  return addImageIfList(
    <Card
      w="100%"
      onClick={onClick}
      variant="outline"
      position="relative"
      cursor={isClickable ? 'pointer' : 'default'}
      _hover={
        isClickable
          ? {
              _before: {
                content: "''",
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                border: '1px solid',
                bg: 'backgroundInputHover',
                borderColor: 'brandPrimary',
                pointerEvents: 'none',
              },
            }
          : undefined
      }
      _before={{
        content: isActive ? '""' : 'none',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        border: '1px solid',
        borderColor: 'brandPrimary',
        pointerEvents: 'none',
      }}
    >
      {isActive && (
        <>
          <Box
            animation={animation('0.1s')}
            pointerEvents="none"
            border="1px solid"
            borderColor="brandPrimary"
            opacity="0.3"
            position="absolute"
            zIndex="-1"
            top="-6px"
            left="-6px"
            right="-6px"
            bottom="-6px"
          />
          <Box
            animation={animation('0.3s')}
            pointerEvents="none"
            border="1px solid"
            borderColor="brandPrimary"
            opacity="0.1"
            position="absolute"
            zIndex="-1"
            top="-12px"
            left="-12px"
            right="-12px"
            bottom="-12px"
          />
        </>
      )}
      <CardHeader pl="24px" pr="24px" pb={0}>
        <Flex>
          <HStack spacing={2} justify="space-between" w="100%">
            {renderHeaderIndicator()}

            <HStack>
              {renderHeaderStats && typeof renderHeaderStats === 'function'
                ? renderHeaderStats()
                : renderHeaderStatsDefault()}
            </HStack>
          </HStack>

          <Spacer />
          {isList && (
            <UserDetailsHorizontal
              ml="16px"
              display={{ base: 'none', xl: 'flex' }}
              {...host}
              postfix="Host"
              isAvatarBordered
            />
          )}
        </Flex>
        {isGrid && <FeaturedImage mt="16px" tavernId={tavern.id} />}
      </CardHeader>

      <CardBody pl="24px" pr="24px" pt="12px">
        <Flex alignItems="flex-end">
          <Box w="100%" pr="24px">
            <Box
              display="-webkit-box"
              css={`
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              `}
              overflow="hidden"
              text-overflow="ellipsis"
              w="100%"
              mt={isList ? { base: 0 } : 0}
              mb="8px"
              textStyle="h3"
            >
              {title}
            </Box>
            <AllowedNftCollections collections={allowlist} maxVisible={1} mb="14px" />
            {description && (
              <Box textStyle="body14regular" color="typographySecondary" mb="10px" wordBreak="break-all">
                {description}
              </Box>
            )}
            <TopicsList
              topics={
                tavern.topicNames?.map((name, i) => {
                  return { name, id: i.toString() };
                }) ?? []
              }
              onTopicClick={onTopicClick}
              isClickable
              mb={isGrid ? '12px' : 0}
            />
          </Box>
          {isList && (
            <>
              <Spacer />
              <Box display={{ base: 'none', xl: 'flex' }}>
                <ListenersListWithAvatars
                  listenerCount={listenerCount}
                  joiners={joiners}
                  speakers={speakers}
                  cohosts={cohosts}
                />
              </Box>
            </>
          )}
        </Flex>
        {buttons && isGrid && <Box>{buttons}</Box>}
      </CardBody>

      <CardFooter pl="24px" pr="24px" pt={0}>
        <Box w="100%">
          <Flex alignItems="center" justifyContent="space-between">
            {isGrid && <UserDetailsHorizontal isAvatarBordered {...host} postfix="Host" />}
            {footerLeft && isGrid && footerLeft}
          </Flex>
          {isList && (
            <Flex flexDirection={{ base: 'column', xl: 'row' }} alignItems={{ base: 'flex-start', xl: 'center' }}>
              <Spacer />
              {buttons && (
                <Flex justifyContent={{ base: 'flex-start', xl: 'flex-end' }} w="100%">
                  {buttons}
                </Flex>
              )}
              {footerLeft && (
                <Flex
                  w="100%"
                  mt="20px"
                  alignItems="center"
                  justifyContent="space-between"
                  display={{ base: 'flex', xl: 'none' }}
                >
                  <UserDetailsHorizontal
                    display={{ base: 'flex', xl: 'none' }}
                    {...host}
                    isVertical={false}
                    postfix="Host"
                  />
                  {footerLeft}
                </Flex>
              )}
            </Flex>
          )}
        </Box>
      </CardFooter>
    </Card>
  );
};

export const TavernCard = chakra(TavernCardComponent);
