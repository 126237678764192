/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Avatar, Flex, Link, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { FollowUnfollowButton } from '../FollowUnfollowButton/FollowUnfollowButton';
import { IconProfile, IconTwitter, IconWarpcast } from '../../theme/foundations/customIcons';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';
import { TavernModal } from '../TavernModal/TavernModal';
import { UserModalType } from './types';
import { getUserImage } from '../../utils/user-util';

export const UserModal: FC<UserModalType> = ({ user, isOpen, onClose }) => {
  const navigate = useNavigate();

  const avatarBg = useColorModeValue('white', 'black');

  // TODO: since the session doesn't hold additional user data, we should look up the user
  const twitter = user?.provider === 'twitter' ? user.username : '';
  const farcaster = user?.provider === 'farcaster' ? user.username : '';

  return (
    <TavernModal isOpen={isOpen} isCloseButtonVisible onClose={onClose}>
      <Flex flexDirection="column" width="100%">
        <Flex mb="20px" width="100%" justifyContent="center" alignItems="center" position="relative">
          <Avatar
            ml="auto"
            mr="auto"
            borderRadius="50%"
            boxSize={{ xl: '96px', sm: '80px' }}
            src={getUserImage(user)}
            // Set icon and bg to keep Avatar from rendering
            // bright green fallback.
            icon={<></>}
            bg={avatarBg}
            border="2px solid white"
          />
        </Flex>
        <Flex alignItems="center" ml="auto" mr="auto">
          <Text
            maxWidth="106px"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            textStyle="body16regular"
            _hover={{
              cursor: 'pointer',
              color: 'brandPrimary',
            }}
            onClick={() => {
              navigate(`/profiles/${user.id}`);
            }}
          >
            {user.name || user.id}
          </Text>
          {twitter && (
            <Link display="flex" boxSize="16px" ml="8px" href={`https://twitter.com/${twitter}`} target="_blank">
              <IconTwitter boxSize="16px" color="typographySecondary" />
            </Link>
          )}
          {farcaster && (
            <Link display="flex" boxSize="16px" ml="8px" href={`https://warpcast.com/${farcaster}`} target="_blank">
              {IconWarpcast}
            </Link>
          )}
        </Flex>
        <VStack mt="24px" width="100%" justify="center" align="center" spacing={4}>
          {!user.isAnon && <FollowUnfollowButton userId={user.id} style={{ marginRight: '16px' }} />}
          <TavernButton
            variant="tertiary"
            leftIcon={<IconProfile />}
            onClick={() => {
              navigate(`/profiles/${user.id}`);
            }}
          >
            Go to Profile
          </TavernButton>
        </VStack>
      </Flex>
    </TavernModal>
  );
};
