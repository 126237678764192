import _ from 'lodash';
import { collection, doc, getDoc, onSnapshot, query, where } from 'firebase/firestore';

import { Session, SessionType, SpeakRequest, TavernHost } from '../types/tavern';
import { ProdUser, User } from '../types/user';
import { db } from '../connect';
import { notEmpty } from './not-empty';

export const userIdFromSession = (session: Session): string => {
  const nodeSegments = _.get(session, 'user._key.path.segments', []);
  return String(_.last(nodeSegments));
};

export const userIdFromRequest = (request: SpeakRequest): string => {
  const nodeSegments = _.get(request, 'user._key.path.segments', []);
  return String(_.last(nodeSegments));
};

export const getUserFromUserId = async (userId: string): Promise<User> => {
  const userDoc = await getDoc(doc(collection(db, 'users'), userId));
  return userDoc.data() as User;
};

export const userCanBanOrKick = (userType: SessionType, targetSessionType: SessionType) => {
  if (
    targetSessionType === SessionType.Speaker ||
    targetSessionType === SessionType.Listener ||
    targetSessionType === SessionType.Joiner
  ) {
    return userType === SessionType.Host || userType === SessionType.CoHost;
  }

  if (targetSessionType === SessionType.CoHost) {
    return userType === SessionType.Host;
  }

  return false;
};

// We will need to build in paging for this later on when we have popular users.
export const usersFollowersSubscription = (userId: string, callback: (users: User[]) => void) => {
  return onSnapshot(query(collection(db, 'users'), where('following', 'array-contains', userId)), (snapshot) =>
    callback(snapshot.docs.map((follower) => follower.data() as User).filter(notEmpty))
  );
};

export const usersFollowingSubscription = (followingIds: string[], callback: (users: User[]) => void) => {
  if (followingIds.length === 0) {
    callback([]);
    return;
  }

  const batchSize = 30;
  const unsubscribes: any = [];

  const chunks = _.chunk(followingIds, batchSize);

  let combinedUsers: User[] = [];

  chunks.forEach((chunk, index) => {
    const unsubscribe = onSnapshot(query(collection(db, 'users'), where('id', 'in', chunk)), (snapshot) => {
      const currentBatchUsers = snapshot.docs.map((userFollowing) => userFollowing.data() as User).filter(notEmpty);
      combinedUsers = [...combinedUsers, ...currentBatchUsers];

      if (index === chunks.length - 1) {
        callback(combinedUsers);
      }
    });

    unsubscribes.push(unsubscribe);
  });

  return () => {
    unsubscribes.forEach((unsub: any) => unsub());
  };
};

export const getUserImage = (user?: ProdUser | User | TavernHost | null) => {
  if (!user) {
    return '';
  }

  return _.get(user, 'session.nft') || user.nft || user.avatar || '';
};
