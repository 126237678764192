import { Box, HStack } from '@chakra-ui/react';
import { useState, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel } from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';
import _ from 'lodash';
import { collection, doc, setDoc } from 'firebase/firestore';

import { IconSearch } from '../../../theme/foundations/customIcons';
import { TavernInput } from '../../uiKit/TavernInput/TavernInput';
import { db } from '../../../connect';
import GlobalPlayerContext from '../../../context/global-player-context';
import TavernDetailContext from '../../../context/tavern-detail-context';

const gf = new GiphyFetch('taPEbS55YOOCSL56rPTHADATlxKp6R38');

export const BarSection = () => {
  const { tavernId } = useParams();
  const [searchQuery, setSearchQuery] = useState('');

  const { userSession } = useContext(GlobalPlayerContext);
  const { gifs } = useContext(TavernDetailContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleGif = async (id: string) => {
    setSearchQuery('');

    if (userSession === null) {
      return;
    }

    const reaction = doc(collection(doc(collection(db, 'taverns'), tavernId), 'gifs'));
    const sessionRef = doc(collection(doc(collection(db, 'taverns'), tavernId), 'sessions'), userSession.id);

    setIsLoading(true);

    await setDoc(reaction, {
      id,
      session: sessionRef,
      timestamp: Date.now(),
    });

    setIsLoading(false);
  };

  const orderedGifs = _.chain(gifs)
    .groupBy('id')
    .map((items, id) => {
      const maxItem = _.maxBy(items, 'timestamp');
      return {
        id,
        count: items.length,
        timestamp: maxItem ? maxItem.timestamp : undefined,
      };
    })
    .orderBy(['timestamp'], ['desc'])
    .value();

  const fetchGifsByIds: any = useCallback(
    async (offset = 0, limit = 10) => {
      try {
        const selectedIds = orderedGifs.map((g) => g.id).slice(offset, offset + limit);

        if (selectedIds.length) {
          const response = await gf.gifs(selectedIds);

          return {
            data: response.data.map((gifData: any) => ({
              ...gifData,
              count: orderedGifs.find((g) => g.id === gifData.id)?.count || 0,
            })),
            pagination: {
              total_count: orderedGifs.length,
              count: response.data.length,
              offset,
            },
          };
        }

        return { data: [], pagination: { total_count: 0, count: 0, offset: 0 } };
      } catch (error) {
        console.error('Error fetching GIFs by IDs:', error);
        return { data: [], pagination: { total_count: 0, count: 0, offset: 0 } };
      }
    },
    [orderedGifs]
  );

  return (
    <Box>
      <Carousel
        gifHeight={200}
        gutter={6}
        fetchGifs={(offset) => fetchGifsByIds(offset)}
        noLink
        key={gifs.map((g) => g.id).join(',')}
        noResultsMessage="It's a bit empty in here..."
        onGifVisible={(gif: any, e) => {
          if (e) {
            const element = e.target as HTMLElement;
            const overlay = document.createElement('div');
            overlay.innerText = `${gif.count as string}`;

            Object.assign(overlay.style, {
              position: 'absolute',
              top: '10px',
              right: '10px',
              backgroundColor: 'black',
              color: 'white',
              padding: '5px 10px',
              borderRadius: '20px',
              fontSize: '0.9em',
              fontWeight: 'bold',
              zIndex: '10',
            });

            element.style.position = 'relative';
            element.parentNode?.appendChild(overlay);
          }
        }}
      />
      {userSession && (
        <>
          <Box mb="24px" mt="24px">
            <HStack justifyContent="flex-start">
              <TavernInput
                leftIcon={<IconSearch />}
                value={searchQuery}
                placeholder="Search Giffy"
                onChange={(e) => setSearchQuery(e.target.value)}
                isDisabled={isLoading}
              />
            </HStack>
          </Box>
          <Carousel
            gifHeight={200}
            gutter={6}
            fetchGifs={(offset: number) => gf.search(searchQuery, { offset, limit: 10 })}
            key={searchQuery}
            noLink
            onGifClick={(gif: any, event) => {
              event.stopPropagation();
              handleGif(gif.id).catch(console.error);
            }}
            noResultsMessage={searchQuery ? 'Nothing Found' : ''}
          />
        </>
      )}
    </Box>
  );
};
