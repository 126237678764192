import React, { FC, useContext, useEffect, useState } from 'react';
import { Box, Highlight, Link, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import AuthContext from '../../context/auth';
import GlobalPlayerContext from '../../context/global-player-context';
import NotificationsContext from '../../context/notifications';
import {
  FollowedByUserNotification,
  FolloweeTavernJoinNotification,
  FolloweeTavernStartNotification,
  NotificationType,
  UserGoingTavernStartNotification,
} from '../../types/notification';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';
import { getTavernById } from '../../utils/tavern-util';
import { getUserFromUserId } from '../../utils/user-util';
import { markNotificationAsSeen } from '../../services/notifications';
import { useTavernToast } from '../hooks/useTavernToast';

interface TavernNotificationsWrapperProps {
  children: React.ReactNode;
}

// Shows toast notification when new notification is arrived
export const TavernNotificationsWrapper: FC<TavernNotificationsWrapperProps> = (props) => {
  const { children } = props;

  const toast = useTavernToast();
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  const { connectedTavern } = useContext(GlobalPlayerContext);
  const { notifications } = useContext(NotificationsContext);

  const [lastNotificationToastTimestamp, setLastNotificationToastTimestamp] = useState<number>(Date.now());

  const getToastId = (type: NotificationType, contentId: string) => {
    return `notification-${type.toString()}-${contentId}`;
  };

  const showGoingToTavernNotificationToast = async (
    notificationId: string,
    tavernId: string,
    type: NotificationType
  ) => {
    try {
      const tavern = await getTavernById(tavernId);
      if (!tavern) {
        return;
      }

      const isHost = tavern.host.id === user?.id;
      const toastId = getToastId(type, tavernId);

      if (!isHost) {
        showToast(
          toast,
          "Tavern you're going to has started",
          toastId,
          notificationId,
          <Box>
            <Box mb="8px">
              <Highlight
                query={tavern.title}
                styles={{ color: 'typographyPrimary' }}
              >{`Tavern ${tavern.title} just started`}</Highlight>
            </Box>

            {connectedTavern?.id !== tavern.id ? (
              <TavernButton
                variant="primary"
                onClick={() => {
                  toast.close(toastId);
                  markNotificationAsSeen(notificationId).catch(console.error);
                  navigate(`/taverns/${tavernId}`);
                }}
              >
                Go to Tavern
              </TavernButton>
            ) : (
              <></>
            )}
          </Box>
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const showFollowedByUserNotificationToast = async (notificationId: string, followerId: string) => {
    try {
      const follower = await getUserFromUserId(followerId);
      if (!follower) {
        return;
      }

      const toastId = getToastId(NotificationType.FollowedByUser, followerId);

      showToast(
        toast,
        'New Follower',
        toastId,
        notificationId,
        <Box mb="8px" maxW="282px">
          <Text>You have a new follower,</Text>
          <Link
            color="typographyPrimary"
            style={{ wordWrap: 'break-word' }}
            onClick={() => {
              toast.close(toastId);
              markNotificationAsSeen(notificationId).catch(console.error);
              navigate(`/profiles/${follower.id}`);
            }}
          >
            {follower.name ?? follower.id}
          </Link>
        </Box>
      );
    } catch (err) {
      console.error(err);
    }
  };

  const showFolloweeTavernStartToast = async (notificationId: string, tavernId: string, followeeId: string) => {
    try {
      const tavern = await getTavernById(tavernId);
      const followee = await getUserFromUserId(followeeId);
      if (!tavern || !followee) {
        return;
      }

      const toastId = getToastId(NotificationType.FolloweeTavernStart, tavernId);

      showToast(
        toast,
        'Tavern has started',
        toastId,
        notificationId,
        <Box>
          <Box mb="8px">
            <Highlight query={tavern.title} styles={{ color: 'typographyPrimary' }}>{`Tavern ${
              tavern.title
            } just started by ${followee.name ?? followee.id}`}</Highlight>
          </Box>

          {connectedTavern?.id !== tavern.id ? (
            <TavernButton
              variant="primary"
              onClick={() => {
                toast.close(toastId);
                markNotificationAsSeen(notificationId).catch(console.error);
                navigate(`/taverns/${tavernId}`);
              }}
            >
              Go to Tavern
            </TavernButton>
          ) : (
            <></>
          )}
        </Box>
      );
    } catch (err) {
      console.error(err);
    }
  };

  const showFolloweeTavernJoinToast = async (notificationId: string, tavernId: string, followeeId: string) => {
    try {
      const tavern = await getTavernById(tavernId);
      const followee = await getUserFromUserId(followeeId);
      if (!tavern || !followee) {
        return;
      }

      const toastId = getToastId(NotificationType.FolloweeTavernStart, tavernId);

      showToast(
        toast,
        `Joined a Tavern`,
        toastId,
        notificationId,
        <Box>
          <Box mb="8px">
            <Highlight query={tavern.title} styles={{ color: 'typographyPrimary' }}>{`${
              followee.name ?? followee.id
            } joined ${tavern.title}`}</Highlight>
          </Box>

          {connectedTavern?.id !== tavern.id ? (
            <TavernButton
              variant="primary"
              onClick={() => {
                toast.close(toastId);
                markNotificationAsSeen(notificationId).catch(console.error);
                navigate(`/taverns/${tavernId}`);
              }}
            >
              Go to Tavern
            </TavernButton>
          ) : (
            <></>
          )}
        </Box>
      );
    } catch (err) {
      console.error(err);
    }
  };

  // Show toasts when new notification appears
  // Mark notification as seen if user closes it himself
  useEffect(() => {
    // get notifications that are older then the last toast
    const toastNotifs = notifications.filter((notif) => {
      return notif.timestamp > lastNotificationToastTimestamp;
    });

    if (toastNotifs.length) {
      const oldest = Math.max(...toastNotifs.map((notif) => notif.timestamp));
      setLastNotificationToastTimestamp(oldest);
    }

    toastNotifs.forEach((notif) => {
      switch (notif.type) {
        case NotificationType.UserGoingTavernStart: {
          const casted = notif as UserGoingTavernStartNotification;
          showGoingToTavernNotificationToast(casted.id, casted.tavern.id, casted.type).catch(console.error);
          break;
        }
        case NotificationType.FollowedByUser: {
          const casted = notif as FollowedByUserNotification;
          showFollowedByUserNotificationToast(casted.id, casted.followerId).catch(console.error);
          break;
        }
        case NotificationType.FolloweeTavernStart: {
          const casted = notif as FolloweeTavernStartNotification;
          showFolloweeTavernStartToast(casted.id, casted.tavern.id, casted.followee.id).catch(console.error);
          break;
        }
        case NotificationType.FolloweeTavernJoin: {
          const casted = notif as FolloweeTavernJoinNotification;
          showFolloweeTavernJoinToast(casted.id, casted.tavern.id, casted.followee.id).catch(console.error);
          break;
        }
      }
    });
  }, [notifications, user]);

  return <>{children}</>;
};

const showToast = (toast: any, title: string, toastId: string, notificationId: string, description: JSX.Element) => {
  toast({
    status: 'success',
    position: 'top-right',
    title,
    size: 'sm',
    id: toastId,
    description,
    duration: 5000,
    isClosable: true,
    onUserClose: () => {
      markNotificationAsSeen(notificationId).catch(console.error);
    },
  });
};
