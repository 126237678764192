import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import React from 'react';
import { TavernList } from './components/lists/tavern-list';
import { TavernStatus } from './types/tavern';
import { ProfileDetail } from './screens/profile-detail';
import { Roots } from './screens/roots';
import { TavernLanding } from './screens/tavern-landing';
import { TavernDetail } from './screens/tavern-detail';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import { TavernForm } from './components/TavernForm/TavernForm';
import { TavernEdit } from './components/TavernForm/TavernEdit';
import { Notifications } from './screens/notifications';
import { SettingsScreen } from './screens/settings-screen';
import { Layout } from './components/Layout/Layout';

export const Router = ({ children }: { children: React.ReactNode }) => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '',
          element: <TavernLanding />,
        },
        {
          path: 'roots',
          element: <Roots />,
        },
        {
          path: 'profiles/:uid',
          element: <ProfileDetail />,
        },
        {
          path: 'taverns',
          element: <TavernList status={TavernStatus.Live} />,
        },
        {
          path: 'taverns/:tavernId',
          element: <TavernDetail />,
        },
        {
          path: 'taverns/:tavernId/edit',
          element: (
            <ProtectedRoute>
              <TavernEdit />
            </ProtectedRoute>
          ),
        },
        {
          path: 'create',
          element: (
            <ProtectedRoute>
              <TavernForm />
            </ProtectedRoute>
          ),
        },
        {
          path: 'upcoming',
          element: <TavernList status={TavernStatus.Upcoming} />,
        },
        {
          path: 'past',
          element: <TavernList status={TavernStatus.Past} />,
        },
        {
          path: 'notifications',
          element: (
            <ProtectedRoute>
              <Notifications />
            </ProtectedRoute>
          ),
        },
        {
          path: 'settings',
          element: (
            <ProtectedRoute>
              <SettingsScreen />
            </ProtectedRoute>
          ),
        },
        {
          path: '*', // Catch-all route
          element: <Navigate to="/" replace />,
        },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
      <>{children}</>
    </>
  );
};
