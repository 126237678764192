import { forwardRef, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { TavernInputProps } from './types';

export const TavernInput = forwardRef<TavernInputProps, 'input'>(({ placeholder, leftIcon, variant, ...rest }, ref) => {
  return (
    <InputGroup ref={ref}>
      {leftIcon && variant !== 'link' && (
        <InputLeftElement fontSize="2xl" h="100%" color="typographyTertiary">
          {leftIcon}
        </InputLeftElement>
      )}
      {variant === 'link' && (
        <InputLeftElement h="100%" w="85px" p="0 4px 0 12px" color="typographyTertiary">
          https://
        </InputLeftElement>
      )}
      <Input placeholder={placeholder} variant={variant} {...rest} />
    </InputGroup>
  );
});
