/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, HStack, Spinner, Text } from '@chakra-ui/react';
import { isNil, isNaN } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';

import GlobalPlayerContext from '../../context/global-player-context';
import { PlayPauseButton } from '../uiKit/PlayPauseButton/PlayPauseButton';
import { PlaybackControls } from '../uiKit/PlaybackControls/PlaybackControls';
import { TavernSlider } from '../uiKit/TavernSlider/TavernSlider';
import { noop } from '../../utils/noop';
import { tavernFormatDuration } from '../../utils/player-util';

const newSpeedFromClick = (currentSpeed: number) => {
  switch (currentSpeed) {
    case 1:
      return 1.5;
    case 1.5:
      return 2;
    case 2:
      return 1;
    default:
      return 1;
  }
};

export const RecordedTavernPlayer = () => {
  const { recordedPlayState, setRecordedPlayState, recordedTavernProgress, setRecordedTavernProgress } =
    useContext(GlobalPlayerContext);

  const url = recordedPlayState?.url;
  const newProgress = recordedPlayState?.newProgress;
  const isPlaying = recordedPlayState?.isPlaying || false;

  const [noAudioFound, setNoAudioFound] = useState<boolean>(true);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);

  const audio = useRef<HTMLAudioElement | undefined>(undefined);
  const updateScrubberRef = useRef<NodeJS.Timeout>();

  const startTimer = () => {
    stopTimer();
    updateScrubberRef.current = setInterval(() => {
      if (audio?.current) {
        setCurrentProgress(audio.current.currentTime);
      }
    }, 100);
  };

  const stopTimer = () => {
    clearInterval(updateScrubberRef.current);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const seekToTime = params.get('t');
    if (seekToTime && audio.current) {
      const timeSeekSeconds = parseFloat(seekToTime);
      audio.current.currentTime = timeSeekSeconds;
    }
  }, [audio.current]);

  // Create new audio if url changed
  useEffect(() => {
    if (url) {
      audio.current = new Audio(url);
      audio.current.load();
      audio.current.addEventListener('canplaythrough', () => {
        if (recordedPlayState?.initiatePlayback) {
          handleStartPlay();
        }

        setIsPlaying(true);
        startTimer();
        setNoAudioFound(false);
      });
    } else {
      stopTimer();
      audio.current = undefined;
      setNoAudioFound(true);
    }

    return () => {
      stopTimer();
      if (audio?.current) {
        audio.current?.pause();
        setIsPlaying(false);
      }
    };
  }, [url]);

  useEffect(() => {
    if (audio && audio.current && !isNil(newProgress)) {
      audio.current.currentTime = newProgress;
      audio.current
        .play()
        .then(noop)
        .catch((e) => {
          console.error(e);
          setIsPlaying(false);
        });
    }
  }, [newProgress]);

  useEffect(() => {
    if (!audio || !audio.current) {
      return;
    }

    if (isPlaying) {
      audio.current
        .play()
        .then(noop)
        .catch((e) => {
          console.error(e);
          setIsPlaying(false);
        });
    } else {
      audio.current.pause();
    }
  }, [isPlaying]);

  const setCurrentProgress = (value: number) => {
    if (recordedPlayState) {
      setRecordedTavernProgress(value);
    }
  };

  const handleStartPlay = () => {
    if (recordedPlayState && audio.current) {
      // TODO: if it's the host, and the tavern doesn't already have a duration, update the record
      setRecordedPlayState({
        ...recordedPlayState,
        initiatePlayback: false,
        isPlaying: true,
        duration: audio.current?.duration || 0,
      });
    }
    startTimer();
  };

  const setIsPlaying = (value: boolean) => {
    if (recordedPlayState && audio.current) {
      setRecordedPlayState({
        ...recordedPlayState,
        isPlaying: value,
        duration: audio.current?.duration || 0,
      });
    }
  };

  const totalDuration = isNaN(audio.current?.duration) ? 0 : audio.current?.duration ?? 0;

  const toggleIsPlaying = () => {
    if (isPlaying) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  };

  const handleContinuePlaying = async () => {
    if (isPlaying) {
      await audio.current?.play();
    }
    startTimer();
  };

  return noAudioFound ? (
    <HStack>
      <Text>Generating recording</Text>
      <Spinner />
    </HStack>
  ) : (
    <Flex alignItems="center" minH="35px" width="100%" minWidth="0">
      <PlayPauseButton isPlaying={isPlaying} onClick={toggleIsPlaying} size="xs" />

      <Box w="100%" minWidth="0px" m="0 8px">
        <Flex alignItems="center">
          <Text textStyle="body10regular" color="typographySecondary">
            {tavernFormatDuration(recordedTavernProgress)}
          </Text>

          <Box w="100%" m="0 12px">
            <TavernSlider
              max={totalDuration}
              value={recordedTavernProgress}
              onChangeStart={() => {
                clearInterval(updateScrubberRef.current);
                audio.current?.pause();
              }}
              onChangeEnd={() => {
                handleContinuePlaying().catch(console.error);
              }}
              onChange={(value) => {
                if (audio.current) {
                  audio.current.currentTime = value;
                }
              }}
            />
          </Box>

          <Text textStyle="body10regular" color="typographySecondary">
            -{tavernFormatDuration(totalDuration - recordedTavernProgress)}
          </Text>

          <Box ml="12px">
            <PlaybackControls
              speed={playbackSpeed}
              onSpeedClick={() => {
                const newSpeed = newSpeedFromClick(playbackSpeed);
                if (audio.current) {
                  audio.current.playbackRate = newSpeed;
                }
                setPlaybackSpeed(newSpeed);
              }}
              onSkipBackClick={() => {
                if (audio.current) {
                  audio.current.currentTime -= 10;
                }
              }}
              onSkipForwardClick={() => {
                if (audio.current) {
                  audio.current.currentTime += 10;
                }
              }}
            />
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};
