/* eslint-disable */
import { KeyboardEvent } from 'react';
import { ButtonProps } from '@chakra-ui/button';
import { DateObj } from 'dayzed';

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const WEEKDAYS_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export interface SingleDatepickerProps extends DatepickerProps {
  date?: Date;
  onDateChange: (date: Date) => void;
  configs?: DatepickerConfigs;
  disabled?: boolean;
  defaultIsOpen?: boolean;
  closeOnSelect?: boolean;
  id?: string;
  usePortal?: boolean;
}

export const DefaultConfigs: CalendarConfigs = {
  dateFormat: 'yyyy-MM-dd',
  monthNames: MONTH_NAMES,
  dayNames: WEEKDAYS_SHORT,
  firstDayOfWeek: 0,
};

export interface ArrowKeysReactConfig {
  left?: () => void;
  right?: () => void;
  up?: () => void;
  down?: () => void;
}

export type OnDateSelected = (selectedDate: DateObj, event: React.SyntheticEvent<Element, Event>) => void;

export interface DatepickerProps {
  minDate?: Date;
  maxDate?: Date;
}

export interface DayOfMonthBtnStyleProps {
  defaultBtnProps?: ButtonProps;
  isInRangeBtnProps?: ButtonProps;
  selectedBtnProps?: ButtonProps;
  todayBtnProps?: ButtonProps;
  nextMonthProps?: ButtonProps;
  prevMonthProps?: ButtonProps;
}

export interface DatepickerConfigs {
  dateFormat?: string;
  monthNames?: string[];
  dayNames?: string[];
  firstDayOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
}

export interface CalendarConfigs {
  dateFormat: string;
  monthNames: string[];
  dayNames: string[];
  firstDayOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6;
}

export class ArrowKeysReact {
  config: ArrowKeysReactConfig;

  constructor(config: ArrowKeysReactConfig) {
    this.config = config;
  }

  getEvents() {
    return {
      onKeyDown: (e: KeyboardEvent) => {
        switch (e.key) {
          case 'ArrowDown':
            this.config.down && this.config.down();
            break;
          case 'ArrowLeft':
            this.config.left && this.config.left();
            break;
          case 'ArrowRight':
            this.config.right && this.config.right();
            break;
          case 'ArrowUp':
            this.config.up && this.config.up();
            break;
        }
      },
    };
  }
}
