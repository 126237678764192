import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { OrderSortingPanelProps, SortingValue } from './types';
import { noop } from '../../utils/noop';
import { IconChevronRight } from '../../theme/foundations/customIcons';

interface SortingFieldProps {
  isSelected: boolean;
  field: OrderSortingPanelProps['fields'][number];
}

const SortingField = (props: SortingFieldProps) => {
  const { field, isSelected } = props;
  return (
    <Box
      cursor="pointer"
      color={isSelected ? 'typographyPrimary' : 'typographySecondary'}
      _hover={{
        color: 'typographyPrimary',
      }}
    >
      <HStack spacing="4px">
        <Box>{field.name}</Box>
        <Flex as="div" minWidth="14px" alignItems="center">
          {isSelected && (
            <>
              {field.value === SortingValue.DESC && <IconChevronRight transform="rotate(90deg)" />}

              {field.value === SortingValue.ASC && <IconChevronRight transform="rotate(-90deg)" />}
            </>
          )}
        </Flex>
      </HStack>
    </Box>
  );
};

export const OrderSortingPanel = (props: OrderSortingPanelProps) => {
  const { fields, selected, onChange = noop, ...rest } = props;

  const toggleField = (currentValue: SortingValue) => {
    if (currentValue === SortingValue.ASC) {
      return SortingValue.DESC;
    }

    return SortingValue.ASC;
  };

  const handleFieldClick = (field: OrderSortingPanelProps['fields'][number]) => {
    if (isActiveField(field.name)) {
      onChange(field.name, toggleField(field.value));
    } else {
      onChange(field.name, field.value);
    }
  };

  const isActiveField = (name: string) => {
    if (!selected) {
      return false;
    }
    return selected.name === name;
  };

  return (
    <Box {...rest}>
      <Flex flexDirection="row" flexWrap="wrap">
        <Text color="typographySecondary" textStyle="body14regular" pr="16px">
          Sort by:
        </Text>
        <HStack spacing="16px" textStyle="body14regular">
          {fields.map((field, i) => (
            <Box key={i} onClick={() => handleFieldClick(field)}>
              <SortingField field={field} isSelected={isActiveField(field.name)} />
            </Box>
          ))}
        </HStack>
      </Flex>
    </Box>
  );
};
