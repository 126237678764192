import {
  Box,
  Flex,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { collection, DocumentReference, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';

import AuthContext from '../context/auth';
import GlobalPlayerContext from '../context/global-player-context';
import TavernDetailContext from '../context/tavern-detail-context';
import { DestroyTavernButton } from '../components/buttons/destroy-tavern-button';
import { EditTavernButton } from '../components/buttons/edit-tavern-button';
import { FeaturedImage } from '../components/FeaturedImage/FeaturedImage';
import { GoingSection } from '../components/TavernPage/GoingSection/GoingSection';
import { LocalUploadPopover } from '../components/LocalUploadPopover/LocalUploadPopover';
import { MediaCarouselContainer } from '../components/MediaCarouselContainer/MediaCarouselContainer';
import { ScheduledTavernStats } from '../components/TavernPage/ScheduledTavernStats/ScheduledTavernStats';
import { SessionType, Tavern } from '../types/tavern';
import { SetReminderTavernButton } from '../components/buttons/set-reminder-tavern-button';
import { SpeakersSection } from '../components/TavernPage/SpeakersSection/SpeakersSection';
import { StartJoinTavernButton } from '../components/buttons/start-join-tavern-button';
import { TavernTabs } from '../components/uiKit/TavernTabs/TavernTabs';
import { TopicsList } from '../components/uiKit/TopicsList/TopicsList';
import { User } from '../types/user';
import { db } from '../connect';
import { getUserImage } from '../utils/user-util';
import { LiveAddLinksModal } from '../components/LiveAddLinksModal/LiveAddLinksModal';

interface ScheduledTavernDetailsProps {
  tavern: Tavern;
}

export type Going = {
  id: string;
  user: DocumentReference;
};

export const ScheduledTavernDetail = (props: ScheduledTavernDetailsProps) => {
  const { tavern } = props;
  const { title, description, id } = tavern;
  const { userSession } = useContext(GlobalPlayerContext);
  const { topics, maxSpeakers } = useContext(TavernDetailContext);
  const { user } = useContext(AuthContext);
  const [goingUsers, setGoingUsers] = useState<User[]>([]);
  const [promotedUsers, setPromotedUsers] = useState<User[]>([]);

  const isHost = tavern.host.id === user?.id;
  const isCoHost = Boolean(user?.id && tavern.coHosts?.includes(user.id));

  useEffect(() => {
    if (!id) {
      return;
    }

    const goingQuery = query(collection(db, 'taverns', id, 'going'));
    const unsub = onSnapshot(goingQuery, (d) => {
      const users = d.docs.map((doc) => doc.data());

      if (!users.length) {
        setGoingUsers([]);
        return;
      }

      const fetchUsersInBatches = async (gUsers: any) => {
        const batchSize = 30;
        let allUsers: any = [];

        for (let i = 0; i < gUsers.length; i += batchSize) {
          const currentBatch = gUsers.slice(i, i + batchSize).map((u: any) => u.user.id);
          const usersQuery = query(collection(db, 'users'), where('id', 'in', currentBatch));

          try {
            const res = await getDocs(usersQuery);
            const remoteUsers = res.docs.map((doc) => {
              const userData = doc.data() as User;
              const match = gUsers.find((u: any) => u.user.id === userData.id);

              return {
                ...userData,
                promoted: match.promoted,
              };
            });
            allUsers = allUsers.concat(remoteUsers);
          } catch (error) {
            console.error(error);
          }
        }

        return allUsers;
      };

      fetchUsersInBatches(users)
        .then((allUsers) => {
          const sortedUsers = allUsers.sort((a: any, b: any) => {
            if (a.isAnon && !b.isAnon) {
              return 1;
            }
            if (!a.isAnon && b.isAnon) {
              return -1;
            }
            return 0;
          });

          setGoingUsers(sortedUsers.filter((u: any) => !u.promoted));
          setPromotedUsers(sortedUsers.filter((u: any) => u.promoted));
        })
        .catch(console.error);
    });

    return unsub;
  }, [id]);

  const popoverSize = useBreakpointValue({ base: '330px', xl: '474px' });
  const hasHostingPermission = isHost || isCoHost;

  return (
    <Box width="100%">
      <Flex alignItems="center" mb="16px" flexDirection={{ base: 'column', xl: 'row' }}>
        <Box maxW={{ base: '640px', xl: '240px' }} mt="16px" mb="16px" mr="16px">
          <FeaturedImage tavernId={tavern.id} />
        </Box>
        <VStack align="start" width="100%">
          <Flex alignItems="center" mb="16px">
            <ScheduledTavernStats tavern={tavern} goingUsersCount={goingUsers.length} />
          </Flex>
          <Flex
            flexDirection={{ base: 'column', xl: 'row' }}
            alignItems={{ base: 'flex-start', xl: 'center' }}
            mb="8px"
            width="100%"
          >
            <Box textStyle="h1" pr="24px">
              {title}
            </Box>
            <Spacer />
            <Stack flexDirection={{ base: 'column', xl: 'row' }}>
              <Box mt="8px" mr="8px">
                {isHost ? <StartJoinTavernButton tavern={tavern} /> : <SetReminderTavernButton tavern={tavern} />}
              </Box>
              <Box>
                <EditTavernButton tavern={tavern} />
              </Box>
              <Box>
                <DestroyTavernButton tavern={tavern} />
              </Box>
            </Stack>
          </Flex>
        </VStack>
      </Flex>

      <Box mb="16px">
        <TopicsList topics={topics} />
      </Box>

      {description && (
        <Box mb="24px" color="typographySecondary">
          {description}
        </Box>
      )}

      <Box mb="48px">
        <MediaCarouselContainer hasHostingPermission={hasHostingPermission} />
      </Box>

      {hasHostingPermission && (
        <Box w="100%" mb="28px">
          <LocalUploadPopover creatorId={tavern.host.id} placement="bottom-start" width={popoverSize} closeOnBlur />
          <Box mt="28px">
            <LiveAddLinksModal />
          </Box>
        </Box>
      )}

      <Box mb="42px">
        <Stack width="100%">
          <SpeakersSection
            maxSpeakers={maxSpeakers}
            sessionType={userSession?.type ?? SessionType.Listener}
            hasHostingPermission={hasHostingPermission}
            speakers={[
              {
                id: tavern.host.id,
                name: tavern.host.name || '',
                avatar: getUserImage(tavern.host),
                sessionType: SessionType.Host,
              },
              ...promotedUsers.map((u) => {
                return {
                  id: u.id,
                  name: u.name || '',
                  avatar: u.avatar,
                  sessionType: SessionType.Speaker,
                };
              }),
            ]}
          />
        </Stack>
      </Box>

      {hasHostingPermission && (
        <Box w="100%">
          <LocalUploadPopover placement="bottom-start" width={popoverSize} />
        </Box>
      )}

      <Box>
        <TavernTabs>
          <TabList>
            <Tab key="going">
              <Box mr={4}>Going {goingUsers.length}</Box>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <GoingSection goingUsers={goingUsers} hasHostingPermission={hasHostingPermission} />
            </TabPanel>
          </TabPanels>
        </TavernTabs>
      </Box>
    </Box>
  );
};
