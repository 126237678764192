/* eslint-disable */
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { menuAnatomy } from '@chakra-ui/anatomy';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys);

export default defineMultiStyleConfig({
  baseStyle: {
    list: {
      background: 'backgroundAdditional',
      color: 'typographySecondary',
      border: 'transparent',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
      padding: '8px',
      borderRadius: 0,
      '.chakra-menu__group:not(:first-of-type)': {
        marginTop: '24px',
      },
    },
    item: {
      background: 'transparent',
      padding: '8px 12px',
      borderRadius: 0,
      '.chakra-menu__icon-wrapper': {
        display: 'flex',
        alignItems: 'center',
      },
      _hover: {
        background: 'backgroundHover',
        color: 'typographyPrimary',
      },
    },
    groupTitle: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      marginLeft: '12px',
    },
  },
});
