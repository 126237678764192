/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { collection, doc, setDoc } from 'firebase/firestore';
import { usePrevious } from '@chakra-ui/react';

import GlobalPlayerContext from './global-player-context';
import { noop } from '../utils/noop';
import { getLocalAudioTrack, mute, unmute } from '../services/agora-functions';
import { db, auth } from '../connect';
import { useAudioDevice } from '../hooks/useAudioDevice';

let mediaStream: MediaStream | undefined;
let userHasSpeakingEffect = false;
let streams: MediaStream[] = [];

interface SpeechRecognitionContextType {
  isMuted: boolean;
  setIsMuted: React.Dispatch<React.SetStateAction<boolean>>;
  isForceMuted: boolean;
  setIsForceMuted: React.Dispatch<React.SetStateAction<boolean>>;
  isMicSwitchingState: boolean;
}

const SpeechRecognitionContext = createContext<SpeechRecognitionContextType>({
  isMuted: false,
  setIsMuted: noop,
  isForceMuted: false,
  setIsForceMuted: noop,
  isMicSwitchingState: false,
});

export const SpeechRecognitionProvider: React.FC<any> = ({ children }) => {
  const { connectedTavern, userSession } = useContext(GlobalPlayerContext);
  const sessionId = userSession?.id;
  const prevSessionId = usePrevious(sessionId);

  const { selectedDevice } = useAudioDevice();
  const prevDeviceId = usePrevious(selectedDevice);

  const [isMuted, setIsMuted] = useState<boolean>(userSession?.isMuted ?? false);
  const [isForceMuted, setIsForceMuted] = useState<boolean>(userSession?.isForceMuted ?? false);
  const [isMicSwitchingState, setIsMicSwitchingState] = useState(false);
  const isUserSessionConnectedOrChanged = (!prevSessionId && sessionId) || (sessionId && prevSessionId !== sessionId);
  const connectedTavernId = connectedTavern?.id;

  const userSessionIsMuted = userSession?.isMuted;
  const userSessionIsForceMuted = userSession?.isForceMuted;

  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const startTimestamp = useRef(0);

  const startRecorder = () => {
    if (connectedTavernId && sessionId) {
      navigator.mediaDevices
        .getUserMedia({
          audio: {
            deviceId: selectedDevice,
          },
        })
        .then((stream) => {
          let options = { mimeType: 'audio/webm' };
          let fileName = 'audio.webm';

          if (MediaRecorder.isTypeSupported('audio/mp4')) {
            options = { mimeType: 'audio/mp4' };
            fileName = 'audio.mp4';
          }

          const recorder = new MediaRecorder(stream, options);
          setMediaRecorder(recorder);

          recorder.ondataavailable = (event: BlobEvent) => {
            const formData = new FormData();
            formData.append('audio', event.data, fileName);
            formData.append('tavernId', connectedTavernId);
            formData.append('sessionId', sessionId);
            formData.append('startDate', startTimestamp.current.toString());
            formData.append('endDate', Date.now().toString());
            formData.append('fileName', fileName);

            const user: any = auth.currentUser;

            let baseURL = 'https://us-central1-tavern-bf653.cloudfunctions.net';

            if (process.env.REACT_APP_NODE_ENV === 'dev') {
              baseURL = process.env.REACT_APP_EMULATOR_URL || '';
            }

            fetch(`${baseURL}/generateTranscription`, {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${user.accessToken as string}`,
              },
              body: formData,
            }).catch(console.error);
          };
        })
        .catch(console.error);
    }
  };

  useEffect(() => {
    startRecorder();

    return () => {
      cleanUp();
    };
  }, [connectedTavernId, sessionId]);

  useEffect(() => {
    handleMuteChange(isMuted).catch(console.error);

    if (connectedTavernId && sessionId) {
      if (isMuted) {
        enableDisableTracks(false);
      } else {
        enableDisableTracks(true);
      }
    }
  }, [isMuted]);

  useEffect(() => {
    if (userSession && userSessionIsForceMuted !== undefined) {
      if (userSessionIsForceMuted) {
        const localAudioTrack = getLocalAudioTrack();
        cleanUp();
        if (localAudioTrack) {
          handleMuteChange(true).catch(console.error);
        }
      }
      setIsForceMuted(userSessionIsForceMuted);
    }
  }, [userSessionIsForceMuted]);

  useEffect(() => {
    if (!userSession || !connectedTavern || !mediaRecorder) {
      return;
    }

    let silenceTimer: NodeJS.Timeout | null = null;

    const interval = setInterval(() => {
      const localAudioTrack = getLocalAudioTrack();
      const volume = localAudioTrack?.getVolumeLevel() || 0;

      if (localAudioTrack && volume > 0.55) {
        if (silenceTimer) {
          clearTimeout(silenceTimer);
          silenceTimer = null;
        }
        setUserSpeakingEffect(true).catch(console.error);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (!silenceTimer) {
          silenceTimer = setTimeout(() => {
            setUserSpeakingEffect(false).catch(console.error);
            silenceTimer = null;
          }, 300);
        }
      }
    }, 50);

    return () => {
      clearInterval(interval);
      if (silenceTimer) {
        clearTimeout(silenceTimer);
        silenceTimer = null;
      }
    };
  }, [sessionId, connectedTavernId, mediaRecorder]);

  useEffect(() => {
    if (prevDeviceId && prevDeviceId !== selectedDevice) {
      finalizeAndRestart();
    }
  }, [prevDeviceId, selectedDevice]);

  useEffect(() => {
    if (isUserSessionConnectedOrChanged) {
      setIsMuted(Boolean(userSessionIsMuted));
    }
  }, [userSessionIsMuted, isUserSessionConnectedOrChanged]);

  const enableDisableTracks = (isEnabled: boolean) => {
    mediaStream?.getTracks().forEach((track) => {
      track.enabled = isEnabled;
    });
  };

  const stopTracks = (stream?: MediaStream) => {
    (stream || mediaStream)?.getTracks().forEach((track) => {
      track.stop();
    });
  };

  const setUserSpeakingEffect = async (enabled: boolean) => {
    if (userHasSpeakingEffect === enabled || !userSession) {
      return;
    }
    userHasSpeakingEffect = enabled;
    const sessionDoc = doc(collection(doc(db, 'taverns', connectedTavern?.id as string), 'sessions'), userSession.id);
    await setDoc(sessionDoc, { isSpeaking: enabled }, { merge: true });

    if (enabled) {
      if (mediaRecorder && mediaRecorder.state === 'inactive') {
        mediaRecorder.start();
        startTimestamp.current = Date.now();
      }
    } else {
      stopRecording();
    }
  };

  const handleMuteChange = async (newIsMuted: boolean) => {
    if (!userSession) {
      return;
    }
    setIsMicSwitchingState(true);
    if (newIsMuted) {
      await mute();
    } else {
      if (userSessionIsForceMuted) {
        return setIsMicSwitchingState(false);
      }
      await unmute(selectedDevice);
    }
    setIsMicSwitchingState(false);
    const sessionDoc = doc(collection(doc(db, 'taverns', connectedTavern?.id as string), 'sessions'), userSession.id);
    await setDoc(sessionDoc, { isMuted: newIsMuted }, { merge: true });
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop();
    }
  };

  const stopAllStreams = () => {
    streams.forEach((stream) => {
      stopTracks(stream);
    });
    streams = [];
    mediaStream = undefined;
  };

  const cleanUp = () => {
    try {
      stopRecording();
    } catch (e) {
      console.error(e);
    } finally {
      stopAllStreams();
    }
  };

  const finalizeAndRestart = () => {
    if (connectedTavern) {
      cleanUp();
      startRecorder();
    }
  };

  return (
    <SpeechRecognitionContext.Provider
      value={{ setIsForceMuted, isForceMuted, isMuted, setIsMuted, isMicSwitchingState }}
    >
      {children}
    </SpeechRecognitionContext.Provider>
  );
};

export default SpeechRecognitionContext;
