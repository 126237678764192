import Fuse from 'fuse.js';
import _ from 'lodash';
import { Box, Grid, GridItem, HStack, Text } from '@chakra-ui/react';
import { FC, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import AuthContext from '../../../context/auth';
import { IconSearch } from '../../../theme/foundations/customIcons';
import { InvitationsSectionProps } from './types';
import { SessionType, SpeakInvite } from '../../../types/tavern';
import { TavernInput } from '../../uiKit/TavernInput/TavernInput';
import { TavernUser } from '../../uiKit/TavernUser/TavernUser';
import { UserNoSession } from '../../../types/user';
import { invitedDropDownItems } from '../../uiKit/DropdownItem/DropdownItem';

export const InvitationsSection: FC<InvitationsSectionProps> = ({
  invitations,
  sessionType,
  cardSize = 'lg',
  ...rest
}) => {
  const { tavernId } = useParams();
  const [query, setQuery] = useState('');
  const { user } = useContext(AuthContext);

  const fuse = new Fuse(invitations || [], {
    includeScore: false,
    keys: ['name', 'user.id'],
  });

  const results = fuse.search(query);
  const filteredInvitations = query ? results.map((r) => r.item) : invitations;

  return (
    <Box w="100%" {...rest}>
      <Box mb="24px">
        <HStack justifyContent="flex-start">
          <TavernInput
            leftIcon={<IconSearch />}
            value={query}
            placeholder="Search by name"
            onChange={(e) => setQuery(e.target.value)}
          />
        </HStack>
      </Box>
      <Grid pt="16px" autoFlow="row dense" templateColumns="repeat(auto-fill, 152px)" gap="24px">
        {!filteredInvitations.length && <Text opacity={0.5}>No Results</Text>}
        {filteredInvitations.map((invite: SpeakInvite, i: any) => (
          <GridItem key={i}>
            <TavernUser
              size={cardSize}
              user={asUserMock(invite)}
              sessionType={sessionType}
              inviterName={
                user?.id === invite.invitingHost.id ? 'You' : invite.invitingHostName ?? invite.invitingHost.id
              }
              invitedRole={_.upperFirst(invite.type)}
              isRoleVisible
              dropdownItems={invitedDropDownItems(tavernId as string, invite.id)}
            />
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

export const asUserMock = (invite: SpeakInvite): UserNoSession => {
  return {
    id: invite.id,
    uid: invite.user.id,
    name: invite.name ?? invite.user.id,
    sessionType: SessionType.Joiner,
    avatar: invite.avatar,
  };
};
