import { getDownloadURL, ref } from 'firebase/storage';
import { useEffect, useState } from 'react';

import { Box, chakra } from '@chakra-ui/react';
import { ExpandableImage } from '../ExpandableImage/ExpandableImage';
import { getTavernById } from '../../utils/tavern-util';
import { storage } from '../../connect';

interface FeaturedImageProps {
  tavernId: string;
  url?: string;
  isListView?: boolean;
}

const FeaturedImageComponent = (props: FeaturedImageProps) => {
  const { tavernId, url, isListView, ...rest } = props;
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    if (url) {
      setImageUrl(url);
    }
  }, [url]);

  useEffect(() => {
    // if url passed by props, do not try to load it
    if (url) {
      return;
    }

    const getFeaturedImage = async () => {
      const tavern = await getTavernById(tavernId);

      if (tavern.containsFeaturedImage) {
        getDownloadURL(ref(storage, `taverns/${tavernId}/featured-image`))
          .then((featuredImageUrl) => {
            setImageUrl(featuredImageUrl);
          })
          .catch((e) => {
            console.log(e);
            if (e.code === 'storage/object-not-found') {
              return;
            }
            console.error(`Error getting featured image for tavern ${tavernId}`, e);
          });
      }
    };
    getFeaturedImage().catch(console.error);
  }, [tavernId, url]);

  if (!imageUrl) {
    return <></>;
  }

  return (
    <Box height="100%" width="100%" {...rest}>
      <ExpandableImage
        src={imageUrl}
        height="100%"
        width="100%"
        objectFit={isListView ? 'cover' : 'contain'}
        borderRadius={isListView ? 'none' : 'md'}
        objectPosition="left"
      />
    </Box>
  );
};

export const FeaturedImage = chakra(FeaturedImageComponent);
