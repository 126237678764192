import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  Heading,
  HStack,
  Box,
  Text,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';

import { IconTavernLogo, IconActivity, IconHandUp, IconSearch } from '../theme/foundations/customIcons';
import { TavernInput } from '../components/uiKit/TavernInput/TavernInput';
import { TavernUser } from '../components/uiKit/TavernUser/TavernUser';
import { SessionType } from '../types/tavern';
import { rules } from '../utils/rules';

import { scoresQuery } from '../utils/tavern-util';

export const Roots = () => {
  const [scores, setScores] = useState<number[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    document.title = `Roots | Leaderboard`;
  }, []);

  useEffect(() => {
    scoresQuery(setScores).catch(console.error);
  }, []);

  const fuse = new Fuse(scores || [], {
    includeScore: false,
    keys: ['user.name', 'user.username', 'id'],
  });

  const results = fuse.search(searchQuery);
  const filteredScores = searchQuery ? results.map((r) => r.item) : scores;

  return (
    <Box>
      <Heading>
        <HStack align="center" spacing={4}>
          <IconTavernLogo boxSize="26px" />
          <Text>Roots</Text>
          <Text color="brandPrimary" textStyle="body14regular">
            PreSeason
          </Text>
        </HStack>
      </Heading>
      <VStack width="100%" spacing={8} pb="32px">
        <Tabs width="100%" mt="32px">
          <TabList>
            <Tab>
              <IconActivity mr="8px" boxSize="24px" />
              Leaderboard
            </Tab>
            <Tab>
              <IconHandUp mr="8px" boxSize="16px" />
              Rules
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p="0" pt="32px">
              <Box mb="24px">
                <HStack justifyContent="flex-start">
                  <TavernInput
                    leftIcon={<IconSearch />}
                    value={searchQuery}
                    placeholder="Search by name"
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </HStack>
              </Box>
              <Grid pt="16px" autoFlow="row dense" templateColumns="repeat(auto-fill, 152px)" gap="24px">
                {!filteredScores.length && <Text opacity={0.5}>No Results</Text>}
                {filteredScores.map((scoreUser: any) => {
                  return (
                    <GridItem key={scoreUser.id}>
                      <TavernUser
                        user={{
                          ...scoreUser.user,
                          score: scoreUser.score,
                          sessionType: SessionType.Listener,
                        }}
                        sessionType={SessionType.Listener}
                        rank={scoreUser.rank}
                      />
                    </GridItem>
                  );
                })}
              </Grid>
            </TabPanel>
            <TabPanel p="0" pt="32px">
              <ReactMarkdown components={ChakraUIRenderer()} remarkPlugins={[remarkGfm]}>
                {rules}
              </ReactMarkdown>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </Box>
  );
};
