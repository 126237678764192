import { collection, doc, increment, limit, onSnapshot, orderBy, query, setDoc } from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';

import AuthContext from '../../context/auth';
import GlobalPlayerContext from '../../context/global-player-context';
import { EmojiReactionPanel } from '../EmojiReactionPanel/EmojiReactionPanel';
import { Tavern } from '../../types/tavern';
import { db } from '../../connect';

const defaultEmojis = ['😂', '😍', '😭', '😡', '😳'];

export const LiveAddReactionControl = (props: { tavern: Tavern }) => {
  const { user } = useContext(AuthContext);
  const { userSession } = useContext(GlobalPlayerContext);

  const [displayedEmojis, setDisplayedEmojis] = useState<string[]>(defaultEmojis);

  useEffect(() => {
    if (!user) {
      return;
    }

    const unsubscribe = onSnapshot(
      query(collection(doc(collection(db, 'users'), user.id), 'usedEmojis'), orderBy('lastUsed', 'desc'), limit(5)),
      (snapshot) => {
        const emojis = snapshot.docs.map((emoji) => emoji.id);

        if (emojis.length < 5) {
          const nonDefaultEmojis = emojis.filter((emoji) => !defaultEmojis.includes(emoji));
          setDisplayedEmojis([...nonDefaultEmojis, ...defaultEmojis.slice(nonDefaultEmojis.length)]);
          return;
        }

        setDisplayedEmojis(emojis);
      }
    );

    return unsubscribe;
  }, [user]);

  // We want a consistent sort for the 5 most recently used emojis. Otherwise,
  // as soon as you click an emoji, it becomes the most recently used and
  // jumps to the front of the list.
  const sortEmojisByUnicode = (emojis: string[]): string[] => {
    return emojis.sort((a, b) => {
      const aCodePoint = a.codePointAt(0) || 0;
      const bCodePoint = b.codePointAt(0) || 0;
      return aCodePoint - bCodePoint;
    });
  };

  const currentTime = () => {
    return (Date.now() - props.tavern.startTime) / 1000;
  };

  const handleEmoji = async (emoji: string) => {
    if (userSession === null) {
      return;
    }

    const reaction = doc(collection(doc(collection(db, 'taverns'), props.tavern.id), 'reactions'));
    const sessionRef = doc(collection(doc(collection(db, 'taverns'), props.tavern.id), 'sessions'), userSession.id);

    await setDoc(reaction, {
      id: reaction.id,
      session: sessionRef,
      timestamp: Date.now(),
      type: emoji,
      reactionTime: currentTime(),
    });

    if (!user) {
      return;
    }

    const emojiRef = doc(collection(doc(collection(db, 'users'), user.id), 'usedEmojis'), emoji);

    await setDoc(
      emojiRef,
      {
        id: emojiRef.id,
        count: increment(1),
        lastUsed: Date.now(),
      },
      { merge: true }
    );
  };

  return (
    <EmojiReactionPanel
      emojis={sortEmojisByUnicode(displayedEmojis)}
      mr={2}
      onSelect={(emoji: string) => {
        handleEmoji(emoji).catch(console.error);
      }}
    />
  );
};
