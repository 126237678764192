import { Avatar, Divider, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { NFTCard } from '../NFTCard/NFTCard';
import { TopicTag } from '../TopicTag/TopicTag';
import { NFTCollectionProps } from './types';

export const NFTCollection: FC<NFTCollectionProps> = ({ collection, onClick, isSmall, isSuitable }) => {
  return (
    <Flex mt="16px" w="100%" alignItems="flex-start" flexDirection="column">
      <Flex w="100%" alignItems="center" mb="16px">
        <Avatar w="32px" h="32px" src={collection.image} mr="16px" />
        <Flex w="100%" flexDirection="column">
          <Text w="100%" textStyle="body16regular" display="flex" flexWrap="nowrap">
            {collection.name}
          </Text>
          <Text w="100%" display="flex" flexWrap="nowrap" textStyle="body12regular">
            {`${collection.tokens?.length || 0} tokens`}
          </Text>
        </Flex>
        <Flex>
          {!isSuitable && (
            <TopicTag
              _light={{
                borderColor: 'typographySecondary',
                border: '1px solid',
              }}
              _dark={{
                paddingTop: '1px',
                background: 'none',
                borderColor: 'typographySecondary',
                border: '1px solid',
              }}
            >
              <Text align="center" color="typographySecondary" textStyle="body12regular" whiteSpace="nowrap">
                Not suitable
              </Text>
            </TopicTag>
          )}
        </Flex>
      </Flex>
      <Divider bg="backgroundInput" orientation="horizontal" />
      <Grid
        mt={isSmall ? '0' : '24px'}
        gap={isSmall ? '12px' : { base: '12px', lg: '24px' }}
        rowGap="24px"
        templateColumns={isSmall ? 'repeat(3, 1fr)' : { base: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)' }}
      >
        {collection.tokens?.map((token) => (
          <GridItem key={token.tokenId}>
            <NFTCard isSmall={isSmall} nft={token} onClick={onClick} />
          </GridItem>
        ))}
      </Grid>
    </Flex>
  );
};
