/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import { query, collection, onSnapshot, where, doc } from 'firebase/firestore';
import { useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AlertModal from '../AlertModal/AlertModal';
import { AlertModalProps } from '../AlertModal/types';
import { RequestInviteStatus, SessionType, SpeakInvite } from '../../types/tavern';
import { acceptInviteToSpeak, rejectInviteToSpeak } from '../../services/invite-functions';
import { auth, db } from '../../connect';

export const InvitedToSpeakModal = () => {
  const { tavernId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [activeInvite, setActiveInvite] = useState<SpeakInvite | null>(null);
  const [acceptIsProcessing, setAcceptIsProcessing] = useState(false);
  const [rejectIsProcessing, setRejectIsProcessing] = useState(false);

  const userId = _.get(auth, 'currentUser.uid', null);

  useEffect(() => {
    if (userId === null) {
      return;
    }

    const invites = query(
      collection(db, `taverns/${tavernId as string}/invites`),
      where('user', '==', doc(collection(db, 'users'), userId)),
      where('status', '==', RequestInviteStatus.Pending)
    );
    const unsubInvites = onSnapshot(invites, (querySnapshot) => {
      const invite = _.first(querySnapshot.docs.map((d) => d.data())) as SpeakInvite;
      if (!_.isEmpty(invite)) {
        setActiveInvite(invite);
        onOpen();
      } else {
        setActiveInvite(null);
        onClose();
      }
    });
    return unsubInvites;
  }, [tavernId, userId]);

  if (activeInvite === null) {
    return <></>;
  }

  const titleEnd = activeInvite.type === SessionType.Speaker ? 'speak' : 'co-host';
  const roleText = activeInvite.type === SessionType.Speaker ? 'speak in' : 'co-host';

  const handleAccept = async () => {
    setAcceptIsProcessing(true);
    await acceptInviteToSpeak(tavernId as string, activeInvite.id);
    setAcceptIsProcessing(false);
  };

  const handleReject = async () => {
    setRejectIsProcessing(true);
    await rejectInviteToSpeak(tavernId as string, activeInvite.id);
    setRejectIsProcessing(false);
  };

  const modalProps: AlertModalProps = {
    isOpen,
    onClose,
    isDestructive: true,
    title: `Invite to ${titleEnd}`,
    description: `You've been invited to ${roleText} the Tavern. Accept?`,
    primaryButton: {
      text: 'Accept',
      isLoading: acceptIsProcessing,
      onClick: () => {
        handleAccept().catch(console.error);
      },
    },
    secondaryButton: {
      text: 'Reject',
      isLoading: rejectIsProcessing,
      onClick: () => {
        handleReject().catch(console.error);
      },
    },
  };

  return <AlertModal {...modalProps} />;
};
