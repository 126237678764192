import { httpsCallable } from 'firebase/functions';
import { fbFunctions } from '../connect';

export const acceptRequestToSpeak = async (tavernId: string, requestId: string) => {
  const accept = httpsCallable<{
    tavernId: string;
    requestId: string;
  }>(fbFunctions, 'acceptRequestToSpeak');
  await accept({
    tavernId,
    requestId,
  });
};

export const rejectRequestToSpeak = async (tavernId: string, requestId: string) => {
  const deny = httpsCallable<{
    tavernId: string;
    requestId: string;
  }>(fbFunctions, 'rejectRequestToSpeak');
  await deny({
    tavernId,
    requestId,
  });
};

export const requestToSpeak = async (tavernId: string): Promise<void> => {
  const request = httpsCallable<{
    tavernId: string;
  }>(fbFunctions, 'requestToSpeak');
  await request({
    tavernId,
  });
};

export const cancelRequestToSpeak = async (tavernId: string): Promise<void> => {
  const cancel = httpsCallable<{
    tavernId: string;
  }>(fbFunctions, 'cancelRequestToSpeak');
  await cancel({
    tavernId,
  });
};
