import { Box, Flex, Grid, GridItem, Heading, HStack, Spacer } from '@chakra-ui/react';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';

import Fuse from 'fuse.js';
import AuthContext from '../../context/auth';
import GlobalPlayerContext from '../../context/global-player-context';
import { IconCalendar, IconLive, IconPast, IconSearch } from '../../theme/foundations/customIcons';
import { OrderSortingPanel } from '../OrderSortingPanel/OrderSortingPanel';
import { SortingField, SortingValue } from '../OrderSortingPanel/types';
import { Tavern, TavernStatus, Topic } from '../../types/tavern';
import { TavernCard } from '../TavernCard/TavernCard';
import { TavernCardLive } from '../TavernCard/TavernCardLive';
import { TavernCardPast } from '../TavernCard/TavernCardPast';
import { TavernCardProps } from '../TavernCard/types';
import { TavernCardUpcoming } from '../TavernCard/TavernCardUpcoming';
import { TavernInput } from '../uiKit/TavernInput/TavernInput';
import { db } from '../../connect';

const getSortingFields = (status: TavernStatus): SortingField[] => {
  if (status === TavernStatus.Live) {
    return [
      { name: 'listeners', value: SortingValue.DESC },
      { name: 'start time', value: SortingValue.DESC },
    ];
  }

  if (status === TavernStatus.Past) {
    return [
      { name: 'start time', value: SortingValue.DESC },
      { name: 'play count', value: SortingValue.DESC },
      { name: 'length', value: SortingValue.DESC },
    ];
  }

  if (status === TavernStatus.Upcoming) {
    return [
      { name: 'listeners', value: SortingValue.DESC },
      { name: 'start time', value: SortingValue.DESC },
    ];
  }

  return [];
};

export const TavernList = ({ status }: { status: TavernStatus }) => {
  useEffect(() => {
    document.title = `${status} Taverns`;
  }, [status]);

  const { user } = useContext(AuthContext);
  const { connectedTavern } = useContext(GlobalPlayerContext);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [view, setView] = useState<TavernCardProps['variant']>('List');

  const [taverns, setTaverns] = useState<Tavern[]>([]);
  const [filteredTaverns, setFilteredTaverns] = useState<Tavern[]>([]);

  const [sortingFields, setSortingFields] = useState<SortingField[]>(getSortingFields(status));
  const [selectedField, setSelectedField] = useState(sortingFields[0]);

  const isGrid = view === 'Grid';
  const isList = view === 'List';

  const renderCardComponent = (cardProps: any) => {
    if (status === TavernStatus.Live) {
      return <TavernCardLive {...cardProps} />;
    }

    if (status === TavernStatus.Past) {
      return <TavernCardPast {...cardProps} />;
    }

    if (status === TavernStatus.Upcoming) {
      return <TavernCardUpcoming {...cardProps} />;
    }

    return <TavernCard {...cardProps} />;
  };

  const onTopicClick = (topic: Topic, e?: Event) => {
    e?.stopPropagation();
    setSearchQuery(topic.name);
  };

  const handleSortingChange = (name: string, newValue: SortingValue) => {
    setSelectedField({ name, value: newValue });
    setSortingFields((prevState) => {
      const index = prevState.findIndex((f) => f.name === name);
      if (index <= -1) {
        return prevState;
      }
      const newState = [...prevState];
      newState[index].value = newValue;

      return newState;
    });
    setFilteredTaverns(
      filteredTaverns.sort((a, b) => {
        if (name === 'listeners') {
          return newValue === SortingValue.DESC ? b.listenerCount - a.listenerCount : a.listenerCount - b.listenerCount;
        }

        if (name === 'start time') {
          return newValue === SortingValue.DESC ? b.startTime - a.startTime : a.startTime - b.startTime;
        }

        if (name === 'length') {
          return newValue === SortingValue.DESC
            ? (b.endTime ?? 0) - b.startTime - ((a.endTime ?? 0) - a.startTime)
            : (a.endTime ?? 0) - a.startTime - ((b.endTime ?? 0) - b.startTime);
        }

        if (name === 'play count') {
          return newValue === SortingValue.DESC ? b.playCount - a.playCount : a.playCount - b.playCount;
        }
        return 0;
      })
    );
  };

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredTaverns(taverns);
      return;
    }

    const fuse = new Fuse(taverns || [], {
      includeScore: false,
      shouldSort: false,
      threshold: 0.05,
      keys: ['title', 'description', 'topicNames'],
    });
    setFilteredTaverns(fuse.search(searchQuery).map((e) => e.item));
  }, [searchQuery, taverns]);

  useEffect(() => {
    setSortingFields(getSortingFields(status));
  }, [status]);

  useEffect(() => {
    if (user === null) {
      return;
    }
    setTaverns([]);
    setFilteredTaverns([]);

    const typeQuery = queryForType(status);

    const unsubscribe = onSnapshot(typeQuery, (querySnapshot) => {
      let tavernDocs = querySnapshot.docs.map((doc) => doc.data()) as Tavern[];

      if (status === TavernStatus.Live) {
        tavernDocs = tavernDocs.filter((tavern) => tavern.endTime === undefined);
      }

      if (status === TavernStatus.Upcoming) {
        const currentTimePlus24Hrs = Date.now() - 24 * 60 * 60 * 1000; // Current time minus 24 hours in milliseconds

        tavernDocs = tavernDocs.filter((tavern) => tavern.startTime > currentTimePlus24Hrs);
      }

      setTaverns(tavernDocs);
    });
    return unsubscribe;
  }, [user, status]);

  const getPageIcon = () => {
    if (status === TavernStatus.Live) {
      return <IconLive boxSize="26px" />;
    }
    if (status === TavernStatus.Past) {
      return <IconPast boxSize="26px" />;
    }
    if (status === TavernStatus.Upcoming) {
      return <IconCalendar boxSize="26px" />;
    }

    return <IconLive boxSize="26px" />;
  };

  return (
    <Box>
      <Heading mb="24px">
        <HStack gap="15px">
          {getPageIcon()}
          <Box>{`${status} Taverns`}</Box>
        </HStack>
      </Heading>
      <Box mb="24px">
        <TavernInput
          leftIcon={<IconSearch />}
          value={searchQuery}
          placeholder="Search by Tavern name, description, or topic"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>

      <Box mb="24px">
        <Flex>
          <Box>
            <OrderSortingPanel fields={sortingFields} selected={selectedField} onChange={handleSortingChange} />
          </Box>
          <Spacer />
          <Box display={{ base: 'none', xl: 'block' }}>
            <HStack spacing="16px">
              <Box
                as="button"
                textStyle="body14regular"
                color={isGrid ? 'typographyPrimary' : 'typographySecondary'}
                onClick={() => setView('Grid')}
              >
                Grid
              </Box>
              <Box
                as="button"
                textStyle="body14regular"
                color={isList ? 'typographyPrimary' : 'typographySecondary'}
                onClick={() => setView('List')}
              >
                List
              </Box>
            </HStack>
          </Box>
        </Flex>
      </Box>

      <Grid
        gap={{ base: '16px', xl: '24px' }}
        autoFlow="row dense"
        templateColumns={
          isGrid
            ? {
                base: '100%',
                xl: 'repeat(2, 1fr)',
                '3xl': 'repeat(3, 1fr)',
              }
            : '100%'
        }
      >
        {filteredTaverns.map((tavern: Tavern) => {
          const isActive = connectedTavern?.id === tavern.id;

          return (
            <GridItem key={tavern.id}>
              {renderCardComponent({
                ...tavern,
                variant: view,
                tavern,
                isActive,
                onTopicClick,
              })}
            </GridItem>
          );
        })}
      </Grid>
    </Box>
  );
};

const queryForType = (type: TavernStatus) => {
  switch (type) {
    case TavernStatus.Live:
      return query(collection(db, 'taverns'), where('status', '==', TavernStatus.Live), orderBy('startTime', 'desc'));
    case TavernStatus.Upcoming:
      return query(
        collection(db, 'taverns'),
        where('status', '==', TavernStatus.Upcoming),
        orderBy('startTime', 'asc')
      );
    case TavernStatus.Past:
      return query(collection(db, 'taverns'), where('status', '==', TavernStatus.Past), orderBy('endTime', 'desc'));
  }
};
