import { useState, useEffect } from 'react';
import { Button, forwardRef, useStyleConfig } from '@chakra-ui/react';
import { TavernButtonProps } from './types';

const colors = ['purple.500', 'red.500', 'blue.500', 'green.500', 'yellow.500'];

export const TavernButtonAnime = forwardRef<TavernButtonProps, 'button'>(({ size, variant, ...rest }, ref) => {
  const styles = useStyleConfig('TavernButtonStyle', { variant, size });
  const [colorIndex, setColorIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Button
      sx={{
        ...styles,
        '&&': {
          borderColor: colors[colorIndex],
          color: colors[colorIndex],
        },
      }}
      size={size}
      ref={ref}
      iconSpacing="8px"
      {...rest}
      transition="color 2s, border-color 2s"
    />
  );
});
