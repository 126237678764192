import { defineStyleConfig } from '@chakra-ui/react';

const TavernButtonStyle = defineStyleConfig({
  baseStyle: {
    border: '1px solid',
    backgroundColor: 'transparent',
    borderRadius: '0',
    height: '40px',
    marginTop: '0',
    _focus: {
      boxShadow: '0px 0px 0px 4px rgba(145, 90, 217, 0.25)',
      background: 'rgba(0, 0, 0, 0.01)',
    },
    _disabled: {
      opacity: '0.5',
      pointerEvents: 'none',
      cursor: 'disabled',
    },
  },
  sizes: {
    xs: {
      width: '40px',
      padding: '0',
    },
    md: {
      padding: '0 24px',
      fontFamily: 'body',
      fontWeight: 'regular',
      fontSize: 'lg',
      lineHeight: '24px',
      svg: {
        width: '16px',
        height: '16px',
      },
    },
    sm: {
      padding: '0 12px',
      fontFamily: 'body',
      fontWeight: 'regular',
      fontSize: 'md',
      lineHeight: '20px',
    },
  },
  variants: {
    primary: {
      borderColor: 'brandPrimary',
      color: 'brandPrimary',
      _hover: {
        background: 'brand.120',
      },
      _active: {
        background: 'brand.200',
      },
    },
    secondary: {
      borderColor: 'typographyPrimary',
      color: 'typographyPrimary',
      _hover: {
        background: 'sandyAlpha.460',
        _dark: {
          backgroundColor: 'whiteAlpha.120',
        },
      },
      _active: {
        background: 'sandyAlpha.500',
        boxShadow: 'none',
        _dark: {
          backgroundColor: 'whiteAlpha.160',
        },
      },
      _disabled: {
        _light: {
          borderColor: 'white',
          color: 'white',
        },
      },
    },
    tertiary: {
      borderColor: 'typographySecondary',
      color: 'typographySecondary',
      _hover: {
        backgroundColor: 'sandyAlpha.340',
        _dark: {
          backgroundColor: 'whiteAlpha.80',
        },
      },
      _active: {
        backgroundColor: 'whiteAlpha.160',
        boxShadow: 'none',
      },
    },
    destructive: {
      borderColor: 'accentDestructive',
      color: 'accentDestructive',
      _hover: {
        background: 'rgba(255, 90, 95, 0.12)',
      },
      _active: {
        background: 'rgba(255, 90, 95, 0.2)',
        boxShadow: 'none',
      },
      _focus: {
        boxShadow: '0px 0px 0px 4px rgba(255, 90, 95, 0.25)',
        background: '0',
      },
    },
    link: {
      padding: 0,
      border: 'none',
      height: 'auto',
    },
  },
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
});

export default TavernButtonStyle;
