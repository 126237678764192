/* eslint-disable */
import { progressAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(progressAnatomy.keys);

const TavernProgressStyles = defineMultiStyleConfig({
  baseStyle: {
    filledTrack: {
      bg: 'typographyPrimary',
      height: '2px',
    },
    track: {
      bg: 'typographyTertiary',
    },
  },
  sizes: {
    xs: {
      track: {
        height: '2px',
      },
    },
  },
});

export default TavernProgressStyles;
