import { Spinner, Flex, VStack, useDisclosure, Text } from '@chakra-ui/react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useContext, useState, useEffect } from 'react';
import { OpenloginAdapter } from '@web3auth/openlogin-adapter';
import { WALLET_ADAPTERS } from '@web3auth/base';
import { Web3AuthNoModal } from '@web3auth/no-modal';
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
import { GaslessWallet } from '@gelatonetwork/gasless-wallet';
import { ethers } from 'ethers';

import GlobalPlayerContext from '../../context/global-player-context';
import { auth } from '../../connect';
import { convertAnonSessionToUser } from '../../services/session-functions';
import { leaveAgoraChannel } from '../../services/agora-functions';
import { leaveTavern } from '../../services/tavern-functions';
import { signIn, signInToken, signInFarcaster } from '../../services/signing-functions';
import { useTavernToast } from '../hooks/useTavernToast';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';
import { IconLeave, IconTavernLogo, IconWarpcast } from '../../theme/foundations/customIcons';
import { TavernTooltip } from '../uiKit/TavernTooltip/TavernTooltip';
import { TavernModal } from '../TavernModal/TavernModal';

interface Window {
  addEventListener: any;
  open: any;
  removeEventListener: any;
}

declare const window: Window;

const clientId = 'BC0m4B2qQH-C1cS9zheIrSmSIP7F-h26ZpiLvh2p0Q3YLE4OhiYf4vxJ1IphahiXvfTAtoKDAlfdKeziZ5b_ABI';

const gaslessWalletConfig = {
  apiKey: 'z4_K0P8cixVhg57PuE2zQz_8fbGv_a_WaR80wO1ic8c_',
};

const web3auth = new Web3AuthNoModal({
  clientId,
  web3AuthNetwork: 'cyan',
  chainConfig: {
    chainNamespace: 'eip155',
    chainId: '0x89',
    rpcTarget: 'https://polygon-mainnet.infura.io/v3/9a9c5c7e10084eaa98fd8b71b9cb5dd1',
  },
});

const privateKeyProvider = new EthereumPrivateKeyProvider({
  config: {
    chainConfig: {
      chainNamespace: 'eip155',
      chainId: '0x89',
      rpcTarget: 'https://polygon-mainnet.infura.io/v3/9a9c5c7e10084eaa98fd8b71b9cb5dd1',
    } as any,
  },
});

const openloginAdapter = new OpenloginAdapter({
  adapterSettings: {
    clientId,
    network: 'cyan',
    uxMode: 'redirect',
    redirectUrl: '',
    loginConfig: {
      google: {
        verifier: 'tavern-agg',
        verifierSubIdentifier: 'google',
        typeOfLogin: 'jwt',
        clientId: 'jnutTnJTDsr05rCSHF0FSfpnLOw2aeQ1',
      },
    },
    whiteLabel: {
      name: 'tavern.fm',
      url: 'https://tavern.fm',
      logoLight: 'https://alpha.tavern.fm/logo.png',
      logoDark: 'https://alpha.tavern.fm/logo.png',
      defaultLanguage: 'en',
      dark: true,
      theme: {
        primary: '#000000',
      },
    },
  },
  privateKeyProvider,
});

web3auth.configureAdapter(openloginAdapter);

function isSafari() {
  return (
    (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) || /iPhone/i.test(navigator.userAgent)
  );
}

export const SignInOutButton = (props: { isSignedIn: boolean | undefined; isCollapse?: boolean }) => {
  const { isSignedIn, isCollapse } = props;
  const navigate = useNavigate();
  const toast = useTavernToast();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const { hash } = useLocation();
  const sessionId = new URLSearchParams(hash.substring(1)).get('sessionId');

  const [ready, setReady] = useState(false);
  const [isLoading, setIsLoading] = useState<string>('');
  const { connectedTavern, setConnectedTavern, setUserSession, userSession } = useContext(GlobalPlayerContext);

  const loginUseDisclosure = useDisclosure();

  const handleWeb3Auth = async () => {
    try {
      if (web3auth.status === 'connected') {
        const userData = await web3auth.getUserInfo();

        if (web3auth.provider) {
          const provider = new ethers.BrowserProvider(web3auth.provider);
          const signer = await provider.getSigner();

          const gaslessWallet = new GaslessWallet(web3auth.provider, gaslessWalletConfig);
          await gaslessWallet.init();

          await signIn(userData, signer.address, gaslessWallet.getAddress());
        }
      }
    } catch (error) {
      console.log(error);

      toast({
        title: `Login Failed`,
        description: 'Failed to login, please try again.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });

      if (web3auth.connected) {
        await web3auth.logout();
      }
    }
  };

  useEffect(() => {
    const init = async () => {
      if (web3auth.status !== 'connected') {
        try {
          const anonId = auth.currentUser?.uid;

          // await handleWeb3Auth();

          const signedInWhileInTavern = connectedTavern !== null && userSession !== null && anonId !== undefined;

          if (signedInWhileInTavern) {
            await convertAnonSessionToUser(connectedTavern.id, userSession.id, anonId);
          }
        } catch (error) {
          console.log(error);
        }
      }

      setReady(true);
    };

    init().then().catch(console.error);
  }, []);

  useEffect(() => {
    if (sessionId && web3auth.status !== 'connected') {
      const init = async () => {
        if (web3auth.status !== 'connected') {
          try {
            await web3auth.init();

            const anonId = auth.currentUser?.uid;

            await handleWeb3Auth();

            const signedInWhileInTavern = connectedTavern !== null && userSession !== null && anonId !== undefined;

            if (signedInWhileInTavern) {
              await convertAnonSessionToUser(connectedTavern.id, userSession.id, anonId);
            }
          } catch (error) {
            console.log(error);
          }
        }

        setReady(true);
      };

      init().then().catch(console.error);
    }
  }, [sessionId]);

  useEffect(() => {
    if (token) {
      const init = async () => {
        try {
          await signInToken(token);
        } catch (error) {
          console.log(error);
        }

        setReady(true);
      };

      init().then().catch(console.error);
    }
  }, [token]);

  if (isSignedIn === undefined) {
    return <Spinner />;
  }

  if (!isSignedIn) {
    const handleSignIn = async (provider: string) => {
      if (isSafari()) {
        // toast({
        //   title: `Unblock Popups`,
        //   description: 'Safari & iOS devices have popups blocked by default.',
        //   status: 'info',
        //   duration: 10000,
        //   isClosable: true,
        //   position: 'top',
        // });
      }
      if (web3auth.connected) {
        await web3auth.logout();
      }

      const anonId = auth.currentUser?.uid;

      setIsLoading(provider);

      try {
        await web3auth.init();

        await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
          loginProvider: provider,
          extraLoginOptions: {
            domain: 'https://dev-q2i2cn6kl5avedto.us.auth0.com',
            verifierIdField: 'sub',
          },
        });

        await handleWeb3Auth();

        const signedInWhileInTavern = connectedTavern !== null && userSession !== null && anonId !== undefined;

        if (signedInWhileInTavern) {
          await convertAnonSessionToUser(connectedTavern.id, userSession.id, anonId);
        }
      } catch (error) {
        console.error(error);
        if (web3auth.connected) {
          await web3auth.logout();
        }
      }

      setIsLoading('');
    };

    return (
      <>
        <TavernTooltip placement="right" label={isCollapse ? 'Login' : ''}>
          <TavernButton
            w="100%"
            isLoading={!!isLoading}
            variant="primary"
            leftIcon={<IconTavernLogo boxSize="16px" mr={isCollapse ? '-8px' : '0'} />}
            size={isCollapse ? 'xs' : 'md'}
            onClick={() => {
              loginUseDisclosure.onOpen();
            }}
            isDisabled={!ready}
          >
            {isCollapse ? '' : 'Login'}
          </TavernButton>
        </TavernTooltip>
        <LoginModal
          isOpen={loginUseDisclosure.isOpen}
          onClose={loginUseDisclosure.onClose}
          handleSignIn={handleSignIn}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </>
    );
  }

  if (isSignedIn) {
    const handleSignOut = async () => {
      setIsLoading('true');
      navigate('/');
      if (connectedTavern !== null) {
        setConnectedTavern(null);
        await leaveAgoraChannel();
        await leaveTavern(connectedTavern.id);
        toast({
          title: `Left Tavern.`,
          description: "You've successfully left the Tavern.",
          status: 'info',
          duration: 2000,
          isClosable: true,
        });
      }

      if (web3auth.connected) {
        await web3auth.logout();
      }

      await auth.signOut();
      setIsLoading('');
      setUserSession(null);
    };

    return (
      <TavernTooltip placement="right" label={isCollapse ? 'Sign Out' : ''}>
        <TavernButton
          w="100%"
          variant="tertiary"
          leftIcon={<IconLeave boxSize="16px" mr={isCollapse ? '-8px' : '0'} />}
          size={isCollapse ? 'xs' : 'md'}
          onClick={() => {
            handleSignOut().catch(console.error);
          }}
        >
          {isCollapse ? '' : 'Sign Out'}
        </TavernButton>
      </TavernTooltip>
    );
  }

  return <></>;
};

const LoginModal = ({ isOpen, onClose, handleSignIn, isLoading, setIsLoading }: any) => {
  return (
    <TavernModal isOpen={isOpen} isCloseButtonVisible onClose={onClose}>
      <Flex flexDirection="column" width="100%">
        <VStack mt="24px" width="100%" justify="center" align="center" spacing={4}>
          <Text textStyle="body16bold">Logins</Text>
          <TavernButton
            variant="secondary"
            leftIcon={IconWarpcast}
            width="140px"
            isLoading={isLoading === 'farcaster'}
            isDisabled={isLoading && isLoading !== 'farcaster'}
            onClick={() => {
              setIsLoading('farcaster');
              const authUrl = new URL('https://app.neynar.com/login');
              authUrl.searchParams.append('client_id', 'ed0ff8c4-151b-4d1e-8bfe-69b45929002f');
              const authOrigin = new URL('https://app.neynar.com/login').origin;
              const authWindow = window.open(authUrl.toString(), '_blank');

              const handleWindowMessage = async (event: any) => {
                if (event.origin === authOrigin && event.data.is_authenticated) {
                  if (authWindow) {
                    authWindow.close();
                  }

                  await signInFarcaster(event.data.signer_uuid, event.data.fid);

                  setIsLoading('');
                  onClose();

                  window.removeEventListener('message', handleWindowMessage);
                }
              };

              window.addEventListener('message', handleWindowMessage, false);
            }}
          >
            Farcaster
          </TavernButton>
          <TavernButton
            variant="primary"
            leftIcon={<IconTavernLogo />}
            onClick={() => {
              handleSignIn('google').catch(console.error);
            }}
            width="140px"
            isLoading={isLoading === 'google'}
            isDisabled={isLoading && isLoading !== 'google'}
          >
            Universal
          </TavernButton>
        </VStack>
      </Flex>
    </TavernModal>
  );
};
