import '@rainbow-me/rainbowkit/styles.css';
import { ChakraProvider, useColorMode } from '@chakra-ui/react';
import { connectAuthEmulator, onAuthStateChanged } from 'firebase/auth';
import { connectDatabaseEmulator } from 'firebase/database';
import { collection, connectFirestoreEmulator, doc, getDoc, onSnapshot, query } from 'firebase/firestore';
import { connectFunctionsEmulator } from 'firebase/functions';
import { connectStorageEmulator } from 'firebase/storage';
import { useEffect, useMemo, useState } from 'react';

import './theme/styles.css';
import AuthContext from './context/auth';
// import CollectionsContext from './context/collections';
import GlobalPlayerContext from './context/global-player-context';
import NotificationsContext from './context/notifications';
import theme from './theme/theme';
import { AudioClip, AnonResponse, Reaction, Gif, Session, Tavern, TavernStatus, Score } from './types/tavern';
// import { CollectionsMonitor } from './components/monitors/collections-monitor';
import { ExternalAudio } from './types/audio';
import { ProdUser, UserNft } from './types/user';
import { Notification } from './types/notification';
import { RecordedPlayState } from './types/player';
import { TavernParticipantMonitor } from './components/monitors/tavern-participant-monitor';
// import { UserNFTsMonitor } from './components/monitors/user-nfts-monitor';
import { auth, db, fbFunctions, realtimeDb, storage } from './connect';
import { signInAnon } from './services/signing-functions';
import { startAgoraSubscriptions } from './services/agora-functions';
import { AudioDeviceProvider } from './context/microphone-device-context';
import { AutoJoinTavernWrapper } from './context/auto-join-tavern-wrapper';
import { Router } from './router';
import { SpeechRecognitionProvider } from './context/speech-recognition-context';

export const inEmulator = process.env.NODE_ENV === 'development' && process.env.REACT_APP_NODE_ENV === 'dev';

if (inEmulator) {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFunctionsEmulator(fbFunctions, 'localhost', 5001);
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectStorageEmulator(storage, 'localhost', 9199);
  connectDatabaseEmulator(realtimeDb, 'localhost', 9000);
}

const ForceColorFlip = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    if (colorMode === 'light') {
      toggleColorMode();
    }
  }, [colorMode, toggleColorMode]);

  return null;
};

export const App = () => {
  const [user, setUser] = useState<ProdUser | null>(null);
  const [isAuthStateLoaded, setIsAuthStateLoaded] = useState(false);
  const [userNFTs, setUserNFTs] = useState<UserNft[]>([]);

  const [connectedTavern, setConnectedTavern] = useState<Tavern | null>(null);
  const [userSession, setUserSession] = useState<Session | null>(null);
  const [lastJoinedTavernCheckComplete, setLastJoinedTavernCheckComplete] = useState<boolean>(false);

  const [hosts, setHosts] = useState<Session[]>([]);
  const [coHosts, setCoHosts] = useState<Session[]>([]);
  const [speakers, setSpeakers] = useState<Session[]>([]);
  const [joiners, setJoiners] = useState<Session[]>([]);
  const [listeners, setListeners] = useState<Session[]>([]);
  const [listenerCount, setListenerCount] = useState<number>(0);
  const [playCount, setPlayCount] = useState<number>(0);
  const [recordedTavernProgress, setRecordedTavernProgress] = useState(0);
  const [recordedPlayState, setRecordedPlayState] = useState<RecordedPlayState | null>(null);
  const [externalAudio, setExternalAudio] = useState<ExternalAudio[]>([]);
  const [reactions, setReactions] = useState<Reaction[]>([]);
  const [gifs, setGifs] = useState<Gif[]>([]);
  const [scores, setScores] = useState<Score[]>([]);
  const [audioClips, setAudioClips] = useState<AudioClip[]>([]);
  const [anonResponses, setAnonResponses] = useState<AnonResponse[]>([]);
  // const [collections, setCollections] = useState<NftCollection[]>([]);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  // This is the unix timestamp on of a replaying tavern's progress.
  const recordingProgressTimestamp = useMemo(() => {
    if (connectedTavern === null || connectedTavern?.status !== TavernStatus.Past) {
      return null;
    }
    return connectedTavern?.startTime + recordedTavernProgress * 1000;
  }, [connectedTavern, recordedTavernProgress]);

  useEffect(() => {
    startAgoraSubscriptions();

    onAuthStateChanged(auth, (updatedUser) => {
      if (updatedUser === null) {
        setUser(null);
        signInAnon().catch((error) => console.error(error));
        setIsAuthStateLoaded(true);
      } else {
        getDoc(doc(db, 'users', updatedUser.uid))
          .then((docSnap) => {
            if (docSnap.exists()) {
              setUser(docSnap.data() as ProdUser);
            } else {
              setUser({
                id: updatedUser.uid,
                isAnon: updatedUser.isAnonymous,
                name: null,
              });
            }
            setIsAuthStateLoaded(true);
          })
          .catch((error) => {
            console.error('Error fetching user document:', error);
          });
      }
    });
  }, []);

  useEffect(() => {
    if (!user?.id) {
      return;
    }
    // TODO: add pagination here and a separate query to count all unseen notifications
    const notificationsQuery = query(collection(db, 'users', user.id, 'notifications'));
    const unsub = onSnapshot(
      notificationsQuery,
      (d) => {
        const notifs: Notification[] = d.docs.map((notifDoc) => notifDoc.data() as Notification);
        setNotifications(
          notifs.sort((a, b) => {
            return b.timestamp - a.timestamp;
          })
        );
      },
      (error) => {
        console.error(error);
      }
    );

    const unsubUser = onSnapshot(doc(db, 'users', user.id), (d) => {
      setUser(d.data() as ProdUser);
    });

    return () => {
      unsub();
      unsubUser();
    };
  }, [user?.id]);

  return (
    <ChakraProvider theme={theme}>
      <AudioDeviceProvider>
        <GlobalPlayerContext.Provider
          value={{
            connectedTavern,
            setConnectedTavern,
            userSession,
            setUserSession,
            lastJoinedTavernCheckComplete,
            setLastJoinedTavernCheckComplete,
            hosts,
            setHosts,
            coHosts,
            setCoHosts,
            speakers,
            setSpeakers,
            joiners,
            setJoiners,
            listeners,
            setListeners,
            listenerCount,
            setListenerCount,
            playCount,
            setPlayCount,
            recordedTavernProgress,
            setRecordedTavernProgress,
            recordingProgressTimestamp,
            recordedPlayState,
            setRecordedPlayState,
            externalAudio,
            setExternalAudio,
            reactions,
            setReactions,
            gifs,
            setGifs,
            scores,
            setScores,
            audioClips,
            setAudioClips,
            anonResponses,
            setAnonResponses,
          }}
        >
          <SpeechRecognitionProvider>
            <TavernParticipantMonitor />
            <ForceColorFlip />
            <AuthContext.Provider
              value={{
                isAuthStateLoaded,
                user,
                setUser,
                userNFTs,
                setUserNFTs,
              }}
            >
              <NotificationsContext.Provider
                value={{ notifications, unseenCount: notifications.filter((n) => !n.seen).length }}
              >
                <AutoJoinTavernWrapper>
                  <Router>
                    <></>
                  </Router>
                </AutoJoinTavernWrapper>
              </NotificationsContext.Provider>
            </AuthContext.Provider>
          </SpeechRecognitionProvider>
        </GlobalPlayerContext.Provider>
      </AudioDeviceProvider>
    </ChakraProvider>
  );
};
