import React, { ReactNode } from 'react';
import { noop } from 'lodash';
import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { ItemsListProps } from './types';
import { IconDelete } from '../../../theme/foundations/customIcons';

export const ItemsList = <
  T extends {
    id: string;
    label?: string | null;
    component: ReactNode;
    extraContent?: ReactNode;
  }
>(
  props: ItemsListProps<T>
) => {
  const { items, hasRemoveButton, onButtonClick = noop } = props;

  const handleRemoveButtonClick = (id: T['id']) => {
    onButtonClick(id);
  };

  return (
    <Flex w="100%" direction="column">
      {items.map((item, i) => (
        <div key={i}>
          {item.label && (
            <Text mb="8px" textStyle="body14regular">
              {item.label}
            </Text>
          )}
          <Flex w="100%" mb="8px">
            <Flex width="100%" justifyContent="space-between" alignItems="center">
              <Box w="100%">{item.component}</Box>
              {hasRemoveButton && (
                <Flex w="100%" boxSize="40px" direction="column" alignItems="center" justifyContent="center">
                  <IconButton
                    size="md"
                    height="40px"
                    w="100%"
                    onClick={() => handleRemoveButtonClick(item.id)}
                    variant="link"
                    aria-label="add-co-host"
                  >
                    <IconDelete color="typographySecondary" fontSize="24px" />
                  </IconButton>
                </Flex>
              )}
            </Flex>
          </Flex>
          {item.extraContent}
        </div>
      ))}
    </Flex>
  );
};
