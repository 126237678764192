import { HStack, Text } from '@chakra-ui/react';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import GlobalPlayerContext from '../../context/global-player-context';
import { ListenToTavernButton } from '../buttons/listen-to-tavern-button';
import { TavernCard } from './TavernCard';
import { TavernCardLiveProps } from './types';
import { db } from '../../connect';
import { formatDurationFromNow } from '../../utils/date';

export const TavernCardLive = (props: TavernCardLiveProps) => {
  const navigate = useNavigate();

  const { connectedTavern } = useContext(GlobalPlayerContext);
  const [listenerCount, setListenerCount] = useState<number | undefined>(props.listenerCount);

  useEffect(() => {
    if (props.tavern?.id === undefined) {
      return;
    }
    setListenerCount(props.listenerCount);
    // If costs to run this query become unsustainable, we can
    // remove and fall back to the listenerCount property on Tavern
    // object which is updated every minute.
    const shardsQuery = query(collection(db, `taverns/${props.tavern.id}/listenerCounterShards`));
    const unsub = onSnapshot(shardsQuery, (querySnapshot) => {
      setListenerCount(
        querySnapshot.docs.reduce((acc, doc) => {
          return acc + (doc.data().count as number);
        }, 0)
      );
    });

    return unsub;
  }, [props.listenerCount, props.tavern?.id]);

  return (
    <TavernCard
      {...props}
      isClickable
      listenerCount={listenerCount}
      onClick={() => {
        navigate(`/taverns/${props.tavern.id}`);
      }}
      buttons={
        <HStack>
          <ListenToTavernButton tavern={props.tavern} isDisabled={props.tavern?.id === connectedTavern?.id} />
        </HStack>
      }
      footerLeft={
        <Text color="typographySecondary" fontSize="xs" ml="16px">{`Started ${formatDurationFromNow(
          props.tavern.startTime
        )}`}</Text>
      }
    />
  );
};
