import React, { FC } from 'react';
import { Flex, Icon, useBreakpointValue, Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { HeaderProps } from './types';
import { IconClose, IconHamburger, IconLogo } from '../../theme/foundations/customIcons';

export const Header: FC<HeaderProps> = (props) => {
  const navigate = useNavigate();
  const { isHamburgerOpen = false, onHamburgerClick } = props;

  const logoDisplay = useBreakpointValue({
    base: 'block',
    lg: 'block',
  });

  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      right="0"
      alignItems="center"
      borderBottom="1px solid"
      borderColor="backgroundInput"
      py="16px"
      px={{ xl: '24px', lg: '16px', md: '14px', sm: '12px' }}
      bgColor="backgroundPrimary"
      zIndex={{ base: '16', xl: '5' }}
    >
      <Flex
        as="button"
        alignItems="center"
        justifyContent="center"
        boxSize="40px"
        bgColor="backgroundInput"
        borderRadius="50%"
        mr="24px"
        onClick={onHamburgerClick}
      >
        <Icon color="typographySecondary" as={isHamburgerOpen ? IconClose : IconHamburger} boxSize="24px" />
      </Flex>
      <Box
        onClick={() => {
          navigate('/');
        }}
        _hover={{
          cursor: 'pointer',
        }}
      >
        <IconLogo display={logoDisplay} />
      </Box>
    </Flex>
  );
};
