import { ActionMeta, OnChangeValue } from 'chakra-react-select';
import { Box, Text, chakra } from '@chakra-ui/react';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { noop } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { TavernSelectCreatable } from '../uiKit/TavernSelectCreatable/TavernSelectCreatable';
import { Topic } from '../../types/tavern';
import { TopicOption } from '../uiKit/TavernSelectCreatable/types';
import { TopicsList } from '../uiKit/TopicsList/TopicsList';
import { TopicsManageFormProps } from './types';
import { auth, db } from '../../connect';

function createOptionFromTopic(topic: Topic) {
  return { value: topic.name, label: topic.name };
}

function getValueFromOption(option: TopicOption) {
  return option.value;
}

const TopicsManageFormComponent = (props: TopicsManageFormProps) => {
  const { topics = [], maxTopics, onChange = noop, ...rest } = props;
  const [selectedTopics, setSelectedTopics] = useState<TopicOption[]>(topics.map(createOptionFromTopic));

  const [defaultTopics, setDefaultTopics] = useState<Topic[]>([]);

  const displayedDefaultTopics = useMemo(() => {
    return defaultTopics.filter((topic) => !selectedTopics.some((selectedTopic) => selectedTopic.value === topic.name));
  }, [defaultTopics, selectedTopics]);

  useEffect(() => {
    if (auth.currentUser?.uid === undefined) {
      return;
    }
    getDocs(query(collection(db, `users/${auth.currentUser.uid}/topics`), orderBy('timestamp', 'desc')))
      .then((snapshot) => {
        setDefaultTopics(snapshot.docs.map((doc) => doc.data() as Topic));
      })
      .catch((error) => {
        console.error('Error getting topics: ', error);
      });
  }, []);

  const addTopicOption = (topic: TopicOption) => {
    if (maxTopics && selectedTopics.length >= maxTopics) {
      return;
    }
    setSelectedTopics((prevState) => {
      const newValue = [...prevState, topic];
      onChange(newValue.map(getValueFromOption));
      return newValue;
    });
  };

  const handleTopicAdded = (topic: TopicOption) => {
    if (selectedTopics.some((selectedTopic) => selectedTopic.value === topic.value)) {
      return;
    }
    addTopicOption(topic);
  };

  const handlePredefinedTopicClick = (topic: Topic) => {
    addTopicOption(createOptionFromTopic(topic));
  };

  const handleClear = () => {
    setSelectedTopics([]);
    onChange([]);
  };

  const handleChange = (newValue: OnChangeValue<TopicOption, true>, actionMeta: ActionMeta<TopicOption>) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        setSelectedTopics([...newValue]);
        onChange(newValue.map(getValueFromOption));
        break;
      case 'clear':
        handleClear();
        break;
    }
  };

  return (
    <Box>
      <Box mb="10px">
        <TavernSelectCreatable
          onAdd={handleTopicAdded}
          value={selectedTopics}
          isMulti
          menuIsOpen={false}
          onChange={handleChange}
          {...rest}
        />
      </Box>

      {defaultTopics && defaultTopics.length ? (
        <Box mb="10px">
          <TopicsList topics={displayedDefaultTopics} onTopicClick={handlePredefinedTopicClick} isClickable />
        </Box>
      ) : null}

      {maxTopics ? (
        <Text textStyle="body12regular" color="typographySecondary">
          Add up to {maxTopics} topics
        </Text>
      ) : null}
    </Box>
  );
};

export const TopicsManageForm = chakra(TopicsManageFormComponent);
