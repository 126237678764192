import { Flex, Text, Icon } from '@chakra-ui/react';
import { ProfileEmptyPanelProps } from './types';

export const ProfileEmptyPanel = (props: ProfileEmptyPanelProps) => {
  const { icon, text = 'Nothing here yet' } = props;

  return (
    <Flex flexDirection="column" alignItems="center" p="40px 0">
      <Icon as={icon} color="typographyTertiary" boxSize="56px" />
      <Text mt="24px" color="typographySecondary">
        {text}
      </Text>
    </Flex>
  );
};
