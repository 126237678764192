import { useContext, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import AlertModal from '../AlertModal/AlertModal';
import GlobalPlayerContext from '../../context/global-player-context';
import { AlertModalProps } from '../AlertModal/types';
import { IconLogOut } from '../../theme/foundations/customIcons';
import { StreamActionButton } from '../uiKit/StreamActionButton/StreamActionButton';
import { SessionType, TavernStatus } from '../../types/tavern';
import { TavernTooltip } from '../uiKit/TavernTooltip/TavernTooltip';
import { leaveAgoraChannel } from '../../services/agora-functions';
import { leaveTavern } from '../../services/tavern-functions';
import { useTavernToast } from '../hooks/useTavernToast';

export const LeaveTavernButton = () => {
  const toast = useTavernToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { connectedTavern, setConnectedTavern, userSession, setUserSession } = useContext(GlobalPlayerContext);

  const [buttonLoading, setButtonLoading] = useState(false);

  if (connectedTavern === null || userSession?.type === SessionType.Host) {
    return <></>;
  }

  const handleLeaving = async () => {
    try {
      setButtonLoading(true);

      if (connectedTavern.status === TavernStatus.Live) {
        await leaveAgoraChannel();
      }
      await leaveTavern(connectedTavern.id);
      onClose();
      toast({
        title: `Left Tavern.`,
        description: "You've successfully left the Tavern.",
        status: 'info',
        duration: 2000,
        isClosable: true,
      });
      setUserSession(null);
      setConnectedTavern(null);
    } catch (error: any) {
      setButtonLoading(false);
      console.error('Error leaving Tavern', JSON.stringify(error));
    }
  };

  const modalProps: AlertModalProps = {
    isOpen,
    onClose,
    isDestructive: true,
    title: 'Leave Tavern',
    description: 'Are you sure you want to leave the Tavern?',
    primaryButton: {
      text: 'Leave',
      isDestructive: true,
      isLoading: buttonLoading,
      onClick: () => {
        handleLeaving().catch((error) => console.error(error));
      },
    },
    secondaryButton: {
      text: 'Cancel',
      onClick: () => {
        onClose();
      },
    },
  };

  return (
    <>
      <TavernTooltip label="Leave Tavern">
        <StreamActionButton
          flexShrink="0"
          mr="4px"
          variant="leave"
          aria-label="leave"
          icon={<IconLogOut w="18px" h="18px" />}
          onClick={onOpen}
        />
      </TavernTooltip>
      <AlertModal {...modalProps} />
    </>
  );
};
