import { defineStyleConfig } from '@chakra-ui/react';

const RoundButtonStyle = defineStyleConfig({
  baseStyle: {
    backgroundColor: 'transparent',
    color: 'typographySecondary',
    marginTop: '0',
    padding: '0',
    _hover: {
      backgroundColor: 'backgroundButton',
    },
    _disabled: {
      opacity: '1',
    },
    '.chakra-spinner': {
      borderWidth: '1px',
      borderBottomColor: 'transparent',
      borderLeftColor: 'transparent',
      borderRightColor: 'transparent',
      animationDuration: '1s',
    },
    span: {
      opacity: '1',
    },
    '.chakra-button__spinner': {
      color: 'typographySecondary',
    },
  },
  sizes: {
    xs: {
      width: '32px',
      height: '32px',
      minWidth: '32px',
      svg: {
        width: '16px',
        height: '16px',
      },
      '.chakra-button__spinner': {
        fontSize: '32px',
      },
    },
    lg: {
      width: '40px',
      height: '40px',
      svg: {
        width: '24px',
        height: '24px',
      },
      '.chakra-button__spinner': {
        fontSize: '40px',
      },
    },
  },
  variants: {
    loading: {
      border: '1px solid',
      borderColor: 'backgroundButton',
    },
    emoji: {
      baseStyle: {
        // borderColor: "red",
        border: 'none',
        background: 'red',
        // fontSize: "200px",
      },
      border: 'none',
      borderColor: 'red',
    },
  },
  defaultProps: {},
});

export default RoundButtonStyle;
