import React, { FC, useContext, useEffect } from 'react';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import AuthContext from './auth';
import GlobalPlayerContext from './global-player-context';
import { db } from '../connect';
import { Session, SessionType, Tavern } from '../types/tavern';
import { joinTavern, listenToTavern } from '../services/tavern-functions';
import { useAudioDevice } from '../hooks/useAudioDevice';

interface AutoJoinTavernWrapperProps {
  children: React.ReactNode;
}

export const AutoJoinTavernWrapper: FC<AutoJoinTavernWrapperProps> = ({ children }) => {
  const { user } = useContext(AuthContext);
  const { lastJoinedTavernCheckComplete, setLastJoinedTavernCheckComplete, setConnectedTavern } =
    useContext(GlobalPlayerContext);
  const { fetchDevices, selectedDevice } = useAudioDevice();
  // If user closed the tab while in a tavern, we want them
  // to auto-rejoin it when they re-open.
  useEffect(() => {
    if (user === null || lastJoinedTavernCheckComplete) {
      return;
    }

    const tavernId = user.lastJoinedLiveTavern?.id;
    if (!tavernId) {
      setLastJoinedTavernCheckComplete(true);
      return;
    }

    const getTavern = async () => {
      const userSessionQuery = query(
        collection(db, `taverns/${tavernId}/sessions`),
        where('user', '==', doc(collection(db, 'users'), user.id))
      );

      const [tavernSnap, userSessionSnap] = await Promise.all([
        getDoc(doc(db, 'taverns', tavernId)),
        getDocs(userSessionQuery),
      ]);

      if (tavernSnap.exists() && userSessionSnap.docs.length > 0) {
        const tavern = tavernSnap.data() as Tavern;
        const sessions = userSessionSnap.docs.map((sessionDoc) => sessionDoc.data() as Session);

        // Depending on timing, the user's most recent session may have not
        // ended. If so, we'll use that. Otherwise, we'll use the most recently ended.
        let mostRecentSession = sessions.find((session) => session.endTime === null);

        if (!mostRecentSession) {
          mostRecentSession = sessions.reduce((prev, current) => (prev.startTime > current.startTime ? prev : current));
        }

        if (tavern.endTime === undefined) {
          // eslint-disable-next-line no-debugger
          switch (mostRecentSession.type) {
            case SessionType.Host:
            case SessionType.CoHost:
            case SessionType.Speaker:
              await fetchDevices();
              // isMuted is taken from the last session, to ensure that user is muted on refresh
              await joinTavern(
                tavern.id,
                // WE ARE GOING TO FORCE MUTE ON RECONNECT
                // !(mostRecentSession.isMuted || mostRecentSession.isForceMuted),
                false,
                undefined,
                selectedDevice
              );
              break;
            case SessionType.Joiner:
              await joinTavern(tavern.id, false);
              break;
            case SessionType.Listener:
              await listenToTavern(tavern.id);
              break;
          }

          setConnectedTavern(tavern);
        }
      }
      setLastJoinedTavernCheckComplete(true);
    };
    getTavern().catch((error) => {
      console.error(error);
    });
  }, [user, lastJoinedTavernCheckComplete]);

  return <>{children}</>;
};
