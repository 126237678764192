import { UseToastOptions } from '@chakra-ui/react';

const error = {
  position: 'bottom',
  status: 'error',
  duration: 5000,
} as UseToastOptions;

export const alreadyUploadedError: UseToastOptions = {
  ...error,
  title: `File already uploaded`,
  description: 'This file is already exists and is available.',
};

export const fileExceededError: UseToastOptions = {
  ...error,
  title: `File exceeds max size`,
  description: 'Try compressing the file or uploading a smaller file.',
};

export const uploadError: UseToastOptions = {
  ...error,
  title: `Upload interrupted`,
  description: 'Check your internet connection and try again.',
};

export const errorValidationError: UseToastOptions = {
  ...error,
  title: `Corrupted file`,
  description: 'Try playing the file on your local machine to confirm if the file is intact.',
};

export const abortedError: UseToastOptions = {
  ...error,
  title: `Unknown error`,
  description: 'Contact the platform support team for assistance.',
};

export const errorUploadParamsError: UseToastOptions = {
  ...error,
  title: `Server error`,
  description: 'Try uploading the audio file at a later time.',
};

export const rejectedFileTypeError: UseToastOptions = {
  ...error,
  title: `Unsupported type`,
  description: 'File type is not supported',
};
