import { httpsCallable } from 'firebase/functions';

import { SessionType } from '../types/tavern';
import { fbFunctions } from '../connect';

export const acceptInviteToSpeak = async (tavernId: string, inviteId: string) => {
  const accept = httpsCallable<{
    tavernId: string;
    inviteId: string;
  }>(fbFunctions, 'acceptInviteToSpeak');
  await accept({
    tavernId,
    inviteId,
  });
};

export const rejectInviteToSpeak = async (tavernId: string, inviteId: string) => {
  const reject = httpsCallable<{
    tavernId: string;
    inviteId: string;
  }>(fbFunctions, 'rejectInviteToSpeak');
  await reject({
    tavernId,
    inviteId,
  });
};

export const inviteUserToSpeak = async (
  tavernId: string,
  promoteeUserId: string,
  type: SessionType,
  promoteeName: string | null,
  promoteeAvatar?: string
): Promise<void> => {
  const inviteToSpeak = httpsCallable<{
    tavernId: string;
    promoteeUserId: string;
    type: SessionType;
    promoteeName: string | null;
    promoteeAvatar?: string;
  }>(fbFunctions, 'inviteToSpeak');
  await inviteToSpeak({
    tavernId,
    promoteeUserId,
    type,
    promoteeName,
    promoteeAvatar,
  });
};

export const cancelInviteUserToSpeak = async (tavernId: string, inviteId: string): Promise<void> => {
  const cancel = httpsCallable<{
    tavernId: string;
    inviteId: string;
  }>(fbFunctions, 'cancelInviteToSpeak');
  await cancel({
    tavernId,
    inviteId,
  });
};
