import ReactPlayer from 'react-player';
import { VStack } from '@chakra-ui/react';
import { doc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LinkExtra } from '../../types/tavern';
import { MediaBlockMetadata } from '../MediaBlockMetadata/MediaBlockMetadata';
import { db, storage } from '../../connect';

export const VideoBlock = (props: { link: LinkExtra; hasHostingPermission: boolean }) => {
  const { tavernId } = useParams();
  const { link, hasHostingPermission } = props;

  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>(undefined);

  const playerRef = useRef<ReactPlayer>(null);

  useEffect(() => {
    if (link.type === 'url') {
      setDownloadUrl(link.link);
    } else if (link.type === 'storage-video' || link.type === 'storage-image') {
      getDownloadURL(ref(storage, link.link))
        .then((url) => setDownloadUrl(url))
        .catch(console.error);
    }
  }, [link.type, link.link]);

  useEffect(() => {
    const { video } = link;
    if (!video) {
      return;
    }

    if (video.state === 'playing') {
      playerRef.current?.seekTo(video.lastUpdatedPosition);
      setIsPlaying(true);
    } else if (video.state === 'paused') {
      playerRef.current?.seekTo(video.lastUpdatedPosition);
      setIsPlaying(false);
    }
  }, [link]);

  const setState = async (state: 'playing' | 'paused') => {
    if (hasHostingPermission) {
      try {
        await setDoc(
          doc(db, `taverns/${tavernId as string}/links/${link.id}`),
          { video: { lastUpdatedPosition: progress, state } },
          { merge: true }
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <VStack height="100%" width="100%">
      <ReactPlayer
        ref={playerRef}
        playing={isPlaying}
        url={downloadUrl}
        controls
        muted={!hasHostingPermission}
        height="100%"
        width="auto"
        // maxHeight="100%"
        // maxWidth="100%"
        onPlay={async () => {
          setIsPlaying(true);
          await setState('playing');
        }}
        onPause={async () => {
          setIsPlaying(false);
          await setState('paused');
        }}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        onProgress={(progress) => {
          setProgress(progress.playedSeconds);
        }}
      />
      <MediaBlockMetadata link={link} hasHostingPermission={hasHostingPermission} />
    </VStack>
  );
};
