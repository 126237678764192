export enum SortingValue {
  ASC = 'asc',
  DESC = 'desc',
}

// extend this type for your specific dropdowns
export type SortingField = {
  // better keep is unique
  name: string;
  // asc, desc, etc
  value: SortingValue;
};

export interface OrderSortingPanelProps {
  fields: SortingField[];
  selected?: SortingField;
  onChange: (type: string, newValue: SortingValue) => void;
}
