import { SessionType } from '../types/tavern';
import { User, UserNoSession } from '../types/user';

export const followingUserAsUserMock = (user: User): UserNoSession => {
  return {
    id: user.id,
    name: user.name ?? user.id,
    sessionType: SessionType.Listener,
    avatar: user.avatar,
    nft: user.nft,
  };
};
