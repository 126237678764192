import { defineStyleConfig } from '@chakra-ui/react';

const TavernHeadingStyles = defineStyleConfig({
  baseStyle: {
    textStyle: { base: 'h3', xl: 'h2' },
    fontWeight: 'regular',
  },
});

export default TavernHeadingStyles;
