import { Flex, Spacer, Text } from '@chakra-ui/react';

import { AudioMixerTriggerProps } from './types';
import { Iconlive, IconPlaylist, IconUnLive } from '../../theme/foundations/customIcons';

export const AudioMixerStatus = (props: AudioMixerTriggerProps) => {
  const { numberPlaying, hasTrack } = props;

  return (
    <Flex
      w="100%"
      background="backgroundMixerButton"
      borderRadius="24px"
      p="12px 16px"
      minW={hasTrack ? 385 : undefined}
    >
      <Flex>
        {hasTrack ? (
          <Iconlive boxSize="24px" color="typographySecondary" />
        ) : (
          <IconUnLive boxSize="24px" color="typographySecondary" />
        )}

        <Text textStyle="body16regular" color="typographyPrimary" m="0 12px">
          Upload Media
        </Text>

        {hasTrack && (
          <Text textStyle="body16regular" color="typographySecondary">
            {numberPlaying === 0 ? 'not playing' : `playing track`}
          </Text>
        )}
      </Flex>

      <Spacer />

      <IconPlaylist ml="12px" boxSize="24px" color="#6C6C6C" />
    </Flex>
  );
};
