import { FC, useContext, useState } from 'react';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { Box, Flex, VStack } from '@chakra-ui/react';
import { TavernInput } from '../uiKit/TavernInput/TavernInput';
import { IconSearch } from '../../theme/foundations/customIcons';
import { NFTCollection } from '../uiKit/NFTCollection/NFTCollection';
import { ProfileNFTsListProps } from './types';
import { NftData } from '../NFTInfoModal/types';
import AuthContext from '../../context/auth';

export const NFTsList: FC<ProfileNFTsListProps> = ({
  allowDefault,
  collections,
  isSuitable,
  isSmall,
  setSelectedNft,
}) => {
  const { user } = useContext(AuthContext);
  const [searchQuery, setSearchQuery] = useState('');

  const handleOnNftCardClick = (value: NftData) => {
    if (setSelectedNft) {
      setSelectedNft(value.id);
    }
  };

  const nfts = _.flatMap(collections, 'tokens');

  const fuse = new Fuse(nfts, {
    includeScore: false,
    keys: ['name', 'tokenId', 'properties.value', 'collection.name'],
  });
  const searchResults = fuse.search(searchQuery);

  const grouped = _.chain(searchResults)
    .map((r) => r.item)
    .groupBy('collection.id')
    .map((tokens) => {
      const details = _.clone(tokens[0].collection);
      return {
        ...details,
        tokens,
      };
    })
    .value();

  const results = searchQuery.length ? grouped : collections.filter((g) => !_.isEmpty(g.tokens));

  return (
    <>
      <Flex width="100%" height="100%" flexDirection="column">
        <TavernInput
          leftIcon={<IconSearch />}
          value={searchQuery}
          placeholder="Search by token or collection"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Box maxHeight="60vh" overflowY="auto">
          <VStack spacing="32px">
            {allowDefault && (
              <NFTCollection
                isSuitable={isSuitable}
                isSmall={isSmall}
                collection={{
                  id: '1',
                  image: user?.avatar,
                  name: 'Tavern Faces',
                  tokens: [
                    {
                      id: 'default',
                      image: user?.avatar || '',
                      tokenId: '',
                      owner: '',
                      creator: '',
                      properties: [],
                      collection: {
                        id: '1',
                        image: user?.avatar,
                        name: 'Tavern Faces',
                      },
                    },
                  ],
                }}
                onClick={handleOnNftCardClick}
              />
            )}
            {results.map((collection) => (
              <NFTCollection
                isSuitable={isSuitable}
                isSmall={isSmall}
                key={collection.id}
                collection={collection}
                onClick={handleOnNftCardClick}
              />
            ))}
          </VStack>
        </Box>
      </Flex>
    </>
  );
};
