import { chakra, VStack } from '@chakra-ui/react';
import { getDownloadURL, ref } from 'firebase/storage';
import { useEffect, useState } from 'react';

import { ExpandableImage } from '../ExpandableImage/ExpandableImage';
import { LinkExtra } from '../../types/tavern';
import { MediaBlockMetadata } from '../MediaBlockMetadata/MediaBlockMetadata';
import { storage } from '../../connect';

const ImageBlockComponent = (props: { link: LinkExtra; hasHostingPermission: boolean }) => {
  const { link, hasHostingPermission, ...rest } = props;

  const [downloadUrl, setDownloadUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (link.type === 'url') {
      setDownloadUrl(link.link);
    } else if (link.type === 'storage-video' || link.type === 'storage-image') {
      getDownloadURL(ref(storage, link.link))
        .then((url) => setDownloadUrl(url))
        .catch(console.error);
    }
  }, [link.type, link.link]);

  return (
    <VStack w="100%" h="100%" {...rest}>
      <ExpandableImage src={downloadUrl} flex="1 0 auto" w="100%" h="100%" />
      <MediaBlockMetadata link={link} hasHostingPermission={hasHostingPermission} />
    </VStack>
  );
};

export const ImageBlock = chakra(ImageBlockComponent);
