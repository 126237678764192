import { MenuItem, Spinner } from '@chakra-ui/react';
import { ReactElement, useEffect, useState } from 'react';
import {
  IconAsterisk,
  IconBlock,
  IconJoined,
  IconMicrophoneOff,
  IconMicrophoneOn,
  IconSpeaker,
  IconStar,
  IconDelete,
} from '../../../theme/foundations/customIcons';
import { Session, SessionType } from '../../../types/tavern';
import { UserMock } from '../../../types/user';
import {
  banUserFromTavern,
  moveSessionToType,
  unbanUserFromTavern,
  promoteUserInTavern,
  demoteUserInTavern,
} from '../../../services/user-functions';
import { muteSession } from '../../../services/session-functions';
import { cancelInviteUserToSpeak, inviteUserToSpeak } from '../../../services/invite-functions';
import { useTavernToast } from '../../hooks/useTavernToast';
import { userIdFromSession } from '../../../utils/user-util';
import { noop } from '../../../utils/noop';

// TODO: For dropdown items, use useMenu hook to close menu after action is complete.
export const speakingDropDownItems = (displayedUser: UserMock, userSession: Session | null, tavernId: string) => {
  if (
    (userSession?.type !== SessionType.Host && userSession?.type !== SessionType.CoHost) ||
    displayedUser.id === userSession?.id ||
    userSession === null
  ) {
    return undefined;
  }

  const items = [<ForceMuteDropdownItem tavernId={tavernId} targetSession={displayedUser.session} />];

  if (displayedUser.sessionType === SessionType.CoHost) {
    items.push(<MoveToSpeakerDropdownItem tavernId={tavernId} targetSession={displayedUser.session} />);
  } else {
    if (displayedUser.sessionType !== SessionType.Speaker) {
      items.push(<InviteToSpeakDropdownItem tavernId={tavernId} targetSession={displayedUser.session} />);
    }
    items.push(<InviteToCoHostDropdownItem tavernId={tavernId} targetSession={displayedUser.session} />);
  }

  items.push(<MoveToJoinerDropdownItem tavernId={tavernId} targetSession={displayedUser.session} />);

  if (displayedUser.id !== userSession?.user.id) {
    items.push(<BlockDropdownItem tavernId={tavernId} targetSession={displayedUser.session} />);
  }

  return items;
};

export const joinerDropDownItems = (displayedUser: UserMock, userSession: Session | null, tavernId: string) => {
  if ((userSession?.type !== SessionType.Host && userSession?.type !== SessionType.CoHost) || userSession === null) {
    return undefined;
  }

  return [
    <InviteToSpeakDropdownItem tavernId={tavernId} targetSession={displayedUser.session} />,
    <InviteToCoHostDropdownItem tavernId={tavernId} targetSession={displayedUser.session} />,
    <BlockDropdownItem tavernId={tavernId} targetSession={displayedUser.session} />,
  ];
};
export const invitedDropDownItems = (tavernId: string, inviteId: string) => {
  return [<CancelInviteDropdownItem tavernId={tavernId} inviteId={inviteId} />];
};

export const blockedDropdownItems = (displayedUser: UserMock, tavernId: string) => {
  return [<UnblockDropdownItem tavernId={tavernId} userId={displayedUser.id} />];
};

export const goingDropdownItems = (userId: string, tavernId: string) => {
  return [<GoingPromoteDropdownItem tavernId={tavernId} userId={userId} />];
};

export const goingSpeakerDropdownItems = (userId: string, tavernId: string) => {
  return [<GoingDemoteDropdownItem tavernId={tavernId} userId={userId} />];
};

export const CancelInviteDropdownItem = (props: {
  tavernId: string;
  inviteId: string;
  onClose?: () => void;
  onProcessing?: (value: boolean) => void;
}) => {
  const { onClose = noop, onProcessing = noop } = props;
  const toast = useTavernToast();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onProcessing(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleCancelInvite = async () => {
    setIsLoading(true);
    try {
      await cancelInviteUserToSpeak(props.tavernId, props.inviteId);
      toast({
        title: `Cancelled invite.`,
        description: "You've successfully cancelled this user's invite to speak.",
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.error('Error cancelling invite', JSON.stringify(error));
    }
    setIsLoading(false);
  };

  return (
    <MenuItem
      icon={isLoading ? <Spinner /> : <IconBlock />}
      onClick={() => {
        handleCancelInvite().catch(console.error);
      }}
    >
      Cancel Invite
    </MenuItem>
  );
};

const InviteToSpeakDropdownItem = (props: {
  tavernId: string;
  targetSession: Session;
  onClose?: () => void;
  onProcessing?: (value: boolean) => void;
}) => {
  const { onClose = noop, onProcessing = noop } = props;
  const toast = useTavernToast();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onProcessing(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleSpeakerInvite = async () => {
    setIsLoading(true);
    try {
      await inviteUserToSpeak(
        props.tavernId,
        userIdFromSession(props.targetSession),
        SessionType.Speaker,
        props.targetSession.name,
        props.targetSession.nft || props.targetSession.avatar
      );
      toast({
        title: `Invited user to become speaker.`,
        description: "You've successfully invited this user to speak.",
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      onClose();
    } catch (error: any) {
      console.error('Error inviting user to speak', JSON.stringify(error));
      if (error.code === 'functions/already-exists') {
        toast({
          title: `Already invited.`,
          description: 'This user already has an invite pending.',
          status: 'info',
          duration: 2000,
          isClosable: true,
        });
      } else if (error.code === 'functions/permission-denied') {
        toast({
          title: `Invite error`,
          description: "Can't invite new speaker, user may be banned or room is at limit",
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <MenuItem
      icon={isLoading ? <Spinner /> : <IconSpeaker />}
      closeOnSelect={false}
      onClick={() => {
        handleSpeakerInvite().catch(console.error);
      }}
    >
      Invite as a Speaker
    </MenuItem>
  );
};

const BlockDropdownItem = (props: {
  tavernId: string;
  targetSession: Session;
  onClose?: () => void;
  onProcessing?: (value: boolean) => void;
}) => {
  const { onClose = noop, onProcessing = noop } = props;
  const toast = useTavernToast();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onProcessing(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleBlock = async () => {
    setIsLoading(true);
    try {
      await banUserFromTavern(props.tavernId, userIdFromSession(props.targetSession));
      toast({
        title: `Blocked user.`,
        description: "You've blocked a user from the tavern.",
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      onClose();
    } catch (error: any) {
      console.error('Error blocking user', JSON.stringify(error));
    }
    setIsLoading(false);
  };

  return (
    <MenuItem
      icon={isLoading ? <Spinner /> : <IconBlock />}
      closeOnSelect={false}
      onClick={() => {
        handleBlock().catch(console.error);
      }}
    >
      Block
    </MenuItem>
  );
};

const UnblockDropdownItem = (props: {
  tavernId: string;
  userId: string;
  onClose?: () => void;
  onProcessing?: (value: boolean) => void;
}) => {
  const { onClose = noop, onProcessing = noop } = props;
  const toast = useTavernToast();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onProcessing(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleUnblock = async () => {
    setIsLoading(true);
    try {
      await unbanUserFromTavern(props.tavernId, props.userId);
      toast({
        title: `Unbanned user.`,
        description: "You've unbanned a user from the tavern.",
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.error('Error unblocking user', JSON.stringify(error));
    }
    setIsLoading(false);
  };

  return (
    <MenuItem
      icon={isLoading ? <Spinner /> : <IconBlock />}
      closeOnSelect={false}
      onClick={() => {
        handleUnblock().catch(console.error);
      }}
    >
      Unblock
    </MenuItem>
  );
};

const MoveToSpeakerDropdownItem = (props: {
  tavernId: string;
  targetSession: Session;
  onClose?: () => void;
  onProcessing?: (value: boolean) => void;
}) => {
  const { onClose = noop, onProcessing = noop } = props;
  const toast = useTavernToast();

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    onProcessing(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleMoveToSpeaker = async () => {
    setIsLoading(true);
    try {
      await moveSessionToType(SessionType.Speaker, props.tavernId, userIdFromSession(props.targetSession));
      toast({
        title: `Moved user to speaker.`,
        description: "You've successfully moved this user to the speaker role.",
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      onClose();
    } catch (error: any) {
      console.error('Error moving user to speaker', JSON.stringify(error));
    }
    setIsLoading(false);
  };

  return (
    <MenuItem
      icon={isLoading ? <Spinner /> : <IconSpeaker />}
      closeOnSelect={false}
      onClick={() => {
        handleMoveToSpeaker().catch(console.error);
      }}
    >
      Move to Speaker
    </MenuItem>
  );
};

const InviteToCoHostDropdownItem = (props: {
  tavernId: string;
  targetSession: Session;
  onClose?: () => void;
  onProcessing?: (value: boolean) => void;
}) => {
  const { onClose = noop, onProcessing = noop } = props;
  const toast = useTavernToast();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onProcessing(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleInviteCoHost = async () => {
    setIsLoading(true);
    try {
      await inviteUserToSpeak(
        props.tavernId,
        userIdFromSession(props.targetSession),
        SessionType.CoHost,
        props.targetSession.name,
        props.targetSession.avatar
      );
      toast({
        title: `Invited user to Co-Host.`,
        description: "You've successfully invited this user to co-host.",
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      onClose();
    } catch (error: any) {
      console.error('Error inviting user to co-host: ', JSON.stringify(error));
      if (error.code === 'functions/already-exists') {
        toast({
          title: `Already invited.`,
          description: 'This user already has an invite pending.',
          status: 'info',
          duration: 2000,
          isClosable: true,
        });
      } else if (error.code === 'functions/permission-denied') {
        toast({
          title: `Invite error`,
          description: "Can't invite new Co-Host, user may be banned or room is at limit",
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <MenuItem
      icon={isLoading ? <Spinner /> : <IconAsterisk />}
      closeOnSelect={false}
      onClick={() => {
        handleInviteCoHost().catch(console.error);
      }}
    >
      Invite as a Co-Host
    </MenuItem>
  );
};

export const MoveToJoinerDropdownItem = (props: {
  tavernId: string;
  targetSession: Session;
  toastTitle?: string;
  toastDescription?: string;
  onClose?: () => void;
  onProcessing?: (value: boolean) => void;
}) => {
  const { onClose = noop, onProcessing = noop } = props;
  const toast = useTavernToast();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onProcessing(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleMoveToJoiner = async () => {
    setIsLoading(true);
    try {
      await muteSession(props.tavernId, props.targetSession.id, {
        isMuted: true,
      });
      await moveSessionToType(SessionType.Joiner, props.tavernId, userIdFromSession(props.targetSession));
      toast({
        title: props.toastTitle ?? `Moved user to audience.`,
        description: props.toastDescription ?? "You've successfully moved this user to audience.",
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      onClose();
    } catch (error: any) {
      console.error('Error moving user to joiner', JSON.stringify(error));
    }
    setIsLoading(false);
  };

  return (
    <MenuItem
      icon={isLoading ? <Spinner /> : <IconJoined />}
      closeOnSelect={false}
      onClick={() => {
        handleMoveToJoiner().catch(console.error);
      }}
    >
      Move to Audience
    </MenuItem>
  );
};

const ForceMuteDropdownItem = (props: {
  tavernId: string;
  targetSession: Session;
  onClose?: () => void;
  onProcessing?: (value: boolean) => void;
}) => {
  const { onClose = noop, onProcessing = noop, targetSession } = props;
  const toast = useTavernToast();

  const [isLoading, setIsLoading] = useState(false);

  const { isForceMuted } = targetSession;

  useEffect(() => {
    onProcessing(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleMute = async () => {
    setIsLoading(true);
    try {
      const newForceMuted = !isForceMuted;
      await muteSession(props.tavernId, props.targetSession.id, {
        isForceMuted: newForceMuted,
        isMuted: isForceMuted ? true : undefined,
      });
      toast({
        title: `${newForceMuted ? 'Muted' : 'Umuted'} user.`,
        description: `You've successfully ${newForceMuted ? 'muted' : 'unmuted'} this user.`,
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      onClose();
    } catch (error: any) {
      console.error('Error force muting/unmuting user', JSON.stringify(error));
    }
    setIsLoading(false);
  };

  let micIcon: ReactElement;
  if (isLoading) {
    micIcon = <Spinner />;
  } else {
    micIcon = !props.targetSession.isForceMuted ? <IconMicrophoneOff /> : <IconMicrophoneOn />;
  }

  return (
    <MenuItem
      isDisabled={isLoading}
      icon={micIcon}
      closeOnSelect={false}
      onClick={() => {
        handleMute().catch(console.error);
      }}
    >
      {isForceMuted ? 'Unmute' : 'Mute'}
    </MenuItem>
  );
};

const GoingPromoteDropdownItem = (props: {
  tavernId: string;
  userId: string;
  onClose?: () => void;
  onProcessing?: (value: boolean) => void;
}) => {
  const { onClose = noop, onProcessing = noop } = props;
  const toast = useTavernToast();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onProcessing(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handlePromote = async () => {
    setIsLoading(true);
    try {
      await promoteUserInTavern(props.tavernId, props.userId);

      toast({
        title: `Promoted user.`,
        description: "You've promoted the user in the tavern.",
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.error('Error promoting user', JSON.stringify(error));
    }
    setIsLoading(false);
  };

  return (
    <MenuItem
      icon={isLoading ? <Spinner /> : <IconStar />}
      closeOnSelect={false}
      onClick={() => {
        handlePromote().catch(console.error);
      }}
    >
      Promote
    </MenuItem>
  );
};

const GoingDemoteDropdownItem = (props: {
  tavernId: string;
  userId: string;
  onClose?: () => void;
  onProcessing?: (value: boolean) => void;
}) => {
  const { onClose = noop, onProcessing = noop } = props;
  const toast = useTavernToast();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onProcessing(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handlePromote = async () => {
    setIsLoading(true);
    try {
      await demoteUserInTavern(props.tavernId, props.userId);

      toast({
        title: `Demote user.`,
        description: "You've demoted the user in the tavern.",
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.error('Error demoting user', JSON.stringify(error));
    }
    setIsLoading(false);
  };

  return (
    <MenuItem
      icon={isLoading ? <Spinner /> : <IconDelete />}
      closeOnSelect={false}
      onClick={() => {
        handlePromote().catch(console.error);
      }}
    >
      Demote
    </MenuItem>
  );
};
