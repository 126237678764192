import { useState } from 'react';
import { Avatar, Flex, HStack, Link, Text, useColorModeValue } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { IconTwitter } from '../../theme/foundations/customIcons';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';
import { SpeakRequest } from '../../types/tavern';
import { acceptRequestToSpeak, rejectRequestToSpeak } from '../../services/request-functions';
import { useTavernToast } from '../hooks/useTavernToast';

export const RequestBlock = (props: { request: SpeakRequest }) => {
  const name = props.request.name ?? props.request.user.id;
  const twitter = undefined;

  const { tavernId } = useParams();
  const toast = useTavernToast();

  const [acceptIsLoading, setAcceptIsLoading] = useState(false);
  const [rejectIsLoading, setRejectIsLoading] = useState(false);

  const avatarBg = useColorModeValue('white', 'black');

  return (
    <Flex
      w="100%"
      alignItems={{ base: 'flex-start', xl: 'center' }}
      justifyContent="space-between"
      p="16px"
      bg="backgroundInput"
      flexDirection={{ base: 'column', xl: 'row' }}
      maxW={{ base: '100%', xl: '825px' }}
    >
      <Flex alignItems={{ base: 'flex-start', xl: 'center' }}>
        <Avatar size="md" src={props.request.avatar} name={name} bg={avatarBg} icon={<></>} border="1px solid white" />
        <Flex alignItems="center" ml="16px">
          <Text textStyle="body16regular">{name}</Text>
          {twitter && (
            <Link
              display="flex"
              boxSize="16px"
              ml="8px"
              href={`https://twitter.com/${twitter as string}`}
              _hover={{ textDecoration: 'none' }}
              target="_blank"
            >
              <IconTwitter color="typographySecondary" boxSize="16px" />
            </Link>
          )}
        </Flex>
      </Flex>
      <HStack mt={{ base: '-8px', xl: '0' }} ml={{ base: '64px', xl: '16px' }} spacing="16px">
        <TavernButton
          isLoading={acceptIsLoading}
          disabled={acceptIsLoading || rejectIsLoading}
          variant="secondary"
          onClick={() => {
            setAcceptIsLoading(true);

            acceptRequestToSpeak(tavernId as string, props.request.id)
              .then(() => {
                toast({
                  title: 'Accepted request to speak.',
                  description: 'This user is now a Speaker.',
                  status: 'success',
                  duration: 2000,
                  isClosable: true,
                });

                setAcceptIsLoading(false);
              })
              .catch((error) => {
                console.error(error);
                toast({
                  title: 'Failed to accept request to speak.',
                  description: 'Accept request to speak failed, try again.',
                  status: 'error',
                  duration: 2000,
                  isClosable: true,
                });

                setAcceptIsLoading(false);
              });
          }}
        >
          Accept
        </TavernButton>
        <TavernButton
          isLoading={rejectIsLoading}
          disabled={acceptIsLoading || rejectIsLoading}
          variant="tertiary"
          onClick={() => {
            setRejectIsLoading(true);
            rejectRequestToSpeak(tavernId as string, props.request.id)
              .then(() => {
                setRejectIsLoading(false);
              })
              .catch((error) => console.error(error));
          }}
        >
          Reject
        </TavernButton>
      </HStack>
    </Flex>
  );
};
