/* eslint-disable */
import { VStack, Text, SimpleGrid, Box, Stack, Flex, IconButton } from '@chakra-ui/react';
import { useDayzed, Props as DayzedHookProps } from 'dayzed';
import { ArrowKeysReact } from '../types';
import React, { Fragment, useCallback, useMemo } from 'react';
import { CalendarConfigs, DatepickerProps } from '../types';
import { DayOfMonth } from './dayOfMonth';
import { IconChevronLeft, IconChevronRight } from '../../../../theme/foundations/customIcons';

interface CalendarPanelProps extends DatepickerProps {
  dayzedHookProps: Omit<DayzedHookProps, 'children' | 'render'>;
  configs: CalendarConfigs;
  onMouseEnterHighlight?: (date: Date) => void;
  isInRange?: (date: Date) => boolean | null;
}

export const CalendarPanel: React.FC<CalendarPanelProps> = ({
  dayzedHookProps,
  configs,
  onMouseEnterHighlight,
  isInRange,
}) => {
  const renderProps = useDayzed(dayzedHookProps);
  const { calendars, getBackProps, getForwardProps } = renderProps;

  const weekdayNames = useMemo(() => {
    const firstDayOfWeek = configs.firstDayOfWeek;
    const dayNames = configs.dayNames;
    if (firstDayOfWeek && firstDayOfWeek > 0) {
      return configs.dayNames.slice(firstDayOfWeek, dayNames.length).concat(dayNames.slice(0, firstDayOfWeek));
    }
    return dayNames;
  }, [configs.firstDayOfWeek, configs.dayNames]);

  // looking for a useRef() approach to replace it
  const getKeyOffset = useCallback((num: number) => {
    const e = document.activeElement;
    let buttons = document.querySelectorAll('button');
    buttons.forEach((el, i) => {
      const newNodeKey = i + num;
      if (el === e) {
        if (newNodeKey <= buttons.length - 1 && newNodeKey >= 0) {
          buttons[newNodeKey].focus();
        } else {
          buttons[0].focus();
        }
      }
    });
  }, []);

  const arrowKeysReact = new ArrowKeysReact({
    left: () => {
      getKeyOffset(-1);
    },
    right: () => {
      getKeyOffset(1);
    },
    up: () => {
      getKeyOffset(-7);
    },
    down: () => {
      getKeyOffset(7);
    },
  });

  if (calendars.length <= 0) {
    return null;
  }

  return (
    <Stack className="datepicker-calendar" direction={['column', 'column', 'row']} {...arrowKeysReact.getEvents()}>
      {calendars.map((calendar, calendarIdx) => {
        return (
          <VStack key={calendarIdx} height="100%" padding="20px">
            <Flex w="100%" justifyContent="space-between" alignItems="center" gap="2">
              <Text textStyle="body16regular" color="typographyPrimary">
                {configs.monthNames[calendar.month]} {calendar.year}
              </Text>
              <Box as="div">
                <Fragment>
                  <IconButton
                    backgroundColor="transparent"
                    color="typographySecondary"
                    fontSize="lg"
                    w="32px"
                    h="32px"
                    _hover={{
                      backgroundColor: 'transparent',
                      color: 'globalWhite',
                    }}
                    _focus={{
                      backgroundColor: 'transparent',
                    }}
                    _active={{
                      backgroundColor: 'transparent',
                    }}
                    aria-label="Back"
                    icon={<IconChevronLeft />}
                    {...getBackProps({ calendars })}
                  />
                </Fragment>

                <Fragment>
                  <IconButton
                    backgroundColor="transparent"
                    color="typographySecondary"
                    fontSize="lg"
                    w="32px"
                    h="32px"
                    _hover={{
                      backgroundColor: 'transparent',
                      color: 'globalWhite',
                    }}
                    _focus={{
                      backgroundColor: 'transparent',
                    }}
                    _active={{
                      backgroundColor: 'transparent',
                    }}
                    aria-label="Forward"
                    icon={<IconChevronRight />}
                    {...getForwardProps({ calendars })}
                  />
                </Fragment>
              </Box>
            </Flex>
            <SimpleGrid columns={7} spacing={0} mt="0!important" textAlign="center">
              {weekdayNames.map((day, dayIdx) => (
                <Box
                  textStyle="body12regular"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  w="36px"
                  h="36px"
                  color="typographySecondary"
                  textTransform="uppercase"
                  key={dayIdx}
                >
                  {day}
                </Box>
              ))}
              {calendar.weeks.map((week, weekIdx) => {
                return week.map((dateObj, index) => {
                  const key = `${calendar.month}-${calendar.year}-${weekIdx}-${index}`;
                  if (!dateObj) return <Box w="36px" h="36px" bg="red" key={key} />;
                  const { date } = dateObj;
                  return (
                    <>
                      <DayOfMonth
                        key={key}
                        dateObj={dateObj}
                        renderProps={renderProps}
                        isInRange={isInRange && isInRange(date)}
                        onMouseEnter={() => {
                          if (onMouseEnterHighlight) onMouseEnterHighlight(date);
                        }}
                      />
                    </>
                  );
                });
              })}
            </SimpleGrid>
          </VStack>
        );
      })}
    </Stack>
  );
};
