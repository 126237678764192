import React, { createContext, FC, useEffect, useState } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { usePrevious } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import { changeMicDevice, changeSpeakerDevice } from '../services/agora-functions';
import { noop } from '../utils/noop';

interface MicrophoneDeviceContextProps {
  audioDevices: MediaDeviceInfo[];
  selectedDevice?: string;
  setSelectedDevice: (deviceId: string) => void;
  fetchDevices: (skipPermissionCheck?: boolean) => Promise<void>;
  isDevicesSupported: boolean;
  audioSpeakers: MediaDeviceInfo[];
  selectedSpeaker?: string;
  setSelectedSpeaker: (deviceId: string) => void;
}

interface MicrophoneDeviceProviderProps {
  children: React.ReactNode;
}

export const MicrophoneDeviceContext = createContext<MicrophoneDeviceContextProps>({
  audioDevices: [],
  selectedDevice: undefined,
  setSelectedDevice: noop,
  fetchDevices: async () => {},
  isDevicesSupported: false,
  audioSpeakers: [],
  selectedSpeaker: undefined,
  setSelectedSpeaker: noop,
});

function useAudioOutputDevices(devices: MediaDeviceInfo[], setDevices: any) {
  useEffect(() => {
    const fetchDevices = () => {
      if (navigator.mediaDevices && typeof navigator.mediaDevices.enumerateDevices === 'function') {
        navigator.mediaDevices
          .enumerateDevices()
          .then((allDevices) => {
            const outputDevices = allDevices.filter((device) => device.kind === 'audiooutput');
            setDevices(outputDevices);
          })
          .catch((err) => {
            console.error('Error accessing devices: ', err);
          });
      } else {
        console.warn('Browser does not support MediaDevices.enumerateDevices()');
      }
    };

    fetchDevices();

    const handleDeviceChange = () => {
      fetchDevices();
    };

    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
    };
  }, []);

  return devices;
}

export const AudioDeviceProvider: FC<MicrophoneDeviceProviderProps> = ({ children }) => {
  const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
  const prevAudioDevices = usePrevious(audioDevices);
  const [selectedDevice, setSelectedDevice] = useState<string>();
  const isDevicesSupported = navigator.mediaDevices && 'enumerateDevices' in navigator.mediaDevices;
  const [audioSpeakers, setAudioSpeakers] = useState<MediaDeviceInfo[]>([]);
  const [selectedSpeaker, setSelectedSpeaker] = useState<string>();

  useAudioOutputDevices(audioSpeakers, setAudioSpeakers);

  useEffect(() => {
    if (isEqual(prevAudioDevices, audioDevices) || !audioDevices || !audioDevices.length) {
      return;
    }
    // If the previously selected device is not in the list anymore, select the first device in the list
    if (!selectedDevice || !audioDevices.some((device) => device.deviceId === selectedDevice)) {
      handleDeviceChange(audioDevices.length > 0 ? audioDevices[0].deviceId : undefined);
    } else if (selectedDevice === 'default') {
      handleDeviceChange(selectedDevice);
    }
  }, [audioDevices]);

  const handleDeviceChange = (deviceId: string | undefined) => {
    if (!deviceId) {
      setSelectedDevice(deviceId);
      return;
    }
    // if we pass deviceId === 'default' convert it to real device id
    changeMicDevice(getDeviceId(deviceId))
      .then(() => {
        setSelectedDevice(deviceId);
      })
      .catch(console.error);
  };

  const getDeviceId = (deviceId: string) => {
    let result = deviceId;
    if (result === 'default') {
      result = getDefaultMicId() || deviceId;
    }

    return result;
  };

  const handleSpeakerChange = (deviceId: string | undefined) => {
    if (!deviceId) {
      setSelectedSpeaker(deviceId);
      return;
    }
    // if we pass deviceId === 'default' convert it to real device id
    changeSpeakerDevice(getSpeakerId(deviceId))
      .then(() => {
        setSelectedSpeaker(deviceId);
      })
      .catch(console.error);
  };

  const getSpeakerId = (deviceId: string) => {
    let result = deviceId;
    if (result === 'default') {
      result = getDefaultSpeakerId() || deviceId;
    }

    return result;
  };

  const getDefaultMicId = () => {
    const defaultDevice = audioDevices.find((device) => device.deviceId === 'default');
    if (!defaultDevice) {
      return undefined;
    }

    // Get the ID of the MediaStreamTrack
    const defaultDeviceLabel = defaultDevice.label.replace(/^Default - /, '');
    const notDefaultDevice = audioDevices.find((device) => {
      return device.deviceId !== 'default' && device.label === defaultDeviceLabel;
    });

    if (!notDefaultDevice) {
      return undefined;
    }

    return notDefaultDevice.deviceId;
  };

  const getDefaultSpeakerId = () => {
    const defaultDevice = audioSpeakers.find((device) => device.deviceId === 'default');
    if (!defaultDevice) {
      return undefined;
    }

    // Get the ID of the MediaStreamTrack
    const defaultDeviceLabel = defaultDevice.label.replace(/^Default - /, '');
    const notDefaultDevice = audioSpeakers.find((device) => {
      return device.deviceId !== 'default' && device.label === defaultDeviceLabel;
    });

    if (!notDefaultDevice) {
      return undefined;
    }

    return notDefaultDevice.deviceId;
  };

  // Function to fetch audio devices
  const fetchDevices = async (skipPermissionCheck?: boolean): Promise<void> => {
    if (!isDevicesSupported) {
      return;
    }

    const devices = await AgoraRTC.getMicrophones(skipPermissionCheck);
    setAudioDevices(devices);
  };

  useEffect(() => {
    if (!isDevicesSupported) {
      return;
    }
    // Fetch devices initially
    // we skip permissions check because we want to trigger popup only when user really needs to use mic
    // and do not show it as soon as user opens the app
    fetchDevices(true).catch(console.error);
  }, [isDevicesSupported]);

  useEffect(() => {
    if (!isDevicesSupported) {
      return;
    }
    // Add event listener for device changes
    const handleDeviceChangeListener = () => {
      fetchDevices().catch(console.error);
    };
    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChangeListener);

    // Clean up event listener on unmount
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChangeListener);
    };
  }, [isDevicesSupported]);

  return (
    <MicrophoneDeviceContext.Provider
      value={{
        audioDevices,
        selectedDevice,
        setSelectedDevice: handleDeviceChange,
        fetchDevices,
        isDevicesSupported,
        audioSpeakers,
        selectedSpeaker,
        setSelectedSpeaker: handleSpeakerChange,
      }}
    >
      {children}
    </MicrophoneDeviceContext.Provider>
  );
};
