/* eslint-disable */
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { cardAnatomy } from '@chakra-ui/anatomy';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

export default defineMultiStyleConfig({
  baseStyle: {
    container: {
      borderRadius: 0,

      _dark: {
        background: 'black.1000',
        borderColor: 'whiteAlpha.200',
      },
      _light: {
        background: 'sandy.200',
        borderColor: 'blackAlpha.300',
      },
    },
  },
});
