import { Button } from '@chakra-ui/react';
import { DateObj, RenderProps } from 'dayzed';
import React, { useMemo } from 'react';
import { DatepickerProps, DayOfMonthBtnStyleProps } from '../types';

interface DayOfMonthProps extends DatepickerProps {
  renderProps: RenderProps;
  isInRange?: boolean | null;
  dateObj: DateObj;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const DayOfMonth: React.FC<DayOfMonthProps> = ({ dateObj, renderProps, onMouseEnter }) => {
  const { date, selected, selectable, today, nextMonth, prevMonth } = dateObj;
  const { getDateProps } = renderProps;

  const styleBtnProps: DayOfMonthBtnStyleProps = useMemo(
    () => ({
      defaultBtnProps: {
        w: '36px',
        h: '36px',
        mw: 'auto',
        background: 'transparent',
        borderColor: 'transparent',
        borderRadius: '0',
        border: 'none',
        fontFamily: 'body',
        fontWeight: 'regular',
        fontSize: 'md',
        lineHeight: '20px',
        _hover: {
          color: 'brandPrimary',
        },
        _active: {
          background: 'transparent',
        },
        _disabled: {
          pointerEvents: 'none',
          color: 'typographyTertiary',
        },
      },
      todayBtnProps: {
        background: 'backgroundInput',
      },
      nextMonthProps: {
        color: 'typographySecondary',
      },
      prevMonthProps: {
        color: 'typographyTertiary',
      },
      selectedBtnProps: {
        background: 'brandPrimary',
        color: 'white',
        _hover: {
          background: 'brandPrimary',
          color: 'white',
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectable]
  );

  return (
    <Button
      {...getDateProps({
        dateObj,
        disabled: !selectable,
        onMouseEnter,
      })}
      isDisabled={!selectable}
      {...styleBtnProps.defaultBtnProps}
      {...(selected && selectable && styleBtnProps.selectedBtnProps)}
      {...(today && styleBtnProps.todayBtnProps)}
      {...(today && selected && styleBtnProps.selectedBtnProps)}
      {...(nextMonth && styleBtnProps.nextMonthProps)}
      {...(nextMonth && selected && styleBtnProps.selectedBtnProps)}
      {...(prevMonth && styleBtnProps.prevMonthProps)}
    >
      {date.getDate()}
    </Button>
  );
};
