import { HStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { CardHeaderIndicator } from '../../TavernCard/CardHeaderIndicator';
import { CardHeaderIndicatorAnime } from '../../TavernCard/CardHeaderIndicatorAnime';
import { IconJoined, IconPast, IconTavernLogo } from '../../../theme/foundations/customIcons';
import { Tavern } from '../../../types/tavern';
import { formatDate } from '../../../utils/date';

interface ScheduledTavernStatsProps {
  tavern: Tavern;
  goingUsersCount: number;
}

export const ScheduledTavernStats = (props: ScheduledTavernStatsProps) => {
  const { tavern, goingUsersCount } = props;
  const { startTime, scoreMultiplier } = tavern;
  const [startDateFormatted, setStartDateFormatted] = useState<string>(formatDate(startTime));

  useEffect(() => {
    setStartDateFormatted(formatDate(startTime));
  }, [startTime]);

  return (
    <HStack spacing="24px">
      <CardHeaderIndicator icon={IconPast} label={startDateFormatted} />
      <CardHeaderIndicator icon={IconJoined} label={`${goingUsersCount}`} />
      {scoreMultiplier && (
        <CardHeaderIndicatorAnime
          icon={IconTavernLogo}
          label={`${scoreMultiplier}x`}
          tooltip="Roots Bonus Multiplier"
        />
      )}
    </HStack>
  );
};
