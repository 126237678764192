import { httpsCallable } from 'firebase/functions';
import { signInAnonymously, signInWithCustomToken } from 'firebase/auth';

import { auth, fbFunctions } from '../connect';

export const signIn = async (providerData: any, signer: string, account: string) => {
  const verifySigning = httpsCallable<
    {
      providerData: any;
      signer: string;
      account: string;
    },
    {
      token: string;
    }
  >(fbFunctions, 'verifySignedMessage');

  const verifySignResponse = await verifySigning({
    providerData,
    signer,
    account,
  });

  await signInWithCustomToken(auth, verifySignResponse.data.token);
};

export const signInFarcaster = async (signer: string, fid: string) => {
  const verifyFarcasterSigning = httpsCallable<
    {
      signer: string;
      fid: string;
    },
    {
      token: string;
    }
  >(fbFunctions, 'verifyFarcasterSigning');

  const verifyFarcasterSigningResponse = await verifyFarcasterSigning({
    signer,
    fid,
  });

  await signInWithCustomToken(auth, verifyFarcasterSigningResponse.data.token);
};

export const signInToken = async (token: any) => {
  await signInWithCustomToken(auth, token);
};

export const signInAnon = async () => {
  const anonUser = await signInAnonymously(auth);
  const createUser = httpsCallable<{ userId: string }>(fbFunctions, 'createAnonUser');
  await createUser({
    userId: anonUser.user.uid,
  });
};
