/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { createContext } from 'react';

import { ExternalAudio } from '../types/audio';
import { RecordedPlayState } from '../types/player';
import { Session, Tavern, Reaction, AudioClip, AnonResponse, Score, Gif } from '../types/tavern';

export default createContext({
  connectedTavern: null as Tavern | null,
  setConnectedTavern: (state: Tavern | null) => {},

  userSession: null as Session | null,
  setUserSession: (state: Session | null) => {},

  lastJoinedTavernCheckComplete: false as boolean,
  setLastJoinedTavernCheckComplete: (state: boolean) => {},

  hosts: [] as Session[],
  setHosts: (state: Session[]) => {},

  coHosts: [] as Session[],
  setCoHosts: (state: Session[]) => {},

  speakers: [] as Session[],
  setSpeakers: (state: Session[]) => {},

  joiners: [] as Session[],
  setJoiners: (state: Session[]) => {},

  listeners: [] as Session[],
  setListeners: (state: Session[]) => {},

  listenerCount: 0 as number,
  setListenerCount: (state: number) => {},

  playCount: 0 as number,
  setPlayCount: (state: number) => {},

  // keep current progress outside of RecordedPlayState
  // because it is refreshed every 100ms which prevents update of other
  // RecordedPlayState fields
  recordedTavernProgress: 0 as number,
  setRecordedTavernProgress: (value: number) => {},

  recordingProgressTimestamp: null as number | null,

  recordedPlayState: null as RecordedPlayState | null,
  setRecordedPlayState: (state: RecordedPlayState) => {},

  externalAudio: [] as ExternalAudio[],
  setExternalAudio: (state: ExternalAudio[]) => {},

  reactions: [] as Reaction[],
  setReactions: (state: Reaction[]) => {},

  gifs: [] as Gif[],
  setGifs: (state: Gif[]) => {},

  scores: [] as Score[],
  setScores: (state: Score[]) => {},

  audioClips: [] as AudioClip[],
  setAudioClips: (state: AudioClip[]) => {},

  anonResponses: [] as AnonResponse[],
  setAnonResponses: (state: AnonResponse[]) => {},
});
