import { FC, useContext, useEffect, useRef } from 'react';

import { VisuallyHiddenInput } from '@chakra-ui/react';
import GlobalPlayerContext from '../../context/global-player-context';
import { IconLogOut } from '../../theme/foundations/customIcons';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';
import { StreamActionButton } from '../uiKit/StreamActionButton/StreamActionButton';
import { TavernTooltip } from '../uiKit/TavernTooltip/TavernTooltip';
import { useTavernToast } from '../hooks/useTavernToast';

interface ShareTavernButtonProps {
  isCollapsed?: boolean;
}

export const ShareTavernButton: FC<ShareTavernButtonProps> = (props) => {
  const { isCollapsed } = props;
  const toast = useTavernToast();
  const { recordedTavernProgress } = useContext(GlobalPlayerContext);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const isAndroid = /Android/i.test(navigator.userAgent);

  useEffect(() => {
    if (isAndroid && inputRef.current) {
      inputRef.current.value = `${window.location.href.split('?')[0]}?t=${recordedTavernProgress.toFixed(2)}`;
    }
  }, [recordedTavernProgress, isAndroid]);

  const handleShare = async () => {
    const shareText = `${window.location.href.split('?')[0]}?t=${recordedTavernProgress.toFixed(2)}`;
    const shareToast = () => {
      toast({
        title: `Link copied at ${recordedTavernProgress.toFixed(2)} seconds.`,
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    };
    // Fallback for metamask android
    if (isAndroid && inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
      shareToast();
    } else {
      await navigator.clipboard.writeText(shareText);
      shareToast();
    }
  };

  if (isCollapsed) {
    return (
      <>
        {isAndroid && <VisuallyHiddenInput ref={inputRef} />}
        <TavernTooltip label="Share at time">
          <StreamActionButton
            flexShrink="0"
            aria-label="Share at time"
            icon={<IconLogOut boxSize="24px" transform="rotate(90deg)" />}
            onClick={handleShare}
          />
        </TavernTooltip>
      </>
    );
  }

  return (
    <>
      {isAndroid && <VisuallyHiddenInput ref={inputRef} />}
      <TavernButton leftIcon={<IconLogOut transform="rotate(90deg)" />} variant="secondary" onClick={handleShare}>
        Share at time
      </TavernButton>
    </>
  );
};
