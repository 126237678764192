import _ from 'lodash';
import { Session, SessionType } from '../types/tavern';
import { UserMock } from '../types/user';

export const sessionIsType = (session: Session | null, type: SessionType) => {
  if (!session) {
    return false;
  }
  return session.type === type;
};

export const sessionIsOneOfType = (session: Session | null, types: SessionType[]) => {
  if (!session) {
    return false;
  }
  return types.includes(session.type);
};

export const hasJoined = (session: Session | null) => {
  if (!session) {
    return false;
  }
  return session.type !== SessionType.Listener;
};

export const hasSpeakingPermissions = (session: Session | null) => {
  return sessionIsOneOfType(session, [SessionType.Host, SessionType.CoHost, SessionType.Speaker]);
};

export const hasHostingPermission = (session: Session | null) => {
  return sessionIsOneOfType(session, [SessionType.Host, SessionType.CoHost]);
};

export const sessionAsUserMock = (session: Session): UserMock => {
  return {
    id: session.id,
    name: session.name ?? session.user.id,
    sessionType: session.type,
    avatar: session.avatar,
    session,
  };
};

export const sessionsFilteredForReplay = (
  sessions: Session[],
  displayLiveData: boolean,
  recordingProgressTimestamp: number | null,
  offset: number
): Session[] => {
  if (!displayLiveData && recordingProgressTimestamp === null) {
    return _.uniqBy(sessions, (s) => s.user.id);
  }

  if (displayLiveData || recordingProgressTimestamp === null) {
    return sessions;
  }

  const filteredSessions = sessions.filter((session) => {
    return (
      session.startTime - offset * 1000 <= recordingProgressTimestamp &&
      (session.endTime === null || session.endTime === undefined || session.endTime > recordingProgressTimestamp)
    );
  });

  return _.uniqBy(filteredSessions, (s) => s.user.id);
};
