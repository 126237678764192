import { createContext } from 'react';
import { UserNft, ProdUser } from '../types/user';

export default createContext({
  user: {} as ProdUser | null,
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  setUser: (state: ProdUser | null) => {},
  userNFTs: [] as UserNft[],
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  setUserNFTs: (state: UserNft[]) => {},
  userTaverns: {} as any,
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  setUserTaverns: (state: any) => {},
});
