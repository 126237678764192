import { Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark } from '@chakra-ui/react';
import { noop, isNaN } from 'lodash';
import { TavernSliderProps } from './types';

export const TavernSlider = (props: TavernSliderProps) => {
  const {
    value,
    defaultValue = 0,
    isDisabled,
    onChange = noop,
    onChangeStart = noop,
    onChangeEnd = noop,
    orientation = 'horizontal',
    hasMark,
    size,
    max,
  } = props;

  return (
    <Slider
      variant={isDisabled ? 'disabled' : 'default'}
      isDisabled={isDisabled}
      onChange={onChange}
      onChangeStart={onChangeStart}
      onChangeEnd={onChangeEnd}
      focusThumbOnChange={false}
      defaultValue={defaultValue}
      value={isNaN(value) ? 0 : value}
      max={max}
      h="10px"
      size={size}
      orientation={orientation}
    >
      {hasMark && <SliderMark value={50} className="slider-mark-left" />}
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb />
      {hasMark && <SliderMark value={50} className="slider-mark-right" />}
    </Slider>
  );
};
