import { useNavigate } from 'react-router-dom';

import { IconRevert } from '../../theme/foundations/customIcons';
import { Tavern } from '../../types/tavern';
import { TavernButton } from '../uiKit/TavernButton/TavernButton';
import { auth } from '../../connect';

export const EditTavernButton = (props: { tavern: Tavern }) => {
  const navigate = useNavigate();

  if (props.tavern.host.id !== auth.currentUser?.uid) {
    return <></>;
  }

  return (
    <TavernButton
      leftIcon={<IconRevert />}
      variant="secondary"
      onClick={() => {
        navigate('edit');
      }}
      mr="8px"
    >
      Edit
    </TavernButton>
  );
};
